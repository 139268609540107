import { action, makeObservable, observable, reaction } from "mobx";
import { QuestionType, FormType } from "../../common/types/question";
import { SiteObject } from "../../common/types/site";
import { DashboardService } from "../services/DashboardService";

export class DashboardStore {

    allForms: FormType[] = []
    allQuestions: QuestionType[] = []
    allSites: SiteObject[] = []
    site: SiteObject = {}
    siteId: string = ""
    isLoading: boolean = false
    constructor(private dashboardServices: DashboardService) {
        makeObservable(this, {
            allForms: observable,
            allQuestions: observable,
            allSites: observable,
            site: observable,
            siteId: observable,
            isLoading: observable,
            setIsLoading: action.bound,
            setSelectedSite: action.bound,
            setAllSites:action
        });

        reaction(() => this.siteId, (state) => {
            this.fetchSite(this.siteId)
        })

        this.initialize();

    }

    async initialize() {
        this.loadSites()
        this.allforms()
    }

    async allforms() {
        let _question: QuestionType[] = []
        const _forms = await this.dashboardServices.getAllForms();
        _forms.forEach(form => {
            form.subs!.forEach(sub => {
                const subQuestion = sub.questions ? sub.questions : []
                subQuestion.forEach(q => {
                    _question.push(q)
                    if (q.followUps && q.followUps.length > 0)
                        _question = [..._question, ...q.followUps]
                });
            });
        });
        this.allForms = _forms
        this.allQuestions = _question
    }

    setIsLoading(flag: boolean) {
        this.isLoading = flag
    }

    async fetchSite(siteId: string) {
        this.setIsLoading(true)
        this.dashboardServices.getSite(siteId).then((response) => {
            this.setSelectedSite(response)
            this.setIsLoading(false)
        })
    }

    async loadSites() {
        this.dashboardServices.getSites().then((response) => {
            this.setAllSites(response);
        });
    }

    setSelectedSite(item: SiteObject) {
        this.site = item;
    }

    setAllSites(items: SiteObject[]) {
        this.allSites = items;
    }


}

export function genId(): string {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
}
