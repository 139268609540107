
import { convertInchesToTwip, Paragraph, TableCell, VerticalAlign } from "docx";
import { QuestionType } from "../../common/types/question";
import { CellWriter, findQuestion, NO_INFO } from "../utility";

const docx = require("docx");
const { Table, TableRow, } = docx;


type freezerSummary = {
    description?: string
    response?: string
    alert?: string
}

type freezerQs = {
    questionIds: string[]
}

export const SummarizeFreezers = (results: any, questions: QuestionType[]): freezerSummary[] => {
    var result: freezerSummary[] = []

    freezerQsMap.map(trail => (
        result.push({
            description: findQuestion(questions, trail.questionIds[0]) as string,
            response: results[trail.questionIds[0]] ? results[trail.questionIds[0]] : NO_INFO,
            alert: results[trail.questionIds[1]] ? results[trail.questionIds[1]] : NO_INFO

        })
    ))
    return result
}

const freezerQsMap: freezerQs[] = [
    {
        questionIds: ["e626", "0829"]
    },
    {

        questionIds: ["69d5", "0829"]
    },
    {

        questionIds: ["129e", "0829"]
    },
    {

        questionIds: ["cfb7", "0829"]
    }
]


export const _3_4_2_FreezerTable = (counts: freezerSummary[]) => new Table({
    rows: [
        new TableRow({
            children: [
                new TableCell({
                    children: [new Paragraph({ text: "Fridges/Freezers on site" })],
                    verticalAlign: VerticalAlign.CENTER,
                    margins: {
                        top: convertInchesToTwip(0.1),
                        bottom: convertInchesToTwip(0.1),
                        left: convertInchesToTwip(0.2),
                        right: convertInchesToTwip(0.2),
                    },
                    shading: {
                        color: "00FFFF",
                        fill: "#A4CA39",
                    } 
                }),
                new TableCell({
                    children: [new Paragraph({ text: "Number" })],
                    verticalAlign: VerticalAlign.CENTER,
                    margins: {
                        top: convertInchesToTwip(0.1),
                        bottom: convertInchesToTwip(0.1),
                        left: convertInchesToTwip(0.2),
                        right: convertInchesToTwip(0.2),
                    },
                    shading: {
                        color: "00FFFF",
                        fill: "#A4CA39",
                    }, 
                }),
                
                new TableCell({
                    children: [new Paragraph({ text: "Automatic warning if temperature deviates" })],
                    verticalAlign: VerticalAlign.CENTER,
                    margins: {
                        top: convertInchesToTwip(0.1),
                        bottom: convertInchesToTwip(0.1),
                        left: convertInchesToTwip(0.2),
                        right: convertInchesToTwip(0.2),
                    },
                    shading: {
                        color: "00FFFF",
                        fill: "#A4CA39",
                    }, 
                }),

            ],
        })].concat(

            counts.map(count => new TableRow({
                children: [
                    CellWriter(count.description),
                    CellWriter(count.response?.toString()),
                    CellWriter(count.alert?.toString()),
                ]
            }))
        )

})
