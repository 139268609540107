import React from 'react'
import { EntryRatingEnum } from '../types/site'

type svgProps = {
    fill: string
    switchStar: (rating: EntryRatingEnum) => void
}
function StarSVG({ fill, switchStar }: svgProps) {
    let stroke = 'gray'
    let _fill = 'none'
    if (fill.includes('red')) {
        _fill = 'red'
    }
    if (fill.includes('#FFBF00')) {
        _fill = '#FFBF00'
    }
    if (fill.includes('green')) {
        _fill = 'green'
    }

    return (
        <div className='flex'>
            <svg xmlns="http://www.w3.org/2000/svg" className="cursor-pointer h-5 w-5 border-none" fill={_fill} viewBox="0 0 24 24" stroke={stroke} strokeWidth={2} onClick={() => switchStar(EntryRatingEnum.RED)}>
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" className="cursor-pointer h-5 w-5 border-none" fill={(_fill === ('red')) ? 'none' : _fill} viewBox="0 0 24 24" stroke={stroke} strokeWidth={2} onClick={() => switchStar(EntryRatingEnum.AMBER)}>
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" className="cursor-pointer h-5 w-5 border-none" fill={_fill === 'green' ? _fill : 'none'} viewBox="0 0 24 24" stroke={stroke} strokeWidth={2} onClick={() => switchStar(EntryRatingEnum.GREEN)}>
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
        </div>

    )
}

export default StarSVG
