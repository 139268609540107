import { QuestionListStore } from "./QuestionListStore";
import { AdminFormsListStore } from "./QuestionSectionListStore";
import { RootStore } from "../../../store/types/rootstore";
import { AdminService } from "../services/AdminService";
import { NewQuestionStore } from "./NewQuestionStore";
import { AdministratorsStore } from "./AdministratorStore";
import { AdminViews } from "./AdminViewStore";
import { AdminSites } from "./AdminSites";

export type AdminStoreTypes = {
  newQuestionStore?: NewQuestionStore;
  adminQuestionsStore?: QuestionListStore;
  adminQuestionSectionsStore?: AdminFormsListStore;
  administratorStore?:AdministratorsStore;
  adminViewStore?:AdminViews;
  adminSites?:AdminSites
};

export function injectAdminIntoRoot(root: RootStore) {
  const service = new AdminService();
  root.adminQuestionsStore = new QuestionListStore(service);
  root.adminQuestionSectionsStore = new AdminFormsListStore(service);
  root.newQuestionStore = new NewQuestionStore(service);
  root.administratorStore = new AdministratorsStore(service)
  root.adminViewStore = new AdminViews()
  root.adminSites = new AdminSites(service)
}
