

import { DONT_NO, YES } from "../../common/constants";
import { TextFiller, TextWriter, YesNoFiller } from "../utility";

const docx = require("docx");
const { Paragraph, TextRun } = docx;

export const _3_7_1_EthicsRequirements = (iecName: string, isCentral:string, hasWrittenProc:string, compositeName:string, usuallyHaveQs:string, questionType:string, reqAdvice:string, studyList:string, hasToBePresent:string) => new Paragraph({
    children: [
      
        new TextRun({ text: "\n", break: 2 }),

        TextWriter(`You routinely use `),
        TextWriter(TextFiller(iecName)),
        TextWriter(` and this`),
        TextWriter(YesNoFiller(isCentral, ' is ',' is not')),
        TextWriter(` central IEC`),
        TextWriter(`. They`),
        TextWriter(YesNoFiller(hasWrittenProc, ' do have ',' do not have ')),
        TextWriter(`written procedures in place and the composition is `),
        TextWriter(TextFiller(compositeName)),
        TextWriter(` You reported that`),
        TextWriter(YesNoFiller(usuallyHaveQs, ' it is ',' it is not')),
        TextWriter(`usual for your IEC to have questions and/or ask for additional information requiring re-submission, and that these questions most commonly relate to `),
        TextWriter(TextFiller(questionType)),
        TextWriter(` You advised `),
        TextWriter(TextFiller(reqAdvice === YES ? studyList: DONT_NO)),
        TextWriter(` regarding study design may impact recruitment and/or a favourable ethics opinion. The lead investigator at the site`),
        TextWriter(YesNoFiller(hasToBePresent, ' is ',' is not ')),
        TextWriter(`required to be present at the IEC meeting when the trial is discussed
        `),
        new TextRun({ text: "\n", break: 1 }),

        TextWriter(`Table 12 below provides the usual timelines for the IEC you use at your site`),
        new TextRun({ text: "\n", break: 2 }),
       
    ], spacing: {
        line: 360,
    },
})
