import { genId } from "../../admin/store/NewQuestionStore";
import { SiteActivityType, SiteObject } from "./site";



var today = new Date();
export var GMT = today.toUTCString()

var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();

export var FILE_DATE = mm + '_' + dd + '_' + yyyy;

export const SiteCreatedActivity = (description: string, siteId: string, user: string): SiteActivityType => {
  return {
    id: `${genId()}_${GMT}`,
    detail: `Site(${description}: (${siteId})) was successfully created`,
    timeline: GMT,
    title: "CREATED NEW SITE",
    user: user
  }
}

export const SiteQuestionAnswered = (site: SiteObject, user: string): SiteActivityType => {
  return {
    id: `${genId()}_${GMT}`,
    detail: `Site(${site.description?.institutionName}: (${site.description?.siteId})) answers were updated`,
    timeline: GMT,
    title: "SITE QUESTIONS ANSWERED",
    user: user
  }
}

export const SiteStatusChanged = (site: SiteObject, user: string): SiteActivityType => {
  return {
    id: `${genId()}_${GMT}`,
    detail: `Site(${site.description?.institutionName}: (${site.description?.siteId})) status changed to ${site?.meta?.status}`,
    timeline: GMT,
    title: "SITE STATUS CHANGED",
    user: user
  }
}

export const SiteAddTeamMember = (site: SiteObject, user: string): SiteActivityType => {
  return {
    id: `${genId()}_${GMT}`,
    detail: `Site(${site.description?.institutionName}: (${site.description?.siteId})) added a new team member`,
    timeline: GMT,
    title: "NEW TEAM MEMBER ADDED",
    user: user
  }
}

export const SiteTeamMemberUpdate = (site: SiteObject, user: string, member: string): SiteActivityType => {
  return {
    id: `${genId()}_${GMT}`,
    detail: `Site(${site.description?.institutionName}: (${site.description?.siteId})) updated a team member ${member} profile`,
    timeline: GMT,
    title: "TEAM MEMBER RECORD UPDATED",
    user: user
  }
}