
import { ParagraphBreaker} from "../utility";
import { Bullet0Para, Bullet1Para,Bullet2Para } from "./methodology";

export const Objectives = () =>  [

 
        Bullet0Para("The overall objectives of this assessment are:"),
        ParagraphBreaker(),
     
        Bullet1Para("To assess the site’s readiness for participation in commercially funded clinical trials according to ICH GCP, local and global requirements"),
        ParagraphBreaker(),
        Bullet1Para("To address the topics listed below and provide the summary of information for your site"),
        Bullet2Para("Provide an overview of site resources for performing such trials"),
        Bullet2Para("Provide an insight into facilities for managing patients e.g. lab services, specific assessments such as CT, pharmacy")   ,
        Bullet2Para("To summarise the type of site, and the research setting"),
        Bullet2Para("Provide outline of ethics and regulatory requirement leading to estimated timelines for study start up ")     ,
        Bullet2Para("Provide information on import and export regulations for IMP and biological samples"),
        Bullet2Para("Provide identification of key therapeutic areas covered by each site"),
        Bullet2Para("Identify key interests for clinical trials"),
        Bullet1Para("To provide a gap analysis and eMQT recommendations on how to fill these gaps")

    ]
