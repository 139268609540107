
import React from 'react'
import { SavedSearchQuery } from '../../types'
import NewDialog from './dialog'

type addFormProps = {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  save: any
  setQuery: React.Dispatch<React.SetStateAction<SavedSearchQuery>>
  query: SavedSearchQuery
}

function SaveQuery({ isOpen, setIsOpen, save, setQuery, query }: addFormProps) {

  function handleSave(): void {
    save(query);
    setIsOpen(false)
  }

  return (
    <NewDialog title="Save Query" isOpen={isOpen} setIsOpen={setIsOpen} >
      <div className="flex space-y-2 flex-col overflow-auto">
        <input className='w-full p-2' placeholder='Set title' onChange={(e) => setQuery({ ...query, title: e.target.value })} />
        <button className="p-2 px-2 bg-atomGreen rounded text-gray-100 font-semibold" onClick={() => handleSave()}> Save</button>

      </div>
    </NewDialog>
  )
}

export default SaveQuery



