
import { getAuth } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { RootFire } from './types';

const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,// "",
  authDomain:  process.env.REACT_APP_FIREBASE_AUTH_DOMAIN, //"",
  projectId:  process.env.REACT_APP_FIREBASE_PROJECT_ID,// "",
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,// "",
  messagingSenderId:  process.env.REACT_APP_FIREBASE_MESSAGEING_SENDER_ID, //"",
  appId: process.env.REACT_APP_FIREBASE_APP_ID,// "",
  measurementId:  process.env.REACT_APP_FIREBASE_MEASURE_ID//, ""
};


export const setupFireStore = (): RootFire => {
  const firebaseApp = firebase.initializeApp(FIREBASE_CONFIG);
  return {
    Auth: getAuth(firebaseApp),
    FirebaseStorage: getStorage(firebaseApp),
    Firestore: getFirestore(firebaseApp)
  }
}