import React from 'react'
import { SiteActivityType } from '../../../common/types/site'

type activityProps = {
    logs: SiteActivityType[] | undefined
}
function Activity({ logs }: activityProps) {

    const allLogs = logs || []
    return (
        <div className='bg-white pb-5'>
            <h4 className='text-lg text-gray-500 p-2'>Notifications</h4>

            <div>
                {allLogs.map(log => (<div className='px-2 my-2 py-1'>
                    <h4 className='text-xs'><span className='font-bold uppercase text-xs'>{log.timeline} : </span> {log.title} by <span className='text-atomGreen text-xs'> {log.user}</span></h4>
                    <p className='text-xs text-gray-400'>{log.detail}</p>
                </div>))}
            </div>

     
        </div>
    )
}

export default Activity
