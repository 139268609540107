import React from 'react'
import { QuestionnaireType } from '../common/types/question'

type QAChildProps = {
    qa: QuestionnaireType,
    setQA: React.Dispatch<React.SetStateAction<QuestionnaireType>>
}

function LongQA({ qa, setQA }: QAChildProps) {
    const { question } = qa
    function handleEditMoreListItemAddFrequency(index: number, e: React.ChangeEvent<HTMLSelectElement>, res: string): void {
        let holder = qa!.response as string[] || []
        holder[index] = holder[index] + '_' + e.target.value
        setQA({ ...qa, response: holder })
    }
    function handleEditMoreListItem(index: number, e: React.ChangeEvent<HTMLInputElement>, res: string): void {
        let holder = qa!.response as string[] || []
        holder[index] = e.target.value
        setQA({ ...qa, response: holder })
    }
   
    return (
        <div className="flex w-full my-2 py-2" key={question.sectionId + question.questionId}>
            <p className="font-bold self-center w-6/12"> {question.statement}</p>
            <div className="w-1/12"></div>
            <section className={"flex-1 self-center"}>
                {[...qa.response as string[]].map((res, index) =>
                    <div key={index} className='flex'>
                        <input className="rounded w-full p-2 border flex-1" placeholder="Answer here" onChange={(e) => handleEditMoreListItem(index, e, res)} value={res.split('_')[0]} />
                        <select title='frequency' name='frequency' onChange={(e) => handleEditMoreListItemAddFrequency(index, e, res)} className='flex-1 border border-gray-100' >
                            <option defaultValue="" value="">Choose Frequency</option>
                            <option selected={res.split('_')[1] === 'WEEKLY'} value="WEEKLY">WEEKLY</option>
                            <option selected={res.split('_')[1] === 'MONTHLY'} value="MONTHLY">MONTHLY</option>
                            <option selected={res.split('_')[1] === 'QUARTERLY'} value="QUARTERLY">QUARTERLY</option>
                            <option selected={res.split('_')[1] === 'YEARLY'} value="YEARLY">YEARLY</option>
                        </select>
                    </div>
                )}
                <div className="flex">
                    <button className="p-1 px-3 rounded mt-1 border bg-green-50" onClick={() => setQA({ ...qa, response: [...qa.response as string[], ""] })}>Add New</button>
                </div>
            </section>
        </div>
    )
}

export default LongQA




