

import { NO } from "../../common/constants";
import {  TextFiller, TextWriter, YesNoFiller } from "../utility";

const docx = require("docx");
const { Paragraph, TextRun } = docx;

export const _3_6_2_SampleMgtA = (canMgtPK: string, pKDays: string, tubeSizes: string, otherTube:string ) => new Paragraph({
    children: [

        TextWriter(`The lab`),
        TextWriter(YesNoFiller(canMgtPK, ' is', ' is not ')),
        TextWriter(`able to manage PK or PD samples 24/7`),
        TextWriter(canMgtPK === NO ? ` but can accommodate ${(pKDays +" ").replaceAll(",",", ")}.` : ''),
        new TextRun({ text: "\n", break: 2 }),

        TextWriter(`The Tube sizes you routinely use are`),
        TextWriter(TextFiller(tubeSizes? tubeSizes.toString().replace("Other",otherTube): "").replaceAll(",",", ")),
        
        new TextRun({ text: "\n", break: 2 }),

        TextWriter(`Table 9 below provides information on the shipment requirements at your site: `),

        new TextRun({ text: "\n", break: 2 })

    ], spacing: {
        line: 360,
    },
})
