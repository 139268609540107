import React, { useState } from 'react'
import { searchKey } from '../../screens/find/keys'
import { SearchQuery } from '../../types'
import NewDialog from './dialog'
import Select from 'react-select';

type addFormProps = {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    save: any
}

function AddSearchCriterial({ isOpen, setIsOpen, save }: addFormProps) {

    const [search, setSearch] = useState<SearchQuery>({})

    function handleSave(): void {
        if (!search.key || !search.value) {
            return
        }
        save(search);
        setIsOpen(false)
    }


    const options = []

    const keys: {
        key?: string,
        value?: any
    }[] = [{}]

    for (let [key, value] of searchKey) {
        keys.push({ key: key, value: value })
        options.push({ label: value, value: key })
    }




    return (
        <NewDialog title="Add Search Query" isOpen={isOpen} setIsOpen={setIsOpen} >
            <div className="flex space-y-2 flex-col overflow-auto">
                <Select options={options}
                    isMulti={false}
                    placeholder='Choose Search Key'
                    maxMenuHeight={125}
                    onChange={e => setSearch({ ...search, key: e?.value })}
                    className="z-50"
                />
                <input placeholder="Value." value={search.value} onChange={(e) => { setSearch({ ...search, value: e.target.value }) }} className="p-2 bg-white w-full focus:outline-none border" />
                <div className="h-1"></div>
                <button className="p-2 px-2 bg-atomGreen rounded text-gray-100 font-semibold" onClick={() => handleSave()}> Save</button>
            </div>
        </NewDialog>
    )
}

export default AddSearchCriterial



