import { XCircleIcon } from '@heroicons/react/outline'
import React from 'react'

type notificationProps ={
    visible: boolean
    note?:string
}

function Index({visible, note}:notificationProps) {
    return (
        <div className={' bg-red-100 rounded-md px-5 py-2 font-medium flex justify-between ' + (visible === false && 'hidden')}>
            <h4>{note}</h4>
            <XCircleIcon className='w-4 h-4'/>
        </div>
    )
}

export default Index
