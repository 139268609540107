import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect, useRouteMatch, useHistory } from "react-router-dom";
import AdminHome from '../../../modules/admin/screens/AdminHome';
import AdminSites from '../../../modules/admin/screens/AdminSites';
import AdminWiki from '../../../modules/admin/screens//AdminWiki';
import FormQuestions from '../../../modules/admin/screens/AdminQuestions/FormQuestions';
import AdminForms from '../../../modules/admin/screens/AdminQuestions/AdminForms';
import Sidebar from './sidebar';
import AdminAdministrators from '../../../modules/admin/screens/AdminAdministrator/AdminAdministrators';
import { useStore } from '../../../store/StoreProvider';

function Admin() {
    let match = useRouteMatch();
    let history = useHistory()
    const {  userStore } = useStore();

    userStore?.initialize().then(() => {
        if (userStore?.isAdmin === false) {
            history.push("/sites")
        }
    })


    return (
        <Router>
            <div className="">
                <div className="h-full w-full flex overflow-hidden">
                    <Sidebar />
                    <div className="relative flex flex-1 overflow-y-auto overflow-x-hidden">
                        <Switch>
                            <Route path={`${match.path}/dashboard`} render={() => <AdminHome />} />
                            <Route path={`${match.path}/forms`} render={() => <AdminForms />} />
                            <Route path={`${match.path}/form/:id`} render={() => <FormQuestions />} />
                            <Route path={`${match.path}/administrators`} render={() => <AdminAdministrators />} />
                            <Route path={`${match.path}/sites`} render={() => <AdminSites />} />
                            <Route path={`${match.path}/wiki`} render={() => <AdminWiki />} />
                            <Redirect to="/admin/dashboard" from="/admin" />
                        </Switch>
                    </div>
                </div>
           </div>
        </Router>
    )
}

export default Admin

