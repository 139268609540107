import { action, makeObservable, observable, reaction } from "mobx";
import { autorun } from "mobx";
import { AdminService } from "../../modules/admin/services/AdminService";
import { Administrator } from "../../modules/common/types/admin";
import { UserObjectType } from "../../modules/common/types/user";
import { getToken, setToken } from "../localsotage";
import { RootStore } from "./rootstore";



export class UserStore {
  @observable
  currentUser: UserObjectType = {};

  @observable
  isLoading: boolean = false;

  @observable
  isAuthenticated: boolean = false;


  @observable
  isAdmin: boolean = false;

  @observable
  admins: Administrator[] = []


  constructor(private adminServices: AdminService) {

    makeObservable(this);
    this.initialize();

    autorun(() => {
      this.loadCurrentUser();
      let _isAdmin = false
      let user = this.currentUser
      if (!user) {
        this.setIsAdmin(_isAdmin)
        return
      }
      let result = this.admins.find(({ email }) => email?.toLowerCase() === user!.email?.toLowerCase());
      if (!result) {
        this.setIsAdmin(_isAdmin)
        return
      }
      _isAdmin = true
      this.setIsAdmin(_isAdmin)
    });

    reaction(() => this.currentUser, (state) => {
      let _isAdmin = false
      let user = this.currentUser
      if (!user) {
        this.setIsAdmin(_isAdmin)
        return
      }
      let result = this.admins.find(({ email }) => email?.toLowerCase() === user!.email?.toLowerCase());
      if (!result) {
        this.setIsAdmin(_isAdmin)
        return
      }
      _isAdmin = true
      this.setIsAdmin(_isAdmin)
    })
  }

  async initialize() {
    const admins = await this.adminServices.fetchAllAdmins()

    this.setAdmins(admins)
    this.getUserFromLocalStorage()
  }

  @action
  setIsLoading(flag: boolean) {
    this.isLoading = flag;
  }

  @action
  setIsAuthenticated(flag: boolean) {
    this.isAuthenticated = flag;
  }
  @action
  setIsAdmin(flag: boolean) {
    this.isAdmin = flag;
  }
  @action
  setAdmins(items: Administrator[]) {
    this.admins = items;
  }

  @action
  setCurrentUser(items: UserObjectType) {
    this.currentUser = items;
  }

  @action
  setUser(user: UserObjectType) {
    setToken(user);
    this.currentUser = user;
    this.setIsAuthenticated(true)
  }

  async loadCurrentUser(): Promise<void> {
    this.setIsLoading(true);
    // Check user token stored in local storage
    try {
    } catch (error) {
    } finally {
      this.setIsLoading(false);
    }
  }

  getIsAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  getUserFromLocalStorage() {
    let result: boolean = false;
    let user = getToken();
    if (user && user !== "") {
      this.setIsAuthenticated(true);

      let result = this.admins.find(({ email }) => email?.toLowerCase() === user!.email?.toLowerCase());
      if (!result) {
        this.setIsAdmin(false)
      } else {
        this.setIsAdmin(true);
      }
      this.setCurrentUser(user)
    }
    return result;
  }
}

export type UerStoreType = {
  userStore?: UserStore;
};

export const injectUserIntoRoot = (root: RootStore) => {
  const adminService = new AdminService();
  root.userStore = new UserStore(adminService);
};
