import React, { useEffect, useState } from 'react'
import { useStore } from '../../../../../store/StoreProvider';
import { ref, listAll, getDownloadURL, StorageReference } from "firebase/storage";
import { setupFireStore } from '../../../../../firebase/firebase.config';


type cvFile = {
    name?: string;
    path?: string
}

function Index() {
    const { sitesListSore: siteSore } = useStore();
    const { FirebaseStorage: storage } = setupFireStore()

    const site = siteSore?.selectedSite
    const [cv, setCvs] = useState<cvFile[]>([])
    const [gcp, setGcp] = useState<cvFile[]>([])
    const [edc, setEdc] = useState<cvFile[]>([])
    const [res, setRes] = useState<cvFile[]>([])

    // Create a reference under which you want to list
    const cvRefs = ref(storage, `${site?.description?.siteId}/cv`);
    const gcpRefs = ref(storage, `${site?.description?.siteId}/gcp`);
    const edcRefs = ref(storage, `${site?.description?.siteId}/edc`);
    const resRefs = ref(storage, `${site?.description?.siteId}/q`);

    let cvs: cvFile[] = []
    let gcps: cvFile[] = []
    let edcs: cvFile[] = []
    let ress: cvFile[] = []

    // Find all the prefixes and items.
    const getContent = async (ref: StorageReference, files: cvFile[]) => {
        await listAll(ref)
            .then((res) => {
                res.prefixes.forEach((folderRef) => {
                });
                res.items.forEach((itemRef) => {
                    files.push({ name: itemRef.name, path: itemRef.fullPath })
                });
            }).catch((error) => {
                console.log(error)
                // Uh-oh, an error occurred!
            });
    }

    const downloadCV = (path: string) => {
        getDownloadURL(ref(storage, path))
            .then((url) => {
                // This can be downloaded directly:
                const xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.onload = (event) => {
                };
                xhr.open('GET', url);
                xhr.send();
                window.open(url, '_blank');

            })
            .catch((error) => {
                console.log(error)
                // Handle any errors
            });
    }
    useEffect(() => {
        getContent(gcpRefs, gcps).then(() => {
            setGcp(gcps)
        })

        getContent(cvRefs, cvs).then(() => {
            setCvs(cvs)
        })

        getContent(edcRefs, edcs).then(() => {
            setEdc(edcs)
        })
        getContent(resRefs, ress).then(() => {
            setRes(ress)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) 

    return (
        <div>

            <div className="z-40 bg-white flex-1">
                <div className=" border-atomGreen mx-1">
                    <div className="p-10">
                        <h6 className="p-1 px-3 -mx-2 text-lg bg-gray-100 text-gray-300">Curricula vitae</h6>
                        <table className="w-full mt-5 mb-2">
                            <thead>
                                <tr className="font-bold text-sm px-3">
                                    <td className="px-3">Name</td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody className="text-xs">
                                {cv.map((cv, i) => <tr key={i + "cv"}>
                                    <td className="px-3"> <div className="border-l-2 border-green-100  w-full py-1"> <p className="px-3">{cv.name}</p>
                                    </div></td>
                                    <td className="py-1 px-3"> <button className="px-5 bg-gray-100 py-1" onClick={() => { downloadCV(cv.path!) }}>View File</button> </td>
                                </tr>)}

                            </tbody>
                        </table>
                    </div>

                    <div className="p-10">
                        <h6 className="p-1 px-3 -mx-2 text-lg bg-gray-100 text-gray-300">Certificates and Documents</h6>


                        <table className="w-full mt-5 mb-2">
                            <thead>
                                <tr className="font-bold text-sm px-3">
                                    <td className="px-3">Name</td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody className="text-xs">
                                {edc.map((cv, i) => <tr key={i + "edc"}>
                                    <td className="px-3"> <div className="border-l-2 border-green-100  w-full py-1"> <p className="px-3">{cv.name}</p>
                                    </div></td>
                                    <td className="py-1 px-3"> <button className="px-5 bg-gray-100 py-1" onClick={() => { downloadCV(cv.path!) }}>View File</button> </td>
                                </tr>)}
                                {gcp.map((cv, i) => <tr key={i + "gcp"}>
                                    <td className="px-3"> <div className="border-l-2 border-green-100  w-full py-1"> <p className="px-3">{cv.name}</p>
                                    </div></td>
                                    <td className="py-1 px-3"> <button className="px-5 bg-gray-100 py-1" onClick={() => { downloadCV(cv.path!) }}>View File</button> </td>
                                </tr>)}
                                {res.map((cv, i) => <tr key={i + "res"}>
                                    <td className="px-3"> <div className="border-l-2 border-green-100  w-full py-1"> <p className="px-3">{cv.name}</p>
                                    </div></td>
                                    <td className="py-1 px-3"> <button className="px-5 bg-gray-100 py-1" onClick={() => { downloadCV(cv.path!) }}>View File</button> </td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Index

