import { observer } from 'mobx-react';
import React, { useState } from 'react'
import { useStore } from '../../../../../store/StoreProvider';
import { TeamMemberType } from '../../../../common/types/site';
import EditTeamMember from './EditTeamMember';

function TeamMembersList() {
    const [open, setOpen] = useState(false)

    const { sitesListSore: siteSore } = useStore();

    const [editTm, setEditTm] = useState<TeamMemberType | undefined>(undefined)

    const staff = siteSore!.selectedSite.accessToStaff!.tms || []
    return (
        <div>
            <div className="flex w-full overflow-scroll">
                <table className="table w-full">
                    <thead className="">
                        <tr className=" text-black font-black">
                            <td className="p-3 px-5">Name</td>
                            <td className="p-3 px-5">Roles</td>
                            <td className="p-3 px-5">Phone</td>
                            <td className="p-3 px-5">Email</td>
                            <td className="p-1 px-5"></td>
                        </tr>
                    </thead>
                    <tbody>
                        {staff? (staff.map(member => <tr className="border-t-2 border-gray-100" key={member.name}>
                            <td className="  p-3 px-5">{member.name}</td>
                            <td className="  p-3 px-5">{member.roles!.map(e => e.replace("_", " ")).join()}</td>
                            <td className="  p-3 px-5">{member.dLineCode}{member.directLine}</td>
                            <td className="  p-3 px-5">{member.emailAddress}</td> 
                            <td className="  p-3 px-5 float-right text-atomGreen self-center">
                                <svg onClick={() => { setEditTm(undefined); setOpen(true); setEditTm(member); }} xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 self-center" viewBox="0 0 20 20" fill="currentColor">
                                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                </svg>
                            </td>
                        </tr>)) : (<p>No record found!</p>)}
                    </tbody>
                </table>
                {
                    editTm && <EditTeamMember open={open} setOpen={setOpen} tm={editTm!} setEditTm={setEditTm} />
                }
            </div>
        </div>
    )
}


export default observer(TeamMembersList)

