import React from 'react'
import { QuestionnaireType } from '../common/types/question'

type QAChildProps = {
    qa: QuestionnaireType,
    setQA: React.Dispatch<React.SetStateAction<QuestionnaireType>>
}

function DateQA({ qa, setQA }: QAChildProps) {
    const { question } = qa
    return (
        <div className="flex w-full my-2 py-2" key={question.sectionId + question.questionId}>
            <p className="font-bold self-center w-6/12"> {question.statement}</p>
            <div className="w-1/12"></div>
            <section className={"flex-1 self-center"}>
                <input className="rounded w-full p-2" placeholder="Answer here" type="month" onChange={(e) => setQA({ ...qa, response: e.target.value })} value={qa.response} />
            </section>
        </div>
    )
}


export default DateQA
