export const searchKey = new Map();

//General Descriptions
searchKey.set("description.country", "Country");
searchKey.set("description.siteId", "Site Id");
searchKey.set("description.emailAddress", "Email Address");
searchKey.set("description.leadInvestigatorName", "Lead Investigator Name");
searchKey.set("description.institutionName", "Institution Name");
searchKey.set("description.department", "Department");
//Form 1
searchKey.set("results.50a3","Has a dedicated Clinical Trial unit")
//Form 2
searchKey.set("results.296f","Has undertaken any clinical trials in the past 3 years")
searchKey.set("results.a345","Got Sponsorship from")
searchKey.set("results.a97c","Has experience in conducting Phase I studies Clinical Trials")
searchKey.set("results.c3b0","Has experience in conducting  Phase II proof of concept studies Clinical Trials")
searchKey.set("results.9fad","Has experience in conducting Phase III Clinical Trials")
searchKey.set("results.e9e2","Has experience in conducting Post marketing/Phase IV studies in the last 3 years?")
searchKey.set("results.28d0","Has experience in conducting Registries/RWE Clinical Trials in the last 3 years?")
searchKey.set("results.979e","Has experience in conducting Clinical Pharmacology studies Clinical Trials in the last 3 years?")
searchKey.set("results.2ca9","Has experience in conducting Biomarker studies Clinical Trials")
searchKey.set("results.a627","Has experience in conducting Sample collection projects, e.g. blood, saliva, tissue, etc.")
searchKey.set("results.e352","Has experience in conducting Genetic Sampling studies in the last 3 years")
searchKey.set("results.89ed","Has experience in conducting Device studies")
searchKey.set("results.f9f9","Has experience in conducting Nutraceutical studies")
searchKey.set("results.4aeb","Able to recruit patients from any external sources?")
searchKey.set("results.8045","Used referrals in the past")
searchKey.set("results.6608","have experience in performing and/or measuring 12 lead ECGs?")
searchKey.set("results.dbf0","have experience in performing radiological assessments")
searchKey.set("results.5113","have experience in performing histopathogical assessments?")
searchKey.set("results.30a4","have experience in performing and/or measuring Visual Analogue Scales?")
//Form 3
searchKey.set("results.3d00","Number of beds")
searchKey.set("results.f5f9","Number of subjects/patients could you accommodate in your dept at one time")
searchKey.set("results.34de","Have freezers available on site")
searchKey.set("results.b255","Have specific archiving facilities?")
//Form 4
searchKey.set("results.fc4a","have a central IECs")
searchKey.set("results.549d","Do they have written procedures in place? (check compliance if required)")
searchKey.set("results.cd3a","What is the average time to IEC approval (from the initial submission date")
searchKey.set("results.30a4","What is the frequency of the IEC meetings (e.g. weekly, monthly, etc")
searchKey.set("results.30a4","What is the length of time that the study documents are required at your site from the sponsor prior to IEC submission to allow for submission preparation, translations, etc.?")
searchKey.set("results.30a4","How soon will the IEC communicate its decision A) verbally and B) in writing after the meeting?")
searchKey.set("results.30a4","What documents are required by the IEC for submission? Please list in full and whether any of these documents require specific signatures or approval to get IEC/IRB approval")
searchKey.set("results.30a4","Does your IEC require written updates on study progress and/or just a report at the end of the study?")
searchKey.set("results.30a4","If Yes, What are the specific reporting requirements to your local IEC - SAEs management,Study progress, Protocol Deviations etc.,? What is the frequency of reporting")
searchKey.set("results.30a4","Are there any specific Ethics Committee requirements?")
searchKey.set("results.30a4","Does your site have a standard ICF template that is required to be used and adapted for the study?")
searchKey.set("results.30a4","Does your Regultory body/Health Authority require written updates on study progress and/or just a report at the end of the study?")
searchKey.set("results.30a4","What are the specific reporting requirements to your Regultory body/Health Authority -  SAEs management, / Study progress, Protocol Deviations etc.,? What is the frequency of reporting")
searchKey.set("results.30a4","What are the timelines for reporting at the end of the study?")
//Form 5
searchKey.set("results.30a4","Does your site have experience of Investigational Medicinal Product (IMP) management?")
searchKey.set("results.30a4","Does the site have experience with electronic Randomization and Trial Supply Management System used for accountability, IP receipt acknowledgement")
searchKey.set("results.30a4","Is the pharmacy GMP accredited? ")
searchKey.set("results.30a4","Do you have a Qualified Person?")
searchKey.set("results.30a4","Provide the address where IMP should be shipped to under details")
searchKey.set("results.30a4","List the formulation types you have experience with")
searchKey.set("results.30a4","Does the site agree to observation of drug administration by the monitor")
searchKey.set("results.30a4","Do you maintain your own drug accountability records for trial medications?")
searchKey.set("results.30a4","Do you have a dedicated area for freezers/fridges to maintain IMP in accordance with the manufacturers instructions")
searchKey.set("results.30a4","Can IMP destruction be done at site? ")
searchKey.set("results.30a4","Is there medical cover for 24 hour contact?")
searchKey.set("results.30a4","Does the site have emergency resuscitation equipment on site?")
//Form 6
searchKey.set("results.30a4","Does your site (and team) have access to the internet?")
searchKey.set("results.30a4","Does the site have the correct site connection speed")
searchKey.set("results.30a4","How do you endure that patient data is not lost and patient privacy is protected (e.g. SOPs)")
//Form 7
searchKey.set("results.30a4","Is your facility GLP certified? If yes, attach accreditation?")
searchKey.set("results.30a4","Can the site collect and process PK and PD samples 24/7")
searchKey.set("results.30a4","Do you have the capability to access online portals from central lab vendor (kit provider) e.g. for ordering kits, downloading results, viewing lab manuals and training materials, lab requisition forms, query resolution")
searchKey.set("results.30a4","Does your site have Lab ranges for normal values/references ranges?")
searchKey.set("results.30a4","What days can you ship samples?")
searchKey.set("results.30a4","Would site like to receive lab flow chart in local language?")
searchKey.set("results.30a4","Do you have a pathology lab co-located at your site?")
searchKey.set("results.30a4","Do you routinely carry out Haematoxylin & Eosin staining?")
searchKey.set("results.30a4","Can your site process biopsies as Fresh Frozen tissue?")
searchKey.set("results.30a4","Does your site policy allow archival tissue and FFPE blocks be sent from your site, If our clinical protocol requires it?")
searchKey.set("results.30a4","What tests does your facility routinely perform")
searchKey.set("results.30a4","Does your facility have the capability for sample collection for genetic testing?")
searchKey.set("results.30a4","Does your facility have the capability for sample collection for Other specific testing?")
searchKey.set("results.30a4","Does the site's laboratory have the capability to transfer lab data electronically according to client specifications?")
searchKey.set("results.30a4","Have you previously used central labs for specialist testing?")

//searchKey.set("","")


