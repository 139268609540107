
import { TeamMemberType } from "../../common/types/site";
import { TextWriter } from "../utility";

const docx = require("docx");
const { Paragraph, TextRun } = docx;

export const _3_1_LocationStaffingB = ( lead: TeamMemberType) => {

    const psd = lead.psd || []
    return new Paragraph({
        children: [

            new TextRun({ text: "\n", break: 2 }),

            TextWriter(psd.length > 1 ? "This site offers " : "This site offer "),
            TextWriter(psd.join(', ') || " "),
            TextWriter(" setting and also part of the "),
            TextWriter(lead.specialtyNetwork || ""),
            TextWriter("."),

        ],
        spacing: {
            line: 276
        }
    })
}


