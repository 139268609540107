import { observer } from 'mobx-react';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useParams } from 'react-router';
import Header from '../../../../components/layouts/Header'
import Skeleton from '../../../../components/layouts/Skeleton';
import { useStore } from '../../../../store/StoreProvider';
import { AccessToStaffTabID, SiteExperienceTabID, HomeTabID, MediaTabID, SiteFacilityTabID, SiteEthicsTabID, SiteInfraTabID, SitePharmacyTabID, SiteBioSampleTabID } from '../../../common/constants';
import { FormType } from '../../../common/types/question';
import { EntryStatusEnum, SiteObject } from '../../../common/types/site';
import SiteHomePage from "./SiteHomePage"
import AccessToStaff from "./AccessToStaff"
import Notifier from './Notifier';
import SiteExperience from './SiteExperience';
import { SiteQuestionAnswered, SiteStatusChanged } from '../../../common/types/utility';
import SiteFacility from './SiteFacility';
import SiteEthics from './SiteEthics';
import SiteMedia from './SiteMedia';
import SitePharma from './SitePharma';
import SiteInfra from './SiteInfra';
import SiteBioSample from './SiteBioSample';
import { ChevronRightIcon } from '@heroicons/react/outline';

type SiteDashboardProps = {
    selected?: SiteObject
}

type Params = {
    id: string;
};


function SiteDashboardHome({ selected }: SiteDashboardProps) {
    const { id } = useParams<Params>();
    const homeSwitch = { sectionId: HomeTabID, title: "Home" }
    const mediaSwitch = { sectionId: MediaTabID, title: "Media" }
    const { sitesListSore: siteSore, siteQuestioningStore: questionStore, userStore } = useStore();
    const [openNotifier, setOpenNotifier] = useState<boolean>(false)

    useEffect(() => {
        siteSore?.initialize().then(() =>
            siteSore!.fetchSite(id).then(res => {
                questionStore!.loadQuestionSections()
            }).then(() => {
                questionStore?.setSelectedSection(homeSwitch)
                console.log(siteSore.isLoading)
            }).catch(e => {
                alert(e)
            }))
                            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) 

    function handleSectionSwitch(item: FormType): void {
        questionStore!.setSelectedSection(item)
    }

    function handleStatusChange(status: EntryStatusEnum): void {
        siteSore?.setSelectedSite({ ...selectedSite, meta: { ...selectedSite?.meta, status: status } })
        let log = SiteStatusChanged(selectedSite!, userStore?.currentUser.email || 'Anonymous')
        siteSore?.updateSite(log).then(() =>
            window.location.reload()
        )
    }



    const selectedSite = siteSore?.selectedSite
    const forms = questionStore?.items.slice().sort((a, b) => a.index! - b.index!)

    const createLog = () => {
        return SiteQuestionAnswered(selectedSite!, userStore?.currentUser.email || 'Anonymous')
    }
    return (
        <>
            <Header user={userStore!.currentUser!} />
            <div className="flex items-center  w-full min-h-full justify-center">
                <div className="w-full lg:w-2/3 p-5 flex flex-col lg:flex-row items-start lg:items-center rounded bg-white shadow my-20 mt-36">
                    {siteSore?.isLoading &&
                        <Skeleton />
                    }
                    {(!siteSore?.isLoading && selectedSite?.accessToStaff?.tms) &&
                        <div className="flex flex-col w-full">

                            <div className="flex my-1 flex-col py-3 px-5">
                                <div className="flex justify-between w-full">
                                    <div className="w-9/12">
                                        <h3 className=" text-gray-800 dark:text-gray-100 text-xl tracking-normal font-medium mb-1">{selectedSite?.description?.department} | {selectedSite?.description?.institutionName}</h3>
                                        <p className="text-xs">Status: <span className="text-atomGreen text-xs">{selectedSite.meta?.status}</span> </p>

                                    </div>
                                    <div className=" self-center space-x-3 hidden md:flex">
                                        {selectedSite.meta?.status === EntryStatusEnum.ENTRY && <button className=" border-green-400 border-2 bg-white transition duration-150 ease-in-out focus:outline-none hover:bg-gray-100 rounded text-green-400 px-10  text-xs whitespace-nowrap h-7" onClick={() => handleStatusChange(EntryStatusEnum.REVIEW)}>Submit for Review</button>
                                        }
                                        <button className=" border-red-400 border-2 bg-white transition duration-150 ease-in-out focus:outline-none hover:bg-gray-100 rounded text-red-400 whitespace-nowrap h-7 px-10 text-xs">Archive Record</button>
                                    </div>



                                </div>

                                <div className="flex flex-row space-x-5 mt-2">
                                    <div className="flex flex-row space-x-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-4 self-center text-gray-800" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                                        </svg>
                                        <p className="text-gray-400 self-center">  {selectedSite?.description?.address}</p>
                                    </div>
                                    <div className="flex flex-row space-x-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 self-center text-gray-800" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                                        </svg>
                                        <p className="text-gray-400">  {selectedSite?.description?.emailAddress}</p>
                                    </div>

                                    <div className="flex flex-row space-x-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 self-center text-gray-800" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z" />
                                        </svg>
                                        <p className="text-gray-400"> {selectedSite?.description?.phoneNumber}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-row bg-gray-50  w-full overflow-y-hidden border-gray-200 text-atomGreen">
                                <div className={"p-1 md:p-2  flex space-x-2  border-r-2 border-gray-100   justify-around cursor-pointer self-center " + (HomeTabID === questionStore!.selectedSection?.sectionId && " border-b-4 border-atomGreen ")} onClick={() => { handleSectionSwitch(homeSwitch) }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 self-center text-atomGreen" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                                    </svg>
                                    <span className="block self-center text-xs">Home</span>
                                </div>
                                {forms?.map(section => section.isLive && <div className={"flex-1 p-1 md:p-2 flex space-x-2 border-r-2 border-gray-200 justify-around cursor-pointer self-center text-atomGreen " + (section.sectionId === questionStore!.selectedSection?.sectionId && " border-b-4  border-atomGreen ")} key={section.title} onClick={() => handleSectionSwitch(section)}>
                                    {section.sectionId === questionStore!.selectedSection?.sectionId ? <p className={"text-xs self-center whitespace-nowrap uppercase "}>{section.title}</p>
                                        : <p className={"text-xs self-center whitespace-nowrap "}>{section.shortName}</p>
                                    }
                                </div>
                                )}
                                <div className={"p-1 md:p-2  flex space-x-2   border-gray-200   justify-around cursor-pointer self-center " + (MediaTabID === questionStore!.selectedSection?.sectionId && " border-b-4 border-atomGreen ")} onClick={() => { handleSectionSwitch(mediaSwitch) }}>

                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 self-center" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 4v16M17 4v16M3 8h4m10 0h4M3 12h18M3 16h4m10 0h4M4 20h16a1 1 0 001-1V5a1 1 0 00-1-1H4a1 1 0 00-1 1v14a1 1 0 001 1z" />
                                    </svg>
                                    <span className="block font-bold self-center text-xs">Files</span>
                                </div>
                                <div className={"p-1 md:p-2  flex space-x-2   border-gray-400   justify-around cursor-pointer self-center "}>
                                    <ChevronRightIcon className="h-3 w-3 self-center" onClick={(e) => { e.currentTarget.scrollTo(1000, 1000) }} />
                                </div>
                                <div className="p-1 md:p-2  flex space-x-2  border-gray-400 justify-around cursor-pointer self-center">

                                </div>
                            </div>

                            <div className="flex  flex-col">
                                {questionStore?.selectedSection?.sectionId === HomeTabID &&
                                    <SiteHomePage />
                                }
                                {questionStore?.selectedSection?.sectionId === AccessToStaffTabID &&
                                    <AccessToStaff />
                                }
                                {questionStore?.selectedSection?.sectionId === SiteExperienceTabID &&
                                    <SiteExperience />
                                }
                                {questionStore?.selectedSection?.sectionId === SiteFacilityTabID &&
                                    <SiteFacility />
                                }
                                {questionStore?.selectedSection?.sectionId === SiteEthicsTabID &&
                                    <SiteEthics />
                                }
                                {questionStore?.selectedSection?.sectionId === SitePharmacyTabID &&
                                    <SitePharma />
                                }
                                {questionStore?.selectedSection?.sectionId === SiteInfraTabID &&
                                    <SiteInfra />
                                }
                                {questionStore?.selectedSection?.sectionId === SiteBioSampleTabID &&
                                    <SiteBioSample />
                                }
                                {questionStore?.selectedSection?.sectionId === MediaTabID &&
                                    <SiteMedia />
                                }
                            </div>
                            <button onClick={() => { siteSore?.updateSiteResponses(createLog()).then(() => setOpenNotifier(!openNotifier)).then(() => { }) }} className="bg-atomGreen p-3 w-full font-bold uppercase text-gray-100">Save All Changes</button>
                        </div>
                    }
                    <Notifier open={openNotifier} setOpen={setOpenNotifier} />
                </div>
            </div>

        </>
    )
}



export default observer(SiteDashboardHome)



