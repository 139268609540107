
import React from 'react'
import NewDialog from './dialog'
import { useStore } from '../../../../store/StoreProvider';

type addFormProps = {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

function SearchHistory({ isOpen, setIsOpen }: addFormProps) {
    const { searchStore} = useStore();

    const history = searchStore?.history

    return (
        <NewDialog title="Saved Search History" isOpen={isOpen} setIsOpen={setIsOpen} >
            <div className="flex space-y-2 flex-col overflow-auto">
                {history?.map(item => <div onClick={() => {searchStore?.setQuery(item.queries); setIsOpen(false)}} key={item.id}>{item.title}</div>)}
            </div>
        </NewDialog>
    )
}

export default SearchHistory



