
import { TableCell, Paragraph, VerticalAlign, convertInchesToTwip } from "docx";
import { QuestionType } from "../../common/types/question";
import { CellFillWriter, CellWriter, findQuestion, NO_INFO } from "../utility";

const docx = require("docx");
const { Table, TableRow, } = docx;


type impSummary = {
    description?: string
    response?: string
}

type trailQuestionMAp = {
    questionIds: string[]
}

export const SummarizeImp = (results: any, questions: QuestionType[]): impSummary[] => {
    var result: impSummary[] = []

    trialQuestionIdMaps.map(trail => (
        result.push({
            description: findQuestion(questions, trail.questionIds[0]) as string,
            response: results[trail.questionIds[0]] ? results[trail.questionIds[0]] : NO_INFO

        })
    ))
    return result
}

const trialQuestionIdMaps: trailQuestionMAp[] = [
    {
        questionIds: ["d31b", "f476", "f28b"]
    },
    {

        questionIds: ["bc89", "7311", "c020"]
    },
    {

        questionIds: ["d81e", "176e", "8f57"]
    },
    {

        questionIds: ["8f97", "9e0d", "4e49"]
    },
    {

        questionIds: ["ddb1", "a4d5", "12f2"]
    },
    {

        questionIds: ["8ffd", "d0be", "9cd2"]
    }
    ,
    {

        questionIds: ["be5c", "61ce", "39f0"]
    },
    {

        questionIds: ["8e93", "8712", "8f3f"]
    },
    {

        questionIds: ["ced5", "af51", "0629"]
    },
  
    {

        questionIds: ["1429", "a35b", "f6a1"]
    },
  
    {

        questionIds: ["1110", "a35b", "f6a1"]
    },
  
    {

        questionIds: ["7391", "a35b", "f6a1"]
    },
  
    {

        questionIds: ["f8a8", "", ""]
    },
    {

        questionIds: ["ce02", "", ""]
    },
    {

        questionIds: ["f7e4", "", ""]
    },
    {

        questionIds: ["5630", "", ""]
    },
    {

        questionIds: ["cc16", "", ""]
    },  {

        questionIds: ["e7fb", "", ""]
    },
  
    {

        questionIds: ["70cb", "a35b", "f6a1"]
    },
  
    {

        questionIds: ["e077", "a35b", "f6a1"]
    },
  
    {

        questionIds: ["ffc7", "a35b", "f6a1"]
    }
]


export const _3_3_4_IMPTable = (counts: impSummary[]) => new Table({
    rows: [
        new TableRow({
            
            children: [
                CellFillWriter("IMP Management"),

        
                new TableCell({
                    children: [new Paragraph({ text: "" })],
                    verticalAlign: VerticalAlign.CENTER,
                    margins: {
                        top: convertInchesToTwip(0.1),
                        bottom: convertInchesToTwip(0.1),
                        left: convertInchesToTwip(0.2),
                        right: convertInchesToTwip(0.2),
                    },
                    shading: {
                        color: "00FFFF",
                        fill: "#A4CA39",
                    }, 
                }),

            ],
        })].concat(

            counts.map(count => new TableRow({
                children: [
                    CellWriter(count.description),
                    CellWriter(count.response?.toString().replaceAll(",",", ").replace("Others",'') ),
                ]
            }))
        )

})
