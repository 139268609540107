import { RootStore } from "../../../store/types/rootstore";
import { SearchService } from "../services/SearchService";
import { SiteSearchStore } from "./SiteSerchStore";

export type SiteSearchTypes = {
  searchStore?: SiteSearchStore;
};

export function injectSearchIntoRoot(root: RootStore) {
  const service = new SearchService();
  root.searchStore = new SiteSearchStore(service);
}
