
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react'
import { useStore } from '../../store/StoreProvider';
import { QResponseTypeEnum, QuestionnaireType, QuestionType } from '../common/types/question'
import { SiteObject } from '../common/types/site';
import DateQA from './DateQA';
import FileQA from './FileQA';
import ListQA from './ListQA';
import LongQA from './LongQA';
import MultipleQA from './MultipleQA';
import MultiStaff from './MultiStaff';
import NumbQA from './NumbQA';
import ShortQA from './ShortQA';
import SingleQA from './SingleQA';
import StaffQA from './StaffQA';
import TFrqQA from './TFrqQA';
import VFileQA from './VFileQA';

type QuestionProps = {
    q: QuestionType
}

function QuestionItem({ q }: QuestionProps) {

    const { sitesListSore: siteStore } = useStore();

    const [siteObject, setSiteObject] = useState<SiteObject>({ ...siteStore?.selectedSite })

    const [qa, setQa] = useState<QuestionnaireType>({ question: q, response: "" })
    useEffect(() => {
        setQa({ ...qa, response: getResponse(qa.question.questionId) })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [qa.question]) 

    const { question } = qa


    function getResponse(qId: string): string | number | string[] | undefined {
        let response: string | number | string[] | undefined = ""
        let site = { ...siteStore?.selectedSite }
        let responses = site.responses || []

        const result = responses.find(({ questionId }) => questionId === qId);
        //console.log("Checking for " + qId, " found " + JSON.stringify(result))

        if (!result) {
            return ""
        }
        // console.log("found")
        response = result!.response
        return response
    }



    useEffect(() => {
        if (!qa.response) {
            return
        }
        let site = { ...siteStore?.selectedSite }
        let responses = site.responses || []
        let index = responses.findIndex(({ questionId }) => questionId === qa.question.questionId)
        if (index === -1) {
            responses.push({ questionId: qa.question.questionId, responseType: qa.question.type!, response: qa.response })
        } else {
            responses[index].response = qa.response
        }


        setSiteObject({ ...siteObject, responses: responses })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [qa.response])


    return (
        <>
            {question.type === QResponseTypeEnum.SHORT && <ShortQA qa={qa} setQA={setQa} key={qa.question.questionId} />}
            {question.type === QResponseTypeEnum.INTEGER && <NumbQA qa={qa} setQA={setQa} key={qa.question.questionId} />}
            {question.type === QResponseTypeEnum.LONG && <LongQA qa={qa} setQA={setQa} key={qa.question.questionId} />}
            {question.type === QResponseTypeEnum.DATE && <DateQA qa={qa} setQA={setQa} key={qa.question.questionId} />}
            {question.type === QResponseTypeEnum.LIST && <ListQA qa={qa} setQA={setQa} key={qa.question.questionId} />}
            {question.type === QResponseTypeEnum.TEXTFREQUENCY && <TFrqQA qa={qa} setQA={setQa} key={qa.question.questionId} />}
            {question.type === QResponseTypeEnum.VFILE && <VFileQA qa={qa} setQA={setQa} />}
            {question.type === QResponseTypeEnum.STAFF && <StaffQA qa={qa} setQA={setQa} />}
            {question.type === QResponseTypeEnum.MULTIPLE_STAFF && <MultiStaff qa={qa} setQA={setQa} />}
            {question.type === QResponseTypeEnum.FILE && <FileQA qa={qa} setQA={setQa} />}
            {question.type === QResponseTypeEnum.SINGLE && <SingleQA qa={qa} setQA={setQa} />}
            {question.type === QResponseTypeEnum.MULTIPLE && <MultipleQA qa={qa} setQA={setQa} />}

        </>)
}


export default observer(QuestionItem)
