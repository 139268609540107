
import { QuestionType } from "../../common/types/question";
import { CellFillWriter, CellWriter, findQuestion, NO_INFO } from "../utility";

const docx = require("docx");
const { Table, TableRow, } = docx;


type tumorSummary = {
    description?: string
    response?: string
}

type tumorQuestionMAp = {
    questionIds: string[]
}

export const SummarizeTumour = (results: any, questions: QuestionType[]): tumorSummary[] => {
    var result: tumorSummary[] = []

    trialQuestionIdMaps.map(trail => (
        result.push({
            description: findQuestion(questions, trail.questionIds[0]) as string,
            response: results[trail.questionIds[0]] ? results[trail.questionIds[0]] : NO_INFO

        })
    ))
    return result
}

const trialQuestionIdMaps: tumorQuestionMAp[] = [
    {
        questionIds: ["ce01", "", ""]
    }, {
        questionIds: ["3357", "", ""]
    }, {
        questionIds: ["52d3", "", ""]
    }, {
        questionIds: ["3db7", "", ""]
    }, {
        questionIds: ["1e38", "", ""]
    },
    
]


export const _3_6_2_TumorTable = (counts: tumorSummary[]) => new Table({
    rows: [
        new TableRow({
            children: [
                CellFillWriter("Trail pathology support activities"),
                CellFillWriter("")
               
            ],
        })].concat(

            counts.map(count => new TableRow({
                children: [
                    CellWriter(count.description),
                    CellWriter(count.response?.toString()),
                ]
            }))
        )

})
