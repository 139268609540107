import { action, makeObservable, observable, reaction } from "mobx";
import { FormSubSectionType, FormType, QuestionType } from "../../common/types/question";
import { GMT } from "../../common/types/utility";
import { AdminService } from "../services/AdminService";
import { data } from "./data";

export class AdminFormsListStore {
  items: FormType[] = [];
  selectedSection: FormType | undefined = undefined;
  selectedSubSection: FormSubSectionType | undefined = undefined;
  isLoading: boolean = false;
  constructor(private adminServices: AdminService) {
    makeObservable(this, {
      items: observable,
      selectedSection: observable,
      selectedSubSection: observable,
      isLoading: observable,
      setIsLoading:action,
      setSelectedSection:action,
      setSubSelectedSection:action
    });
    this.initialize();

    this.loadQuestionSections();

    reaction(() => this.selectedSection, () => {
      this.selectedSubSection = undefined
    })

     // this.move()
  }

  async initialize() { }

  private setQuestionSections(items: FormType[] | []) {
    this.items = items;
  }

  setIsLoading(flag: boolean) {
    this.isLoading = flag;
  }

  setSelectedSection(item: FormType) {
    this.selectedSection = item;
  }

  setSubSelectedSection(item: FormSubSectionType | undefined) {
    this.selectedSubSection = item;
  }

  getSubSelectedSection() {
    return this.selectedSubSection;
  }

  async addNewForm(newForm: FormType) {
    let response = await this.adminServices.createNewForm(newForm)
    return response
  }

  async addNewSubForm(formId: string, newSubForm: FormSubSectionType) {
    let response = await this.adminServices.createSubSection(formId, newSubForm)
    return response
  }

  async updateForm(qs: FormType, user?:string) {
    let response = await this.adminServices.updateSection({...qs, updatedAt:GMT, updatedBy:user || "admin"})
    return response
  }

  async updateSubForm(formId: string, update: FormSubSectionType) {
    const section = await this.adminServices.getSection(formId)
    const subs = section.subs
    let index = subs!.findIndex(({ id }) => id === update.id);
    subs![index!] = update
    const res = await this.adminServices.updateSection(section)
    return res;
  }

  async loadQuestionSections() {
    let response = await this.adminServices.getAllForms();
    this.setQuestionSections(response);
    this.setSelectedSection(response[0])
  }

  async refreshSections() {
    let response = await this.adminServices.getAllForms();
    this.setQuestionSections([...response]);
  }



  findSection(formId: string | undefined) {
    const result = this.items.find(({ sectionId }) => sectionId === formId);
    return result
  }



  updateQuestionOrder(formId: string, subIndex: number, qs: QuestionType[]) {
    let form = this.findSection(formId)
    let subSection = form!.subs![subIndex]
    subSection.questions = qs
    // set item
  }

  async move() {
    data.forEach(async d => {
      await this.adminServices.createNewForm( { ...d } as FormType)
    });
  }

}
