import { observer } from 'mobx-react';
import React, { useState } from 'react'
import { useStore } from '../../../../../store/StoreProvider';
import { LogisticsPartner } from '../../../../common/types/site';
import EditLogistics from './EditLogistics';

function TeamMembersList() {
    const [open, setOpen] = useState(false)
    const { sitesListSore: siteSore} = useStore();
    const [editPartner, setEditPartner] = useState<LogisticsPartner | undefined>(undefined)

    return (
        <div>
            <div className="">
                <table className="table w-full">
                    <thead className="">
                        <tr className=" text-black font-black uppercase">
                            <td className="p-3 px-5">Name</td>
                            <td className="p-3 px-5">Address</td>
                            <td className="p-3 px-5">Contact Person</td>
                            <td className="p-3 px-5">Email</td>
                            <td className="p-3 px-5">Accepts All</td>
                            <td className="p-1 px-5"></td>
                        </tr>
                    </thead>
                    <tbody>
                        {siteSore!.selectedSite.lps! ? (siteSore?.selectedSite.lps.map(member => <tr className="text-xs" key={member.regId}>
                            <td className="  p-3 px-5">{member.name}</td>
                            <td className="  p-3 px-5">{member.address} ~ {member.location} ~ {member.country}</td>
                            <td className="  p-3 px-5">{member.primaryContact}</td>
                            <td className="  p-3 px-5">{member.emailAddress}</td>
                            <td className="  p-3 px-5">{member.acceptAll ? "YES" : "NO"}</td>
                            <td className="  p-3 px-5 float-right text-atomGreen self-center">
                                <svg onClick={() => { setEditPartner(member); setOpen(true) }} xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 self-center " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>

                                                     </td>
                        </tr>)) : (<p>No record found!</p>)}
                    </tbody>
                </table>
                {
                    editPartner && <EditLogistics open={open} setOpen={setOpen} partner={editPartner!} setPartner={setEditPartner} />
                }
            </div>
        </div>
    )
}

export default observer(TeamMembersList)