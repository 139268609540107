import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/images/market_quality_trials.png';
import { useHistory } from "react-router-dom";
import NewSiteDialogue from '../../modules/sites/components/NewSiteDialogue';
import { observer } from 'mobx-react';
import { UserObjectType } from '../../modules/common/types/user';
import { useStore } from '../../store/StoreProvider';
import NotAdmin from '../../modules/admin/components/dialog/no_admin_access';
import { SearchIcon } from '@heroicons/react/outline';


type HeaderProp = {
    user: UserObjectType
}
function Header({ user }: HeaderProp) {
    let history = useHistory();
    const [open, setOpen] = useState<boolean>(false)
    const [adminError, setAdminError] = useState<boolean>(false)
    const { administratorStore: adminsStore } = useStore();
    const admins = adminsStore?.admins || []

    function handleAdminSwitch(): void {
        if (admins?.findIndex(x => x.email?.toLowerCase() ===user.email?.toLowerCase()) !==-1) {
            history.push("/admin")
        } else {
            setAdminError(true)
        }
    }

    return (
        <header className="flex flex-col justify-center align-middle text-gray-100 bg-white body-font shadow z-20 fixed w-full">
            <div className="w-full py-2 flex flex-row items-start justify-between rounded self-center px-10 space-x-5 border-b-2">
                <img className="self-center " src={logo} alt="atom logo" style={{ height: 40 }} />

                <div className="py-3 flex flex-row items-start rounded self-center space-x-5">
                    <button className="flex items-center mr-3 mt-3 md:mt-0  text-atomGreen">
                        <span>Welcome {user.name} </span>
                    </button>
                    <button className="flex items-center mr-3 mt-3 md:mt-0 text-gray-800" onClick={() => { localStorage.clear(); history.push("/") }}>
                        <span>Sign Out</span>
                    </button>
                </div>
            </div>

            <div className="w-full lg:w-2/3 py-3 flex flex-row items-start rounded self-center space-x-5 justify-between">

                <div className="flex">
                    <button className="flex items-center mr-3 mt-3 md:mt-0 text-gray-800">
                        <span className="mr-2">

                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" width={16} height={16} stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4" />
                            </svg>
                        </span>
                        <span><Link to={"/dashboard"}>Dashboard</Link></span>
                    </button>
                    <button className="flex items-center mr-3 mt-3 md:mt-0 text-gray-800">
                        <span className="mr-2">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" width={16} height={16}>
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                            </svg>
                        </span>
                        <span><Link to={"/sites"}> Sites list</Link></span>
                    </button>
                    <button className="flex items-center mr-3 mt-3 md:mt-0 text-gray-800">
                        <span className="mr-2">
                            <SearchIcon className='h-5 w-5' />
                        </span>
                        <span><Link to={"/search"}>Search</Link></span>
                    </button>
                </div>
                <div className='flex space-x-2'>
                    <button onClick={() => setOpen(true)} className="flex items-center mt-3 md:mt-0 text-gray-800 bg-atomGreen px-10">
                        <span className="p-1 rounded text-white text-sm">
                            Add New Site
                        </span>

                    </button>

                    <button onClick={() => handleAdminSwitch()} className="flex items-center mt-3 md:mt-0 text-gray-800 bg-gray-800 px-10">
                        <span className="p-1 rounded text-white text-sm">
                            Goto Admin
                        </span>

                    </button>
                </div>

            </div>
            <NewSiteDialogue open={open} setOpen={setOpen} />
            <NotAdmin isOpen={adminError} setIsOpen={setAdminError} />
        </header>

    )
}


export default observer(Header)


