import { action, makeObservable, observable } from "mobx";
import { SiteObject } from "../../common/types/site";
import { AdminService } from "../services/AdminService";

export class AdminSites {
    sites: SiteObject[] = [];

    constructor(private adminServices: AdminService) {
        makeObservable(this, {
            sites: observable,
            setSites: action.bound
        });
        this.initializeSites()
    }
    async initializeSites() {
        const sites = await this.adminServices.getAllSites()
        this.setSites(sites)
    }

    setSites(payload: SiteObject[]) {
        this.sites = payload;
    }

    async updateSite(site: SiteObject){
        await  this.adminServices.updateSite(site.description!.siteId , {...site}).then(() => this.initializeSites())
    }

}
