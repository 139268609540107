import React from 'react'
import { CountryDistribution } from '../../types/types'

type countryProps = {
    currentState?: string
    countries: CountryDistribution[]
    setCurrentState: any
}
function CountriesDist({ countries, setCurrentState, currentState }: countryProps) {
    return (
        <div className="w-full overflow-x-scroll xl:overflow-x-hidden z-20 space-y-1 " key={currentState}>
            <div className="overflow-x-auto ">
                <table className="min-w-full block max-h-72 overflow-y-auto w-full">
                    <tbody className='w-full block'>
                        {countries?.map(country => {
                            return (
                                <tr className={'border-b w-full cursor-pointer flex ' + (country.count === 0 && " hidden ") + (country.code === currentState && ' bg-atomGreen')} onClick={() => setCurrentState(country.code)}>
                                    <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap text-left">
                                        {country.name}</td>
                                    <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap text-right flex-1">
                                        {country.count}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}


export default CountriesDist
