import React from 'react'
import { Administrator } from '../../../common/types/admin'
import NewDialog from './dialog'

type addFormProps = {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    newAdmin: Administrator
    setNewAdmin: any
    save: any,
}

function AddNewAdmin({ isOpen, setIsOpen, save, newAdmin, setNewAdmin }: addFormProps) {
    return (
        <NewDialog title="Add Administrator" isOpen={isOpen} setIsOpen={setIsOpen} >
            <div className="space-y-2">
                <input type="text" name="title" placeholder="Name" onChange={(e) => setNewAdmin({ ...newAdmin, "name": e.target.value })} className="mt-1 block w-full sm:text-sm border border-gray-300 focus:outline-none rounded-md p-3" />
                <input type="email" name="email" placeholder="Email Address" onChange={(e) => setNewAdmin({ ...newAdmin, "email": e.target.value })} className="mt-1 block w-full sm:text-sm border border-gray-300 focus:outline-none rounded-md p-3" />

                <div className="flex justify-center">
                    <button className="p-2 border-0 bg-atomGreen mt-2 text-xs text-white px-5" onClick={() => { if (newAdmin.email ==='' || !newAdmin.name) { alert("Enter a valid input") } else { save(newAdmin) } }}>Save & Continue</button>
                </div>
            </div>
        </NewDialog>
    )
}

export default AddNewAdmin


