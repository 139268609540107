import { doc, updateDoc, arrayUnion, setDoc, collection, getDocs, getDoc } from "firebase/firestore";
import { SiteActivityType, SiteObject } from "../../common/types/site";
import { FormType } from "../../common/types/question";
import { setupFireStore } from "../../../firebase/firebase.config";

const {  Firestore: db } = setupFireStore()

export class SiteService {

    async getSites(): Promise<SiteObject[]> {
        let results: any = [];
        const response = collection(db, "sites");
        const data = await getDocs(response);
        data.docs.forEach((item) => {
            results = [...results, item.data()];
        });

        return results;
    };

    async getSite(Id: string): Promise<SiteObject> {
        const response = doc(db, "sites", Id);
        const data = await getDoc(response);
        return data.data() as SiteObject;
    };

    async createNewSite(siteObject: SiteObject): Promise<void> {
        let site = doc(db, "sites", siteObject.description!.siteId);
        const result = await setDoc(site, {
            ...siteObject
        });
        return result
    }

    async updateSite(site: SiteObject, log: SiteActivityType) {
        const siteRef = doc(db, "sites", site.description!.siteId)
        const response = await updateDoc(siteRef, site)
        const activity = doc(db, "logs", log.id)
    
      await setDoc(activity, { ...log })
        return response
    }
    async updateSiteResponses(site: SiteObject, log: SiteActivityType) {
        const siteRef = doc(db, "sites", site.description!.siteId)
        const response = await updateDoc(siteRef, {
            ...site
        })
        const activity = doc(db, "logs", log.id)
      
        await setDoc(activity, { ...log })
        return response
    }

    async appendLog(site: SiteObject, log: SiteActivityType) {
        const siteRef = doc(db, "sites", site.description!.siteId)
        const activity = doc(db, "logs", log.id)
        const result = await updateDoc(siteRef, {
            logs: arrayUnion(log)
        })
    await setDoc(activity, { ...log })
        return result
    }

    async getQuestionForms(): Promise<FormType[]> {
        let response: FormType[] = [];
        const result = collection(db, "sections");
        const data = await getDocs(result);
        data.docs.forEach((item) => {
            response = [...response, item.data() as FormType];
        });

        return response || [];
    }


}