import { observer } from 'mobx-react';
import React, { useState } from 'react'
import { useStore } from '../../../../../store/StoreProvider';
import { StaffCountTagEnum, StaffJdEnum, StaffMemberType } from '../../../../common/types/site'
import { SiteQuestionAnswered } from '../../../../common/types/utility';


type AddStaffCountProps = {
    tag: StaffCountTagEnum
}

function AddStaffCount({ tag }: AddStaffCountProps) {

    const { sitesListSore: siteSore, userStore } = useStore();
    const [staffCount, setStaffCount] = useState<StaffMemberType>({ tag: tag })

    function handleOthersChange(e: React.ChangeEvent<HTMLInputElement>): void {
        setStaffCount({ ...staffCount, jobDescription: e.target.value as StaffJdEnum })
    }

    function handleSaveNewCount(): void {
        if (staffCount.jobDescription && staffCount.jobDescription !=="") {
            siteSore?.addStaffCount(staffCount, createLog())
            setStaffCount({ ...staffCount, jobDescription: "", count: 0 })
        }
    }

    const createLog = () => {
        return SiteQuestionAnswered(siteSore?.selectedSite!, userStore?.currentUser.email || 'Anonymous')
    }

    return (
        <div className="flex">
            <div className="flex w-1/2  space-x-3 py-2">
                <div className="flex-1">
                    <label className="float-left font-bold self-center w-full uppercase mt-2 ">Others</label>
                </div>
                <div className="flex-1 flex space-x-2">
                    <input className="p-2 bg-gray-50" type="text" placeholder="Others" onChange={(e) => handleOthersChange(e)} value={staffCount.jobDescription} />
                    <input className="p-2 w-16 text-center bg-gray-50" type="number" placeholder="0" value={staffCount.count} min={0} onChange={(e) => setStaffCount({ ...staffCount, count: Number(e.target.value) })} />
                </div>

                <div></div>
            </div>
            <div className=" self-center">
                <button onClick={() => { handleSaveNewCount(); siteSore!.updateSite(createLog()).then(() => setStaffCount({ tag: tag })) }} className="text-atomGreen px-3 py-1 border-2 border-atomGreen flex self-center align-middle">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                    </svg>
                    <span className="self-center text-xs px-2"> Add Another</span>
                </button>
            </div>
        </div>
    )
}

export default observer(AddStaffCount)
