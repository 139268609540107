

import { SummarizeTrials, _3_2_ClinicalTrainTable } from "./section3/_3_2_clinicalTrainTable";
import { _3_0_Overview } from "./section3/_3_0_overview";
import { _3_1_LocationStaffingA } from "./section3/_3_1_LocationStaffingA";
import { _3_1_LocationStaffingB } from "./section3/_3_1_LocationStaffingB";
import { SummarizeCount, _3_1_StaffCountTable } from "./section3/_3_1_staffCountTable";
import { _3_2_ExperienceTraining } from "./section3/_3_2_experienceTraining";
import { SummarizeTraining, _3_2_StaffTrainingTable } from "./section3/_3_2_staffTrainingTable";
import { getResult, NO_INFO, TOCBreaker } from "./utility";
import { _3_2_TrainingSummary } from "./section3/_3_2_trainingSummary";
import { _3_2_ExperienceSummary } from "./section3/_3_2_experienceSummary";
import { _3_3_SiteProcessSummary } from "./section3/_3_3_siteProcessSummary";
import { _3_3_1_PatientDataMgt } from "./section3/_3_3_1_patientDataMgt";
import { _3_3_2_SourceDoc } from "./section3/_3_3_2_sourceDoc";
import { _3_3_4_AuditingSummary } from "./section3/_3_3_4_AuditingSummary";
import { SiteObject, TeamMemberRoleEnum } from "../common/types/site";
import { ReporterStore } from "./store/ReporterStore";
import { SummarizeProcess, _3_3_ProcessAssessTable } from "./section3/_3_3_processAssessTable";
import { SummarizeContract, _3_3_3_contractingTable } from "./section3/_3_3_3_contractingTable";
import { __Breaker } from "./section3/__break";
import { _3_5_PharmacySummary } from "./section3/_3_5_pharmaSummary";
import { TOC } from "./content/table";
import { __ParagraphsHead } from "./section3/__paragraphsHead";
import { ExecutiveSummary } from "./summary/exercutiveSummary";
import { __SubParagraphsHead } from "./section3/__subParagraphsHead";
import { Objectives } from "./obm/objective";
import { Methodologies } from "./obm/methodology";
import { __PageBreak } from "./section3/__pageBreak";
import { GapAnalysis } from "./gap/gap";
import { CoverPage } from "./coverpage/cover";
import { ImageLogo } from "./coverpage/logo";
import { _3_1_ExternalPatient } from "./section3/_3_1_ExternalPatient";
import { SummarizeDocLocation, _3_3_3_DocumentationTable } from "./section3/_3_3_3_DocumentationTable";
import { _3_3_5_MonitorSummary } from "./section3/_3_3_monitoringSummary";
import { _3_3_4_InfraIntro } from "./section3/_3_4_InfraIntro";
import { _3_4_1_FacilityIntro } from "./section3/_3_4_1_FacilityInto";
import { _3_4_2_StorageIntro } from "./section3/_3_4_1_StorageInto";
import { SummarizeFreezers, _3_4_2_FreezerTable } from "./section3/_3_4_2_FreezerTable";
import { _3_3_4_FreezerSummary } from "./section3/_3_4_1_FreezerSummary";
import { _3_4_4__TechAndEDC } from "./section3/_3_4_1_TechEdc";
import { _3_3_4_PharmacyIntro } from "./section3/_3_4_PharmacyIntro";
import { _3_3_4_IMPIntro } from "./section3/_3_4_1_IMPIntro";
import { SummarizeImp, _3_3_4_IMPTable } from "./section3/_3_4_1_IMPTable";
import { _3_6_1_BioSampleIntro } from "./section3/_3_6_1_BioSampleIntro";
import { SummarizeFreezers6, _3_6_2_FreezerTable } from "./section3/_3_6_2_FreezerTable";
import { _3_6_2_SampleMgtA } from "./section3/_3_6_2_SampleMgtA";
import { _3_6_2_SampleMgtB } from "./section3/_3_6_2_SampleMgtB";
import { _3_6_3_TumorTissueA } from "./section3/_3_6_3_TumourTissueA";
import { _3_6_5_LocalAssay } from "./section3/_3_6_5_LocalAssay";
import { _3_7_1_EthicsRequirements } from "./section3/_3_7_1_EthicsRequirements";
import { _3_7_1_EthicsRequirementsCont } from "./section3/_3_7_1_EthicsRequirementsCont";
import { _3_7_4_RegulatoryRequirements } from "./section3/_3_7_1_RegulatoryReq";
import { SummarizeShipment, _3_6_2_ShipmentTable } from "./section3/_3_6_2_ShipmentTable";
import { SummarizeTumour, _3_6_2_TumorTable } from "./section3/_3_6_2_TumorTable";
import { SummarizeDoc, _3_7_1_EthicsDocTable } from "./section3/_3_7_1_EthicsDocTable";
import { _3_4_3_MonitorSummary } from "./section3/_3_4_3_monitorSummary";
import { _3_6_1_Centrifuge } from "./section3/_3_6_1_Cetrifuges";
import { SummarizeEthicsReq, _3_7_1_EthicsReqTable } from "./section3/_3_7_1_EthicsReqTable";
import { _3_6_3_TumorTissueB } from "./section3/_3_6_3_TumourTissueB";
import { SummarizeTt, _3_6_3_TumourTissueTable } from "./section3/_3_6_3_TumourTissueTable";
import { _3_6_1_BioIntro } from "./section3/_3_6_1_BioIntro";
import { _3_8_TheraFocus } from "./section3/_3_8_TheraFocus";
import { AlignmentType, Footer, Header, PageNumber, Paragraph, TextRun } from "docx";
import { HeaderImageLogo } from "./coverpage/header";
const docx = require("docx");
const { Document, Packer } = docx;
const { TODAY_MONTH, TODAY_YEAR, } = require("./utility");

const doc = (site: SiteObject, reporter: ReporterStore | undefined): Document => {
    const teamMembers = site.accessToStaff?.tms || []
    console.log(teamMembers)
    const lead = teamMembers.find(q => q.roles?.includes(TeamMemberRoleEnum.LEAD_INVESTIGATOR))
    const description = { ...site.description }
    const allQuestions = reporter?.allQuestions.filter(x => x.isLive ===true) || []
    const results = { ...site.results }
    const labs = site.lps || []

    return new Document({
        sections: [{
            headers: {
                default: new Header({
                    children: [HeaderImageLogo()],
                }),
            },
            footers: {
                default: new Footer({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.RIGHT,

                            children: [
                                new TextRun({
                                    children: [PageNumber.CURRENT],
                                }),
                                new TextRun(` | ISA report issued on (ADD DATE)`),
                            ],
                        }),
                    ],
                }),
            },
            properties: {
                 titlePage: true,
            },
            children: [
                ImageLogo(),
                CoverPage(description),
                __PageBreak(),
                TOCBreaker(),
                ...TOC(),
                __PageBreak(),
                __ParagraphsHead("1.0 Executive Summary"),
                __Breaker(),
                ExecutiveSummary(site.meta),
                __PageBreak(),
                __ParagraphsHead("2.0 Objectives and Methodology"),
                __Breaker(),
                __SubParagraphsHead("2.1 Objectives"),
                __Breaker(),
                ...Objectives(),
                __Breaker(),
                __Breaker(),
                __SubParagraphsHead("2.2 Methodologies"),
                __Breaker(),
                ...Methodologies(),
                __PageBreak(),
                __ParagraphsHead("3.0 Site Capabilities Detailed Output"),
                _3_0_Overview(),
                __ParagraphsHead("3.1 Site location and staffing"),
                _3_1_LocationStaffingA(description, lead!),
                _3_1_StaffCountTable(SummarizeCount(site.accessToStaff!.staffs!)),
                _3_1_LocationStaffingB( lead!),
                _3_1_ExternalPatient(getResult(results, "4aeb"), getResult(results, "1461"), getResult(results, "8045"), getResult(results, "de3c")),
                __Breaker(),
                __ParagraphsHead("3.2 Site Experience and Training"),
                _3_2_ExperienceTraining(getResult(results, "3567"), getResult(results, "a66a"), getResult(results, "977b")),
                _3_2_StaffTrainingTable(SummarizeTraining(site.accessToStaff!.tms!)),
                _3_2_TrainingSummary(getResult(results, ""), getResult(results, "b247"), lead?.name || "", getResult(results, "3f05"), getResult(results, "7919")),
                __SubParagraphsHead("Site Experience"),
                _3_2_ExperienceSummary(getResult(results, "296f"), getResult(results, "a345"), getResult(results, "68e4"), getResult(results, "bc05"), getResult(results, "86b5")),
                _3_2_ClinicalTrainTable(SummarizeTrials(site.results, allQuestions)),
                __Breaker(),
                __ParagraphsHead("3.3 Specific Site processes"),
                __Breaker(),
                __SubParagraphsHead("3.3.1. Clinical assessments"),
                _3_3_SiteProcessSummary(),
                _3_3_ProcessAssessTable(SummarizeProcess(site.results, allQuestions)),
                __Breaker(),
                __SubParagraphsHead("3.3.2. Managing patient data for clinical trials"),
                _3_3_1_PatientDataMgt(getResult(results, "525d"), getResult(results, "6f86"), getResult(results, "1e4a"), getResult(results, "d98a"), getResult(results, "ce22"), getResult(results, "ff1c"), getResult(results, "578b"), getResult(results, "65a5"), getResult(results, "7562"), getResult(results, "ce0f"), getResult(results, "bdde"), getResult(results, "9a4b")),
                __SubParagraphsHead("3.3.3. Documentation"),
                _3_3_2_SourceDoc(getResult(results, "471d")),
                _3_3_3_DocumentationTable(SummarizeDocLocation(site.results, allQuestions)),
                __Breaker(),
                __SubParagraphsHead("3.3.4. Contracting for Trials"),
                _3_3_3_contractingTable(SummarizeContract(site.results, allQuestions)),
                __Breaker(),


                __Breaker(),
                __SubParagraphsHead("3.3.5. Auditing"),
                _3_3_4_AuditingSummary(getResult(results, "1dc9"), getResult(results, "06c8")),
                __ParagraphsHead("3.4 Site Infrastructure and facilities"),
                _3_3_4_InfraIntro(),
                __SubParagraphsHead("3.4.1 Facilities for trial patients"),
                _3_4_1_FacilityIntro(getResult(results, '3d00'), getResult(results, '3c00'), getResult(results, '4520'), getResult(results, '1350'), getResult(results, 'f5f9'), getResult(results, '24ff'), getResult(results, '24ff'), getResult(results, '6cee')),
                __SubParagraphsHead("3.4.2 Storage Facilities"),
                _3_4_2_StorageIntro(getResult(results, 'aea0'), getResult(results, '1b7c'), getResult(results, '60f2')),
                _3_4_2_FreezerTable(SummarizeFreezers(results, allQuestions)),
                _3_3_4_FreezerSummary(getResult(results, '26d0'), getResult(results, '3682'), getResult(results, '8b8f'), getResult(results, '83de'), getResult(results, '4816')),
                __SubParagraphsHead("3.4.3 Meeting monitoring needs"),
                _3_3_5_MonitorSummary(getResult(results, "bf04"), getResult(results, "2552"), getResult(results, "b10b"), getResult(results, "c371"), getResult(results, "1699"), getResult(results, "d343"), getResult(results, "ae68")),

                _3_4_3_MonitorSummary(getResult(results, "2552"), getResult(results, "bf04"), getResult(results, "1699"), getResult(results, "87d0"), getResult(results, "b255"), getResult(results, "e895"), getResult(results, "da4c"), getResult(results, "fd17"), getResult(results, "e815"), getResult(results, "")),
                __SubParagraphsHead("3.4.4 Technology and EDC Facilities"),
                _3_4_4__TechAndEDC(getResult(results, "c136"), getResult(results, "b351"), getResult(results, "24bc"), getResult(results, "bdde"), getResult(results, "d343"), getResult(results, "ae68"), getResult(results, "4140"), getResult(results, "ffa5"), getResult(results, "9a4b"), getResult(results, "ac0f"), getResult(results, "7207"), getResult(results, "a5d0"), getResult(results, 'ae42'), getResult(results, "e815"), getResult(results, "fd17"), getResult(results, "70e0"), getResult(results, "d9d3")),
                __ParagraphsHead("3.5 Pharmacy and Drug Management including Safety Reporting"),
                _3_3_4_PharmacyIntro(),
                __SubParagraphsHead("3.5.1 IMP Management"),
                _3_3_4_IMPIntro(getResult(results, '8f97')),
                _3_3_4_IMPTable(SummarizeImp(results, allQuestions)),
                __Breaker(),
                __SubParagraphsHead("3.5.2 Emergency and Safety Management "),
                _3_5_PharmacySummary(getResult(results, "663e"), getResult(results, "bd32"), getResult(results, "9bf9"), getResult(results, "577e"), getResult(results, "5b3a"), getResult(results, "12f9"), getResult(results, "caf4"), getResult(results, "ec72"), getResult(results, "2ce0"), getResult(results, "250d"), getResult(results, "4664"), getResult(results, "d884"), getResult(results, "22f2"), getResult(results, "8b5c"), getResult(results, "ac2b"), getResult(results, "0c53")),

                __ParagraphsHead("3.6 Bio Sample management"),
                __Breaker(),
                _3_6_1_BioIntro(),
                __SubParagraphsHead("3.6.1 Laboratory Facilities"),
                __Breaker(),
                _3_6_1_BioSampleIntro(labs, description.address || NO_INFO, "", "", getResult(results, "994f"), getResult(results, "09ae"), getResult(results, "765a"), getResult(results, "c390"), getResult(results, "6a76"), getResult(results, "1660"), getResult(results, "a51f"), getResult(results, "7d4f"), getResult(results, "cbdd"), getResult(results, "ea24"), getResult(results, "fc9a"), getResult(results, "75e2"), getResult(results, "e5e7"), getResult(results, "9883"), getResult(results, "5575"), getResult(results, "9abf"), getResult(results, "46e8"), getResult(results, "a090")),
                _3_6_2_FreezerTable(SummarizeFreezers6(results, allQuestions)),
                _3_6_1_Centrifuge(getResult(results, "9abf"), getResult(results, "46e8"), getResult(results, "a090")),
                __SubParagraphsHead("3.6.2 Sample Management"),
                __Breaker(),
                _3_6_2_SampleMgtA(getResult(results, "5745"), getResult(results, "b830"), getResult(results, "639f"), getResult(results, 'b003')),
                _3_6_2_ShipmentTable(SummarizeShipment(results)),
                _3_6_2_SampleMgtB(getResult(results, "cde0"), getResult(results, "cc53"), getResult(results, "eca8"), getResult(results, "eca8"), getResult(results, "a00b")),
                __Breaker(),

                __SubParagraphsHead("3.6.3 Tumour tissue Processing"),
                _3_6_3_TumorTissueA(getResult(results, "3a0e")),
                _3_6_3_TumourTissueTable(SummarizeTt(results, allQuestions)),
                _3_6_3_TumorTissueB(getResult(results, "809c"), getResult(results, "776b")),
                _3_6_2_TumorTable(SummarizeTumour(results, allQuestions)),
                __Breaker(),
                __SubParagraphsHead("3.6.4 Local Assay Availability/Experience"),
                _3_6_5_LocalAssay([getResult(results, '917e')], getResult(results, "deac"), getResult(results, "4f4c")),

                __ParagraphsHead("3.7 Local Ethics and Regulatory Requirements, including consent"),
                __Breaker(),
                __SubParagraphsHead("3.7.1 Ethics Requirements"),
                _3_7_1_EthicsRequirements(getResult(results, "c2be"), getResult(results, "fc4a"), getResult(results, "549d"), getResult(results, "8966"), getResult(results, "b206"), getResult(results, "2b2a"), getResult(results, "0d1e"), getResult(results, "9048"), getResult(results, "0dd6")),
                _3_7_1_EthicsReqTable(SummarizeEthicsReq(results, allQuestions)),
                __Breaker(),
                _3_7_1_EthicsDocTable(SummarizeDoc(results, allQuestions)),
                _3_7_1_EthicsRequirementsCont(getResult(results, "a9ea"), getResult(results, "560e"), getResult(results, "53fa"), getResult(results, "5f74"), getResult(results, "fada"), getResult(results, "e5b3"), getResult(results, "f7f0"), getResult(results, '00b6'), getResult(results, "2892")),
                __SubParagraphsHead("3.7.2 Regulatory Requirements"),
                _3_7_4_RegulatoryRequirements(getResult(results, "77ab"), getResult(results, "29f7"), getResult(results, "15f9"), getResult(results, "2db2"), getResult(results, "bbd6"), getResult(results, "a2d7"), getResult(results, "e289"), getResult(results, "ed99"), getResult(results, "80d5"), getResult(results, "c98c"), getResult(results, "ec72"), getResult(results, "f0aa")),
                __ParagraphsHead("3.8 Therapeutic Focus"),
                __Breaker(),
                _3_8_TheraFocus(lead!),
                __PageBreak(),
                __ParagraphsHead("4.0 Gap Analysis"),
                __Breaker(),
                GapAnalysis(),
                __PageBreak(),
                __ParagraphsHead("5.0 Recommendations"),
                __PageBreak(),
                __ParagraphsHead("6.0 Conclusion"),

            ],
        }],
    });

}
export const Generate_Report = (reporter: ReporterStore | undefined, site: SiteObject, id: string) => {

    if (!site.accessToStaff) {
        alert("Error Generating Report, Try Again!")
        return
    }
    reporter!.setSelectedSite(site)
    if (site.results) {
        Packer.toBlob(doc(site, reporter)).then((blob: Blob) => {

            var binaryData = [];
            binaryData.push(blob);

            const anchor = window.document.createElement('a');
            anchor.href = window.URL.createObjectURL(new Blob(binaryData, { type: "application/zip" }))

            anchor.download = `${site.description?.siteId}_${site.description?.institutionName}_${site.description?.department}_REPORT_Generate_${TODAY_MONTH}_${TODAY_YEAR}.docx`;
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
            window.URL.revokeObjectURL(anchor.href);
        });
    } else {
        alert("NONE OF THE QUESTIONNAIRE QUESTIONS HAVE BEEN ANSWERED YET")
    }
}








// Used to export the file into a .docx file

// Done! A file called 'My Document.docx' will be in your file system.