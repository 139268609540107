import React from 'react'
import NewDialog from './dialog'

type addFormProps = {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>

}

function NotAdmin({ isOpen, setIsOpen,  }: addFormProps) {
    return (
        <NewDialog title="Add Administrator" isOpen={isOpen} setIsOpen={setIsOpen} >
            <div className="space-y-2">
                <p className="mt-1 block w-full sm:text-sm border border-gray-300 focus:outline-none rounded-md p-3">
                    You do not have access to the admin portal. Kindly contact the administrators for further assistance.
                </p>

                <div className="flex justify-center">
                    <button className="p-2 border-0 bg-atomGreen mt-2 text-xs text-white px-5" onClick={() => { setIsOpen(false)  }}>Continue</button>
                </div>
            </div>
        </NewDialog>
    )
}

export default NotAdmin


