import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { LogisticsPartner } from '../../../../common/types/site'
import { useStore } from '../../../../../store/StoreProvider'
import { SiteQuestionAnswered } from '../../../../common/types/utility'
import { genId } from '../../../../admin/store/NewQuestionStore'
import { setupFireStore } from '../../../../../firebase/firebase.config'
import { ref, uploadBytes } from 'firebase/storage'
const { FirebaseStorage: storage } = setupFireStore()

type AddNewLogisticsProps = {
    open: boolean
    setOpen: any
}

export default function AddNewLogistics({ open, setOpen }: AddNewLogisticsProps) {
    const { sitesListSore: siteSore,  userStore } = useStore();
    const [partner, setPartner] = useState<LogisticsPartner>({})
    const cancelButtonRef = useRef(null)
    const site = siteSore?.selectedSite

    function addPartner() {
        let selected = siteSore?.selectedSite
        partner.regId = genId()
        selected!.lps = [...selected?.lps || [], partner]
        siteSore?.updateSite(createLog())
    }

    function handleFileUpload(e: React.ChangeEvent<HTMLInputElement>) {
        let files = e.target.files
        if (!files && files!.length < 1) {
            return
        }
        const file = files![0]
        const filename = `${site?.description?.siteId}/q/partner_${file.name.replace(" ", "_")}`
        const fileSorageRef = ref(storage, filename);
        uploadBytes(fileSorageRef, e.target.files![0]).then((snapshot) => {
            setPartner({ ...partner, "cvLink": snapshot.ref.fullPath })
        });
    }

    const createLog = () => {
        return SiteQuestionAnswered(siteSore?.selectedSite!, userStore?.currentUser.email || 'Anonymous')
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0" >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"   >
                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="sm:flex ">

                                    <div className="mt-3 text-center sm:mt-0 sm:text-left space-y-2 w-full">
                                        <Dialog.Title as="h4" className="text-lg leading-4 text-center mb-2 font-medium text-gray-900">
                                            Lab Service
                                        </Dialog.Title>

                                        <div className="flex w-full px-3  flex-col">
                                            <p className=" text-xs text-gray-700 leading-4d ">Name</p>
                                            <div className="flex w-full flex-1">
                                                <input className={" text-xs bg-gray-100 rounded  border-gray-100 p-2  focus:outline-none w-full placeholder-gray-300 text-gray-800 self-center "} name="name" value={partner.name} onChange={(e) => { setPartner({ ...partner, name: e.target.value }) }} />
                                            </div>
                                        </div>

                                        <div className="flex w-full px-3 space-x-2">
                                            <div className="flex w-full  flex-col ">
                                                <p className=" text-xs text-gray-700 leading-4d ">Country</p>
                                                <div className="flex w-full flex-1">
                                                    <input className={" text-xs bg-gray-100 rounded  border-gray-100 p-2  focus:outline-none w-full placeholder-gray-300 text-gray-800 self-center "} name="location" value={partner?.country} onChange={(e) => { setPartner({ ...partner, country: e.target.value }) }} />
                                                </div>
                                            </div>

                                            <div className="flex w-full flex-col ">
                                                <p className=" text-xs text-gray-700 leading-4d ">Location</p>
                                                <div className="flex w-full flex-1">
                                                    <input className={" text-xs bg-gray-100 rounded  border-gray-100 p-2  focus:outline-none w-full placeholder-gray-300 text-gray-800 self-center "} name="location" value={partner?.location} onChange={(e) => { setPartner({ ...partner, location: e.target.value }) }} />
                                                </div>
                                            </div>
                                        </div>



                                        <div className="flex w-full px-3 space-x-2">
                                            <div className="flex w-full  flex-col ">
                                                <p className=" text-xs text-gray-700 leading-4d ">Contact Person</p>
                                                <div className="flex w-full flex-1">
                                                    <input className={" text-xs bg-gray-100 rounded  border-gray-100 p-2  focus:outline-none w-full placeholder-gray-300 text-gray-800 self-center "} name="location" value={partner?.primaryContact} onChange={(e) => { setPartner({ ...partner, primaryContact: e.target.value }) }} />
                                                </div>
                                            </div>

                                            <div className="flex w-full flex-col">
                                                <p className=" text-xs text-gray-700 leading-4d ">Role</p>
                                                <div className="flex w-full flex-1">
                                                    <input className={" text-xs bg-gray-100 rounded  border-gray-100 p-2  focus:outline-none w-full placeholder-gray-300 text-gray-800 self-center "} name="location" value={partner?.role} onChange={(e) => { setPartner({ ...partner, role: e.target.value }) }} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex w-full px-3 flex-col">
                                            <p className=" text-xs text-gray-700 leading-4d">Address</p>
                                            <div className="flex w-full flex-1">
                                                <textarea className={" text-xs bg-gray-100 rounded  border-gray-100 p-2  focus:outline-none w-full placeholder-gray-300 text-gray-800 self-center "} name="location" value={partner?.address} onChange={(e) => { setPartner({ ...partner, address: e.target.value }) }} />
                                            </div>
                                        </div>

                                        <div className="flex w-full px-3 flex-col">
                                            <p className=" text-xs text-gray-700 leading-4d">Attach CV</p>
                                            <div className="flex w-full flex-1">
                                                {partner.cvLink ? <input className={" text-xs bg-gray-100 rounded  border-gray-100 p-2  focus:outline-none w-full placeholder-gray-300 text-gray-800 self-center "} name="cvLink" type='file' onChange={(e) => { handleFileUpload(e) }} />
                                                    : <p>{partner.cvLink}</p>}
                                            </div>
                                        </div>

                                        <div className="flex w-full px-3 space-x-2">
                                            <div className="flex w-full flex-col">
                                                <p className=" text-xs text-gray-700 leading-4d ">Phone</p>
                                                <div className="flex w-full flex-1">
                                                    <input className={" text-xs bg-gray-100 rounded  border-gray-100 p-2  focus:outline-none w-full placeholder-gray-300 text-gray-800 self-center "} name="location" value={partner?.generalNumber} onChange={(e) => { setPartner({ ...partner, generalNumber: e.target.value }) }} />
                                                </div>
                                            </div>

                                            <div className="flex w-full flex-col">
                                                <p className=" text-xs text-gray-700 leading-4d">Email Address</p>
                                                <div className="flex w-full flex-1">
                                                    <input className={" text-xs bg-gray-100 rounded  border-gray-100 p-2  focus:outline-none w-full placeholder-gray-300 text-gray-800 self-center "} name="location" value={partner?.emailAddress} onChange={(e) => { setPartner({ ...partner, emailAddress: e.target.value }) }} />
                                                </div>
                                            </div>
                                        </div>



                                        <div className="flex w-full px-3 flex-col">
                                            <p className=" text-xs text-gray-700 leading-4d">Other Numbers</p>
                                            <div className="flex w-full flex-1">
                                                <input className={" text-xs bg-gray-100 rounded  border-gray-100 p-2  focus:outline-none w-full placeholder-gray-300 text-gray-800 self-center "} name="location" value={partner?.otherNumber} onChange={(e) => { setPartner({ ...partner, otherNumber: e.target.value }) }} />
                                            </div>
                                        </div>

                                        <div className="flex w-full px-3">
                                            <p className=" text-xs text-gray-700 leading-4d">Should all Laboratory related materials be shipped to this primary Lab/Institute when referenced for a specific trial?</p>
                                            <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                                <input type="checkbox" name="toggle" id="toggle" className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer" checked={partner?.acceptAll} onChange={(e) => { setPartner({ ...partner, acceptAll: e.target.checked }) }} />
                                                <label className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
                                            </div>
                                            <label className="">YES</label>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-atomGreen text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={() => { addPartner(); setPartner({}); setOpen(false) }}
                                >
                                    Save
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={() => { setPartner({}); setOpen(false) }}
                                    ref={cancelButtonRef}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}