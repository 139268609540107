
import { DONT_NO, YES } from "../../common/constants";
import { TextFiller, TextWriter, YesNoFiller } from "../utility";

const docx = require("docx");
const { Paragraph, TextRun } = docx;

export const _3_3_4_AuditingSummary = (audited: string, auditDetails: string) => new Paragraph({
    children: [

        new TextRun({ text: "\n", break: 2 }),

        TextWriter("You reported that your site"),
        TextWriter(YesNoFiller(audited, " has been ", " has not been ")),
        TextWriter("inspected/audited"),
        TextWriter(audited === YES ? "and the audit reports provided " : DONT_NO),
        TextWriter(TextFiller(audited === YES ? auditDetails: ".")),

        new TextRun({ text: "\n", break: 2 }),

    ], spacing: {
        line: 360,
    },
})
