
import { QuestionType } from "../../common/types/question";
import { CellFillWriter, CellWriter, findQuestion, NO_INFO } from "../utility";

const docx = require("docx");
const { Table, TableRow, } = docx;


type clinicalTrailSummary = {
    description?: string
    response?: string
    count?: number
    patients?: number
}

type trailQuestionMAp = {
    questionIds: string[]
}

export const SummarizeTrials = (results: any, questions: QuestionType[]): clinicalTrailSummary[] => {
    var result: clinicalTrailSummary[] = []

    trialQuestionIdMaps.map(trail => (
        result.push({
            count: results[trail.questionIds[1]] ? results[trail.questionIds[1]] : "",
            description: findQuestion(questions, trail.questionIds[0]) as string,
            patients: results[trail.questionIds[2]] ? results[trail.questionIds[2]] : NO_INFO,
            response: results[trail.questionIds[0]] ? results[trail.questionIds[0]] : NO_INFO

        })
    ))
    return result
}

const trialQuestionIdMaps: trailQuestionMAp[] = [
    {
        questionIds: ["a97c", "f476", "f28b"]
    },
    {

        questionIds: ["c3b0", "7311", "c020"]
    },
    {

        questionIds: ["9fad", "176e", "8f57"]
    },
    {

        questionIds: ["e9e2", "9e0d", "4e49"]
    },
    {

        questionIds: ["28d0", "a4d5", "12f2"]
    },
    {

        questionIds: ["979e", "d0be", "9cd2"]
    }
    ,
    {

        questionIds: ["9f87", "61ce", "39f0"]
    },
    {

        questionIds: ["29b7", "8712", "8f3f"]
    },
    {

        questionIds: ["89ed", "af51", "0629"]
    },
  
    {

        questionIds: ["f9f9", "a35b", "f6a1"]
    }
]


export const _3_2_ClinicalTrainTable = (counts: clinicalTrailSummary[]) => new Table({
    rows: [
        new TableRow({
            children: [
                CellFillWriter("Trail Type"),
                CellFillWriter("Experience"),
                CellFillWriter("3 Year Record"),
                CellFillWriter("Patient Population in the last 3 years"),
          

            ],
        })].concat(

            counts.map(count => new TableRow({
                children: [
                    CellWriter(count.description),
                    CellWriter(count.response?.toString()),
                    CellWriter(count.count?.toString()),
                    CellWriter(count.patients?.toString())
                ]
            }))
        )

})
