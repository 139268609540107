import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import { useStore } from '../../../../store/StoreProvider';
import StarSVG from '../../../common/components/starsvg';
import { EntryRatingEnum, EntryStatusEnum, SiteMetaProps, SiteObject } from '../../../common/types/site';
import { GMT } from '../../../common/types/utility';

type queryProps = {
    content: string
    rating?: EntryRatingEnum
    status?: EntryStatusEnum
}

function Index() {

    const [query, setQuery] = useState<queryProps>({ content: '', })
    const [statusModal, setStatusModal] = useState(false)
    const [filteredSite, setFilteredSite] = useState<SiteObject[] | undefined>(undefined)
    const { adminSites, userStore } = useStore();
    const sites = adminSites?.sites

    useEffect(() => {
        setFilteredSite(sites)
    }, [sites])

    function saveChanges(site: SiteObject) {
        adminSites?.updateSite(site)
    }

    function toggleStatusModal() {
        setStatusModal(!statusModal)
    }

    useEffect(() => {
        const _sites = sites || []
        let filtered = _sites?.filter(s =>
        (
            s.description?.institutionName?.toLowerCase().includes(query.content.toLowerCase()) ||
            s.description?.department?.toLowerCase().includes(query.content.toLowerCase())))

        if (query.status)
            filtered = filtered.filter(s => s.meta?.status ===query.status)
        if (query.rating)
            filtered = filtered.filter(s => s.meta?.rating ===query.rating)
        setFilteredSite(filtered)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query])




    return (
        <div className=" w-full">

            <div className="flex justify-center px-10 bg-white shadow m-5">

                <div className='w-full md:w-2/6 flex'>
                    <p className='text-3xl font-bold text-gray-800 self-center'>Sites List</p>
                </div>

                <div className='flex  justify-between w-full md:w-4/6'>
                    <div className='w-full md:w-1/3 p-10'>
                        <input placeholder="Search with institution details" className='p-3 bg-gray-200 w-full rounded' onChange={(e) => setQuery({ ...query, content: e.target.value })} />
                    </div>
                    <div className='w-full md:w-1/3 p-10'>
                        <select title='title' className='p-3 bg-gray-200 w-full rounded' onChange={e => setQuery({ ...query, status: e.target.value as EntryStatusEnum })} >
                            <option value={""}>Choose Status</option>
                            <option selected={query.status === EntryStatusEnum.ARCHIVED} value={EntryStatusEnum.ARCHIVED}>{EntryStatusEnum.ARCHIVED}</option>
                            <option selected={query.status === EntryStatusEnum.REVIEW} value={EntryStatusEnum.REVIEW}>{EntryStatusEnum.REVIEW}</option>
                            <option selected={query.status === EntryStatusEnum.VALID} value={EntryStatusEnum.VALID}>{EntryStatusEnum.VALID}</option>
                            <option selected={query.status === EntryStatusEnum.ENTRY} value={EntryStatusEnum.ENTRY}>{EntryStatusEnum.ENTRY}</option>
                        </select>

                    </div>
                    <div className='w-full md:w-1/3 p-10'>
                        <select title='title' className='p-3 bg-gray-200 w-full rounded' onChange={e => setQuery({ ...query, rating: e.target.value as EntryRatingEnum })}>
                            <option value={""}>Choose Rating</option>
                            <option selected={query.rating === EntryRatingEnum.GREEN} value={EntryRatingEnum.GREEN}>{EntryRatingEnum.GREEN}</option>
                            <option selected={query.rating === EntryRatingEnum.RED} value={EntryRatingEnum.RED}>{EntryRatingEnum.RED}</option>
                            <option selected={query.rating === EntryRatingEnum.AMBER} value={EntryRatingEnum.AMBER}>{EntryRatingEnum.AMBER}</option>
                        </select>
                    </div>
                </div>

            </div>

            <div className="w-full overflow-x-scroll xl:overflow-x-hidden p-5 z-20 space-y-1">
                <div className="overflow-x-auto">
                    <table className="min-w-full  shadow">
                        <thead className="border-b  bg-gray-800">
                            <tr className="font-extrabold text-gray-50">
                                <th scope="col" className="text-sm  px-6 py-4 text-left">
                                    Site ID
                                </th>
                                <th scope="col" className="text-sm  px-6 py-4 text-left">
                                    Institution Name
                                </th>
                                <th scope="col" className="text-sm px-6 py-4 text-left">
                                    Created By
                                </th>
                                <th scope="col" className="text-sm px-6 py-4 text-left">
                                    Date Submitted
                                </th>
                                <th scope="col" className="text-sm  px-6 py-4 text-left">
                                    Status
                                </th>
                                <th scope="col" className="text-sm  px-6 py-4 text-left">
                                    Reviewed By
                                </th>
                                <th scope="col" className="text-sm   px-6 py-4 text-left">
                                    Last Reviewed
                                </th>
                                <th scope="col" className="text-sm px-6 py-4 text-left">
                                    Rating
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredSite?.map(site => {
                                return (
                                    <SiteItem site={site} statusChange={toggleStatusModal} save={saveChanges} user={userStore?.currentUser.email} />
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    )
}

type SiteItemProp = {
    site: SiteObject,
    statusChange: any,
    save: any,
    user?: string
}

function SiteItem({ site, statusChange, save, user }: SiteItemProp) {

    const history = useHistory()
    const { sitesListSore: siteStore } = useStore();

    function handleSwitchSvg(status: EntryRatingEnum | undefined) {
        site = { ...site, meta: { ...site.meta, rating: status, lastUpdated: GMT } }
        save(site)
    }

    function handleStatusChange(value: string) {
        let _meta: SiteMetaProps = {}
        if (value === EntryStatusEnum.REVIEW) {
            _meta = { dateReview: GMT, dateEntry: "", dateApproved: "", lastUpdated: GMT }
        }
        if (value === EntryStatusEnum.VALID) {
            _meta = { dateReview: "", dateEntry: "", dateApproved: GMT, lastReviewBy: user, lastUpdated: GMT }
        }
        if (value === EntryStatusEnum.ENTRY) {
            _meta = { dateReview: "", dateEntry: GMT, dateApproved: "", lastReviewBy: user, lastUpdated: GMT }
        }
        site = { ...site, meta: { ...site.meta, status: value as EntryStatusEnum, ..._meta } }
        save(site)
    }

    function handleSiteSelected(site: SiteObject): void {
        history.push(`/site/${site.description!.siteId}`)
        siteStore?.setSelectedSite(site)
        window.location.reload()
    }

    return (

        <tr className="border-b">
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900" onClick={() => handleSiteSelected(site)}>{site.description?.siteId}</td>
            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                <p> {site.description?.institutionName}</p>
                <p> {site.description?.department}</p>
            </td>
            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {site.meta?.createdBy}
            </td>
            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {site.meta?.dateReview}
            </td>
            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap flex">
                <select title='_' onChange={e => { handleStatusChange(e.target.value) }} className={'self-center px-5 py-1 rounded-full border border-gray-500 ' + (site?.meta?.status === EntryStatusEnum.ENTRY && "  ") + (site?.meta?.status === EntryStatusEnum.REVIEW && "  ") + (site?.meta?.status === EntryStatusEnum.ARCHIVED && " ") + (site?.meta?.status === EntryStatusEnum.VALID && "  ")}>
                    <option value={""} disabled>Choose status</option>
                    <option value={EntryStatusEnum.ENTRY} selected={EntryStatusEnum.ENTRY === site.meta?.status}>{EntryStatusEnum.ENTRY}</option>
                    <option value={EntryStatusEnum.REVIEW} selected={EntryStatusEnum.REVIEW === site.meta?.status}>{EntryStatusEnum.REVIEW}</option>
                    <option value={EntryStatusEnum.VALID} selected={EntryStatusEnum.VALID === site.meta?.status}>{EntryStatusEnum.VALID}</option>
                    <option value={EntryStatusEnum.ARCHIVED} selected={EntryStatusEnum.ARCHIVED === site.meta?.status}>{EntryStatusEnum.ARCHIVED}</option>
                </select>
            </td>
            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {site.meta?.lastReviewBy}

            </td>
            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {site.meta?.lastUpdated}
            </td>
            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                <StarSVG fill={(site.meta?.rating ===EntryRatingEnum.RED ? " red " : "") + (site.meta?.rating ===EntryRatingEnum.AMBER ? " #FFBF00 " : "") + (site.meta?.rating ===EntryRatingEnum.GREEN ? " green " : "")} switchStar={(rating) => handleSwitchSvg(rating)} />
            </td>
        </tr>
    )
}

export default observer(Index)