import React from 'react'
import { useStore } from '../../../store/StoreProvider';
import Header from '../../../components/layouts/Header'
import Activities from '../components/logs/activity'

function Logs() {
    const { userStore, logsListSore: logsStore } = useStore();
    const logs = logsStore?.items
    return (
        <>
            <Header user={userStore!.currentUser} />
            <div className="flex items-center  w-full min-h-full">
                <div className="w-full lg:w-2/3  flex-col lg:flex-row items-start lg:items-center rounded  mt-40 space-y-5">
                    <Activities logs={logs} />
                </div>
            </div>
        </>
    )
}

export default Logs