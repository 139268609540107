
import { TeamMemberType } from "../../common/types/site";
import { TextWriter } from "../utility";

const docx = require("docx");
const { Paragraph, TextRun } = docx;

export const _3_1_LocationStaffingA = (description: any, lead: TeamMemberType) => new Paragraph({
    children: [

        new TextRun({ text: "\n", break: 2 }),
        TextWriter(`Your site ${description.siteId} (eMQT reference Number) is the ${description.department} department, ${description.institutionName}, ${description.location} ${description.country} overseen by ${description.leadInvestigatorName}.`),


        new TextRun({ text: "\n", break: 1 }),
        TextWriter("Your current team for Clinical Trials, along with the number of open positions is shown in table 1.")
    ],
    spacing: {
        line: 360,
    },
})


