
import { BLankNoTFiller, TextFiller, TextWriter, YesNoFiller } from "../utility";

const docx = require("docx");
const { Paragraph, TextRun } = docx;

export const _3_5_PharmacySummary = (can24: string, hasFile:string, hasWritten: string, codeLocation: string, hasAgreement: string, emergencyDistance: string, complianceProcedure:string, dischargeInfo:string, seeInvestigator:string, evacuationProcedure: string, hasResuscitation: string, resuscitationCheck:string, resuscitationTrained: string,keepFireDoors: string, keepFireEquip: string, trainedStaff: string) => new Paragraph({
    children: [

        new TextRun({ text: "\n", break: 2 }),
        TextWriter(`Your site`),
        TextWriter(YesNoFiller(can24, ' can ', ' cannot ')),
        TextWriter(`provide 24hr medical cover and `),
        TextWriter(BLankNoTFiller(hasFile, 'has provided ', 'has not provided ')),
        TextWriter(`a written procedure that is followed in the event of a medical emergency which is to  ${hasWritten}. Emergency code break information is kept in `),
        TextWriter(TextFiller(codeLocation)),
        TextWriter(`. Your site`),
        TextWriter(YesNoFiller(hasAgreement, ' has ', ' does not ')),
        TextWriter(`have an agreement with the local emergency treatment facility which is `),
        TextWriter(TextFiller(emergencyDistance)),
        TextWriter(`.`),


        new TextRun({ text: "\n", break: 2 }),
        TextWriter(`Your site monitors subject compliance and safety by `),
        TextWriter(TextFiller(complianceProcedure)),
        TextWriter(` and provides `),
        TextWriter(TextFiller(dischargeInfo)),
        TextWriter(` when they are discharged from our facility and/or between visits. If subjects need immediate access to the investigator, they are given the following instructions `),
        TextWriter(TextFiller(seeInvestigator)),
        TextWriter(` When at the facility they`),
        TextWriter(YesNoFiller(evacuationProcedure, ' are', ' are not')),
        TextWriter( ` informed of the evacuation procedure `),

        new TextRun({ text: "\n", break: 2 }),
        TextWriter(`Your site`),
        TextWriter(YesNoFiller(hasResuscitation, ' is ', ' is not ')),
        TextWriter(`equipped with emergency resuscitation equipment which`),
        TextWriter(YesNoFiller(resuscitationCheck, ' is ', ' is not ')),
        TextWriter(`stored in an easy to access place and is serviced and checked on site. `),
        TextWriter(TextFiller((resuscitationTrained+"").toString().replaceAll(",",", "))),
        TextWriter(` are trained to use it.`),
        // keepFireDoors: string, keepFireEquip: string, trainedStaff: string
        //8b5c, ac2b, 0c53
        new TextRun({ text: "\n", break: 2 }),
        TextWriter(`Fire doors`),
        TextWriter(YesNoFiller(keepFireDoors, ' are ', ' are not ')),
        TextWriter(`kept clear and fire equipment `),
        TextWriter(YesNoFiller(keepFireEquip, ' is regularly ', ' is not regularly ')),
        TextWriter(`serviced. Staff`),
        TextWriter(YesNoFiller(trainedStaff, ' are ', ' are not ')),
        TextWriter(`trained to use the fire equipment.`),

        new TextRun({ text: "\n", break: 2 }),

    ], spacing: {
        line: 360,
    },
})
