
import { BreakWriter, HeaderWriter, TextWriter,  YesNoFiller } from "../utility";

const docx = require("docx");
const { Paragraph, TextRun } = docx;

export const _3_2_ExperienceTraining = (hasOngoingTraining: string, trainingDetails:string, recordMaintained:string) => new Paragraph({
    children: [

        new TextRun({ text: "\n", break: 2 }),

        TextWriter('Your site'),
        TextWriter(YesNoFiller(hasOngoingTraining, ' does ', ' does not ')),
        TextWriter('have an ongoing training programme for Clinical Trials. '),
        BreakWriter(),
        TextWriter('Staff training records '),
        TextWriter(YesNoFiller(recordMaintained, ' are ', ' are not ')),
        TextWriter('maintained and up to date for staff.'),
        BreakWriter(),
        HeaderWriter(`Staff training for Clinical Trials is listed in Table 2`),
        BreakWriter()

    ],
    spacing: {
        line: 360,
    },
})
