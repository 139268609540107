import { action, makeObservable, observable, reaction } from "mobx";
import { QuestionType, FormType } from "../../common/types/question";
import { SiteObject } from "../../common/types/site";
import { ReporterService } from "../services/ReporterService";

export class ReporterStore {

    allForms: FormType[] = []
    allQuestions: QuestionType[] = []
    site: SiteObject = {}
    siteId: string = ""
    isLoading: boolean = false
    constructor(private reportServices: ReporterService) {
        makeObservable(this, {
            allForms: observable,
            allQuestions: observable,
            site: observable,
            siteId: observable,
            isLoading: observable,
            setIsLoading: action.bound,
            setSelectedSite: action.bound
        });

        reaction(() => this.siteId, (state) => {
            this.fetchSite(this.siteId)
        })

        this.initialize();

    }

    async initialize() {
        this.allforms()
    }

    async allforms() {
        let _question: QuestionType[] = []
        const _forms = await this.reportServices.getAllForms();
        _forms.forEach(form => {
            form.subs!.forEach(sub => {
                const subQuestion = sub.questions ? sub.questions : []
                subQuestion.forEach(q => {
                    _question.push(q)
                    if (q.followUps && q.followUps.length > 0)
                        _question = [..._question, ...q.followUps]
                });
            });
        });
        this.allForms = _forms
        this.allQuestions = _question
    }

    setIsLoading(flag: boolean) {
        this.isLoading = flag
    }

    async fetchSite(siteId: string) {
        this.setIsLoading(true)
        this.reportServices.getSite(siteId).then((response) => {
            this.setSelectedSite(response)
            this.setIsLoading(false)
        })
    }

    setSelectedSite(item: SiteObject) {
        this.site = item;
    }

   
}

export function genId(): string {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
}
