import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { setupFireStore } from "../../firebase/firebase.config";
import { FIREBASE_USER_NOT_FOUND, PASSWORD } from "../../modules/common/constants";

const { Auth } = setupFireStore()

export function authenticateWithFireBase(email: string) {
    signInWithEmailAndPassword(Auth, email, PASSWORD)
        .catch((error) => {
            const errorCode = error.code;
            if (errorCode === FIREBASE_USER_NOT_FOUND) {
                createUserWithEmailAndPassword(Auth, email, PASSWORD)
                    .then(() => {
                        signInWithEmailAndPassword(Auth, email, PASSWORD)
                    })
            }
        });
}