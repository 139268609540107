

import { TeamMemberType } from "../../common/types/site";
import { TextWriter } from "../utility";

const docx = require("docx");
const { Paragraph, TextRun } = docx;

export const _3_8_TheraFocus = (lead :TeamMemberType) => new Paragraph({
    children: [
      
        TextWriter(`You have reported that your therapeutic focus is primarily ${lead.specialties?.join(', ')} with additional interest and expertise in ${lead.otherPSDs?.join(', ')}`),
        new TextRun({ text: "\n", break: 2 }),
       
    ], spacing: {
        line: 360,
    },
})
