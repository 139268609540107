import { XIcon } from '@heroicons/react/outline'
import React from 'react'
import { QuestionnaireType } from '../common/types/question'

type QAChildProps = {
    qa: QuestionnaireType,
    setQA: React.Dispatch<React.SetStateAction<QuestionnaireType>>
}

function VFileQA({ qa, setQA }: QAChildProps) {
    const { question } = qa

    function handleDeleteItemFromList(res: string, index: number): void {
        let holder = qa.response as string[] || []
        let _holder = holder.filter(x => x !== res)
        setQA({ ...qa, response: [..._holder] })
    }

    function handleAddMoreToCheckListList() {
        let _holder = qa.response as string[] || []
        setQA({ ...qa, response: [..._holder, "_REQUIRE_SIGNATURE"] })

    }

    function handleEditCheckMoreListItem(index: number, e: React.ChangeEvent<HTMLInputElement>, res: string): void {
        let holder = qa!.response as string[] || []
        let signature = res.includes("_REQUIRE_SIGNATURE") ? "_REQUIRE_SIGNATURE" : "_REQUIRE_NO_SIGNATURE"
        holder[index] = e.target.value + signature
        setQA({ ...qa, response: holder })
    }

    function handleChangeSignatureRequirement(index: number, e: React.ChangeEvent<HTMLInputElement>, res: string): void {
        let holder = qa!.response as string[] || []
        let signature = e.target.checked ===true ? "_REQUIRE_SIGNATURE" : "_REQUIRE_NO_SIGNATURE"
        res = res.replace("_REQUIRE_SIGNATURE", "").replace("_REQUIRE_NO_SIGNATURE", "")
        holder[index] = res + signature
        setQA({ ...qa, response: holder })
    }

    return (
        <div className="flex w-full my-2 py-2" key={question.sectionId + question.questionId}>
            <p className="font-bold self-center w-6/12"> {question.statement}</p>
            <div className="w-1/12"></div>
            <section className={"flex-1 self-center"}>
                {[...qa.response as string[]].map((res, index) =>
                (<div key={index} className='flex space-x-2' >
                    <input title='-' type='checkbox' className='self-center' checked={res.includes("_REQUIRE_SIGNATURE")} onChange={(e) => handleChangeSignatureRequirement(index, e, res)} />
                    <input className="rounded w-full p-2 border" placeholder="Answer here" onChange={(e) => handleEditCheckMoreListItem(index, e, res)} value={res.replace("_REQUIRE_SIGNATURE", "").replace("_REQUIRE_NO_SIGNATURE", "")} />
                    <XIcon className='h-4 w-5 self-center' onClick={() => handleDeleteItemFromList(res, index)} />
                </div>))}
                <div className="flex">
                    <button className="p-1 px-3 rounded mt-1 border bg-green-50" onClick={() => { handleAddMoreToCheckListList() }}>Add New</button>
                </div>
            </section>
        </div>
    )
}

export default VFileQA
