
import { TextWriter } from "../utility";

const docx = require("docx");
const { Paragraph, TextRun } = docx;

export const GapAnalysis = () => new Paragraph({
    children: [

        TextWriter("The assessment detailed in Section 3 identified the gaps outlined below in [insert relevant topics from 3.1-3.6 above]. eMQT has evaluated the data provided and collated using ATOM to assess the gaps that should be addressed to ensure Pharma sees your site as fully compliant and able to participate in the trials they aim to run."),
       
        new TextRun({ text: "\n", break: 2 })
    ],
    spacing: {
        line: 360,
    },
})
