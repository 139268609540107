import React, { useState } from 'react'
import Header from '../../../../components/layouts/Header'
import { useStore } from '../../../../store/StoreProvider';
import { PlusIcon, SaveAsIcon, XIcon } from '@heroicons/react/outline';
import AddSearchCriterial from '../../components/dialog/add_search_criterial';
import { SavedSearchQuery, SearchQuery } from '../../types';
import { QueryConstraint, where } from 'firebase/firestore';
import { observer } from 'mobx-react';
import { searchKey } from './keys';
import './style.css';
import { useHistory } from 'react-router-dom';
import { SiteObject } from '../../../common/types/site';
import SearchHistory from '../../components/dialog/search_history';
import SaveQuery from '../../components/dialog/save_history';
import { genId } from '../../../admin/store/NewQuestionStore';
import { CSVLink } from "react-csv";

function Index() {
    const { searchStore, userStore, sitesListSore: siteStore } = useStore();
    const history = useHistory()

    const [search, setSearch] = useState(false)
    const [_history, _setHistory] = useState(false)
    const [_query, _setQuery] = useState(false)
    const queries = searchStore?.queries || []
    const results = searchStore?.results || []
    const [query, setQuery] = useState<SavedSearchQuery>({ title: "", id: genId(), queries: searchStore?.queries || [] })

    const handleAddNewQuery = (query: SearchQuery) => {
        searchStore?.setQuery([...queries, query])
    }

    function handleRemoveQuery(query: SearchQuery, index: number): void {
        searchStore?.setQuery([...queries].filter((x, i) => i !== index))
    }

    function handlePerformSearch(): void {
        searchStore?.setResults([])
        searchStore?.setLoading(true)
        let _queries: QueryConstraint[] = []
        queries.forEach(query => {
            _queries.push(where(query.key!, "==", query.value))
        });
        searchStore?.performSearch(_queries).then(() => searchStore?.setLoading(false)
        )
    }

    function handleSiteSelected(site: SiteObject): void {
        history.push(`/site/${site.description!.siteId}`)
        siteStore?.setSelectedSite(site)
    }

    function getSearchHistory(): void {
        _setHistory(!_history)
    }

    function handleSaveQuery(search: SavedSearchQuery): void {
        searchStore!.saveSearch({ ...search, queries: searchStore!.queries })
    }
    
    function generateExportData(site: SiteObject): string | import("react-csv/components/CommonPropTypes").Data {
        var response = searchStore!.allQuestions.map((q) => ({ question: q.statement, answer: getResult(site.results, q.questionId) }))
        var data: any[] = []
        var tms = site.accessToStaff?.tms?.map(function (elem) {
            return `${elem.name} - ${elem.roles?.map(e => e.replace('_', '')).join(' ')}`;
        }).join(",");
        data.push({ ...site.description, "teamMembers": tms, "question": "", "answer": "" })
        response.forEach(element => {
            data.push({ "question": element.question, "answer": element.answer });
        });
        return data;
    }

    const getResult = (results: any, questionId: string): string => {
        const _results = new Map(Object.entries({ ...results }));
        _results.get("50a3")
        let _res: any = _results.get(questionId) || ""

        return _res.toString()
    }

    function generateAllData(): string | import("react-csv/components/CommonPropTypes").Data {
        var data: any[] = []
        results.forEach(site => {
            var response = searchStore!.allQuestions.map((q) => ({ question: q.statement, answer: getResult(site.results, q.questionId) }))
            var tms = site.accessToStaff?.tms?.map(function (elem) {
                return `${elem.name} - ${elem.roles?.map(e => e.replace('_', '')).join(' ')}`;
            }).join(",");

            data.push({ ...site.description, "teamMembers": tms, "question": "", "answer": "" })
            response.forEach(element => {
                data.push({ "question": element.question, "answer": element.answer });
            });
        });
        return data;
    }

    return (

        <>
            <Header user={userStore!.currentUser!} />
            <div className="flex items-center  w-full min-h-full justify-center">
        <div className="w-full lg:w-2/3 p-5 flex flex-col lg:flex-row items-start lg:items-center rounded bg-white shadow my-20 mt-36">
                    <h4 className="font-semibold text-lg my-2 px-2">Site Search</h4>
                    <div className="w-full pb-5 ">
                        { /**  <input placeholder="Simple search by - site id, email or phone" className="p-2 bg-white w-full focus:outline-none" />*/}
                        <div className="flex">
                            {queries.map((query, index) => <div className="pl-5 py-2 bg-gray-50 flex m-1 text-xs" key={index}>
                                <span className="font-semibold">{searchKey.get(query.key!)} </span>
                                <span className="px-1">:</span>
                                <span className="text-gray-400"> {query.value}</span>
                                <XIcon onClick={() => handleRemoveQuery(query, index)} className="h-3 w-3 self-center  cursor-pointer ml-5 mr-2 text-red-600" />
                            </div>)}
                            <div className='text-xs flex   rounded cursor-pointer px-2 p-1 bg-gray-50 mx-1' onClick={() => setSearch(!search)}>
                                <label className='self-center px-2'>Add New</label>
                                <PlusIcon className="w-4 self-center text-atomGreen" />
                            </div>
                            {queries.length > 0 && <div className='text-xs flex   rounded cursor-pointer px-2 bg-atomGreen' onClick={() => _setQuery(!_query)}>
                                <label className='self-center px-2'>Save Search</label>
                                <SaveAsIcon className="w-4 self-center text-gray-800" />
                            </div>}

                        </div>
                        <div className='flex justify-between w-full border-t'>
                            <button className='px-10 my-2 bg-atomGreen py-1 text-gray-50 rounded' onClick={() => handlePerformSearch()}>Search</button>
                            <button className='px-10 my-2 text-atomGreen py-1  rounded' onClick={() => getSearchHistory()}>Search History</button>
                        </div>
                    </div>

                    <section className="w-full">
                        <h4>Search Results</h4>
                        <div>
                            {!searchStore?.loading && <i className="fas fa-circle-notch fa-spin" />}
                            {results.length === 0 ? <div className='flex justify-center w-full'>
                                <h4 className='text-center text-2xl font-semibold text-gray-400 justify-self-center place-content-center'>No Sites Found</h4>
                            </div> :
                                <div>
                                    {results.map((site, index) =>
                                        <div key={index} className='w-full bg-gray-100 my-2 p-2 cursor-pointer shadow flex'>

                                            <div className='w-1/4 px-5 text-atomGreen'>
                                                <h4 className='font-bold'>{site.description?.institutionName}</h4>
                                                <small>{site.meta?.status}</small>
                                            </div>
                                            <div className='w-1/4 space-y-1 text-sm'>
                                                <h4 className=''>{site.description?.phoneNumber}</h4>
                                                <h4 className=''>{site.description?.address}</h4>
                                                <h4 className=''>{site.description?.leadInvestigatorName}</h4>
                                            </div>
                                            <div className='w-1/4 flex flex-col space-y-1 text-sm'>
                                                <label>Team Member count: <span>{site.accessToStaff?.tms?.length}</span></label>
                                                <button className='p-1 text-sm border' onClick={() => handleSiteSelected(site)}>View Details</button>
                                                <CSVLink
                                                    data={generateExportData(site)}
                                                    asyncOnClick={true}
                                                    title='Export Site'
                                                    filename={site.description?.department || "SiteExport"}
                                                    className='p-1 text-sm bg-atomGreen text-white text-center rounded-sm'
                                                    onClick={(event, done) => {
                                                    }}
                                                >
                                                    Download me
                                                </CSVLink>
                                            </div>
                                        </div>)
                                    }
                                </div>}
                            <div className='my-10'>
                                {results.length > 0 && <CSVLink
                                    data={generateAllData()}
                                    asyncOnClick={true}
                                    title='Export Site'
                                    filename='SearchResult'
                                    className='p-2 text-sm bg-atomGreen text-white text-center rounded-sm px-10 my-10'
                                    onClick={(event, done) => {
                                    }}
                                >
                                    Download all Site Result
                                </CSVLink>}
                            </div>

                        </div>
                    </section>
                </div>
                <AddSearchCriterial isOpen={search} setIsOpen={setSearch} save={handleAddNewQuery} />
                <SearchHistory isOpen={_history} setIsOpen={_setHistory} />
                <SaveQuery isOpen={_query} setIsOpen={_setQuery} save={() => handleSaveQuery(query)} query={query} setQuery={setQuery} />
           </div>
        </>
    )
}

export default observer(Index)