import React, { useState } from 'react'
import { useStore } from '../../../../../store/StoreProvider';
import { countries } from '../../../../common/constants/countries';
import { SiteGeneralDescription } from '../../../../common/types/site';

function Index() {
    const { sitesListSore: siteSore } = useStore();   
     //eslint-disable-next-line    @typescript-eslint/no-unused-vars
    const [created, setCreated] = useState(false)
    const [error, setError] = useState({ email: "", service: "", siteId: "", leadName: "" })

    const newEntry = siteSore?.selectedSite!.description!

    function handleTextChange(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLSelectElement>): void {
        setError({ email: "", service: "", siteId: "", leadName: "" })
        let copiedValue = { ...newEntry }
        let name = e.target.name as keyof SiteGeneralDescription
        copiedValue[name] = e.target.value as string
        copiedValue.phoneNumber = copiedValue.phoneNumber?.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
        siteSore!.updateDescription(copiedValue)
    }

    return (
        <div>

            <div className="z-40 bg-white flex-1">
                <div className=" border-atomGreen mx-1">
                    <div className="p-10">
                        <div className="space-y-6">
                            <div></div>
                            <div className="flex w-full px-3">
                                <p className="text-base text-gray-700 leading-4d w-4/12 self-center">Site ID</p>
                                <div className="flex w-full flex-1">
                                    <p className="text-base leading-4d text-gray-400 w-full px-3">{newEntry.siteId} </p>
                                </div>
                            </div>
                            <div className="flex w-full px-3 ">
                                <p className="text-base text-gray-700 leading-4d w-4/12 self-center">Institution Name</p>
                                <div className="flex w-full flex-1">
                                    <input className={" bg-white rounded  border-gray-100 p-3  focus:outline-none w-full placeholder-gray-300 text-gray-800 self-center " + (created === false && "  bg-gray-50 border ")} disabled={created} type="" value={newEntry.institutionName} name="institutionName" onChange={(e) => handleTextChange(e)} />
                                </div>
                            </div>
                            <div className="flex w-full px-3">
                                <p className="text-base text-gray-700 leading-4d w-4/12 self-center">Department</p>
                                <div className="flex w-full flex-1">
                                    <input title='D' placeholder='H' className={" bg-white rounded  border-gray-100 p-3  focus:outline-none w-full placeholder-gray-300 text-gray-800 self-center " + (created === false && "  bg-gray-50 border ")} disabled={created} type="" value={newEntry.department} name="department" onChange={(e) => handleTextChange(e)} />
                                </div>
                            </div>
                            <div className="flex w-full px-3">
                                <p className="text-base text-gray-700 leading-4d w-4/12 self-center">Country</p>
                                <div className="flex w-full flex-1">
                                    <select className={" self-center border-gray-100 p-3 my-1 focus:outline-none placeholder-gray-300 text-sm text-gray-800 w-full " + (created === false && "  bg-gray-100 border ")} disabled={created} name="country" onChange={(e) => { handleTextChange(e) }}>
                                        <option defaultValue="Choose Country">Country</option>
                                        {countries.map(country => (
                                            <option value={country.name} key={country.isoCode} selected={newEntry.country === country.name}>{country.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="flex w-full px-3">
                                <p className="text-base text-gray-700 leading-4d w-4/12 self-center">Town/City</p>
                                <div className="flex w-full flex-1">
                                    <input className={" bg-white rounded  border-gray-100 p-3  focus:outline-none w-full placeholder-gray-300 text-gray-800 self-center " + (created === false && "  bg-gray-50 border ")} disabled={created} type="" value={newEntry.location} name="location" onChange={(e) => handleTextChange(e)} />
                                </div>
                            </div>
                            <div className="flex w-full px-3">
                                <p className="text-base text-gray-700 leading-4d w-4/12 self-center">Address</p>
                                <div className="flex w-full flex-1">
                                    <textarea className={" bg-white rounded  border-gray-100 p-3  focus:outline-none w-full placeholder-gray-300 text-gray-800 self-center " + (created === false && "  bg-gray-50 border ")} disabled={created} name="address" onChange={(e) => handleTextChange(e)} value={newEntry.address} />
                                </div>
                            </div>



                            <div className="flex w-full px-3">
                                <p className="text-base  text-gray-700 leading-4d w-4/12 self-center">Site General Phone number</p>
                                <div className="flex w-full flex-1">
                                    <select title='-' className={" self-center border-gray-100 p-3 my-1 focus:outline-none placeholder-gray-300 text-sm text-gray-800 w-30 " + (created === false && "  bg-gray-100 border ")} disabled={created} name="ccode" onChange={(e) => { handleTextChange(e) }}>
                                        <option defaultValue="Code">code</option>
                                        {countries.map(country => (
                                            <option value={country.dialCode} key={country.isoCode} selected={newEntry.ccode === country.dialCode}>{country.dialCode}</option>
                                        ))}
                                    </select>

                                    <input title='-' className={"bg-white rounded border-gray-100 p-3  focus:outline-none placeholder-gray-300 text-gray-800 self-center flex-1 " + (created === false && "  bg-gray-50 border ")} disabled={created} type="phone" value={newEntry.phoneNumber} name="phoneNumber" onChange={(e) => handleTextChange(e)} />
                                </div>
                            </div>

                            <div className="flex w-full px-3">
                                <p className="text-base  text-gray-700 leading-4d w-4/12 self-center">Site Email Address</p>
                                <div className="flex w-full flex-1 flex-col">
                                    <input className={" bg-white rounded  border-gray-100 p-3  focus:outline-none w-full placeholder-gray-300 text-gray-800 self-center " + (created === false && "  bg-gray-50 border ")} disabled={true} type="" value={newEntry.emailAddress} name="emailAddress" onChange={(e) => handleTextChange(e)} />
                                    {error.email && <p className="float-left text-red-400">{error.email}</p>}
                                </div>
                            </div>

                            <div className="flex w-full px-3">
                                <p className="text-base  text-gray-700 leading-4d w-4/12 self-center">Lead Investigator Name </p>
                                <div className="flex w-full flex-1">
                                    <input disabled={true} className={"bg-white rounded  border-gray-100 p-3  focus:outline-none w-full placeholder-gray-300 text-gray-800 self-center  " + (created === false && "  bg-gray-50 border ")} type="" value={newEntry.leadInvestigatorName} name="leadInvestigatorName" onChange={(e) => handleTextChange(e)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Index