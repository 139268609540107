
import { RootStore } from "../../../store/types/rootstore";
import { ReporterService } from "../services/ReporterService";
import { ReporterStore } from "./ReporterStore";

export type ReporterStoreTypes = {
    reporterStore?: ReporterStore;
};

export function injectReportIntoRoot(root: RootStore) {
    const service = new ReporterService();
    root.reporterStore = new ReporterStore(service);
}