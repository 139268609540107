import React from 'react'

function Skeleton() {
    return (
        <div className="w-full rounded-md mx-auto my-20">
            <div className="flex animate-pulse flex-row items-center  justify-evenly space-x-5">
                <div className="w-24 bg-gray-300 h-24 rounded-full ">
                </div>
                <div className="w-24 bg-gray-300 h-24 rounded-full ">
                </div>
                <div className="w-24 bg-gray-300 h-24 rounded-full ">
                </div>
                <div className="w-24 bg-gray-300 h-24 rounded-full ">
                </div>
            </div>
        </div>


    )
}

export default Skeleton

