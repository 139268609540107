
import { HeadingLevel } from "docx";


const docx = require("docx");
const { Paragraph, TextRun } = docx;

export const __ParagraphsHead = (header: string) => new Paragraph({
    children:[
        new TextRun({  text: header,
            color: "#000000", font: "Arial",}),

    ],font: "Arial",
    heading: HeadingLevel.HEADING_1,
});
