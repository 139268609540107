import { observer } from 'mobx-react';
import React from 'react'
import { useStore } from '../../store/StoreProvider';
import QuestionItem from './QuestionItem';

type QuestionnaireProps = {
    subId: string
    showTitle?: boolean
}
function Questionnaire({ subId, showTitle }: QuestionnaireProps) {

    const { siteQuestioningStore: siteQuestions } = useStore();
    const sub = siteQuestions!.selectedSection!.subs?.find(({ id }) => id === subId);
    let questions = sub?.questions?.filter(q => q.isLive && !q.isAdminOnly)

    return (
        <div className="py-5">
            {showTitle && <h6 className=" px-3 -mx-2 text-base bg-gray-100 text-atomGreen uppercase">{sub?.title}</h6>}
            {sub && questions!.map((question, index) => <QuestionItem q={question} key={index + question.questionId + index} />)}
        </div>
    )
}


export default observer(Questionnaire)