import { collection, getDocs } from "firebase/firestore";
import { setupFireStore } from "../../../firebase/firebase.config";
import { SiteActivityType } from "../types/site";

const { Firestore: db } = setupFireStore()

export class LogService {


    async fetchAllLogs(): Promise<SiteActivityType[]> {
        let results: SiteActivityType[] = [];
        const response = collection(db, "logs");
        const data = await getDocs(response);
        data.docs.forEach((item) => {
            results = [...results, item.data() as SiteActivityType];
        });

        return results;
    }
}