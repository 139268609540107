

import { TextWriter } from "../utility";

const docx = require("docx");
const { Paragraph, TextRun } = docx;

export const _3_3_4_PharmacyIntro = () => new Paragraph({
    children: [
      
        new TextRun({ text: "\n", break: 2 }),
        TextWriter("Management of the IMP throughout the lifecycle of any trial and appropriate safety reporting is paramount. This section reports on your sites experience and facilities in this regard."),
        new TextRun({ text: "\n", break: 2 }),
       
    ], spacing: {
        line: 360,
    },
})
