
import { TextWriter, YesNoFiller } from "../utility";

const docx = require("docx");
const { Paragraph, TextRun } = docx;

export const _3_3_5_MonitorSummary = (hasSpace: string, hasLargeRoom: string,  canAccommodate: string,withRestriction: string, willHaveInternet: string, isFastInternet: string, speedInternet: string,) => new Paragraph({
    children: [


        new TextRun({ text: "\n", break: 2 }),
        TextWriter("Your site "),
        TextWriter(YesNoFiller(hasSpace, "has ", "has no ")),
        TextWriter("space for monitoring activities and"),
        TextWriter(YesNoFiller(hasLargeRoom, " does have ", " does not have ")),

        TextWriter("larger meeting room e.g. initiation sessions and"),
        TextWriter(YesNoFiller(canAccommodate, " can ", " can not ")),

        TextWriter("accommodate regular visits from a monitor"),
        TextWriter(YesNoFiller(withRestriction, " with ", " without ")),

        TextWriter("restrictions. The monitor"),
        TextWriter(YesNoFiller(willHaveInternet, " will ", " will not ")),

        TextWriter("be able to have easy internet access whilst on the site."),
        new TextRun({ text: "\n", break: 2 }),

    ], spacing: {
        line: 360,
    },
})
