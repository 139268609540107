import React from 'react'
import { QuestionnaireType } from '../common/types/question'

type QAChildProps = {
    qa: QuestionnaireType,
    setQA: React.Dispatch<React.SetStateAction<QuestionnaireType>>
}

function MultipleQA({ qa, setQA }: QAChildProps) {
    const { question } = qa
    return (
        <section className={"w-full flex flex-col  my-2 py-2"} key={question.sectionId + question.questionId}>
            <div className="flex-col w-full">
                <div className="flex w-full">
                    <p className="font-bold self-center w-6/12"> {question.statement}</p>
                    <div className="w-1/12"></div>

                    {question.options!.length > 3 ?
                        (<div className="w-4/12 px-1">
                            {question!.options?.map((option, i) => {
                                let response = qa.response as string[];
                                let selected = response.includes(option)
                                return (<div className="flex" key={question.questionId + i}>
                                    <input title='-' className="w-3 mr-2 self-center" type="checkbox" name={question.questionId} onChange={() => setQA({...qa, response: [...qa.response as string[], option]})} checked={selected} />
                                    <label className="p-2 flex-1 w-full self-center"> {option} </label>
                                </div>)
                            })}
                        </div>) : (<div className="w-4/12 px-1 flex">
                            {question!.options?.map((option, i) => {
                                let response = qa.response as string[];
                                let selected = response.includes(option)
                                return (<div className="flex" key={question.questionId + i}>
                                    <input title='-' className="w-3 mr-2 self-center" type="checkbox" name={question.questionId}  onChange={() => setQA({...qa, response: [...qa.response as string[], option]})} checked={selected} />
                                    <label className="p-2 flex-1 w-full self-center"> {option} </label>
                                </div>)
                            })}
                        </div>)
                    }
                </div>
            </div>
        </section>
    )
}

export default MultipleQA