

import { ParagraphBreaker } from "../utility";

const docx = require("docx");
const { Paragraph, TextRun } = docx;

export const Methodologies = () => [
 
    Bullet0Para("eMQT uses the output from our internal tool ATOM (Assessment Tool for Overseas Markets) to evaluate site readiness for participation in ICH GCP compliant trials as required by Pharma. Sources of information used to address the objectives include:"),

    ParagraphBreaker(),

    Bullet1Para("Internal data from ATOM as provided by your site in meetings and/or site visits"),


    Bullet1Para("External, public and generally available relevant information"),

    ParagraphBreaker(),

    Bullet0Para("Initial data about your site was collected via [insert type of mtg i.e. phone/video/in person] during [insert month/yr] when [insert contact] provided the responses to our questions for the data required for ATOM. Following receipt and entry of the data, together with review and inclusion of any relevant external data sources such as local regulatory/Health Ministry requirements"),
    ParagraphBreaker(),

    Bullet0Para("The output from ATOM is then summarised in the form of this ISA (Individual Site Assessment) report together with a detailed gap analysis. eMQT will grade the output according to the site readiness for designation as a Centre of Excellence together with recommendations on how to fill identified gaps."),
    ParagraphBreaker()

]

export function Bullet0Para(content: string) {
    return new Paragraph({
        children: [new TextRun({
            text: content, font: "Arial" ,  size: 22,
        })],
        bullet: {
            level: 0,
        }, spacing: {
            line: 360,
        }, font: "Arial"
    })
}


export function Bullet1Para(content: string) {
    return new Paragraph({
        children: [new TextRun({
            text: content, font: "Arial",   size: 22,
        })],
        bullet: {
            level: 1,
        }, spacing: {
            line: 360,
        }, font: "Arial"
    })
}

export function Bullet2Para(content: string) {
    return new Paragraph({
        children: [new TextRun({
            text: content, font: "Arial",   size: 22,
        })],
        bullet: {
            level: 2,
        }, spacing: {
            line: 360,
        }, font: "Arial"
    })
}