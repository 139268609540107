import React from 'react'
import { QuestionnaireType } from '../common/types/question'

type QAChildProps = {
    qa: QuestionnaireType,
    setQA: React.Dispatch<React.SetStateAction<QuestionnaireType>>
}

function LongQA({ qa, setQA }: QAChildProps) {
    const { question } = qa
    return (
        <div className="flex w-full my-2 py-2" key={question.questionId + question.sectionId}>
            <p className="font-bold self-center w-6/12"> {question.statement}</p>
            <div className="w-1/12"></div>
            <section className={"flex-1 self-center"}>
            <input className="bg-gray-50 border  p-2 px-3  focus:outline-none w-full placeholder-gray-300  text-gray-700" placeholder="Answer here" value={qa.response} onChange={(e) => setQA({...qa, response:e.target.value})} />
            </section>
        </div>
    )
}

export default LongQA




