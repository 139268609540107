

import { DONT_NO, YES } from "../../common/constants";
import { BLankNoTFiller, TextWriter, YesNoFiller } from "../utility";

const docx = require("docx");
const { Paragraph, TextRun } = docx;

export const _3_6_2_SampleMgtB = ( residue: string, canStoreResidue: string, haveDestroyDoc: string, canProvideDoc: string, providedDoc: string) => new Paragraph({
    children: [

        new TextRun({ text: "\n", break: 2 }),
        TextWriter(`Your routine`),
        TextWriter(YesNoFiller(residue, " dispose ", " do not dispose ")),
        TextWriter(`any residual sample after analysis/aliquots for shipment are completed. You are`),
        TextWriter(YesNoFiller(canStoreResidue, ' able ', ' not able ')),
        TextWriter(`to store residual sample until the end of the study if required. There`),
        TextWriter(YesNoFiller(haveDestroyDoc, ' is ', ' is not ')),
        TextWriter(`a documented destruction process and evidence of destruction`),
        TextWriter(YesNoFiller(canProvideDoc, ' can ', ' cannot ')),
        TextWriter(`be provided.`),
        TextWriter(canProvideDoc === YES? ` The process document`: DONT_NO),
        TextWriter(canProvideDoc === YES? BLankNoTFiller(providedDoc, ' has been provided', ' has not been provided'): DONT_NO),
        new TextRun({ text: "\n", break: 2 }),

    ], spacing: {
        line: 360,
    },
})
