import React, { useState } from 'react'
import { QResponseTypeEnum, QuestionTagEnum, QuestionType } from '../../../common/types/question'
import AddFollowQuestion from '../../components/dialog/add_follow_question'

type EditQuestionProps = {
    q: QuestionType | undefined,
    index: number | undefined,
    handleQuestionUpdate: any,
    handleDeleteOption: any,
    handleAddNewOption: any,
    handleAddFollowUp: any
}

export default function EditQuestion({ q, index, handleQuestionUpdate, handleDeleteOption, handleAddNewOption, handleAddFollowUp }: EditQuestionProps): React.ReactElement {

    const [seeAddQuestion, setSeeAddQuestion] = useState(false)
    const [newOption, setNewOption] = useState("")
    const [followMeta, setFollowMeta] = useState("")

    if (!q) {
        return (
            <div className="w-full p-3">
                <h4 className="">Select a question to view and edit content.</h4>
            </div>)
    }
    return (
        <div key={q?.questionId} className="w-full p-3">
            <div className="pb-2">
                <p className="font-bold text-gray-700 my-1 text-xs">Question</p>
                <textarea className="p-2 w-full rounded border border-gray-100 h-32 text-xs" onChange={(e) => handleQuestionUpdate(q, e, "statement", index)}>{q?.statement}</textarea>
            </div>
            <div className="pb-2">
                <p className="font-bold text-gray-700 my-1 text-xs">Report Output</p>
                <textarea className="p-2 w-full rounded border border-gray-100 h-16 text-xs" onChange={(e) => handleQuestionUpdate(q, e, "reportOutput", index)}>{q?.reportOutput}</textarea>
            </div>

            <div className="pb-2">
                <p className="font-bold text-gray-700 my-1 text-xs">Response Type</p>
                <select onChange={(e) => { handleQuestionUpdate(q, e, "type", index) }} className="p-2 rounded w-full border border-gray-100 text-xs">
                    <option defaultValue="">Choose response type</option>
                    <option selected={q?.type === QResponseTypeEnum.SHORT} value={QResponseTypeEnum.SHORT}>Short Text</option>
                    <option selected={q.type === QResponseTypeEnum.INTEGER} value={QResponseTypeEnum.INTEGER}>Number</option>

                    <option selected={q?.type === QResponseTypeEnum.LONG} value={QResponseTypeEnum.LONG}>Long Text</option>
                    <option selected={q?.type === QResponseTypeEnum.DATE} value={QResponseTypeEnum.DATE}>DATE</option>
                    <option selected={q?.type === QResponseTypeEnum.SINGLE} value={QResponseTypeEnum.SINGLE}>Single Choice</option>
                    <option selected={q?.type === QResponseTypeEnum.MULTIPLE} value={QResponseTypeEnum.MULTIPLE}>Multiple Answers</option>
                    <option selected={q?.type === QResponseTypeEnum.LIST} value={QResponseTypeEnum.LIST}>List</option>
                    <option selected={q?.type === QResponseTypeEnum.FILE} value={QResponseTypeEnum.FILE}>File Upload</option>
                    <option selected={q?.type === QResponseTypeEnum.STAFF} value={QResponseTypeEnum.STAFF}>Staff</option>
                    <option selected={q.type === QResponseTypeEnum.MULTIPLE_STAFF} value={QResponseTypeEnum.MULTIPLE_STAFF}>Multiple Staff</option>
                    <option selected={q.type === QResponseTypeEnum.VFILE} value={QResponseTypeEnum.VFILE}>FILE WITH SIGNATURE</option>

                </select>
            </div>
            <div className="pb-2">
                <p className="font-bold text-gray-700 my-1 text-xs">Options</p>
                <div className=" space-y-2">
                    <div className="space-x-2  flex flex-wrap">
                        {q.options?.map((option, _index) => <div className="px-3 py-1 bg-gray-200 text-gray-800 rounded flex text-xs  m-1" key={index}>{option}
                            <svg xmlns="http://www.w3.org/2000/svg" onClick={() => handleDeleteOption(q, option, index)} className="h-3 w-3 self-center text-gray-500 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </div>)}
                    </div>

                    <div className="bg-gray-50   flex"><input className="text-xs self-center bg-transparent p-1 focus:border-0 focus:outline-none placeholder-gray-200 flex-1" onChange={(e) => setNewOption(e.target.value)} placeholder="Add option" />
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 cursor-pointer bg-atomGreen" onClick={() => { handleAddNewOption(q, newOption, index); setNewOption("") }} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                        </svg>
                    </div>

                </div>
            </div>
            <div className="pb-2">
                <p className="font-bold text-gray-700 my-1 text-xs">Add follow up</p>
                <div className="flex space-x-2 text-xs">
                    <button className={"flex-1 border rounded py-1 " + (followMeta ===QuestionTagEnum.YesFollowUps && " bg-atomGreen")} onClick={() => setFollowMeta(QuestionTagEnum.YesFollowUps)}>Yes</button>
                    <button className={"flex-1 border rounded py-1 " + (followMeta ===QuestionTagEnum.NoFollowUps && " bg-atomGreen")} onClick={() => setFollowMeta(QuestionTagEnum.NoFollowUps)}>No</button>
                    <button className={"flex-1 border rounded py-1 " + (followMeta ===QuestionTagEnum.OtherFollowUps && " bg-atomGreen")} onClick={() => setFollowMeta(QuestionTagEnum.OtherFollowUps)}>Others</button>
                </div>
            </div>
            <div className="pb-2">
                <button className="transition duration-150 ease-in-out hover:bg-atomGreen focus:outline-none border text-black px-5 py-2 text-xs self-center w-full" onClick={() => { if (q.meta === QuestionTagEnum.MainQuestion && followMeta) { setSeeAddQuestion(!seeAddQuestion) } }}>Add New Question</button>
                <AddFollowQuestion isOpen={seeAddQuestion} setIsOpen={setSeeAddQuestion} section={q.sectionId} qid={q.questionId} _meta={followMeta as QuestionTagEnum} save={handleAddFollowUp} />
            </div>

        </div>
    )
}



