import {  makeObservable, observable, } from "mobx";
import { Administrator } from "../../common/types/admin";
import { AdminService } from "../services/AdminService";

export class AdministratorsStore {

    admins: Administrator[] = []
    isAdmin: boolean = false
    constructor(private adminServices: AdminService) {
        makeObservable(this, {
            admins: observable,
            isAdmin: observable,
        });
        this.initialize();
    }

    private setAdmins(items: Administrator[] | []) {
        this.admins = items;
    }

    async removeAdmin(admin: Administrator) {
        await this.adminServices.removeAdmin(admin);
        let copyAdmins = [...this.admins]
        let index = copyAdmins.findIndex(v => v === admin)
        if (index === -1) {
            this.setAdmins(copyAdmins);
        } else {
            console.log("df")
           copyAdmins.splice(index, 1)
            const left = copyAdmins.filter(v => v !== admin)
            this.setAdmins(left)
        }

    }

    async addAdmin(admin: Administrator) {
       await this.adminServices.addAdmin(admin);
        let copyAdmins = [...this.admins]
        this.setAdmins(copyAdmins.concat([admin]))
    }

    async initialize() {
        let response = await this.adminServices.fetchAllAdmins();
        this.setAdmins(response)

    }
}