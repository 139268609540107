
import { YES } from "../../common/constants";
import { TextFiller, TextWriter, YesNoFiller } from "../utility";

const docx = require("docx");
const { Paragraph } = docx;

export const _3_1_ExternalPatient = (ableToRecruit: string, recruitSource: string, recruitExp: string, whereRecruit: string) => new Paragraph({
    children: [

        TextWriter("You "),
        TextWriter(YesNoFiller(ableToRecruit, 'are ', 'are not ')),
        TextWriter("able to recruit patients from sources outside your site"),
        TextWriter(TextFiller(ableToRecruit === YES ? " and these come from " : "DONT_NO")),
        TextWriter(TextFiller(ableToRecruit === YES ? recruitSource : "DONT_NO")),
        TextWriter(YesNoFiller(recruitExp, " and you do ", " and you do not ")),
        TextWriter("have experience of referrals from "),
        TextWriter(TextFiller(recruitExp === YES ? whereRecruit : "DONT_NO")),
        TextWriter(" other sites.")
    ],
    spacing: {
        line: 276
    }
})
