export type NewSiteViewModel = {
    institutionName?: string,
    siteId: string,
    location?: string,
    department?: string,
    address?: string,
    leadInvestigatorName: string,
    phoneNumber?: string,
    emailAddress?: string,
    otherPhones?: string,
    leadInvestigatorCvLink?: string,
    country?: string
    ccode?: string
}

export const INITIAL_SITE: NewSiteViewModel = {
    institutionName: "",
    siteId: "",
    location: "",
    leadInvestigatorName: "",
    address: "",
    otherPhones: "",
    department: "",
    emailAddress: "",
    leadInvestigatorCvLink: "",
    phoneNumber: "",
    ccode: ""
}

export const CLEAR_ERROR = { email: "", service: "", siteId: "", leadName: "" }
