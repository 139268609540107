import { genId } from "../../admin/store/NewQuestionStore";
import { EntryStatusEnum, SiteActivityType, SiteObject, StaffCountTagEnum, StaffJds, StaffMemberType, TeamMemberRoleEnum, TeamMemberType } from "../../common/types/site";
import { GMT } from "../../common/types/utility";
import { NewSiteViewModel } from "../components/types";

export function fileResponseChanges(e: React.ChangeEvent<HTMLInputElement>): File | undefined {
    let files = e.target.files
    if (!files || files!.length < 1) {
        return undefined
    }
    return files[0]
}

export function validateEmail(email?: string): boolean {
    if (!email) {
        return false
    }
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const NewSiteViewModelToSiteObject = (newEntry: NewSiteViewModel, siteCreatedLog: SiteActivityType): SiteObject => {
    var initialMembers: TeamMemberType[] = [
        {
            regId: genId(),
            name: newEntry.leadInvestigatorName,
            roles: [TeamMemberRoleEnum.LEAD_INVESTIGATOR],
            cvLink: newEntry.leadInvestigatorCvLink,
            mLineCode: newEntry.ccode,
            dLineCode: newEntry.ccode,
        },
    ];

    let hired: StaffMemberType[] = StaffJds.map(jd => { return { jobDescription: jd, count: 0, tag: StaffCountTagEnum.HIRED } })
    let toHire: StaffMemberType[] = StaffJds.map(jd => { return { jobDescription: jd, count: 0, tag: StaffCountTagEnum.TO_HIRE } })

    var site: SiteObject = {
        description: {
            siteId: newEntry.siteId,
            leadInvestigatorName: newEntry.leadInvestigatorName,
            location: newEntry.location,
            address: newEntry.address,
            department: newEntry.department,
            emailAddress: newEntry.emailAddress,
            institutionName: newEntry.institutionName,
            leadInvestigatorCvLink: newEntry.leadInvestigatorCvLink,
            otherPhones: newEntry.otherPhones,
            phoneNumber: newEntry.phoneNumber,
            country: newEntry.country,
            ccode: newEntry.ccode
        },
        accessToStaff: { tms: initialMembers, staffs: [...hired, ...toHire], access: [] },
        meta: { lastUpdated: GMT, dateCreated: GMT, dateEntry: GMT, status: EntryStatusEnum.ENTRY },
        logs: [siteCreatedLog],
        responses: [],
        lps: []
    };

    return site;
};
