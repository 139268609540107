import { action, makeObservable, observable } from "mobx";
import { FormType } from "../../common/types/question";
import { SiteService } from "../services/SiteService";

export class SiteQuestioningStore {
    items: FormType[] = [];
    selectedSection: FormType | undefined = undefined;

    constructor(private siteService: SiteService) {
        makeObservable(this, {
            items: observable,
            selectedSection: observable,
            _setSelectedSection :action.bound,
            setSelectedSection: action.bound,
        });
        this.initialize();

        this.loadQuestionSections();
    }

    async initialize() { }

    private setQuestionSections(items: FormType[] | []) {
        this.items = items;
    }


     _setSelectedSection(item: FormType) {
        console.log(item.sectionId)
        this.selectedSection = item;
    }


     setSelectedSection(item: FormType) {
        console.log(item.sectionId)
        this._setSelectedSection(item);
    }


    async loadQuestionSections() {
        let response = await this.siteService.getQuestionForms();
        this.setQuestionSections(response);
    }


   // getSelectSection(sectionId: string) {
   //     const result = this.items.find(({ sectionId }) => sectionId === sectionId);
   //     this.setSelectedSection(result!);
   // }
}
