import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartOptions,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useStore } from '../../../../store/StoreProvider';
import { EntryStatusEnum } from '../../../common/types/site';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options: ChartOptions<"bar"> = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
        },
        title: {
            display: false,
            text: 'Sites by category',
        },

    },
   /** scales: {
        tick: {
            beginAtZero: true,
            display:true,
            bounds: "ticks",
             ticks: {
                 stepSize: 0.1,
                 count: 10,
                 display:true,
             }
             
        }
    } */
   
};



export function StatusCount() {
    const { dashboardStore: dashStore } = useStore();
    const sites = dashStore?.allSites || []
    let labels: string[] = [EntryStatusEnum.ENTRY, EntryStatusEnum.REVIEW, EntryStatusEnum.VALID, EntryStatusEnum.ARCHIVED]


    function countStatus(status: string): number {
        let total = 0;
        sites.forEach(x => {
            if (x.meta?.status === status)
                total++
        });
        return total;
    }
    let data: any = {
        labels,
        datasets: [
            {
                label: 'Sites by current status',
                data: labels.map(status => countStatus(status)),
                backgroundColor: 'rgba(174, 206, 76, 1)',
            }
        ]

        ,
    };

    return (<Bar options={options} data={data} />)
}


