import React, { ChangeEvent, useState } from 'react'
import { QuestionProps } from '../../../../common/types/question'
import { MemberRoles, TeamMemberType, TeamMemberRoleEnum, TeamMemberSpecialtyEnum } from '../../../../common/types/site'
import { MultiSelect, Option } from "react-multi-select-component";
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useEffect } from 'react';
import { MemberResponsibilities, PSDs, SpecialtyArea } from '../../../../common/constants';
import { useStore } from '../../../../../store/StoreProvider';
import { countries } from '../../../../common/constants/countries';
import { ref, uploadBytes } from '@firebase/storage';
import { genId } from '../../../../admin/store/NewQuestionStore';
import { FILE_DATE, SiteAddTeamMember } from '../../../../common/types/utility';
import { setupFireStore } from '../../../../../firebase/firebase.config';
import { fileResponseChanges } from '../../../operations/utilities';


export const InputResponse: React.FC<QuestionProps> = ({ name, label, placeholder, value, defaultValue, onInputChange }) => {
    return (<div className="flex w-full space-x-1">
        <label className="text-left block font-bold text-gray-800 w-1/3 self-center tracking-wider text-xs ">{label}</label>
        <input className="text-xs bg-gray-100 border border-gray-200 p-2 rounded my-1  focus:outline-none w-2/3 placeholder-gray-300  text-gray-800 self-center" placeholder={placeholder} name={name} onChange={(e) => onInputChange!(e)} value={value} />
    </div>)
}

export const PhoneResponse: React.FC<QuestionProps> = ({ name, cCodeName, label, placeholder, value, defaultValue, onCCodeChange, onInputChange }) => {
    return (<div className="flex w-full space-x-1">
        <label className="text-left block font-bold text-gray-800 w-1/3 self-center tracking-wider text-xs">{label}</label>
        <div className="w-2/3 flex">
            <select title='-' className={" text-xs self-center border-gray-100 bg-gray-50 p-2 my-1 focus:outline-none placeholder-gray-300 text-gray-800 border "} name={cCodeName} onChange={(e) => { onCCodeChange!(e) }}>
                <option defaultValue="Code">Country</option>
                {countries.map(country => (
                    <option value={country.dialCode} selected={defaultValue === country.dialCode} key={country.isoCode}>{country.dialCode}</option>
                ))}
            </select>
            <input className="bg-gray-100 border border-gray-100 p-2  my-1  focus:outline-none  placeholder-gray-300  text-gray-800 self-center text-xs flex-1" placeholder={placeholder} name={name} onChange={(e) => onInputChange!(e)} value={value} />

        </div>
    </div>)
}

export const SelectResponse: React.FC<QuestionProps> = ({ name, label, placeholder, defaultValue, options, onSelectChange }) => {
    return (<div className="flex w-full space-x-1">
        <label className="text-left block font-bold text-gray-800 w-1/3 self-center tracking-wider text-xs ">{label}</label>
        <select title='-' className="bg-gray-100 border border-gray-200 px-3 my-1 focus:outline-none w-2/3 placeholder-gray-300 text-xs  text-gray-800 h-12 self-center" name={name} onChange={(e) => onSelectChange!(e)}>
            {defaultValue ? <option defaultValue={defaultValue}>{defaultValue}</option> : <option defaultValue={""}>{placeholder}</option>}
            {options?.map(optionItem => <option value={optionItem} key={optionItem}>{optionItem}</option>)}
        </select>
    </div>)
}

export const MultipleSelectWithOthers: React.FC<QuestionProps> = ({ name, label, placeholder, value, multiSelectValues, defaultValue, onMultiSelectChange, options }) => {
    const list: Option[] = []
    options!.forEach(option => {
        list.push({ label: option.replace("_", " "), value: option })
    });

    return (<div className="flex w-full space-x-1 text-right">
        <label className="text-left block font-bold text-gray-800 w-1/3 self-center tracking-wider text-xs ">{label}</label>
        <MultiSelect
            className="w-2/3 py-2 text-xs text-right"
            options={list}
            hasSelectAll={false}
            value={multiSelectValues!}
            onChange={onMultiSelectChange}
            labelledBy="Choose Research Roles"
        />
    </div>
    )
}

const CheckWithTextDetail: React.FC<QuestionProps> = ({ name, label, placeholder, onInputChange, value, hasCheckedDetail, setCheckWDetail, detailLabel }) => {
    return (<div className="flex">
        <div className="w-full lg:w-1/2  flex flex-col">
            <label className="text-left block font-bold text-gray-800  tracking-wider  w-full text-xs">{label}</label>
            <div className="flex  space-x-10 my-2">
                <div className="flex">
                    <input title='-' className="self-center" name={name} type="radio" checked={hasCheckedDetail} onChange={() => setCheckWDetail!(true)} />
                    <label className="self-center px-3 text-xs">Yes</label>
                </div>
                <div className="flex self-center">
                    <input title='-' className="self-center" name={name} type="radio" onChange={() => setCheckWDetail!(false)} />
                    <label className="self-center px-3 text-xs">No</label>
                </div>
            </div>
        </div>

        {hasCheckedDetail &&
            <div className="w-full lg:w-1/2 flex  space-x-10">
                <div className="w-full  flex  space-x-10">
                    <label className="text-left block font-bold text-gray-800 w-1/3 self-center tracking-wider px-10 pl-12 text-xs">{detailLabel}</label>
                    <input className="bg-gray-100 border border-gray-100 p-2 w-2/3  focus:outline-none placeholder-gray-300  text-gray-800 self-center text-xs" placeholder={placeholder} name={name} onChange={(e) => onInputChange!(e)} value={value} disabled={!hasCheckedDetail} />
                </div>
            </div>
        }

    </div>)
}

const GCPTrainingRecord: React.FC<QuestionProps> = ({ name, label, placeholder, setCheckWDetail, value, detailValue, hasCheckedDetail, onInputChange, detailName, onFileChange, tm }) => {
    return (<div className="flex flex-col my-1">
        <label className="my-1 block font-bold text-gray-800 text-left text-xs">{label}</label>

        <div className="flex space-x-1 justify-between">
            <div className="flex flex-col self-center">
                <div className="flex  space-x-10 my-2">
                    <div className="flex self-center">
                        <input title='-' className="self-center" name={name} type="radio" checked={tm.gcpTrained} onChange={() => setCheckWDetail!(true)} />
                        <label className="self-center px-3 text-xs">Yes</label>
                    </div>
                    <div className="flex self-center">
                        <input title='-' className="self-center" checked={!tm.gcpTrained} onChange={() => setCheckWDetail!(false)} name={name} type="radio" />
                        <label className="self-center px-3 text-xs">No</label>
                    </div>
                </div>
            </div>
            {hasCheckedDetail &&
                <div className="space-x-5">
                    <label>Date</label>
                    <input title='-' className="bg-gray-100 border border-gray-200 p-2 px-3 my-1 text-xs focus:outline-none placeholder-gray-300  text-gray-800" type="month" disabled={!hasCheckedDetail} onChange={(e) => onInputChange!(e)} name={name} value={tm.gcpDate} />
                </div>
            }

            {hasCheckedDetail &&
                <input className="bg-gray-100 border border-gray-200 p-2 px-3 my-1 text-xs focus:outline-none placeholder-gray-300  text-gray-800 w-3/12" placeholder="Name of training company" type="text" disabled={!hasCheckedDetail} onChange={(e) => onInputChange!(e)} name={detailName} value={detailValue} />
            }
            {hasCheckedDetail && <>
                {!tm.gcpCertLink ? <input title='-' className="bg-gray-100 border border-gray-200 p-2 px-3 my-1 text-xs  focus:outline-none placeholder-gray-300  text-gray-800 w-3/12" type="file" placeholder="" disabled={!hasCheckedDetail} onChange={(e) => onFileChange!(e)} name={detailName} value={detailValue} /> : <p className="text-xs self-center">{tm.gcpCertLink}</p>}
            </>}

        </div>
    </div>)
}


const EDCTrainingRecord: React.FC<QuestionProps> = ({ name, label, setCheckWDetail, value, detailValue, hasCheckedDetail, onInputChange, detailName, onFileChange, fileUploaded, tm }) => {
    return (<div className="flex flex-col my-1">
        <label className="my-1 block font-bold text-gray-800 text-left text-xs">{label}</label>

        <div className="flex space-x-1 justify-between">
            <div className="flex flex-col self-center">
                <div className="flex  space-x-10 my-2">
                    <div className="flex self-center">
                        <input title='-' className="self-center" name={name} type="radio" checked={tm.edcTrained} onChange={() => setCheckWDetail!(true)} />
                        <label className="self-center px-3 text-xs">Yes</label>
                    </div>
                    <div className="flex self-center">
                        <input title='-' className="self-center" checked={!tm.edcTrained} onChange={() => setCheckWDetail!(false)} name={name} type="radio" />
                        <label className="self-center px-3 text-xs">No</label>
                    </div>
                </div>
            </div>
            {hasCheckedDetail && <div className="space-x-5">
                <label>Date</label>
                <input title='-' className="bg-gray-100 border border-gray-200 p-2 px-3 my-1 text-xs focus:outline-none placeholder-gray-300  text-gray-800" type="month" disabled={!hasCheckedDetail} onChange={(e) => onInputChange!(e)} name={name} value={value} />
            </div>}
            {hasCheckedDetail && <> {!tm.edcCertLink ? <input title='-' className="bg-gray-100 border border-gray-200 p-2 px-3 my-1 text-xs  focus:outline-none placeholder-gray-300  text-gray-800 w-3/12" type="file" placeholder="" disabled={!hasCheckedDetail} onChange={(e) => onFileChange!(e)} name={detailName} value={detailValue} /> : <p className="text-xs self-center">{tm.edcCertLink}</p>}
            </>}

        </div>
    </div>)
}



const FileResponse: React.FC<QuestionProps> = ({ name, label, placeholder, onFileChange, tm }) => {
    return (<div className="flex text-xs">
        <label className=" font-bold text-gray-800 w-1/3 self-center text-left text-xs">{label}</label>
        {tm.cvLink ? <p>{tm.cvLink}</p> : <input className="bg-gray-100 border border-gray-200 p-1 px-3 my-1 text-xs focus:outline-none placeholder-gray-300  text-gray-800 w-2/3" type="file" placeholder={placeholder} name={name} onChange={(e) => onFileChange!(e)} />}

    </div>)
}


const InputTextWithAddNew: React.FC<QuestionProps> = ({ name, label, placeholder, options, addNew, removeNew, onAddMoreInputChange }) => {
    return (<div className="flex space-x-5 my-1 text-xs" key={name}>
        <label className="text-left block font-bold text-gray-800 w-1/3 self-center tracking-wider">{label}</label>
        <div className="flex-1 w-full" key={name}>
            {options!.map((option, index) => <div className="flex bg-gray-100 border border-gray-100 rounded my-1" key={index}> <input placeholder={option} key={index} className="p-2 px-3 my-1  focus:outline-none w-full placeholder-gray-300  text-gray-800 bg-transparent" value={option} onChange={(e) => onAddMoreInputChange!(e, index)} /> <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-3 self-center cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" onClick={() => removeNew!(index)}>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg> </div>)}
        </div>
        <div className="right-0 float-right">
            <button onClick={() => addNew!()} className="px-3 border-2 border-atomGreen my-2 rounded flex p-2 text-atomGreen">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                </svg>
                Add </button>
        </div>
    </div>
    )
}

type AddTeamMemberProps = {
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

function AddTeamMember({ open, setOpen }: AddTeamMemberProps) {
    const { sitesListSore: siteSore, userStore } = useStore();
    const { FirebaseStorage: storage } = setupFireStore()
    const site = siteSore?.selectedSite

    const initTm = { regId: genId(), name: "", dLineCode: site?.description?.ccode, mLineCode: site?.description?.ccode, roles: [], specialties: [], edcTrainingReceived: [], edcSystemExperience: [] }
    const [newTm, setNewTm] = useState<TeamMemberType>(initTm)
    const [error, setError] = useState({ name: "", email: "" })

    const [roleMultiSelected, setRoleMultiSelected] = useState<Option[]>([]);
    //eslint-disable-next-line    @typescript-eslint/no-unused-vars
    const [specialtyMultiSelected, setSpecialtyMultiSelected] = useState<Option[]>([]);
    const [otherPSAMultiSelected, setOtherPSAMultiSelected] = useState<Option[]>([]);
    const [responsibilityMultiSelected, setResponsibilityMultiSelected] = useState<Option[]>([]);
    const [psdOptions, setPsdOptions] = useState<Option[]>([]);


    const inputResponseChanges = (e: ChangeEvent<HTMLInputElement>) => {
        setError({ name: "", email: "" })
        let copiedValue: TeamMemberType = { ...newTm }
        let name = e.target.name as keyof TeamMemberType
        copiedValue[name] = e.target.value as string & boolean & TeamMemberRoleEnum[] & TeamMemberSpecialtyEnum[]
        setNewTm(copiedValue)
    }

    const inputPhoneResponseChanges = (e: ChangeEvent<HTMLInputElement>) => {
        setError({ name: "", email: "" })
        let copiedValue: TeamMemberType = { ...newTm }
        let name = e.target.name as keyof TeamMemberType
        let phone = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
        copiedValue[name] = phone as string & boolean & TeamMemberRoleEnum[] & TeamMemberSpecialtyEnum[]
        setNewTm(copiedValue)
    }

    const handleCheckWithTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        setError({ name: "", email: "" })
        let copiedValue: TeamMemberType = { ...newTm }
        let name = e.target.name as keyof TeamMemberType
        copiedValue[name] = e.target.value as string & boolean & TeamMemberRoleEnum[] & TeamMemberSpecialtyEnum[]
        setNewTm(copiedValue)
    }

    const handleMoreTrainingListChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
        setError({ name: "", email: "" })
        let copiedValue: TeamMemberType = { ...newTm }
        copiedValue.edcTrainingReceived![index] = e.target.value
        setNewTm(copiedValue)
    }

    const handleMoreExperienceListChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
        setError({ name: "", email: "" })
        let copiedValue: TeamMemberType = { ...newTm }
        copiedValue.edcSystemExperience![index] = e.target.value
        setNewTm(copiedValue)
    }

    function handleCCodeChanges(e: React.ChangeEvent<HTMLSelectElement>): void {
        setError({ name: "", email: "" })
        let copiedValue: TeamMemberType = { ...newTm }
        let name = e.target.name as keyof TeamMemberType
        copiedValue[name] = e.target.value as string & boolean & TeamMemberRoleEnum[] & TeamMemberSpecialtyEnum[]
        setNewTm(copiedValue)
    }

    useEffect(() => {
        let copiedValue: TeamMemberType = { ...newTm }
        copiedValue.dLineCode = site?.description?.ccode
        copiedValue.mLineCode = site?.description?.ccode
        setNewTm(copiedValue)
//eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        let copiedValue: TeamMemberType = { ...newTm }
        let list: TeamMemberRoleEnum[] = []
        roleMultiSelected!.forEach(role => {
            list.push(role.value as TeamMemberRoleEnum)
        });
        copiedValue.roles = [...list]
        setNewTm(copiedValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roleMultiSelected])

    useEffect(() => {
        let copiedValue: TeamMemberType = { ...newTm }
        let list: TeamMemberSpecialtyEnum[] = []
        specialtyMultiSelected!.forEach(role => {
            list.push(role.value as TeamMemberSpecialtyEnum)
        });
        copiedValue.specialties = [...list]
        setNewTm(copiedValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [specialtyMultiSelected])

    useEffect(() => {
        let copiedValue: TeamMemberType = { ...newTm }
        let list: TeamMemberSpecialtyEnum[] = []
        otherPSAMultiSelected!.forEach(role => {
            list.push(role.value as TeamMemberSpecialtyEnum)
        });
        copiedValue.otherPSDs = [...list]
        setNewTm(copiedValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [otherPSAMultiSelected])


    useEffect(() => {
        let copiedValue: TeamMemberType = { ...newTm }
        let list: string[] = []
        responsibilityMultiSelected!.forEach(responsibility => {
            list.push(responsibility.value)
        });
        copiedValue.responsibilities = [...list]
        setNewTm(copiedValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [responsibilityMultiSelected])

    useEffect(() => {
        let copiedValue: TeamMemberType = { ...newTm }
        let list: string[] = []
        psdOptions!.forEach(responsibility => {
            list.push(responsibility.value)
        });
        copiedValue.psd = [...list]
        setNewTm(copiedValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [psdOptions])




    const handleAddMoreTraining = () => {
        let copiedValue: TeamMemberType = { ...newTm }
        copiedValue.edcTrainingReceived?.push(`Training title ${copiedValue.edcTrainingReceived.length}`)
        setNewTm(copiedValue)
    }

    const handleAddMoreExperience = () => {
        let copiedValue: TeamMemberType = { ...newTm }
        copiedValue.edcSystemExperience?.push(`System Experience  ${copiedValue.edcSystemExperience.length}`)
        setNewTm(copiedValue)
    }

    const handleRemoveMoreTraining = (index: number) => {
        let copiedValue: TeamMemberType = { ...newTm }
        let filtered = copiedValue.edcTrainingReceived!.filter((list, i) => { return index !== i })
        copiedValue.edcTrainingReceived = filtered
        setNewTm(copiedValue)
    }

    const handleRemoveMoreExperience = (index: number) => {
        let copiedValue: TeamMemberType = { ...newTm }
        let filtered = copiedValue.edcSystemExperience!.filter((list, i) => { return index !== i })
        copiedValue.edcSystemExperience = filtered
        setNewTm(copiedValue)
    }

    function handleNetworkChange(e: boolean): void {
        let copiedValue: TeamMemberType = { ...newTm }
        copiedValue.hasNetwork = e
        if (e === false)
            copiedValue.specialtyNetwork = ""
        setNewTm(copiedValue)
    }

    const selectResponseChanges = (e: ChangeEvent<HTMLSelectElement>) => {
        let copiedValue: TeamMemberType = { ...newTm }
        let name = e.target.name as keyof TeamMemberType
        copiedValue[name] = e.target.value as string & boolean & TeamMemberRoleEnum[] & TeamMemberSpecialtyEnum[]
        setNewTm(copiedValue)
    }

    const handleSaveToDb = () => {
        if (newTm.name === "") {
            setError({ ...error, name: "Team member name is required" })
            return
        }
        let log = SiteAddTeamMember(siteSore?.selectedSite!, userStore?.currentUser.email || 'Anonymous')
        siteSore!.addNewMember(newTm, log)

        siteSore!.updateSite(log).then(() => {
            setNewTm(initTm)
        }); setOpen(false);
    }

    const handleCVUpload = (e: React.ChangeEvent<HTMLInputElement>, tmName: string, role: string | undefined) => {
        const file = fileResponseChanges(e)
        if (!file) {
            return
        }
        let copiedValue: TeamMemberType = { ...newTm }
        let name = e.target.name as keyof TeamMemberType
        role = role + ""
        const filename = `${site?.description?.siteId}/cv/${tmName.replace(" ", "_")}${role.replace(" ", "_")}-${FILE_DATE}-${file.name.replace(" ", "_")}`
        const fileStorageRef = ref(storage, filename);
        uploadBytes(fileStorageRef, e.target.files![0]).then((snapshot) => {
            setNewTm({ ...newTm, cvLink: snapshot.ref.fullPath })
        });
        copiedValue[name] = e.target.value as string & boolean & TeamMemberRoleEnum[] & TeamMemberSpecialtyEnum[]
        setNewTm(copiedValue)
    }

    const handleEDCFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = fileResponseChanges(e)
        if (!file) {
            return
        }
        let copiedValue: TeamMemberType = { ...newTm }
        let name = e.target.name as keyof TeamMemberType
        const fileStorageRef = ref(storage, `${siteSore?.selectedSite.description?.siteId}/edc/${file.name}`);
        uploadBytes(fileStorageRef, e.target.files![0]).then((snapshot) => {
            setNewTm({ ...newTm, edcCertLink: snapshot.ref.fullPath })
        });
        copiedValue[name] = e.target.value as string & boolean & TeamMemberRoleEnum[] & TeamMemberSpecialtyEnum[]
        setNewTm(copiedValue)
    }

    const handleGCPFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = fileResponseChanges(e)
        if (!file) {
            return
        }
        let copiedValue: TeamMemberType = { ...newTm }
        let name = e.target.name as keyof TeamMemberType
        const fileStorageRef = ref(storage, `${siteSore?.selectedSite.description?.siteId}/gcp/${file.name}`);
        uploadBytes(fileStorageRef, e.target.files![0]).then((snapshot) => {
            setNewTm({ ...newTm, gcpCertLink: snapshot.ref.fullPath })
        });
        copiedValue[name] = e.target.value as string & boolean & TeamMemberRoleEnum[] & TeamMemberSpecialtyEnum[]
        setNewTm(copiedValue)
    }


    return (
        <div>

            <Transition appear show={open} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 z-30 overflow-y-auto bg-black bg-opacity-70"
                    onClose={() => { }}     >
                    <div className="max-h-screen text-center">
                        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>
                        <span className="inline-block h-screen align-middle" aria-hidden="true"  >  &#8203;  </span>
                        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95" >

                            <div className="inline-block  w-2/3 lg:w-2/3 overflow-hidden align-middle transition-all transform  max-h-full bg-white shadow-2xl">
                                <div className="px-4 sm:px-6 py-10">
                                    <Dialog.Title className=" text-atomGreen text-center text-3xl font-thin">Add Team Member</Dialog.Title>
                                </div>

                                <div className="mt-3 space-y-5 w-full pb-3">

                                    <h6 className="py-5 px-10 text-lg text-left text-gray-300">Basic Information</h6>

                                    <div className="flex flex-col lg:flex-row w-full  space-x-5">
                                        <div className="w-full lg:w-1/2 px-10">
                                            <InputResponse name="name" label="Name" placeholder="Name of site team member" onInputChange={(e) => inputResponseChanges(e)} tm={newTm} />
                                            <p className="text-red-600">{error.name}</p>
                                        </div>
                                        <div className="w-full lg:w-1/2 px-10">
                                            <InputResponse name="emailAddress" label="Email Address" placeholder="Email Address" onInputChange={(e) => inputResponseChanges(e)} tm={newTm} />
                                            <p className="text-red-600">{error.email}</p>
                                        </div>
                                    </div>

                                    <div className="flex flex-col lg:flex-row w-full  space-x-5">
                                        <div className="w-full lg:w-1/2 px-10">
                                            <PhoneResponse name="directLine" label="Direct Line" placeholder="Direct Line" value={newTm.directLine} cCodeName="dLineCode" onCCodeChange={(e) => handleCCodeChanges(e)} onInputChange={(e) => inputPhoneResponseChanges(e)} tm={newTm} defaultValue={newTm.dLineCode} />
                                        </div>
                                        <div className="w-full lg:w-1/2 px-10">
                                            <PhoneResponse name="mobileNumber" label="Mobile Number" cCodeName="mLineCode" value={newTm.mobileNumber} onCCodeChange={(e) => handleCCodeChanges(e)} placeholder="Mobile Number" onInputChange={(e) => inputPhoneResponseChanges(e)} tm={newTm} defaultValue={newTm.mLineCode} />
                                        </div>
                                    </div>


                                    <hr />

                                    <h6 className="py-5 px-10 text-lg text-left text-gray-300">Specialty Information</h6>


                                    <div className="flex flex-col lg:flex-row w-full  space-x-5">
                                        <div className="w-full lg:w-1/2 px-10">
                                            <MultipleSelectWithOthers name="researchRole" label="Research Role" placeholder="" options={MemberRoles} multiSelectValues={roleMultiSelected} onMultiSelectChange={setRoleMultiSelected} tm={newTm} />
                                        </div>
                                        <div className="w-full lg:w-1/2 px-10">
                                            <InputResponse name="otherRr" label="Others" placeholder="Others" onInputChange={(e) => inputResponseChanges(e)} tm={newTm} />
                                        </div>
                                    </div>

                                    <div className="flex flex-col lg:flex-row w-full  space-x-5">
                                        <div className="w-full lg:w-1/2 px-10">
                                            <SelectResponse name="primarySpecialtyArea" placeholder="Primary Specialty Area" label="Primary Specialty Area" options={[...SpecialtyArea.sort()]} onSelectChange={(e: ChangeEvent<HTMLSelectElement>) => selectResponseChanges(e)} tm={newTm} />
                                        </div>
                                        <div className="w-full lg:w-1/2 px-10">

                                            <MultipleSelectWithOthers name="otherPSDs" label="Others" placeholder="" options={SpecialtyArea.sort()} multiSelectValues={otherPSAMultiSelected} onMultiSelectChange={setOtherPSAMultiSelected} tm={newTm} />

                                        </div>
                                    </div>



                                    <div className="flex flex-col lg:flex-row w-full  space-x-5">
                                        <div className="w-full lg:w-1/2 px-10">
                                            <MultipleSelectWithOthers name="responsibilities" label="Responsibilities" placeholder="" options={MemberResponsibilities} multiSelectValues={responsibilityMultiSelected} onMultiSelectChange={setResponsibilityMultiSelected} tm={newTm} />
                                        </div>
                                        <div className="w-full lg:w-1/2 px-10">
                                            <MultipleSelectWithOthers name="psd" label="Practice Setting Description" placeholder="" options={PSDs} multiSelectValues={psdOptions} onMultiSelectChange={setPsdOptions} tm={newTm} />
                                        </div>
                                        <div className="w-full lg:w-1/2 px-10">
                                            <SelectResponse name="psd" placeholder="Choose Practice Setting Description" label="Practice Setting Description" options={[...PSDs]} onSelectChange={(e: ChangeEvent<HTMLSelectElement>) => selectResponseChanges(e)} tm={newTm} />
                                        </div>
                                    </div>

                                    <div className="flex flex-col lg:flex-row w-full  space-x-5">
                                        <div className="w-full lg:w-1/2 px-10">
                                            <FileResponse name="attachCv" label="Attach CV" onFileChange={(e) => { handleCVUpload(e, newTm.name, newTm.roles?.join()) }} tm={newTm} />
                                        </div>
                                        <div className="w-full lg:w-1/2 px-10">
                                        </div>
                                    </div>

                                    <hr />

                                    <div className="flex flex-col lg:flex-row w-full  space-x-10">
                                        <div className="w-full  px-10">
                                            <CheckWithTextDetail name="specialtyNetwork" label="Membership of Specialty Network" detailLabel="Name of Specialty Network" placeholder="Name of Specialty Network" tm={newTm} hasCheckedDetail={newTm.hasNetwork} setCheckWDetail={(e) => handleNetworkChange(e)} value={newTm.specialtyNetwork} onInputChange={(e) => handleCheckWithTextChange(e)} />
                                        </div>
                                    </div>

                                    <hr />

                                    <div className="flex flex-col lg:flex-row w-full  space-x-10">
                                        <div className="w-full  px-10">
                                            <GCPTrainingRecord name="gcpDate" detailName="gcpCompany" label="GCP Training Received" placeholder="GCP Trainer/Company Name" onFileChange={handleGCPFileUpload} tm={newTm} hasCheckedDetail={newTm.gcpTrained} setCheckWDetail={(flag) => setNewTm({ ...newTm, gcpTrained: flag })} onInputChange={(e) => handleCheckWithTextChange(e)} />

                                        </div>
                                    </div>

                                    <div className="flex flex-col lg:flex-row w-full  space-x-10">
                                        <div className="w-full  px-10">
                                            <EDCTrainingRecord name="edcDate" detailName="edcCertLink" label="EDC Training Received" placeholder="" tm={newTm} hasCheckedDetail={newTm.edcTrained} setCheckWDetail={(flag) => setNewTm({ ...newTm, edcTrained: flag })} onInputChange={(e) => handleCheckWithTextChange(e)} onFileChange={handleEDCFileUpload} />
                                        </div>
                                    </div>
                                    <hr />


                                    <div className="flex flex-col lg:flex-row w-full  space-x-10">
                                        <div className="w-full px-10">
                                            <InputTextWithAddNew name="edcTrainingReceived" label="List EDC system training received" placeholder="" options={newTm.edcTrainingReceived} tm={newTm} addNew={() => handleAddMoreTraining()} removeNew={(index) => { handleRemoveMoreTraining(index) }} onAddMoreInputChange={(e, index) => handleMoreTrainingListChange(e, index)} />
                                        </div>

                                    </div>
                                    <hr />

                                    <div className="flex flex-col lg:flex-row w-full  space-x-10">

                                        <div className="w-full  px-10">
                                            <InputTextWithAddNew name="edcSystemExperience" label="List EDC system experience" placeholder="" options={newTm.edcSystemExperience} tm={newTm} addNew={() => handleAddMoreExperience()} removeNew={(index) => { handleRemoveMoreExperience(index) }} onAddMoreInputChange={(e, index) => handleMoreExperienceListChange(e, index)} />
                                        </div>
                                    </div>
                                    {/*                                    <p className="text-xs">{JSON.stringify(newTm.edcTrainingReceived)}</p>
*/}
                                    <div className="p-1 flex justify-center rounded space-x-2">
                                        <button className="px-10 bg-atomGreen text-gray-50 w-32" onClick={() => { handleSaveToDb(); }}> Save</button>
                                        <button className="px-10 py-2 border border-atomGreen text-gray-800 w-32" onClick={() => { setOpen(false); setNewTm(initTm) }}>Cancel </button>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </div>
    )
}

export default AddTeamMember