

import { TextWriter } from "../utility";

const docx = require("docx");
const { Paragraph, TextRun } = docx;

export const _3_3_4_InfraIntro = () => new Paragraph({
    children: [
      
        new TextRun({ text: "\n", break: 2 }),
        TextWriter(`This addresses the facilities your site has for management of the patients in a trial, facilities to ensure appropriate capture, monitoring and archiving of all the trial data and the infrastructure in place to ensure the activities we do are supported with the required technology, including Information Technology (IT) and data security for global clinical trials.`),
        new TextRun({ text: "\n", break: 2 }),
       
    ], spacing: {
        line: 360,
    },
})
