

import { NO } from "../../common/constants";
import { TextFiller, TextWriter, YesNoFiller } from "../utility";

const docx = require("docx");
const { Paragraph, TextRun } = docx;

export const _3_4_1_FacilityIntro = (bedNumber: string, patientNo:string,separate:string,distance:string, patientActNo:string, hasAllocatedTime:string, hasConsent:string, hasStandard:string) => new Paragraph({
    children: [
      
        new TextRun({ text: "\n", break: 2 }),
        TextWriter("Your unit has "),
        TextWriter(TextFiller(bedNumber)),
        TextWriter(` ${Number(bedNumber) < 1 ?'bed':'beds'} and allows for `),
        TextWriter(TextFiller(patientNo)),
        TextWriter(` ${Number(patientNo) < 1 ?'patient':'patients'} under your care at any one time with`),
        TextWriter(separate ===NO? ' no separate ': ' separate'),
        TextWriter(` areas for male and female subjects. Your patients come from `),
        TextWriter(TextFiller(distance)),
        TextWriter(` to this hospital. The unit can accommodate `),
        TextWriter(TextFiller(patientActNo)),
        TextWriter(` subjects for trials at any one time. For our clinical trials we`),
        TextWriter(YesNoFiller(hasAllocatedTime, " do have ", " do not have ")),
        TextWriter(`allocated rooms for screening, consent and`),
        TextWriter(YesNoFiller(hasConsent, " also ", " but not ")),
        TextWriter(`for trial procedures. We`),
        TextWriter(YesNoFiller(hasStandard, " have ", " do not have ")),
        TextWriter(`rooms for standard trial assessments e.g. ECGs, blood sampling etc.`),

        new TextRun({ text: "\n", break: 2 }),
       
    ], spacing: {
        line: 360,
    },
})
