import React from 'react'
import NewDialog from './dialog'

type addFormProps = {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>

}

function AddForm({ isOpen, setIsOpen }: addFormProps) {
    return (
        <NewDialog title="Add A New Form" isOpen={isOpen} setIsOpen={setIsOpen} >
            <div>
                <input type="text" name="title" className="mt-1 block w-full sm:text-sm border border-gray-300 focus:outline-none rounded-md p-3" />
            </div>
        </NewDialog>
    )
}

export default AddForm

