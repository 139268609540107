
import { RootStore } from "../../../store/types/rootstore";
import { DashboardService } from "../services/DashboardService";
import { DashboardStore } from "./DashboardStore";

export type DashboardStoreTypes = {
    dashboardStore?: DashboardStore;
};

export function injectDashboardIntoRoot(root: RootStore) {
    const service = new DashboardService();
    root.dashboardStore = new DashboardStore(service);
}
