

import { TextFiller, TextWriter, YesNoFiller } from "../utility";

const docx = require("docx");
const { Paragraph, TextRun } = docx;

export const _3_3_4_FreezerSummary = (hasTempLog: string, areMonitored:string, logInfo:string, serviceInfo:string, isAttached:string) => new Paragraph({
    children: [
      
        new TextRun({ text: "\n", break: 2 }),
     
        TextWriter(`There`),
        TextWriter(YesNoFiller(hasTempLog," is "," is not ")),
        TextWriter(`a temperature log maintained for all fridges and freezers and these are monitored `),
        TextWriter(TextFiller(areMonitored)),
        TextWriter(`. These records are kept for `),
        TextWriter(TextFiller(logInfo)),
        TextWriter(`. Servicing of equipment and maintenance records for all are `),
        TextWriter(TextFiller(serviceInfo)),
        TextWriter(` and documentation of the process`),
        TextWriter(YesNoFiller(isAttached, 'is ','is not ')),
        new TextRun({ text: "\n", break: 2 }),
       
    ], spacing: {
        line: 360,
    },
})
