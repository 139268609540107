import { QuestionType, FormType, FormSubSectionType } from "../../common/types/question";
import { doc,  collection, getDocs, deleteDoc, getDoc, updateDoc, setDoc, arrayUnion, } from "firebase/firestore";
import { Administrator } from "../../common/types/admin";
import { SiteObject } from "../../common/types/site";
import { setupFireStore } from "../../../firebase/firebase.config";

const { Firestore:db} = setupFireStore()

export class AdminService {


  async getAllForms(): Promise<FormType[]> {

    let response: FormType[] = [];
    const result = collection(db, "sections");
    const data = await getDocs(result);
    data.docs.forEach((item) => {
      response = [...response, item.data() as FormType];
    });

    return response || [];
  }


  async createNewForm(data: FormType): Promise<void> {

    const newSection = doc(db, "sections", data.sectionId);
    const result = await setDoc(newSection, {
      ...data
    });
    return result
  }

  async createSubSection(formId: string, data: FormSubSectionType): Promise<void> {
    const ref = doc(db, "sections", formId);
    const result = await updateDoc(ref, {
      subs: arrayUnion({ ...data })
    })
    return result
  }

  async getSection(formId: string): Promise<FormType> {

    const sectionRef = doc(db, "sections", formId)
    const data = await getDoc(sectionRef);
    return data.data() as FormType;
  }

  async updateSection(data: FormType): Promise<void> {
    const sectionRef = doc(db, "sections", data.sectionId)
    const result = await updateDoc(sectionRef, { ...data })
    return result
  }

  async addNewQuestion(q: QuestionType): Promise<void> {
    const sectionRef = doc(db, "sections", q.sectionId)
    const result = await updateDoc(sectionRef, {
      questions: arrayUnion({ ...q })
    })
    return result
  }

  async addNewQuestionToSub(formId: string, subs: FormSubSectionType[]): Promise<void> {
    const sectionRef = doc(db, "sections", formId)
    const result = await updateDoc(sectionRef, {
      subs: subs
    })
    return result
  }


  async fetchAllAdmins(): Promise<Administrator[]> {
    let response: Administrator[] = [];
    const result = collection(db, "admins");
    const data = await getDocs(result);
    data.docs.forEach((item) => {
      response = [...response, item.data() as Administrator];
    });
    return response
  }

  async addAdmin(a: Administrator): Promise<void> {
    const result = await setDoc(doc(db, "admins", a.email!), {
      name: a.name,
      email: a.email
    });
    return result
  }

  async removeAdmin(a: Administrator): Promise<void> {
    const result = await deleteDoc(doc(db, "admins", a.email!));
    return result
  }

  async updateForm(formId: string, data: FormType): Promise<void> {
    const sectionRef = doc(db, "sections", formId)
    const result = await updateDoc(sectionRef, data)
    return result
  }


  async getAllSites(): Promise<SiteObject[]> {
    let response: SiteObject[] = [];
    const sectionRef = collection(db, "sites")
    const data = await getDocs(sectionRef);
    data.docs.forEach((item) => {
      response = [...response, item.data() as SiteObject];
    });

    return response || [];
  }

  async getSite(siteId: string): Promise<SiteObject> {
    const sectionRef = doc(db, "sites", siteId)
    const data = await getDoc(sectionRef);
    return data.data() as SiteObject;
  }

  async updateSite(siteId: string, data: SiteObject): Promise<void> {
    const sectionRef = doc(db, "sites", siteId)
    const result = await updateDoc(sectionRef, data)
    return result
  }
}


