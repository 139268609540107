import React from 'react'
import { QuestionnaireType } from '../common/types/question'

type QAChildProps = {
    qa: QuestionnaireType,
    setQA: React.Dispatch<React.SetStateAction<QuestionnaireType>>
}

function ListQA({ qa, setQA }: QAChildProps) {
    const { question } = qa

    function handleEditMoreListItem(index: number, e: React.ChangeEvent<HTMLInputElement>, res: string): void {
        let holder = qa!.response as string[] || []
        holder[index] = e.target.value
        setQA({ ...qa, response: holder })
    }

    return (
        <div className="flex w-full my-2 py-2" key={question.questionId + question.sectionId}>
            <p className="font-bold self-center w-6/12"> {question.statement}</p>
            <div className="w-1/12"></div>
            <section className={"flex-1 self-center"}>
                {[...qa.response as string[]].map((res, index) =>
                    <input key={index} className="rounded w-full p-2 border" placeholder="Answer here" onChange={(e) => handleEditMoreListItem(index, e, res)} value={res} />
                )}
                <div className="flex">
                    <button className="p-1 px-3 rounded mt-1 border bg-green-50" onClick={() => { setQA({ ...qa, response: [...qa.response as string[], ""] }) }}>Add New</button>
                </div>
            </section>
        </div>
    )
}

export default ListQA