import { observer } from 'mobx-react';
import React from 'react'
import { useStore } from '../../../../../store/StoreProvider';
import { StaffMemberType } from '../../../../common/types/site';

type StaffCountProps = {
    tag: string
}


function StaffCounts({ tag }: StaffCountProps) {

    const { sitesListSore: siteSore } = useStore();

    let allStaff =  siteSore!.selectedSite!.accessToStaff?.staffs || []
    function handleCountChange(e: React.ChangeEvent<HTMLInputElement>, staff: StaffMemberType): void {
        let copyValues = [...allStaff]
        let target = copyValues.find(({ tag, jobDescription }) => tag === staff.tag && jobDescription === staff.jobDescription);
        target!.count = Number(e.target.value)
        siteSore?.setSelectedSiteStaffCounts(copyValues)
    }

    let staffs = allStaff.filter(staff  => staff.tag === tag)

    return (
        <div className="py-5 flex-wrap flex">
            {staffs && staffs?.map((staff, index) => <div className="w-6/12 flex justify-between  space-x-2 my-1" key={staff!.jobDescription}>
                <div className="flex-1 self-center">
                    <p className="font-bold self-center w-full uppercase"> {staff!.jobDescription!.toString().replace("_", " ")}</p>
                </div>
                <div className="flex-1 self-center">
                    <input className="p-2 w-10 bg-gray-100" value={staff!.count} onChange={(e) => handleCountChange(e, staff!)} />
                </div>
                <div className="">
                </div>
            </div>)}
        </div>
    )
}


export default observer(StaffCounts)

