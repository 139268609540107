import { ChangeEvent } from "react";
import {  Option } from "react-multi-select-component";
import { TeamMemberType } from "./site";

export type FormType = {
  sectionId: string;
  title: string;
  shortName?: string;
  isLive?: boolean;
  index?: number;
  createdAt?: number;
  updatedAt?: string;
  updatedBy?: string;
  subs?: FormSubSectionType[]
};

export type FormSubSectionType = {
  id?: string;
  title?: string;
  isLive?: boolean;
  questions?: QuestionType[];
}

export type QuestionType = {
  questionId: string;
  statement?: string;
  sectionId: string;
  isLive?: boolean;
  isAdminOnly?: boolean;
  type?: QResponseTypeEnum;
  options?: string[];
  followUps?: Array<QuestionType>;
  index?: number;
  reportOutput?: string;

  meta: QuestionTagEnum
};

export type QuestionnaireType = {
  question: QuestionType;
  response?: string | number | string[] | undefined;
}

export enum QuestionTagEnum {
  YesFollowUps = "YF",
  NoFollowUps = "NF",
  OtherFollowUps = "OF",
  MainQuestion = "MQ"
}

export enum QResponseTypeEnum {
  FILE = "FILE",
  SHORT = "SHORT",
  LONG = "LONG",
  INTEGER = "INTEGER",
  SINGLE = "SINGLE",
  MULTIPLE = "MULTIPLE",
  LIST = "LIST",
  OPTIONAL = "OPTIONAL",
  DATE = "DATE",
  STAFF = "STAFF",
  MULTIPLE_STAFF = "MULTIPLE_STAFF",
  VFILE = "VFILE",
  TEXTFREQUENCY = "TEXTFREQUENCY"
}


export type QuestionResponseType = {
  questionId: string;
  response?: string | number | string[] | undefined;
  responseType: QResponseTypeEnum;
};


export type QuestionProps = {
  tm: TeamMemberType;
  name?: string,
  label?: string,
  placeholder?: string,
  value?: string | string[],
  disabled?: boolean,
  detailLabel?: string,
  cCodeName?: string,
  detailName?: string,
  detailDate?: string,
  checkValue?: boolean,
  detailValue?: string,
  defaultValue?: string,
  onInputChange?: (value: ChangeEvent<HTMLInputElement>) => void,
  onFileChange?: (value: ChangeEvent<HTMLInputElement>) => void,
  onSelectChange?: (value: ChangeEvent<HTMLSelectElement>) => void
  onCCodeChange?: (value: ChangeEvent<HTMLSelectElement>) => void
  onTextAreaChange?: (value: ChangeEvent<HTMLTextAreaElement>) => void
  options?: Array<string>
  onMultiSelectChange?: any
  multiSelectValues?: Option[]
  onCheckWithTextDetailChange?: (name: string) => void
  addNew?: () => void
  removeNew?: (item: any) => void
  hasCheckedDetail?: boolean
  setCheckWDetail?: (hasCheckDetail: boolean) => void
  onAddMoreInputChange?: (value: ChangeEvent<HTMLInputElement>, index: number) => void
  fileUploaded?: boolean
};

export type StaffEDCProps = {
  trainingReceived?: boolean | undefined
  dateValue: string
  fileUploaded?: boolean

}