
import { QuestionType } from "../../common/types/question";
import { CellFillWriter, CellWriter, findQuestion, NO_INFO } from "../utility";

const docx = require("docx");
const { Table, TableRow, } = docx;


type freezerSummary = {
    description?: string
    response?: string
    alert?: string
}

type freezerQs = {
    questionIds: string[]
}

export const SummarizeFreezers6 = (results: any, questions: QuestionType[]): freezerSummary[] => {
    var result: freezerSummary[] = []

    freezerQsMap.map(trail => (
        result.push({
            description: findQuestion(questions, trail.questionIds[0]) as string,
            response: results[trail.questionIds[0]] ? results[trail.questionIds[0]] : NO_INFO,
            alert: results[trail.questionIds[1]] ? results[trail.questionIds[1]] : NO_INFO

        })
    ))
    return result
}

const freezerQsMap: freezerQs[] = [
    {
        questionIds: ["23c1", "cd50"]
    },
    {

        questionIds: ["3ae8", "cd50"]
    },
    {

        questionIds: ["d2f5", "cd50"]
    },  {

        questionIds: ["6769", "cd50"]
    },
 
]


export const _3_6_2_FreezerTable = (counts: freezerSummary[]) => new Table({
    rows: [
        new TableRow({
            children: [
                CellFillWriter("Freezer onsite"),
                CellFillWriter("Number"),
                CellFillWriter("Automatic warning if temperature deviates"),
         

            ],
        })].concat(

            counts.map(count => new TableRow({
                children: [
                    CellWriter(count.description),
                    CellWriter(count.response?.toString()),
                    CellWriter(count.alert?.toString()),
                ]
            }))
        )

})
