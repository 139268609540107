

import {  TextWriter, YesNoFiller } from "../utility";

const docx = require("docx");
const { Paragraph, TextRun } = docx;

export const _3_6_3_TumorTissueA = (hasHistopathology: string) => {

    return new Paragraph({
        children: [

            new TextRun({ text: "\n", break: 2 }),
            TextWriter(`Our facility`),
            TextWriter(YesNoFiller(hasHistopathology, ' has ', ' has not ')),
            TextWriter(`a histopathology lab co-located on site and routinely do the following: `),
           
            new TextRun({ text: "\n", break: 2 }),
            TextWriter(`Table 10`),
            new TextRun({ text: "\n", break: 1 }),

        ], spacing: {
            line: 360,
        },
    })
}