import { RootStore } from "../../../store/types/rootstore";
import { SiteService } from "../services/SiteService";
import { SiteQuestioningStore } from "./SiteQuestioningStore";
import { SitesListStore } from "./SitesListStore";

export type SiteStoreTypes = {
  sitesListSore?: SitesListStore;
  siteQuestioningStore?: SiteQuestioningStore
};

export function injectSitesIntoRoot(root: RootStore) {
  const service = new SiteService();
  root.sitesListSore = new SitesListStore(service);
  root.siteQuestioningStore = new SiteQuestioningStore(service)
}
