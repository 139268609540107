
import { TableCell, Paragraph, VerticalAlign, convertInchesToTwip } from "docx";
import { QuestionType } from "../../common/types/question";
import { CellWriter, findQuestion, NO_INFO } from "../utility";

const docx = require("docx");
const { Table, TableRow, } = docx;


type ethicsSummary = {
    description?: string
    response?: string
}

type ethicsQuestionMAp = {
    questionIds: string[]
}

export const SummarizeEthicsReq = (results: any, questions: QuestionType[]): ethicsSummary[] => {
    var result: ethicsSummary[] = []

    trialQuestionIdMaps.map(trail => (
        result.push({
            description: findQuestion(questions, trail.questionIds[0]) as string,
            response: results[trail.questionIds[0]] ? results[trail.questionIds[0]] : NO_INFO

        })
    ))
    return result
}

const trialQuestionIdMaps: ethicsQuestionMAp[] = [
    {
        questionIds: ["c089", "", ""]
    }, {
        questionIds: ["cd3a", "", ""]
    }, {
        questionIds: ["add5", "", ""]
    }, {
        questionIds: ["7d7c", "", ""]
    }, {
        questionIds: ["770e", "", ""]
    }, {
        questionIds: ["20dd", "", ""]
    }, {
        questionIds: ["3af2", "", ""]
    }, {
        questionIds: ["41cd", "", ""]
    }
    
]


export const _3_7_1_EthicsReqTable = (counts: ethicsSummary[]) => new Table({
    rows: [
        new TableRow({
            children: [
                new TableCell({
                    children: [new Paragraph({ text: "Activities" })],
                    verticalAlign: VerticalAlign.CENTER,
                    margins: {
                        top: convertInchesToTwip(0.1),
                        bottom: convertInchesToTwip(0.1),
                        left: convertInchesToTwip(0.2),
                        right: convertInchesToTwip(0.2),
                    },
                    shading: {
                        color: "00FFFF",
                        fill: "#A4CA39",
                    }, 
                }),
                new TableCell({
                    children: [new Paragraph({ text: "Timeline" })],
                    verticalAlign: VerticalAlign.CENTER,
                    margins: {
                        top: convertInchesToTwip(0.1),
                        bottom: convertInchesToTwip(0.1),
                        left: convertInchesToTwip(0.2),
                        right: convertInchesToTwip(0.2),
                    },
                    shading: {
                        color: "00FFFF",
                        fill: "#A4CA39",
                    }, 
                }),

            ],
        })].concat(

            counts.map(count => new TableRow({
                children: [
                    CellWriter(count.description),
                    CellWriter(count.response?.toString()),
                ]
            }))
        )

})
