
import { TextWriter, TODAY_MONTH, TODAY_YEAR } from "../utility";

const docx = require("docx");
const { Paragraph, TextRun } = docx;

export const _3_0_Overview = () => new Paragraph({
    children: [
        new TextRun({ text: "\n", break: 2 }),

       TextWriter(
        "ATOM has provided output on the key site assessment topics of resources, experience, facilities, local legal requirements for trial approvals and activities as well as your therapeutic interests and expertise."

        ),
        new TextRun({ text: "\n", break: 2 }),
        new TextRun({
            text: `The information held, as approved by you, and deemed current as of ${TODAY_MONTH}, ${TODAY_YEAR} is summarized in this section.`, font: "Arial", size: 22,
        }),
        new TextRun({ text: "\n", break: 2 })
    ],
    spacing: {
        line: 360,
    },
})
