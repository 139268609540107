import { makeObservable, observable, reaction } from "mobx";
import { QuestionType } from "../../common/types/question";
import { AdminService } from "../services/AdminService";

export class QuestionListStore {
  items: QuestionType[] = [];
  section: string = "";

  constructor(private adminServices: AdminService) {
    makeObservable(this, {
        items:observable,
        section:observable
    });
    this.initialize();

    reaction(()=> this.section, (state)=>{
        this.loadSectionQuestion()
    })
  }



  async initialize() {}

  loadSectionQuestion() {}
}
