import { SiteMetaProps } from "../../common/types/site";
import { TextWriter,  toTitleCase } from "../utility";

const docx = require("docx");
const {Paragraph, TextRun } = docx;

const ratingMap = new Map<string, string>([
    ["RED", "Early Stage of Development"],
    ["GREEN", "Centre of Excellence"],
    ["AMBER", "In Development"]
]);


const getMap = (key: string): string => {
    return ratingMap.get(key) || ""
}
export const ExecutiveSummary = (meta?: SiteMetaProps) => new Paragraph({
    children: [
        TextWriter("eMQT has compiled this report on the capabilities of this Investigator Site based on the data reported in internal site assessment tool (ATOM) to collect, maintain and report on site capabilities across Sub-Saharan Africa. This interactive tool allows reporting across all aspects of site capabilities for trials and also insight into the local regulatory and ethics requirements for each location."),
        new TextRun({ text: "\n", break: 1 })
        , TextWriter("The overall objective of this assessment was to assess the site’s readiness for participation in commercially funded clinical trials according to ICH GCP. In summary from the data eMQT received from ATOM it demonstrates that your site has many of the attributes required but also some gaps which have been highlighted in this report."),
        new TextRun({ text: "\n", break: 1 })
        , TextWriter("eMQT is willing and able to work with you, as you may instruct, to support you in addressing the gaps so we can promote your site as a Centre of Excellence and hence a candidate for pharma to consider for the clinical trials they undertake across the globe."),
        new TextRun({ text: "\n", break: 1 })
        , TextWriter("Overall readiness rating: "),
        TextWriter(meta?.rating ? toTitleCase(meta?.rating) + ': ' + getMap(meta!.rating!.toString()) : "NOT RATED"),
        new TextRun({ text: "\n", break: 1 })
    ],
    spacing: {
        line: 360,
    },
})
