import React, { createContext, useContext } from "react";
import { injectAdminIntoRoot } from "../modules/admin/store";
import { injectLogsIntoRoot } from "../modules/common/store";
import { injectDashboardIntoRoot } from "../modules/dashboard/store";
import { injectReportIntoRoot } from "../modules/reporter/store";
import { injectSearchIntoRoot } from "../modules/search/store";
import { injectSitesIntoRoot } from "../modules/sites/store";

import { RootStore } from "./types/rootstore";
import { injectUserIntoRoot } from "./types/userStore";


function createStore() {
    let rootStore: RootStore = {};
    injectUserIntoRoot(rootStore)
    injectAdminIntoRoot(rootStore)
    injectSitesIntoRoot(rootStore)
    injectLogsIntoRoot(rootStore)
    injectSearchIntoRoot(rootStore)
    injectReportIntoRoot(rootStore)
    injectDashboardIntoRoot(rootStore)
    return rootStore;
}


const StoreContext = createContext<RootStore | undefined>(undefined);


export const StoreProvider = ({ children }: any) => {
    const stores = createStore();
    return (
        <StoreContext.Provider value={stores}>
            {children}
        </StoreContext.Provider>
    );
};


export const useStore = () => {
    const store = useContext(StoreContext);
    if (!store) {
        throw new Error("Missing usage of StoreProvider");
    }
    return store;
};