
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useStore } from '../../../store/StoreProvider';
import { countries } from '../../common/constants/countries';
import { StatusCount } from '../../dashboard/components/graph/sitestatus';
import Africa from '../../dashboard/components/map/africa';
import CountriesDist from '../../dashboard/components/site/countries';
import SiteTimeLapsList from '../../dashboard/components/site/list';
import { CountryDistribution } from '../../dashboard/types/types';
import Activities from '../../dashboard/components/logs/activity'

function AdminHome() {

    const { sitesListSore: siteSore, logsListSore: logsStore } = useStore();
    const [countryDist, setCountryDist] = useState<CountryDistribution[]>([])
    const [currentDist, setCurrentDist] = useState<CountryDistribution>({})
    const [currentState, setCurrentState] = useState("")
    const logs = logsStore?.items
    const allLogs = logs ? logs.slice(-4) : []

    let _countryDist: CountryDistribution[] = []
    const sites = siteSore?.items || []

    useEffect(() => {
        _countryDist.length =0
        siteSore?.loadSites().then(() => {
            countries.forEach(country => {
                _countryDist.push({ name: country.name, count: countSites(country.name), code: country.isoCode })
            });
            setCountryDist([..._countryDist].sort((a, b) => (b.count || 0) - (a.count || 0)))
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) 

    useEffect(() => {
        let _country = countries.find(x => x.isoCode === currentState) || { dialCode: "", flag: "", isoCode: "", name: "" }
        setCurrentDist(countryDist.find(x => x.name === _country.name) || { count: 0, name: "" })
//eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentState])

    function countSites(country: string): number {
        let total = 0;
        sites.forEach(x => {
            if (x.description?.country === country)
                total++
        });
        return total;
    }

    return (
        <div className="relative p-10 w-full">
            <div className="flex items-center  w-full min-h-full">
                <div className="w-full  flex-col lg:flex-row items-start lg:items-center rounded  space-y-5">
                    <div className='w-full flex space-x-5'>
                        <div className='w-1/3 flex flex-col shadow-lg self-stretch'>
                            <div className='flex justify-between py-3 px-5 border-t'>
                                <p>Total Site: {sites.length} </p>
                                <p>Selected: {currentDist.name} </p>
                                <p>Count: {currentDist.count}</p>
                            </div>
                            <div className='w-full  bg-gray-50'>
                                <div className='p-5 '>
                                    <Africa currentState={currentState} setCurrentState={setCurrentState} />
                                </div>
                            </div>

                            <div className='w-full bg-gray-100'>
                                <div className=''>
                                    <table className="min-w-full max-h-72 overflow-y-scroll">
                                        <thead className="border-b">
                                            <tr className='bg-gray-800 text-white font-bold'>
                                                <th scope="col" className="text-sm font-medium px-6 py-2 text-left">
                                                    Country
                                                </th>
                                                <th scope="col" className="text-sm font-medium px-6 py-2 text-right">
                                                    Number of Site
                                                </th>
                                            </tr>
                                        </thead>
                                    </table>
                                    <CountriesDist countries={countryDist} currentState={currentState} setCurrentState={setCurrentState} />
                                </div>
                            </div>
                        </div>

                        <div className='w-2/3  flex flex-col space-y-3 self-stretch'>
                            <div className='w-full flex space-x-3'>
                                <div className='w-1/2 bg-white rounded-md flex'>
                                    <div className='p-5 self-center w-full'>
                                        <StatusCount />
                                    </div>
                                </div>

                                <div className='w-1/2 bg-white rounded-md self-stretch'>
                                    <div className='p-5'>
                                        <Activities logs={allLogs} />
                                        <small className='text-atomGreen'>
                                            <span className=' py-1 pb-5'><Link to={"/logs"}> View all</Link></span>
                                        </small>
                                    </div>
                                </div>
                            </div>

                            <div className='w-full flex flex-1'>
                                <div className='w-full bg-white rounded-md max-h-96 overflow-y-auto'>
                                    <div className='p-5'>
                                        <SiteTimeLapsList sites={siteSore?.items || []} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminHome

