
import { QuestionType } from "../../common/types/question";
import { CellFillWriter, CellWriter, findQuestion, NO_INFO } from "../utility";

const docx = require("docx");
const { Table, TableRow } = docx;


type clinicalTrailSummary = {
    description?: string
    response?: string
    count?: number
    patients?: number
}

type trailQuestionMAp = {
    questionIds: string[]
}

export const SummarizeDocLocation = (results: any, questions: QuestionType[]): clinicalTrailSummary[] => {
    var result: clinicalTrailSummary[] = []

    trialQuestionIdMaps.map(trail =>  (
        result.push({
            description: trail.questionIds[1] === "1" ? findQuestion(questions, trail.questionIds[0]) : trail.questionIds[2] as string,
            response: results[trail.questionIds[0]] ? results[trail.questionIds[0]] : NO_INFO
        })
    ))
    return result
}

const trialQuestionIdMaps: trailQuestionMAp[] = [
    {
        questionIds: ["e895", "1"]
    },
    {

        questionIds: ["da4c", "1", "Trail Document are secured by:"]
    },
    {
        questionIds: ["cd28", "1", "Trail Document are secured by:"]
    },
    {

        questionIds: ["60f2", "1", "Trail Document are secured by:"]
    },
    {
        questionIds: ["471d", "1", "Medical records maintained and archiving process"]
    },
    {
        questionIds: ["c2da", "1"]
    },
    {
        questionIds: ["e815", "1", "Electronic archive"]
    },
    {
        questionIds: ["ffa5", "1", "Electronic archive back up"]
    }
]


export const _3_3_3_DocumentationTable = (counts: clinicalTrailSummary[]) => new Table({
    rows: [
        new TableRow({
            children: [
                CellFillWriter("Documentation Management"),
                CellFillWriter(""),
          

            ],
        })].concat(

            counts.map(count => new TableRow({
                children: [
                    CellWriter(count.description),
                    CellWriter(count.response?.toString()),
                ]
            }))
        )

})
