

import {  TextWriter, YesNoFiller } from "../utility";

const docx = require("docx");
const { Paragraph, TextRun } = docx;

export const _3_6_3_TumorTissueB = ( hasDedicatedStore: string, canStoreForm: string) => {


    return new Paragraph({
        children: [

            new TextRun({ text: "\n", break: 2 }),

            TextWriter(`The pathology lab`),
            TextWriter(YesNoFiller(hasDedicatedStore, ' has ', ' has not ')),
            TextWriter(`dedicated storage facilities and`),
            TextWriter(YesNoFiller(canStoreForm, ' has ', ' has not ')),
            TextWriter(`store formalin vials at 4°C `),
            new TextRun({ text: "\n", break: 2 }),
            TextWriter(`For clinical trial work your path lab can meet potential protocol requirements as outlined in Table 11 below: `),

            new TextRun({ text: "\n", break: 2 })

        ], spacing: {
            line: 360,
        },
    })
}