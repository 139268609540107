
import { QuestionType } from "../../common/types/question";
import { CellFillWriter, CellWriter, findQuestion, NO_INFO } from "../utility";

const docx = require("docx");
const { Table, TableRow, } = docx;


type processTrailSummary = {
    description?: string
    type?: string
    response?: string
    count?: number
    patients?: number
}

type trailQuestionMAp = {
    questionIds: string[]
}

export const SummarizeProcess = (results: any, questions: QuestionType[]): processTrailSummary[] => {
    var result: processTrailSummary[] = []

    trialQuestionIdMaps.map(trail => (
        result.push({
            count: results[trail.questionIds[2]] ? results[trail.questionIds[2]] : "0",
            description: findQuestion(questions, trail.questionIds[0]) as string,
            response: results[trail.questionIds[0]] ? results[trail.questionIds[0]] : NO_INFO,
            type: results[trail.questionIds[1]] ? results[trail.questionIds[1]] : "",
            patients: results[trail.questionIds[3]] ? results[trail.questionIds[3]] : "0",

        })
    ))
    return result
}

const trialQuestionIdMaps: trailQuestionMAp[] = [
    {
        questionIds: ["uu87", "aa0e", "8712", "8f3f"]
    },
    {

        questionIds: ["dbf0", "u3rp", "fded", "18ae"]
    },
    {

        questionIds: ["5113", "tt67", "52a6", "8570"]
    },
    {

        questionIds: ["30a4", "77e6", "651e", "b33f"]
    }/**{
        questionIds: ["ddf0", "u3rp", "fded", "18ae"]
    }**/ ,
    {

        questionIds: ["1933", "", "1af1", "ffec"]
    },
    {

        questionIds: ["2ca2", "", "5670", "c4af"]
    }

]

export const _3_3_ProcessAssessTable = (counts: processTrailSummary[]) => new Table({
    rows: [
        new TableRow({
            children: [
                CellFillWriter("Process Experience"),
                CellFillWriter("Response"),
                CellFillWriter("Assessment Type"),
                CellFillWriter("Clinical trails involving this in the last 3 years"),
                CellFillWriter("Patient Population studied"),

            ],
        })].concat(
            counts.map(count => new TableRow({
                children: [
                    CellWriter(count.description),
                    CellWriter(count.response?.toString()),
                    CellWriter(count.type?.toString()),
                    CellWriter(count.count?.toString()),
                    CellWriter(count.patients?.toString())
                ]
            }))
        )
})
