
import { QuestionType } from "../../common/types/question";
import { CellFillWriter, CellWriter, findQuestion, NO_INFO } from "../utility";

const docx = require("docx");
const { Table, TableRow, } = docx;


type docSummary = {
    description?: string
    response?: string
    copies?:string
}

type docQuestionMAp = {
    questionIds: string[]
}

export const SummarizeDoc = (results: any, questions: QuestionType[]): docSummary[] => {
    var result: docSummary[] = []

    trialQuestionIdMaps.map(trail => (
        result.push({
            description: findQuestion(questions, trail.questionIds[0]) as string,
            response: results[trail.questionIds[0]] ? results[trail.questionIds[0]] : NO_INFO,
            copies: results[trail.questionIds[2]] ? results[trail.questionIds[2]] : NO_INFO
        })
    ))
    return result
}

const trialQuestionIdMaps: docQuestionMAp[] = [
    {
        questionIds: ["a253", "a253", "fb68"]
    },  {
        questionIds: ["a9ea", "", "",""]
    },
    
]


export const _3_7_1_EthicsDocTable = (counts: docSummary[]) => new Table({
    rows: [
        new TableRow({
            children: [
                CellFillWriter("Document Details"),
                CellFillWriter("Requirements & Approval Required"),
                CellFillWriter("Number of copies"),
            
            ],
        })].concat(

            counts.map(count => new TableRow({
                children: [
                    CellWriter(count.description),
                    CellWriter(count.response?.toString().split(',').join(' |   ').replaceAll('_',' ').replaceAll("REQUIRE SIGNATURE","signed").replaceAll("REQUIRE NO SIGNATURE","unsigned")),
                    CellWriter(count.copies?.toString()),
                ]
            }))
        )

})
