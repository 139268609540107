import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { Fragment, ReactElement } from 'react'

type dialogProps = {
    title: string
    children: ReactElement
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export default function NewDialog({ isOpen, setIsOpen, title, children }: dialogProps) {

    function closeModal() {
        setIsOpen(false)
    }

    return (
        <>

            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModal}  >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity" />
                        </Transition.Child>
                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="inline-block h-screen align-middle" aria-hidden="true" >
                            &#8203;
                        </span>
                        <Transition.Child as={Fragment} enter="ease-out duration-300"  enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95" >

                            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-sm">

                                <div className="flex justify-between">
                                    <Dialog.Title as="h5" className="font-medium leading-6 text-gray-900 self-center">
                                        {title}
                                    </Dialog.Title>
                                    <div className="mx-auto flex-shrink-0 flex items-center self-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                                        <XIcon className="h-6 w-6 text-red-600" aria-hidden="true" onClick={() => closeModal()} />
                                    </div>
                                </div>

                                {children}
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}