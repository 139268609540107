import React, { ChangeEvent, useState } from 'react'
import { QuestionProps } from '../../../../common/types/question'
import { MemberRoles, TeamMemberType, TeamMemberRoleEnum, TeamMemberSpecialtyEnum } from '../../../../common/types/site'
import { Option } from "react-multi-select-component";
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useEffect } from 'react';
import { MemberResponsibilities, PSDs, SpecialtyArea } from '../../../../common/constants';
import { useStore } from '../../../../../store/StoreProvider';
import { ref, uploadBytes } from 'firebase/storage';
import { setupFireStore } from '../../../../../firebase/firebase.config';
import { FILE_DATE, SiteTeamMemberUpdate } from '../../../../common/types/utility';
import { InputResponse, MultipleSelectWithOthers, PhoneResponse } from './AddTeamMember';


const TmSpecialtyNetwork: React.FC<QuestionProps> = ({ name, label, placeholder, onInputChange, value, setCheckWDetail, detailLabel, checkValue, tm }) => {
    return (<div className="flex">
        <div className="w-full lg:w-1/2  flex flex-col text-xs">
            <label className="text-left block font-bold text-gray-800  tracking-wider  w-full">{label}</label>
            <div className="flex  space-x-10 my-2">
                <div className="flex">
                    <input className="self-center" name={name} type="radio" checked={tm.hasNetwork} onChange={() => setCheckWDetail!(true)} />
                    <label className="self-center px-3">Yes</label>
                </div>
                <div className="flex self-center">
                    <input className="self-center" name={name} type="radio" checked={!tm.hasNetwork} onChange={() => setCheckWDetail!(false)} />
                    <label className="self-center px-3">No</label>
                </div>
            </div>
        </div>
        {checkValue === true && <div className="w-full lg:w-1/2 flex  space-x-10 text-xs">
            <div className="w-full  flex  space-x-10">
                <label className="text-left block font-bold text-gray-800 w-1/3 self-center tracking-wider px-10 pl-12">{detailLabel}</label>
                <input className="bg-gray-100 border border-gray-100 p-2 w-2/3  focus:outline-none placeholder-gray-300  text-gray-800 self-center" placeholder={placeholder} name={name} onChange={(e) => onInputChange!(e)} value={tm.specialtyNetwork} disabled={tm.hasNetwork ? false : true} />
            </div>
        </div>}

    </div>)
}

const TmGCPTraining: React.FC<QuestionProps> = ({ name, label, placeholder, setCheckWDetail, value, detailValue, onInputChange, detailName, onFileChange, checkValue, detailDate, tm }) => {
    return (<div className="flex flex-col my-1 text-xs">
        <label className="my-1 block font-bold text-gray-800 text-left">{label}</label>

        <div className="flex space-x-1 justify-between">
            <div className="flex flex-col self-center">
                <div className="flex  space-x-10 my-2">
                    <div className="flex self-center">
                        <input className="self-center" name={name} type="radio" checked={tm.gcpTrained} onChange={() => setCheckWDetail!(true)} />
                        <label className="self-center px-3">Yes</label>
                    </div>
                    <div className="flex self-center">
                        <input className="self-center" checked={!tm.gcpTrained} onChange={() => setCheckWDetail!(false)} name={name} type="radio" />
                        <label className="self-center px-3">No</label>
                    </div>
                </div>
            </div>
            {tm.gcpTrained && <div className="space-x-5 text-xs">
                <label>Date</label>
                <input className="bg-gray-100 border border-gray-200 p-2 px-3 my-1  focus:outline-none placeholder-gray-300  text-gray-800" type="month" disabled={checkValue === true ? false : true} onChange={(e) => onInputChange!(e)} name={name} value={tm.gcpDate} />
            </div>}
            {tm.gcpTrained && <input className="bg-gray-100 border border-gray-200 p-2 px-3 my-1  focus:outline-none placeholder-gray-300  text-gray-800 w-3/12" placeholder="Name of training company" type="text" disabled={tm.gcpTrained ? false : true} onChange={(e) => onInputChange!(e)} name={detailName} value={detailValue} />}
            {tm.gcpTrained && <>
                {!tm.gcpCertLink ?
                    <input className="bg-gray-100 border border-gray-200 p-2 px-3 my-1 text-xs  focus:outline-none placeholder-gray-300  text-gray-800 w-3/12" type="file" placeholder="" disabled={tm.gcpTrained ? false : true} onChange={(e) => onFileChange!(e)} /> :
                    (<div className='flex flex-col'>
                        <p className="text-xs self-center">{tm.gcpCertLink}</p>
                        <input id={name} className="bg-gray-100 border border-gray-200 p-2 px-3 my-1 text-xs hidden  focus:outline-none placeholder-gray-300  text-gray-800 w-3/12" type="file" placeholder="" disabled={tm.gcpTrained ? false : true} onChange={(e) => onFileChange!(e)} />
                        <label htmlFor={name} className='border p-2 rounded'>Update file</label>
                    </div>)

                }
            </>}

        </div>
    </div>)
}

const TmEdcCert: React.FC<QuestionProps> = ({ name, label, placeholder, setCheckWDetail, value, detailValue, checkValue, onInputChange, onFileChange, detailName, detailDate, tm }) => {
    return (<div className="flex flex-col my-1 text-xs">

        <label className="my-1 block font-bold text-gray-800 text-left">{label}</label>

        <div className="flex space-x-1 justify-between">
            <div className="flex flex-col self-center">
                <div className="flex  space-x-10 my-2">
                    <div className="flex self-center">
                        <input className="self-center" name={name} type="radio" checked={tm.edcTrained} onChange={() => setCheckWDetail!(true)} />
                        <label className="self-center px-3">Yes</label>
                    </div>
                    <div className="flex self-center">
                        <input className="self-center" checked={tm.edcTrained === false} onChange={() => setCheckWDetail!(false)} name={name} type="radio" />
                        <label className="self-center px-3">No</label>
                    </div>
                </div>

            </div>
            {tm.edcTrained === true && <div className="space-x-5">
                <label>Date</label>
                <input className="bg-gray-100 border border-gray-200 p-2 px-3 my-1  focus:outline-none placeholder-gray-300  text-gray-800" type="month" disabled={tm.edcTrained === true ? false : true} onChange={(e) => onInputChange!(e)} name={name} value={tm.edcDate} />
            </div>}

            {tm.edcTrained && <>
                {!tm.edcCertLink ?
                    <input className="bg-gray-100 border border-gray-200 p-2 px-3 my-1 text-xs  focus:outline-none placeholder-gray-300  text-gray-800 w-3/12" type="file" placeholder="" disabled={tm.edcTrained ? false : true} onChange={(e) => onFileChange!(e)} name={detailName} />
                    :
                    (<div className='flex flex-col'>
                        <p className="text-xs self-center">{tm.edcCertLink}</p>
                        <input id={name} className="bg-gray-100 border hidden border-gray-200 p-2 px-3 my-1 text-xs  focus:outline-none placeholder-gray-300  text-gray-800 w-3/12" type="file" placeholder="" disabled={tm.edcTrained ? false : true} onChange={(e) => onFileChange!(e)} name={detailName} />
                        <label htmlFor={name} className='border p-2 rounded'>Update file</label>
                    </div>)
                }
            </>}
        </div>
    </div>)
}

const FileResponse: React.FC<QuestionProps> = ({ name, label, placeholder, onFileChange, tm }) => {
    return (<div className="flex text-xs ">
        <label className=" font-bold text-gray-800 w-1/3 self-center text-left">{label}</label>
        {tm.cvLink ? (<div className='flex flex-col space-y-1'> <p className='text-left'>{tm.cvLink} </p>
            <input id={name} className="bg-gray-100 border hidden border-gray-200 p-1 px-3 my-1 text-xs focus:outline-none placeholder-gray-300  text-gray-800 w-2/3" type="file" placeholder={placeholder} name={name} onChange={(e) => onFileChange!(e)} />

            <label htmlFor={name} className='border p-2 rounded'>Update file</label>
        </div>) : <input className="bg-gray-100 border border-gray-200 p-1 px-3 my-1 text-xs focus:outline-none placeholder-gray-300  text-gray-800 w-2/3" type="file" placeholder={placeholder} name={name} onChange={(e) => onFileChange!(e)} />}
    </div>)
}


const InputTextWithAddNew: React.FC<QuestionProps> = ({ name, label, placeholder, options, addNew, removeNew, onAddMoreInputChange }) => {
    return (<div className="flex space-x-5 my-1 text-xs" key={name}>
        <label className="text-left block font-bold text-gray-800 w-1/3 self-center tracking-wider">{label}</label>
        <div className="flex-1 w-full">
            {options?.map((option, index) => <div className="flex bg-gray-100 border border-gray-100 rounded my-1" key={index}> <input placeholder={option} key={index} className="p-2 px-3 my-1  focus:outline-none w-full placeholder-gray-300  text-gray-800 bg-transparent" value={option} onChange={(e) => onAddMoreInputChange!(e, index)} /> <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-3 self-center cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" onClick={() => removeNew!(index)}>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg> </div>)}
        </div>
        <div className="right-0 float-right">
            <button onClick={() => addNew!()} className="px-3 border-2 border-atomGreen my-2 rounded flex p-2 text-atomGreen">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                </svg>
                Add </button>
        </div>
    </div>
    )
}



type EditTeamMemberProps = {
    tm: TeamMemberType
    setEditTm: React.Dispatch<React.SetStateAction<TeamMemberType | undefined>>
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

function EditTeamMember({ open, setOpen, tm, setEditTm }: EditTeamMemberProps) {
    const { sitesListSore: siteSore, userStore } = useStore();
    const site = siteSore?.selectedSite
    const user = userStore?.currentUser
        //eslint-disable-next-line    @typescript-eslint/no-unused-vars
    const [error, setError] = useState<string>("")
    //eslint-disable-next-line    @typescript-eslint/no-unused-vars
    const [roleCheckWDetails, setRoleCheckWDetails] = useState<boolean>(false)
    const [roleMultiSelected, setRoleMultiSelected] = useState<Option[]>([]);
    const [specialtyMultiSelected, setSpecialtyMultiSelected] = useState<Option[]>([]);
    const [responsibilityMultiSelected, setResponsibilityMultiSelected] = useState<Option[]>([]);
    const [psdOptions, setPsdOptions] = useState<Option[]>([]);

    const { FirebaseStorage: storage } = setupFireStore()
    const [otherPSAMultiSelected, setOtherPSAMultiSelected] = useState<Option[]>([]);



    const inputResponseChanges = (e: ChangeEvent<HTMLInputElement>) => {
        setError("")
        let copiedValue: TeamMemberType = { ...tm }
        let name = e.target.name as keyof TeamMemberType
        copiedValue[name] = e.target.value as string & boolean & TeamMemberRoleEnum[] & TeamMemberSpecialtyEnum[]
        setEditTm(copiedValue)
    }

    const inputPhoneResponseChanges = (e: ChangeEvent<HTMLInputElement>) => {
        let copiedValue: TeamMemberType = { ...tm }
        let name = e.target.name as keyof TeamMemberType
        let phone = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
        copiedValue[name] = phone as string & boolean & TeamMemberRoleEnum[] & TeamMemberSpecialtyEnum[]
        setEditTm(copiedValue)
    }

    const handleCheckWithTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        setError("")
        let copiedValue: TeamMemberType = { ...tm }
        let name = e.target.name as keyof TeamMemberType
        copiedValue[name] = e.target.value as string & boolean & TeamMemberRoleEnum[] & TeamMemberSpecialtyEnum[]
        setEditTm(copiedValue)
    }

    const handleMoreTrainingListChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
        setError("")
        let copiedValue: TeamMemberType = { ...tm }
        copiedValue.edcTrainingReceived![index] = e.target.value
        setEditTm(copiedValue)
    }

    const handleMoreExperienceListChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
        setError("")
        let copiedValue: TeamMemberType = { ...tm }
        copiedValue.edcSystemExperience![index] = e.target.value
        setEditTm(copiedValue)
    }

    useEffect(() => {

        let roles: Option[] = []
        let specialties: Option[] = []
        let otherSpecialties: Option[] = []
        let responsibilities: Option[] = []
        let psds: Option[] = []

        let selectedRoles = tm.roles || []
        selectedRoles!.forEach(e => {
            roles.push({ label: e.replace("_", " "), value: e })
        });
        let selectedSpecialties = tm.specialties || []
        selectedSpecialties!.forEach(e => {
            specialties.push({ label: e, value: e })
        });
        let otherSelectedSpecialties = tm.otherPSDs || []
        otherSelectedSpecialties!.forEach(e => {
            otherSpecialties.push({ label: e, value: e })
        });
        let selectedResponsibilities = tm.responsibilities || []
        selectedResponsibilities!.forEach(e => {
            responsibilities.push({ label: e, value: e })
        });

        let selectedPsds = tm.psd ? typeof tm.psd === 'string' ? [tm.psd] : [...tm.psd] : []
        selectedPsds!.forEach(e => {
            psds.push({ label: e, value: e })
        });
        setOtherPSAMultiSelected(otherSpecialties)
        setResponsibilityMultiSelected(responsibilities)
        setPsdOptions(psds)
        setSpecialtyMultiSelected(specialties)
        setRoleMultiSelected(roles)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let copiedValue: TeamMemberType = { ...tm }
        let list: TeamMemberRoleEnum[] = []
        roleMultiSelected!.forEach(role => {
            list.push(role.value as TeamMemberRoleEnum)
        });
        copiedValue.roles = [...list]
        setEditTm(copiedValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roleMultiSelected])

    useEffect(() => {
        let copiedValue: TeamMemberType = { ...tm }
        let list: TeamMemberSpecialtyEnum[] = []
        specialtyMultiSelected!.forEach(role => {
            list.push(role.value as TeamMemberSpecialtyEnum)
        });
        copiedValue.specialties = [...list]
        setEditTm(copiedValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [specialtyMultiSelected])
    useEffect(() => {
        let copiedValue: TeamMemberType = { ...tm }
        let list: TeamMemberSpecialtyEnum[] = []
        otherPSAMultiSelected!.forEach(role => {
            list.push(role.value as TeamMemberSpecialtyEnum)
        });
        copiedValue.otherPSDs = [...list]
        setEditTm(copiedValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [otherPSAMultiSelected])

    useEffect(() => {
        let copiedValue: TeamMemberType = { ...tm }
        let list: string[] = []
        responsibilityMultiSelected!.forEach(responsibility => {
            list.push(responsibility.value)
        });
        copiedValue.responsibilities = [...list]
        setEditTm(copiedValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [responsibilityMultiSelected])

    useEffect(() => {
        let copiedValue: TeamMemberType = { ...tm }
        let list: string[] = []
        psdOptions!.forEach(responsibility => {
            list.push(responsibility.value)
        });
        copiedValue.psd = [...list]
        setEditTm(copiedValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [psdOptions])



    const handleAddMoreTraining = () => {
        let copiedValue: TeamMemberType = { ...tm }
        let edcs = copiedValue.edcTrainingReceived || []
        edcs?.push(`Training title ${edcs.length}`)
        copiedValue.edcTrainingReceived = edcs
        setEditTm(copiedValue)
    }

    function handleCCodeChanges(e: React.ChangeEvent<HTMLSelectElement>): void {
        let copiedValue: TeamMemberType = { ...tm }
        let name = e.target.name as keyof TeamMemberType
        copiedValue[name] = e.target.value as string & boolean & TeamMemberRoleEnum[] & TeamMemberSpecialtyEnum[]
        setEditTm(copiedValue)
    }

    const handleAddMoreExperience = () => {
        let copiedValue: TeamMemberType = { ...tm }
        let edcs = copiedValue.edcSystemExperience || []
        edcs?.push(`System Experience  ${edcs.length}`)
        copiedValue.edcSystemExperience = edcs
        setEditTm(copiedValue)
    }


    const handleRemoveMoreTraining = (index: number) => {
        let copiedValue: TeamMemberType = { ...tm }
        let filtered = copiedValue.edcTrainingReceived!.filter((list, i) => { return index !== i })
        copiedValue.edcTrainingReceived = filtered
        setEditTm(copiedValue)
    }

    const handleRemoveMoreExperience = (index: number) => {
        let copiedValue: TeamMemberType = { ...tm }
        let filtered = copiedValue.edcSystemExperience!.filter((list, i) => { return index !== i })
        copiedValue.edcSystemExperience = filtered
        setEditTm(copiedValue)
    }

    function handleNetworkChange(e: boolean): void {
        let copiedValue: TeamMemberType = { ...tm }
        copiedValue.hasNetwork = e
        if (e === false)
            copiedValue.specialtyNetwork = ""
        setEditTm(copiedValue)
    }

    function handleGcpStatusChange(e: boolean): void {
        let copiedValue: TeamMemberType = { ...tm }
        copiedValue.gcpTrained = e
        if (e === false) {
            copiedValue.gcpDate = ""
            copiedValue.gcpCompany = ""
        }
        setEditTm(copiedValue)
    }

    function handleEdcStatusChange(e: boolean): void {
        let copiedValue: TeamMemberType = { ...tm }
        copiedValue.edcTrained = e
        if (e === false) {
            copiedValue.edcDate = ""
            copiedValue.edcCertLink = ""
        }
        setEditTm(copiedValue)
    }



    const handleSaveToDb = () => {
        const log = SiteTeamMemberUpdate(site!, user?.email || 'Anonymous', tm.name)
        siteSore!.updateMember(tm, log)
    }

    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>, tmName: string, role: string | undefined) => {
        let files = e.target.files
        if (!files && files!.length < 1) {
            return
        }
        let copiedValue: TeamMemberType = { ...tm }
        let name = e.target.name as keyof TeamMemberType
        const file = files![0]
        role = role + ""
        const filename = `${site?.description?.siteId}/cv/${tmName.replace(" ", "_")}${role.replace(" ", "_")}-${FILE_DATE}-${file.name.replace(" ", "_")}`

        const leadCvRef = ref(storage, filename);

        uploadBytes(leadCvRef, e.target.files![0]).then((snapshot) => {
            // setIsLoading(false)
            setEditTm({ ...tm, cvLink: snapshot.ref.fullPath })
        });
        copiedValue[name] = e.target.value as string & boolean & TeamMemberRoleEnum[] & TeamMemberSpecialtyEnum[]
        setEditTm(copiedValue)

    }

    const handleEDCFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        let files = e.target.files
        if (!files && files!.length < 1) {
            return
        }
        let copiedValue: TeamMemberType = { ...tm }
        let name = e.target.name as keyof TeamMemberType
        const file = files![0]

        const leadCvRef = ref(storage, `${siteSore?.selectedSite.description?.siteId}/edc/${file.name}`);
        uploadBytes(leadCvRef, file).then((snapshot) => {
            // setIsLoading(false)
            setEditTm({ ...tm, edcCertLink: snapshot.ref.fullPath })
        });
        copiedValue[name] = e.target.value as string & boolean & TeamMemberRoleEnum[] & TeamMemberSpecialtyEnum[]
        setEditTm(copiedValue)
    }
    const handleGCPFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        let files = e.target.files
        if (!files && files!.length < 1) {
            return
        }
        let copiedValue: TeamMemberType = { ...tm }
        let name = e.target.name as keyof TeamMemberType
        const file = files![0]

        const leadCvRef = ref(storage, `${siteSore?.selectedSite.description?.siteId}/gcp/${file.name}`);
        uploadBytes(leadCvRef, file).then((snapshot) => {
            // setIsLoading(false)
            setEditTm({ ...tm, gcpCertLink: snapshot.ref.fullPath })
        });
        copiedValue[name] = e.target.value as string & boolean & TeamMemberRoleEnum[] & TeamMemberSpecialtyEnum[]
        setEditTm(copiedValue)
    }

    const close = () => {
        setOtherPSAMultiSelected([])
        setResponsibilityMultiSelected([])
        setPsdOptions([])
        setSpecialtyMultiSelected([])
        setRoleMultiSelected([])
        setOpen(false)
    }
    return (
        <div>

            <Transition appear show={open} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 z-30 overflow-y-auto bg-black bg-opacity-70"
                    onClose={() => { setEditTm(undefined); close() }}     >
                    <div className="max-h-screen text-center">
                        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>
                        <span className="inline-block h-screen align-middle" aria-hidden="true"  >  &#8203;  </span>
                        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95" >

                            <div className="inline-block  w-2/3 lg:w-2/3 overflow-hidden align-middle transition-all transform  max-h-full bg-white shadow-2xl">
                                <div className="px-4 sm:px-6 pt-10">
                                    <Dialog.Title className=" text-atomGreen text-center text-4xl font-thin">Edit Team Member</Dialog.Title>
                                </div>

                                <div className="mt-3 space-y-5 w-full pb-3">

                                    <h6 className="py-5 px-10 text-lg text-left text-gray-300">Basic Information</h6>

                                    <div className="flex flex-col lg:flex-row w-full  space-x-5">
                                        <div className="w-full lg:w-1/2 px-10">
                                            <InputResponse name="name" label="Name" placeholder="Name of site team member" value={tm.name} onInputChange={(e) => inputResponseChanges(e)} tm={tm} />
                                        </div>
                                        <div className="w-full lg:w-1/2 px-10">
                                            <InputResponse name="emailAddress" label="Email Address" placeholder="Email Address" value={tm.emailAddress} onInputChange={(e) => inputResponseChanges(e)} tm={tm} />
                                        </div>
                                    </div>

                                    <div className="flex flex-col lg:flex-row w-full  space-x-5">
                                        <div className="w-full lg:w-1/2 px-10">

                                            <PhoneResponse name="directLine" label="Direct Line" placeholder="Direct Line" cCodeName="dLineCode" tm={tm} onCCodeChange={(e) => handleCCodeChanges(e)} onInputChange={(e) => inputPhoneResponseChanges(e)} value={tm.directLine} defaultValue={tm.dLineCode} />


                                        </div>
                                        <div className="w-full lg:w-1/2 px-10">
                                            <PhoneResponse name="mobileNumber" label="Mobile Number" placeholder="Mobile Number" tm={tm} cCodeName="mLineCode" onCCodeChange={(e) => handleCCodeChanges(e)} onInputChange={(e) => inputPhoneResponseChanges(e)} value={tm.mobileNumber} defaultValue={tm.mLineCode} />
                                        </div>
                                    </div>

                                    <div className="flex flex-col lg:flex-row w-full  space-x-5">
                                        <div className="w-full lg:w-1/2 px-10">
                                            <FileResponse name="primarySpecialtyArea" placeholder="Choose Primary Specialty Area" onFileChange={(e) => { handleFileUpload(e, tm.name, tm.roles?.join('_')) }} tm={tm} label="Update CV" />
                                        </div>
                                        <div className="w-full lg:w-1/2 px-10">
                                        </div>
                                    </div>

                                    <hr />

                                    <h6 className="py-5 px-10 text-lg text-left text-gray-300">Specialty Information</h6>


                                    <div className="flex flex-col lg:flex-row w-full  space-x-5">
                                        <div className="w-full lg:w-1/2 px-10">
                                            <MultipleSelectWithOthers name="researchRole" label="Research Role" placeholder="" tm={tm} options={MemberRoles} multiSelectValues={roleMultiSelected} onMultiSelectChange={setRoleMultiSelected} value={tm.roles! || []} />
                                        </div>
                                        <div className="w-full lg:w-1/2 px-10">
                                            <InputResponse name="otherRr" label="Others" placeholder="Others" onInputChange={(e) => inputResponseChanges(e)} value={tm.otherRr} tm={tm} />
                                        </div>
                                    </div>

                                    <div className="flex flex-col lg:flex-row w-full  space-x-5">
                                        <div className="w-full lg:w-1/2 px-10">
                                            <MultipleSelectWithOthers name="primarySpecialtyArea" label="Primary Specialty Area" placeholder="" options={SpecialtyArea.sort()} multiSelectValues={specialtyMultiSelected} onMultiSelectChange={setSpecialtyMultiSelected} tm={tm} />
                                        </div>
                                        <div className="w-full lg:w-1/2 px-10">
                                            <MultipleSelectWithOthers name="otherPSDs" label="Others" placeholder="" options={SpecialtyArea.sort()} multiSelectValues={otherPSAMultiSelected} onMultiSelectChange={setOtherPSAMultiSelected} tm={tm} />
                                        </div>
                                    </div>




                                    <div className="flex flex-col lg:flex-row w-full  space-x-5">
                                        <div className="w-full lg:w-1/2 px-10">
                                            <MultipleSelectWithOthers name="responsibilities" label="Responsibilities" placeholder="" options={MemberResponsibilities} tm={tm} multiSelectValues={responsibilityMultiSelected} onMultiSelectChange={setResponsibilityMultiSelected} />
                                        </div>
                                        <div className="w-full lg:w-1/2 px-10">
                                            <MultipleSelectWithOthers name="psd" label="Practice Setting Description" placeholder="" options={PSDs} tm={tm} multiSelectValues={psdOptions} onMultiSelectChange={setPsdOptions} />
                                        </div>

                                    </div>
                                    <hr />

                                    <div className="flex flex-col lg:flex-row w-full  space-x-10">
                                        <div className="w-full  px-10">
                                            <TmSpecialtyNetwork name="specialtyNetwork" label="Membership of Specialty Network" detailLabel="Name of Specialty Network" tm={tm} placeholder="Name of Specialty Network" hasCheckedDetail={roleCheckWDetails} setCheckWDetail={(e) => handleNetworkChange(e)} onInputChange={(e) => handleCheckWithTextChange(e)} checkValue={tm.hasNetwork} detailValue={tm.specialtyNetwork} />
                                        </div>
                                    </div>
                                    <hr />

                                    <div className="flex flex-col lg:flex-row w-full  space-x-10">
                                        <div className="w-full  px-10">
                                            <TmGCPTraining name="gcpDate" detailName="gcpCompany" label="GCP Training Received" placeholder="GCP Trainer/Company Name" tm={tm} onFileChange={handleGCPFileUpload} setCheckWDetail={(e) => handleGcpStatusChange(e)} onInputChange={(e) => handleCheckWithTextChange(e)} checkValue={tm.gcpTrained} detailDate={tm.gcpDate} detailValue={tm.gcpCompany} />
                                        </div>
                                    </div>

                                    <div className="flex flex-col lg:flex-row w-full  space-x-10">
                                        <div className="w-full  px-10">
                                            <TmEdcCert name="edcDate" detailName="edcCertLink" label="EDC Training Received" placeholder="" tm={tm} setCheckWDetail={(e) => handleEdcStatusChange(e)} onFileChange={handleEDCFileUpload} onInputChange={(e) => handleCheckWithTextChange(e)} checkValue={tm.edcTrained} detailValue={tm.edcCertLink} detailDate={tm.edcDate} />
                                        </div>
                                    </div>
                                    <hr />

                                    <div className="flex flex-col lg:flex-row w-full  space-x-10">
                                        <div className="w-full px-10">
                                            <InputTextWithAddNew name="edcTrainingReceived" label="List EDC system training received" placeholder="" options={tm.edcTrainingReceived} tm={tm} addNew={() => handleAddMoreTraining()} removeNew={(index) => { handleRemoveMoreTraining(index) }} onAddMoreInputChange={(e, index) => handleMoreTrainingListChange(e, index)} />
                                        </div>

                                    </div>

                                    <hr />


                                    <div className="flex flex-col lg:flex-row w-full  space-x-10">

                                        <div className="w-full  px-10">
                                            <InputTextWithAddNew name="edcSystemExperience" label="List EDC system experience" placeholder="" options={tm.edcSystemExperience} tm={tm} addNew={() => handleAddMoreExperience()} removeNew={(index) => { handleRemoveMoreExperience(index) }} onAddMoreInputChange={(e, index) => handleMoreExperienceListChange(e, index)} />
                                        </div>
                                    </div>

                                    <div className="p-1 flex justify-center rounded my-2 space-x-2">
                                        <button className="px-10 py-2 bg-atomGreen text-gray-50 w-32" onClick={() => { handleSaveToDb(); setEditTm(undefined); close(); }}>Save </button>
                                        <button className="px-10 py-2 border border-atomGreen text-gray-800 w-32" onClick={() => { setEditTm(undefined); close(); }}>Cancel </button>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </div>
    )
}

export default EditTeamMember