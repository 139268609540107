import { GoogleLoginResponse, GoogleLoginResponseOffline, useGoogleLogin } from 'react-google-login';
import { useHistory } from "react-router-dom";
import { useStore } from "../../store/StoreProvider";
import WOMAN from '../../assets/images/woman.png';
import LOGO from '../../assets/images/market_quality_trials.png';
import { authenticateWithFireBase } from './auth';

function AuthenticationPage() {

  const { userStore: store } = useStore();
  const history = useHistory();

  const clientId: string = process.env.REACT_APP_GOOGLE_CLIENT_ID as string;

  function onSuccess(res: GoogleLoginResponseOffline | GoogleLoginResponse) {
    const loginResponse = res as GoogleLoginResponse
    store!.setUser({ ...loginResponse.profileObj })
    authenticateWithFireBase(loginResponse.profileObj.email)
    history.push("/dashboard");
  };

  function onFailure(res: any) {
    console.log('Login failed: res:', res);
    alert('Failed to login');
  };

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
    isSignedIn: false,
    accessType: 'online',
    hostedDomain: "emqt.org.uk",
    prompt: 'consent',
  });


  return (
    <div className="bg-white flex items-center justify-center ">
      <div className="flex flex-row z-50  h-screen justify-center items-center self-center  align-center flex-wrap  w-full  lg:w-1/2 ">
        <div className="flex  inset-0 self-center align-center   drop-shadow-lg p-2 h-1/2 max-h-2/3   md:max-w-2/3 overflow-hidden ">
          <div className="flex w-full flex-row bg-transparent" >
            <div className="p-2 space-y-0 md:space-y-5 bg-white flex w-full md:w-1/2 flex-col justify-evenly md:justify-around h-full sm:px-2 md:px-2 lg:px-10">
              <img className="self-center w-24" src={LOGO} alt="atom logo" />
              <h3 className="text-md md:text-2xl text-center font-bold leading-tight text-gray-800">Mastermind Better <br className='hidden md:flex' />
                Succeed Together</h3>
              <button onClick={() => signIn()} className="button bg-atomGreen py-2  lg:py-3 px-5 font-bold rounded my-5">
                <span className="buttonText">Sign in to your account</span>
              </button>
              <p className=" text-gray-500 text-xs md:text-sm text-center ">Thank you for working with eMQT to, provide all the required information as accurately as possible.</p>
            </div>
            <div className=" h-full overflow-hidden hidden md:flex  md:w-1/2 
            ">
              <img src={WOMAN} className="h-full object-cover" alt="woman banner z-10" />
            </div>
          </div>
        </div>
      </div>
      <div className="fixed bottom-0 bg-atomGreen w-full z-30" style={{ height: "50vh" }}>
        <p className="text-center w-full bottom-10 absolute">© Copyright 2021. eMQT All Rights Reserved.</p>
      </div>
    </div>
  );
}

export default AuthenticationPage;

