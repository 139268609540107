
import { TextFiller, TextWriter, YesNoFiller } from "../utility";

const docx = require("docx");
const { Paragraph, TextRun } = docx;

export const _3_2_TrainingSummary = (isAwareOfTraining: string, staffHasTime:string, leadName :string, leadIsAware :string, youKnowTime:string) => new Paragraph({
    children: [
      
        new TextRun({ text: "\n", break: 2 }),
        TextWriter("You confirmed your site "),
        TextWriter(YesNoFiller(isAwareOfTraining, ' is ', ' is not ')),
        TextWriter("aware of training needs for clinical trials and that "),
        TextWriter(TextFiller(leadName)),
        TextWriter(YesNoFiller(leadIsAware, ' is ', ' is not ')),
        TextWriter("aware of his/her ICH GCP responsibilities and requirements of relevant regulations. You also confirmed your site"),
        TextWriter(YesNoFiller(youKnowTime, ' is ', ' is not ')),
        TextWriter("aware of the time required for this work and that the staff listed"),
        TextWriter(YesNoFiller(staffHasTime, ' does ', ' does not ')),
        TextWriter("have time to complete CRFs and perform site specific duties including the protocol training, and that this will be evaluated again before any protocol can be assigned"),

        new TextRun({ text: "\n", break: 2 }),
       
    ],
    spacing: {
        line: 360,
    },
})
