// Example of how you would create a table and add data to it
// Import from 'docx' rather than '../build' if you install from npm

import { Paragraph, TextRun } from "docx";
import { QuestionType } from "../../common/types/question";
import {  findQuestion, TextFiller, TextWriter, YesNoFiller } from "../utility";

type contractSummary = {
    description?: string
    response?: string
}

type trailQuestionMAp = {
    questionIds: string
    label: string
}

export const SummarizeContract = (results: any, questions: QuestionType[]): contractSummary[] => {
    var result: contractSummary[] = []

    trialQuestionIdMaps.map(trail => (
        result.push({
            description: findQuestion(questions, trail.questionIds) as string,
            response: results[trail.questionIds] ? results[trail.questionIds] : "",

        })
    ))
    return result
}

const trialQuestionIdMaps: trailQuestionMAp[] = [
    {
        label: "Phase 1 Studies",
        questionIds: '0df5'
    },
    {
        label: "Phase II proof of concept",
        questionIds: 'aaa9'
    },
    {
        label: "Phase II proof of concept",
        questionIds: '24b9'
    }
    ,
    {
        label: "Phase II proof of concept",
        questionIds: 'b7b3'
    }
    ,
    {
        label: "Phase II proof of concept",
        questionIds: '44c8'
    }
    ,
    {
        label: "Phase II proof of concept",
        questionIds: '7eb3'
    },
    {
        label: "Phase II proof of concept",
        questionIds: '627f'
    }
]


export const _3_3_3_contractingTable = (counts: contractSummary[]) => new Paragraph({
    children: [

        new TextRun({ text: "\n", break: 2 }),
       TextWriter("For any trial you require a contract with "),
       TextWriter(TextFiller(counts[0].response || '')),
       TextWriter(" and happy to use the sponsor/provider template for all parties and must be "),
       TextWriter(TextFiller(counts[1].response || '')),
       TextWriter(". There"),
       TextWriter(YesNoFiller(counts[3].response || '', ' is ', ' is not ')),
       TextWriter("an overhead payment to be included and the contract "),
       TextWriter(YesNoFiller(counts[2].response || '', 'is needed to be ', 'is not needed to be ')),
       TextWriter("be advised to the local authority. "),
       TextWriter(TextFiller(counts[4].response || "")),
       TextWriter(" is responsible for budget approval and there "),
       TextWriter(YesNoFiller(counts[5].response || '', 'is ', 'is not ')),
       TextWriter("a formal invoicing system at the site. Other aspects are "),
       TextWriter(TextFiller(counts[6].response || "")),

    ], spacing: {
        line: 360,
    },
})