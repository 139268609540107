import { QueryConstraint } from "firebase/firestore";
import { action, makeObservable, observable } from "mobx";
import { FormType, QuestionType } from "../../common/types/question";
import { SiteObject } from "../../common/types/site";
import { SearchService } from "../services/SearchService";
import { SavedSearchQuery, SearchQuery } from "../types";

export class SiteSearchStore {
    queries: SearchQuery[] = [];
    results: SiteObject[] = [];
    loading: boolean = false;
    allSites: SiteObject[] = [];
    history: SavedSearchQuery[] = [];
    allQuestions: QuestionType[]=[]
    allForms: FormType[] = []

    constructor(private searchService: SearchService) {
        makeObservable(this, {
            queries: observable,
            results: observable,
            loading: observable,
            setLoading: action.bound,
            setResults: action.bound,
            setQuery: action.bound,
            setHistory:action
        });
        this.initialize();
    }

    async initialize() {
        this.searchService.getSites().then((response) => {
            this.allSites = response;
        });
        this.searchService.getQueryHistory().then(res => this.setHistory(res))
        this.allforms();
    }


    async allforms() {
        let _question: QuestionType[] = []
        const _forms = await this.searchService.getAllForms();
        _forms.forEach(form => {
            form.subs!.forEach(sub => {
                const subQuestion = sub.questions ? sub.questions : []
                subQuestion.forEach(q => {
                    _question.push(q)
                    if (q.followUps && q.followUps.length > 0)
                        _question = [..._question, ...q.followUps]
                });
            });
        });
        this.allForms = _forms
        this.allQuestions = _question
    }

    async setHistory(queries: SavedSearchQuery[]) {
        this.history = queries
    }

    async setLoading(flag: boolean) {
        this.loading = flag
    }

    
    async setResults(results: SiteObject[]) {
        this.results = results
    }

    async setQuery(queries: SearchQuery[]) {
        this.queries = queries
    }

    async performSearch(queries: QueryConstraint[]) {
        this.searchService.runQuery(queries).then((response) => {
            this.setResults(response)
        });
    }

    async saveSearch(queries: SavedSearchQuery) {
        this.searchService.saveQuery(queries).then((response) => {

        });
    }

    findQuestion = ( questionId: string, sectionId: string): any => {
       // const section = sectionId.split('_')[0] + "_" + sectionId.split('_')[1]
    
        const question = this.allQuestions.find(q => q.questionId ===questionId)
        return question?.statement || ""
    }
}
