
import { TextWriter,  YesNoFiller } from "../utility";

const docx = require("docx");
const { Paragraph, TextRun } = docx;

export const _3_2_ExperienceSummary = (hasEx: string, sponsorName: string, sponsorDate: string, pharmaSponsored: string, localSponsored: string) => {
    console.log(hasEx)
    const _hasExp = hasEx === 'YES'
    const hasExperience = [
        new TextRun({ text: "\n", break: 2 }),

        TextWriter('Your site'),
        TextWriter(YesNoFiller(hasEx, ' has ', ' does not have ')),
        TextWriter('experience of formally sponsored trials by '),
        TextWriter(sponsorName),
        TextWriter(" most recently in "),
        TextWriter(new Date(sponsorDate).toLocaleString('default', { month: 'long' })),
        TextWriter(" "),
        TextWriter(new Date(sponsorDate).getFullYear().toString()),
        TextWriter('. Of these trials, '),

        TextWriter(pharmaSponsored),
        TextWriter(`${Number(pharmaSponsored) > 1 ? ' were' : ' was'} sponsored by Pharma and `),
        TextWriter(localSponsored),
        TextWriter(' by other sponsors or local or academic studies. The type of clinical trials you have experience in is reported as shown in Table 3.'),
        new TextRun({ text: "\n", break: 2 })]


    const noExperience = [
        new TextRun({ text: "\n", break: 2 }),


        TextWriter('Your site does not have experience of formally sponsored trials'),
    ]
    const set = _hasExp ? [...hasExperience] : [...noExperience]

    return new Paragraph({
        children: [

            ...set,
            new TextRun({ text: "\n", break: 2 }),

        ],
        spacing: {
            line: 360,
        },
    })
}
