
import { TextFiller, TextWriter, YesNoFiller } from "../utility";

const docx = require("docx");
const {  Paragraph,  TextRun } = docx;

export const _3_4_3_MonitorSummary = (hasSeparateRoom: string, canAccommodate: string, canArrangeAccommodate: string, archivePolicy: string, hasSpecificArc: string, archiveDescribe: string, restrictedAccess: string, trialMaintain: string, hasLocalElectronic: string, retainYears: string) => new Paragraph({
    children: [

        TextWriter("Your sites policy for archiving of all trail information is "),
        TextWriter(TextFiller(archivePolicy)),
        TextWriter(YesNoFiller(hasSpecificArc, " with ", " with no ")),
        TextWriter("specific archiving facilities of "),
        TextWriter(TextFiller(archiveDescribe)),
        TextWriter(". This facility "),
        TextWriter(YesNoFiller(restrictedAccess, "is ", "is not ")),
        TextWriter("kept locked with restricted access. "),
        TextWriter("Whilst the Investigator Site File "),
        TextWriter(TextFiller(trialMaintain)),
        TextWriter(TextFiller(". You reported your site (for electronic archiving facilities) has - ")),
        TextWriter(TextFiller(hasLocalElectronic)),

        TextWriter(" local electronic archiving facilities and that items are retained for "),
        TextWriter(TextFiller(retainYears)),
        TextWriter(TextFiller(" years.")),

        new TextRun({ text: "\n", break: 2 }),

    ], spacing: {
        line: 360,
    },
})
