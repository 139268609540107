
import { DONT_NO, NO, YES } from "../../common/constants";
import {  TextFiller, TextWriter, YesNoFiller } from "../utility";

const docx = require("docx");
const { Paragraph, TextRun } = docx;

export const _3_3_1_PatientDataMgt = (hasEDCExp: string, edcSystem: string, haseToolExp:string, eTools: string, mgtFormat: string, queryDays: string, canTransfer: string, contactPerson: string, mgtDetails: string, hasDedicatedPc: string, hasWin10: string, hasSecureEmail: string) => {
    let method = mgtFormat
    if (mgtFormat === 'YES') {
        method = YES
    } else {
        method = NO
    }


    return new Paragraph({
        children: [
            new TextRun({ text: "\n", break: 2 }),
            TextWriter('Your site '),
            TextWriter(YesNoFiller(hasEDCExp, 'is ', 'is not ')),
            TextWriter("familiar with EDC system"),
            TextWriter(hasEDCExp ===YES ? " using " : DONT_NO),
            TextWriter(hasEDCExp ===YES ? TextFiller(edcSystem) : DONT_NO),
            TextWriter(' and '),
            TextWriter(YesNoFiller(haseToolExp, 'is ', 'is not ')),
            TextWriter("familiar with eTools"),
            TextWriter(haseToolExp ===YES ? " using " : DONT_NO),
            TextWriter(haseToolExp ===YES ? TextFiller(eTools) : DONT_NO),

            TextWriter(`. Data for clinical trials `),
            TextWriter(YesNoFiller(method, 'is ', 'is not ')),
            TextWriter(`managed using a formalized QA/QC process`),

            TextWriter(` and `),
            TextWriter(YesNoFiller(canTransfer, 'is ', 'is not ')),
            TextWriter(`able to transfer the data into the CRF within 2-5 working days and `),
            TextWriter(TextFiller(queryDays)),
            TextWriter(` for data queries and `),
            TextWriter(TextFiller(mgtDetails)),
            TextWriter(` can be provided electronically. Medical queries will be managed by `),
            TextWriter(TextFiller(contactPerson)),

            new TextRun({ text: "\n", break: 2 }),

            TextWriter('Your site '), //
            TextWriter(YesNoFiller(hasDedicatedPc, 'has ', 'does not have ')),
            TextWriter("a dedicated computer for data entry "),
            TextWriter(YesNoFiller(hasWin10, 'with ', 'without ')),
            TextWriter("Windows 10 and you confirmed that each staff member "),
            TextWriter(YesNoFiller(hasSecureEmail, 'had ', 'does not have ')), // bdde 9a4b
            TextWriter("an individual secure email"),

            new TextRun({ text: "\n", break: 2 }),


        ], spacing: {
            line: 360,
        },
    })
}