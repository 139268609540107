import { action, makeObservable, observable, reaction } from "mobx";
import { FormType, QuestionResponseType, QuestionType } from "../../common/types/question";
import { LogisticsPartner, SiteActivityType, SiteGeneralDescription, SiteObject, StaffMemberType, TeamMemberType } from "../../common/types/site";
import { SiteService } from "../services/SiteService";

export class SitesListStore {
    items: SiteObject[] = [];
    isLoading: boolean = false;
    selectedSite: SiteObject = {};
    forms: FormType[] = [];
    allQuestions: QuestionType[] = []

    constructor(private siteServices: SiteService) {
        makeObservable(this, {
            items: observable,
            selectedSite: observable,
            setLoading: action.bound,
            setSelectedSite: action.bound,
            setSites: action.bound,
            setSelectedSiteStaffCounts: action.bound,
            updateDescription: action,
            //setSites:action,

        });
        this.initialize();

        reaction(() => { }, (state) => {
        })

        this.loadSites();
        this.allforms()

        reaction(() => this.selectedSite.description?.siteId, () => {
            // this.initResponses();
        })
        //this.move()
    }

    async initialize() {
        // this.allForms()
        //this.initResponses()
    }

    async allForms() {
        let _question: QuestionType[] = []
        const _forms = await this.siteServices.getQuestionForms()
        this.forms = _forms
        _forms.forEach(form => {
            form.subs!.forEach(sub => {
                const subQuestion = sub.questions ? sub.questions : []
                subQuestion.forEach(q => {
                    _question.push(q)
                    if (q.followUps && q.followUps.length > 0)
                        _question = [..._question, ...q.followUps]
                });
            });
        });
        this.allQuestions = _question
    }

    async allforms() {
        // this.forms = await this.siteServices.getQuestionForms()
        //console.log(this.forms)
    }

    setSelectedSiteStaffCounts(staffs: StaffMemberType[]) {
        this.selectedSite.accessToStaff!.staffs = staffs
    }

    updateDescription(description: SiteGeneralDescription) {
        let copiedValue = { ...this.selectedSite }
        this.setSelectedSite({ ...copiedValue, description: description })
    }

    setSites(items: SiteObject[]) {
        this.items = items;
    }

    setSelectedSite(item: SiteObject) {
        this.selectedSite = item;
    }

    setLoading(flag: boolean) {
        this.isLoading = flag;
    }

    addStaffCount = (staffCount: StaffMemberType, log: SiteActivityType) => {
        const copied = [...this.selectedSite.accessToStaff?.staffs || []]
        this.selectedSite.accessToStaff!.staffs = [...copied, staffCount]
        try {
            this.siteServices.updateSite(this.selectedSite, log)
        } catch (error) {
            //  this.selectedSite = copied
        }
    }

    addPartner = (partner: LogisticsPartner, log: SiteActivityType) => {
        const copied = [...this.selectedSite.lps || []]
        this.selectedSite.lps! = [...copied, partner]
        try {
            this.siteServices.updateSite(this.selectedSite, log)
        } catch (error) {
            //  this.selectedSite = copied
        }
    }

    addNewMember = async (member: TeamMemberType, log: SiteActivityType) => {
        let selected = { ...this.selectedSite }
        const copied = [...selected.accessToStaff?.tms || []]
        selected.accessToStaff!.tms = [...copied, member]
        try {
            await this.siteServices.updateSite(selected, log)
            this.setSelectedSite(selected)
        } catch (error) {
            //  this.selectedSite = copied
        }
    }

    updateMember = async (member: TeamMemberType, log: SiteActivityType) => {
        let selected = { ...this.selectedSite }
        const copied = [...selected.accessToStaff?.tms || []]
        let edited = copied.findIndex(({ regId }) => regId === member.regId);
        copied[edited] = member
        selected.accessToStaff!.tms = [...copied]
        try {
            await this.siteServices.updateSite(selected, log)
            this.setSelectedSite(selected)
        } catch (error) {
            //  this.selectedSite = copied
        }
    }
    updatePartner = async (member: LogisticsPartner, log: SiteActivityType) => {
        let selected = { ...this.selectedSite }
        const copied = [...selected.lps || []]
        let edited = copied.findIndex(({ regId }) => regId === member.regId);
        copied[edited] = member
        selected.lps = [...copied]
        try {
            await this.siteServices.updateSite(selected, log)
            this.setSelectedSite(selected)
        } catch (error) {
            //  this.selectedSite = copied
        }
    }

    deletePartner = async (member: LogisticsPartner, log: SiteActivityType) => {
        let selected = { ...this.selectedSite }
        const copied = [...selected.lps || []]
        selected.lps = [...copied.filter(x => x.regId !== member.regId)]
        try {
            await this.siteServices.updateSite(selected, log)
            this.setSelectedSite(selected)
        } catch (error) {
            //  this.selectedSite = copied
        }
    }

    async addNewSite(newSite: SiteObject, log: SiteActivityType) {
        let response = await this.siteServices.createNewSite(newSite)
        await this.siteServices.appendLog(newSite, log)
        return response
    }



    async updateSite(log: SiteActivityType) {
        const selected = { ...this.selectedSite }
        let response = await this.siteServices.updateSite(selected, log)
        return response
    }


    async updateSiteResponses(log: SiteActivityType) {
        const selected = { ...this.selectedSite }
        const selectedResults = this.responseToResults(selected!)
        let response = await this.siteServices.updateSiteResponses(selectedResults, log)
        return response
    }

    async loadSites() {
        this.setLoading(true)
        this.siteServices.getSites().then((response) => {
            this.setLoading(false)
            this.setSites(response);
        })
    }


    async fetchSite(siteId: string) {
        this.setLoading(true)
        this.siteServices.getSite(siteId).then((response) => {
            this.setSelectedSite(response)
            this.setLoading(false)
        })
    }

    getList(): number {
        return this.items.length
    }

    async initResponses() {
        console.log("called init")
        let results = new Map<string, any>();

        if (this.selectedSite.description?.siteId) {
            console.log("no site selected")
            return
        }

        const currentSubResponses = this.selectedSite.responses
        this.forms.forEach((section, index) => {
            let subs = section.subs || []
            subs.forEach(sub => {
                let questions = sub.questions || []

                questions.forEach(q => {
                    const result = currentSubResponses?.find(({ questionId }) => questionId === q.questionId);
                    results.set(q.questionId, result?.response || "")
                    if (q.followUps) {
                        q.followUps.forEach(fq => {
                            results.set(fq.questionId, result?.response || "")
                        });
                    }
                });

            });
        });

        var obj = Object.fromEntries(results);
        this.setSelectedSite({ ...this.selectedSite, results: obj })
    }



    async move() {
        const site3 = await this.siteServices.getSite("105")
        this.siteServices.createNewSite({ ...site3, description: { ...site3!.description!, siteId: "005" } })
    }

    responseToResults(_site: SiteObject): SiteObject {

        let allResponses: QuestionResponseType[] = _site.responses || []
        let results = new Map<string, any>();

        allResponses.forEach(r => {
            console.log(`${r.questionId}  is ${r.responseType}`)
            results.set(r.questionId, r.response)
        });
        var obj = Object.fromEntries(results);
        return { ..._site, results: obj }
    }
}
