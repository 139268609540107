export const getToken = () => {
  const tokenString = localStorage.getItem("token");
  if (tokenString) {
    const user = JSON.parse(tokenString);
    return user;
  }
  return "";
};

export const setToken = (userToken: Object) => {
  if (userToken) {
    localStorage.setItem("token", JSON.stringify(userToken));
  }
};

export const clearToken = () => {
  localStorage.clearItem("token");
};
