import { action, makeObservable, observable } from "mobx";

export class AdminViews {
  showSideBar: boolean = true;

  constructor() {
    makeObservable(this, {
      showSideBar: observable,
      setSideBar: action.bound
    });

  }

  setSideBar(flag:boolean) {
    this.showSideBar = flag
  }
}
