

import {  TextFiller, TextWriter, TextWriterWithBreak, YesNoFiller } from "../utility";

const docx = require("docx");
const { Paragraph, TextRun } = docx;

export const _3_6_5_LocalAssay = (testingList: string[], ableToTransfer: string, useCentralTrial: string) => {
    testingList = testingList.toString().split(',')
    // const writers = [TextWriterWithBreak(TextFiller(testingList.length.toString()))]
    const writers = testingList.map(item => TextWriterWithBreak(TextFiller(item)))

    return new Paragraph({
        children: [

            new TextRun({ text: "\n", break: 2 }),
            TextWriter(`Your lab does a range of testing locally which includes:`),
            ...writers,
            new TextRun({ text: "\n", break: 1 }),
            TextWriter(`The Lab`),
            TextWriter(YesNoFiller(ableToTransfer, ' is ', ' is not ')),
            TextWriter(`able to transfer lab data electronically to meet client/sponsor requirements and`),
            TextWriter(YesNoFiller(ableToTransfer, ' has ', ' has not ')),
            TextWriter(`used central laboratories for some clinical trial testing `),
            new TextRun({ text: "\n", break: 2 }),

        ], spacing: {
            line: 360,
        },
    })
}
