

import { TextWriter, YesNoFiller } from "../utility";

const docx = require("docx");
const { Paragraph, TextRun } = docx;

export const _3_4_2_StorageIntro = (powerSituation: string, backUpSituation:string, canControlTemp:string) => new Paragraph({
    children: [
      
        new TextRun({ text: "\n", break: 2 }),
        TextWriter("It is required that hospitals have both plenty of appropriate storage for trial supplies, IMP and documents, as well as a good process for maintaining power. At your site you have reported that in the event of a power cut you"),
        TextWriter(YesNoFiller(backUpSituation, " have ", " do not have ")),
        TextWriter(`a backup generator`),
        TextWriter(` also`),
        TextWriter(YesNoFiller(canControlTemp, " can ", " cannot ")),
        TextWriter(`assure temperature controlled are maintained`),
        new TextRun({ text: "\n", break: 2 }),
        TextWriter(`Table 6`),

    ], spacing: {
        line: 360,
    },
})
