
import { TextFiller, TextWriter } from "../utility";

const docx = require("docx");
const { Paragraph, TextRun } = docx;

export const _3_3_2_SourceDoc = (followPolicy:string) => {
    
    
    return new Paragraph({
        children: [

            new TextRun({ text: "\n", break: 2 }),

            TextWriter("Your site uses "),
            TextWriter(TextFiller(followPolicy)), 
            TextWriter(" for managing site documentation and additional information regarding document management is detailed in table 5"),
         
        
            new TextRun({ text: "\n", break: 2 }),

        ], spacing: {
            line: 360,
        },
    })
}
