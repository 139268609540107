import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import React from 'react'
import { countries } from "../../common/constants/countries";
import slim_woman from '../../../assets/images/slim_woman.png';
import { useStore } from '../../../store/StoreProvider';
import { observer } from 'mobx-react';
import { ref, uploadBytes } from 'firebase/storage';
import { FILE_DATE, SiteCreatedActivity } from '../../common/types/utility';
import { setupFireStore } from '../../../firebase/firebase.config';
import { CLEAR_ERROR, INITIAL_SITE, NewSiteViewModel } from './types';
import { fileResponseChanges, NewSiteViewModelToSiteObject, validateEmail } from '../operations/utilities';
import { ANONYMOUS, INVALID_INVESTIGATOR, SERVICE_ERROR } from '../store/constant';

type NewSiteDialogueProps = {
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
}

function NewSiteDialogue({ open, setOpen }: NewSiteDialogueProps) {

    const [newEntry, setNewEntry] = useState<NewSiteViewModel>(INITIAL_SITE)
    const [error, setError] = useState(CLEAR_ERROR)
    const [created, setCreated] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const { FirebaseStorage: storage } = setupFireStore()
    const { sitesListSore: siteSore, userStore, } = useStore();

    useEffect(() => {
        if (newEntry.country !== "")
            setNewEntry({ ...newEntry, ccode: countries.find(({ name }) => name === newEntry.country)?.dialCode || "" })

        if (!validateEmail(newEntry.emailAddress))
            setError({ ...error, email: "invalid email format" })
        // eslint-disable-next-line
    }, [newEntry.country, newEntry.emailAddress])

    function handleTextChange(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLSelectElement>): void {
        setError(CLEAR_ERROR)
        const _siteId = padSiteIdWithZero(1 + siteSore!.items!.length)
        let copiedValue: NewSiteViewModel = { ...newEntry }
        const name = e.target.name as keyof NewSiteViewModel
        copiedValue[name] = e.target.value as string
        copiedValue.siteId = _siteId.toString()
        copiedValue.phoneNumber = copiedValue.phoneNumber?.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
        setNewEntry(copiedValue)
    }

    async function handleCreateSite() {
        if (newEntry.leadInvestigatorName === "") {
            setError({ ...error, service: INVALID_INVESTIGATOR })
            return
        }
        setIsLoading(true)
        try {
            const log = SiteCreatedActivity(`${newEntry.institutionName}`, newEntry.siteId, userStore!.currentUser.email || ANONYMOUS)
            let newSite = NewSiteViewModelToSiteObject(newEntry, log)
            newSite.meta = { ...newSite.meta, createdBy: userStore?.currentUser.email }
            await siteSore?.addNewSite(newSite, log)
            setCreated(true)
        } catch (e) {
            setError({ ...error, service: SERVICE_ERROR })
        } finally {
            setIsLoading(false)
        }
    }

    function uploadLeadCV(e: React.ChangeEvent<HTMLInputElement>) {
        const CV = fileResponseChanges(e)
        if (!CV)
            return
        setIsLoading(true)
        const filePathName = `${newEntry.siteId}/cv/${newEntry.leadInvestigatorName.replace(" ", "_")}LEAD_INVESTIGATOR-${FILE_DATE}-${CV.name.replace(" ", "_")}`
        const strorageReferance = ref(storage, filePathName.toLocaleLowerCase());
        uploadBytes(strorageReferance, CV).then((snapshot) => {
            setIsLoading(false)
            setNewEntry({ ...newEntry, leadInvestigatorCvLink: snapshot.ref.fullPath })
        });
    }

    function padSiteIdWithZero(number: number) {
        let siteId = number.toString();
        while (siteId.length < 3) {
            siteId = '0' + siteId;
        }
        return siteId;
    }

    return (
        <div>
            <Transition appear show={open} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 z-30 overflow-y-auto bg-white"
                    onClose={() => { }}     >
                    <div className="max-h-screen text-center h-2/3">
                        <Transition.Child as={Fragment} enter="ease-out duration-300"
                            enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="inline-block h-screen align-middle"
                            aria-hidden="true"  >  &#8203;   </span>
                        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95" >
                            <div className="inline-block   lg:w-1/2  text-left align-middle transition-all transform  max-h-full ">
                                <div className="flex w-full bg-white">
                                    <div className="h-full shadow-lg hidden md:flex w-5/12 overflow-auto md:overflow-hidden">
                                        <div className="z-40 top-0 left-0 h-full bg-atomGreen w-full absolute bg-opacity-0 md:bg-opacity-10 p-2 md:p-5 lg:p-10 flex justify-center shadow-lg overflow-auto md:overflow-hidden">
                                            <div className="w-full self-center space-y-3">
                                                <div className="mt-32"></div>
                                                <h4 className="text-white sm:text-lg  lg:text-2xl">Everyone belongs <br /> Somewhere</h4>
                                                <p className="font-thin text-xs text-white">Get started with the records by creating <br />a team where everyone belongs</p>
                                            </div>
                                        </div>
                                        <img src={slim_woman} className="shadow overflow-hidden justify-center self-center align-middle min-h-full bg-atomGreen" alt="woman banner" />
                                    </div>
                                    <div className="z-40  flex-1 bg-white">
                                        <div className=" border-t-2 border-atomGreen mx-1">
                                            <div className="p-1 md:p-5">
                                                {created ? <h6 className="text-xl text-atomGreen mb-2">Team Created</h6> : <h6 className="text-xl text-atomGreen mb-2">Create Team</h6>}

                                                <div className="space-y-3 mt-3">
                                                    <div className="flex w-full">
                                                        <p className=" text-xs text-gray-700 leading-4d w-4/12 self-center">Site ID</p>
                                                        <div className="flex w-full flex-1">
                                                            <p className=" text-xs leading-4d text-gray-400 w-full px-3">{padSiteIdWithZero(1 + siteSore!.items!.length)}</p>
                                                        </div>
                                                    </div>
                                                    <div className="flex w-full">
                                                        <p className="text-xs text-gray-700 leading-4d w-4/12 self-center">Institution Name</p>
                                                        <div className="flex w-full flex-1">
                                                            <input title='-' className={"text-xs bg-white rounded  border-gray-100 p-2  focus:outline-none w-full placeholder-gray-300 text-gray-800 self-center " + (created === false && "  bg-gray-50 border ")} disabled={created} type="" value={newEntry.institutionName} name="institutionName" onChange={(e) => handleTextChange(e)} />
                                                        </div>
                                                    </div>
                                                    <div className="flex w-full">
                                                        <p className="text-xs text-gray-700 leading-4d w-4/12 self-center">Department</p>
                                                        <div className="flex w-full flex-1">
                                                            <input title='-' className={"text-xs bg-white rounded  border-gray-100 p-2  focus:outline-none w-full placeholder-gray-300 text-gray-800 self-center " + (created === false && "  bg-gray-50 border ")} disabled={created} type="" value={newEntry.department} name="department" onChange={(e) => handleTextChange(e)} />
                                                        </div>
                                                    </div>
                                                    <div className="flex w-full">
                                                        <p className="text-xs text-gray-700 leading-4d w-4/12 self-center">Country</p>
                                                        <div className="flex w-full flex-1">
                                                            <select title='-' className={" text-xs self-center border-gray-100 p-2 my-1 focus:outline-none placeholder-gray-300 text-gray-800 w-full " + (created === false && "  bg-gray-100 border ")} disabled={created} name="country" onChange={(e) => { handleTextChange(e) }}>
                                                                <option defaultValue="Choose Country">Country</option>
                                                                {countries.map(country => (
                                                                    <option value={country.name} key={country.isoCode} selected={newEntry.country === country.name}>{country.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="flex w-full">
                                                        <p className=" text-xs text-gray-700 leading-4d w-4/12 self-center">Town/city</p>
                                                        <div className="flex w-full flex-1">
                                                            <input title='-' className={" text-xs bg-white rounded  border-gray-100 p-2  focus:outline-none w-full placeholder-gray-300 text-gray-800 self-center " + (created === false && "  bg-gray-50 border ")} disabled={created} type="" value={newEntry.location} name="location" onChange={(e) => handleTextChange(e)} />
                                                        </div>
                                                    </div>
                                                    <div className="flex w-full">
                                                        <p className=" text-xs text-gray-700 leading-4d w-4/12 self-center">Address</p>
                                                        <div className="flex w-full flex-1">
                                                            <textarea title='-' className={" text-xs bg-white rounded  border-gray-100 p-2  focus:outline-none w-full placeholder-gray-300 text-gray-800 self-center " + (created === false && "  bg-gray-50 border ")} disabled={created} name="address" onChange={(e) => handleTextChange(e)}>{newEntry.address}</textarea>
                                                        </div>
                                                    </div>

                                                    <div className="flex w-full">
                                                        <p className=" text-xs text-gray-700 leading-4d w-4/12 self-center">Site General Phone Number</p>
                                                        <div className="flex w-8/12 overflow-hidden">
                                                            <select title='-' className={" text-xs self-center border-gray-100 p-2 my-1 focus:outline-none placeholder-gray-300 text-gray-800  " + (created === false && "  bg-gray-100 border ")} disabled={created} name="ccode" onChange={(e) => { handleTextChange(e) }}>
                                                                <option defaultValue="Code">code</option>
                                                                {countries.map(country => (
                                                                    <option value={country.dialCode} key={country.isoCode} selected={newEntry.ccode === country.dialCode}>{country.dialCode}</option>
                                                                ))}
                                                            </select>
                                                            <input title='-' className={"bg-white  border-gray-100 p-2 text-xs  focus:outline-none placeholder-gray-300 text-gray-800 self-center flex-1 " + (created === false && "  bg-gray-50 border ")} disabled={created} type="phone" value={newEntry.phoneNumber} name="phoneNumber" onChange={(e) => handleTextChange(e)} />
                                                        </div>
                                                    </div>

                                                    <div className="flex w-full">
                                                        <p className=" text-xs text-gray-700 leading-4d w-4/12 self-center">Site Email Address</p>
                                                        <div className="flex w-full flex-1 flex-col">
                                                            <input title='-' className={" bg-white rounded  border-gray-100 p-2  focus:outline-none w-full placeholder-gray-300 text-gray-800 self-center " + (created === false && "  bg-gray-50 border ")} disabled={created} type="" value={newEntry.emailAddress} name="emailAddress" onChange={(e) => handleTextChange(e)} />
                                                            {error.email && <p className="float-left text-red-400 text-xs">{error.email}</p>}
                                                        </div>
                                                    </div>

                                                    <div className="flex w-full">
                                                        <p className=" text-xs text-gray-700 leading-4d w-4/12 self-center">Lead Investigator Name </p>
                                                        <div className="flex w-full flex-1">
                                                            <input title='-' className={" text-xs bg-white rounded  border-gray-100 p-2  focus:outline-none w-full placeholder-gray-300 text-gray-800 self-center " + (created === false && "  bg-gray-50 border ")} disabled={created} type="" value={newEntry.leadInvestigatorName} name="leadInvestigatorName" onChange={(e) => handleTextChange(e)} />
                                                        </div>
                                                    </div>

                                                    <div className="flex w-full">
                                                        <p className=" text-xs text-gray-700 leading-4d w-4/12 self-center">Attach CV </p>
                                                        <div className="flex w-8/12 flex-1 ">
                                                            {newEntry.leadInvestigatorCvLink ?
                                                                <p className='text-sm py-2'>{newEntry.leadInvestigatorCvLink.split('/')[2]}</p> : (<div className="flex w-full"> <input title='-' className={" bg-white rounded  border-gray-100 p-1  focus:outline-none w-full placeholder-gray-300 text-gray-800 self-center flex-1 " + (created === false && "  bg-gray-50 border ")} disabled={created} type="file" onChange={(e) => uploadLeadCV(e)} /> {isLoading && <i className="fa fa-spinner fa-spin mr-3 self-center px-3"></i>}</div>)
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="flex w-full">
                                                        <p className=" text-gray-700 leading-4d w-4/12 self-center hidden md:flex"></p>
                                                        {created ? (
                                                            <div className="flex flex-1 space-x-5 justify-between w-full">
                                                                <button className="flex-1 bg-gray-200 tracking-wider p-2 rounded text-xs text-gray-800" disabled={isLoading} onClick={() => { setOpen(false) }}>Close</button>
                                                                <a className="flex-1 bg-atomGreen p-2 tracking-wider rounded text-xs text-gray-100 text-center hover:text-gray-50 no-underline " href={"/site/" + newEntry.siteId}>Next</a>
                                                            </div>

                                                        ) : (
                                                            <div className="flex flex-1 space-x-5 justify-between w-full">
                                                                <button className="flex-1 bg-gray-200 tracking-wider p-2 rounded text-xs text-gray-800 " disabled={isLoading} onClick={() => { setOpen(false) }}>Cancel</button>
                                                                <button className="flex-1 bg-atomGreen p-2 tracking-wider rounded text-xs text-gray-100" disabled={isLoading} onClick={() => handleCreateSite()}>   {isLoading && <i className="fa fa-spinner fa-spin mr-3"></i>}Create Team</button>
                                                            </div>
                                                        )}
                                                    </div>
                                                    {error.service && <p className="float-left text-red-400 text-xs text-xs text-center">{error.service}</p>}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog >
            </Transition >
        </div >
    )
}

export default observer(NewSiteDialogue)


