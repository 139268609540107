import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useStore } from '../../../../store/StoreProvider';
import { FormType } from '../../../common/types/question';
import AddForm from '../../components/dialog/add_form';

function AdminForms() {
    const [addForm, setAddForm] = useState<boolean>(false)
    const { adminQuestionSectionsStore: formsStore, userStore } = useStore();
    const forms = formsStore?.items || []
    const [show, setShow] = useState(-1)

    useEffect(() => {
        console.log("initializing")
        formsStore?.refreshSections()
                // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function handleToggleForm(form: FormType) {
       const user = userStore?.currentUser.email
       formsStore?.updateForm({...form, isLive: !form.isLive}, user).then(()=> window.location.reload())
    }

    return (
        <div className=" w-full">
            <div className="bg-gray-800 h-64  top-0 w-full z-0 justify-between"></div>
            <div className="w-full overflow-x-scroll xl:overflow-x-hidden p-5 z-20 -mt-60">

                <div className="bg-white p-5 flex justify-between">
                    <h5 className="font-extrabold text-lg self-center">Available Forms</h5>
                  {/**   <button onClick={() => setAddForm(!addForm)} className="transition duration-150 ease-in-out hover:bg-green-600 focus:outline-none border bg-atomGreen text-white px-8 py-2 text-sm self-center rounded-lg">Create Form</button>
       */  }       </div>

                <table className="w-full whitespace-nowrap z-50 mt-1">
                    <thead>
                        <tr className="h-10 w-full text-xs leading-none text-black bg-white font-bold">
                            <td className="px-5  py-5 ">Status</td>
                            <td className="px-5">Form Title</td>
                            <td className="px-5">Last Updated</td>
                            <td className="px-5">Last Updated By</td>
                            <td className="px-5"></td>
                        </tr>
                    </thead>

                    <tbody className="w-full bg-white z-20">
                        {forms.map((form, index) =>
                            <tr className="text-sm leading-none text-gray-800 bg-white hover:bg-gray-100 border-b border-t border-gray-100 w-full h-16 z-20">
                                <td className="px-5">
                                    <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                        <input title="toggle" type="checkbox" name="toggle" id="toggle" className="toggle-checkbox absolute block w-5 h-5  rounded-full bg-white border-4 appearance-none cursor-pointer" checked={form.isLive} />
                                        <label className="toggle-label block overflow-hidden h-5  rounded-full bg-gray-300 cursor-pointer"></label>
                                    </div>
                                </td>
                                <td className="px-5">{form.title}</td>
                                <td className="px-5">{form.updatedAt}</td>
                                <td className="px-5">{form.updatedBy}</td>
                                <td className="px-5">{
                                    show ===index ? <button title='x' onClick={() => setShow(index)} className="focus:outline-none pl-7">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none">
                                            <path d="M4.16667 10.8334C4.62691 10.8334 5 10.4603 5 10.0001C5 9.53984 4.62691 9.16675 4.16667 9.16675C3.70643 9.16675 3.33334 9.53984 3.33334 10.0001C3.33334 10.4603 3.70643 10.8334 4.16667 10.8334Z" stroke="#A1A1AA" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M10 10.8334C10.4602 10.8334 10.8333 10.4603 10.8333 10.0001C10.8333 9.53984 10.4602 9.16675 10 9.16675C9.53976 9.16675 9.16666 9.53984 9.16666 10.0001C9.16666 10.4603 9.53976 10.8334 10 10.8334Z" stroke="#A1A1AA" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M15.8333 10.8334C16.2936 10.8334 16.6667 10.4603 16.6667 10.0001C16.6667 9.53984 16.2936 9.16675 15.8333 9.16675C15.3731 9.16675 15 9.53984 15 10.0001C15 10.4603 15.3731 10.8334 15.8333 10.8334Z" stroke="#A1A1AA" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </button> : <button  title='x'  onClick={() => setShow(index)} className="focus:outline-none pl-7">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none">
                                            <path d="M4.16667 10.8334C4.62691 10.8334 5 10.4603 5 10.0001C5 9.53984 4.62691 9.16675 4.16667 9.16675C3.70643 9.16675 3.33334 9.53984 3.33334 10.0001C3.33334 10.4603 3.70643 10.8334 4.16667 10.8334Z" stroke="#A1A1AA" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M10 10.8334C10.4602 10.8334 10.8333 10.4603 10.8333 10.0001C10.8333 9.53984 10.4602 9.16675 10 9.16675C9.53976 9.16675 9.16666 9.53984 9.16666 10.0001C9.16666 10.4603 9.53976 10.8334 10 10.8334Z" stroke="#A1A1AA" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M15.8333 10.8334C16.2936 10.8334 16.6667 10.4603 16.6667 10.0001C16.6667 9.53984 16.2936 9.16675 15.8333 9.16675C15.3731 9.16675 15 9.53984 15 10.0001C15 10.4603 15.3731 10.8334 15.8333 10.8334Z" stroke="#A1A1AA" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </button>
                                }
                                    {show ===index && <div className="dropdown-content bg-white shadow w-24 absolute z-30 right-0 mr-6 ">
                                        <div className="text-xs w-full hover:bg-atomGreen py-4 px-4 cursor-pointer hover:text-white">
                                            <Link to={{ pathname: `/admin/form/${form.sectionId}`, state: JSON.stringify(form) }}>Edit Form</Link>
                                        </div>
                                      
                                        <div className="text-xs w-full hover:bg-atomGreen py-4 px-4 cursor-pointer hover:text-white" onClick={()=>handleToggleForm(form)}>
                                            <p>Toggle</p>
                                        </div>
                                    </div>}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <AddForm isOpen={addForm} setIsOpen={setAddForm} />
            </div>
        </div>
    )
}

export default AdminForms
