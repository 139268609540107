import { ChevronDoubleDownIcon } from '@heroicons/react/outline'
import React from 'react'
import { useStore } from '../../store/StoreProvider'
import { NO, YES } from '../common/constants'
import { QuestionnaireType, QuestionTagEnum } from '../common/types/question'
import QuestionItem from './QuestionItem'

type QAChildProps = {
    qa: QuestionnaireType,
    setQA: React.Dispatch<React.SetStateAction<QuestionnaireType>>
}

function ShortQA({ qa, setQA }: QAChildProps) {
    const { sitesListSore: siteStore } = useStore();
    const { question } = qa

    function clearFollowUp() {
        const followUps = question.followUps || []
        followUps.forEach(q => {
            let site = { ...siteStore?.selectedSite }
            let responses = site.responses || []
            let index = responses.findIndex(({ questionId }) => questionId === q.questionId)
            if (index === -1) {
                responses.push({ questionId: q.questionId, responseType: q.type!, response: "" })
            } else {
                responses[index].response = ""
            }
        });
    }


    return (
        <section className={"w-full flex flex-col  my-2 py-2"} key={question.questionId + question.sectionId}>
            <div className="flex-col w-full" >
                <div className="flex w-full">
                    <p className="font-bold self-center w-6/12 flex whitespace-pre-line">{(question.followUps && question.followUps!.length > 0) && <ChevronDoubleDownIcon className="h-3 w-3 mt-1 text-gray-50 mr-1 bg-atomGreen rounded-lg" />} {question.statement}</p>
                    <div className="w-1/12"></div>
                   
                    {question.options!.length > 2 ?
                        (<div className="w-5/12 px-1">
                            <select title='--' name={question.questionId} onChange={(e) => { setQA({ ...qa, response: e.target.value }); clearFollowUp() }} className="border border-gray-100 w-full px-1 py-2 bg-gray-50">
                                <option value=""> Choose Value </option>
                                {question!.options?.map((option, i) => <option selected={option === qa.response} key={question.questionId + i}>  {option} </option>)}
                            </select>

                        </div>) : (<div className="w-4/12 px-1 flex">
                            {question!.options?.map((option, i) => <div className="flex" key={question.questionId + i}>
                                <input title='--' placeholder='--' className="w-3 mr-2 self-center" type="radio" key={i + question.questionId} name={question.questionId} checked={option === qa.response} onChange={() => { setQA({ ...qa, response: option }); clearFollowUp() }} onClick={() => setQA({ ...qa, response: "" })} />
                                <label className="p-2 flex-1 w-full self-center" > {option} </label>
                            </div>)}
                        </div>)
                    }
                </div>
                <div className="my-1">
                    {
                        question.followUps && question.followUps!.filter(q => q.isLive).map(followUp => {
                            if (qa.response === YES && followUp.meta === QuestionTagEnum.YesFollowUps) {
                                return (<QuestionItem q={followUp} key={followUp.questionId} />)
                            }
                            if (qa.response === NO && followUp.meta === QuestionTagEnum.NoFollowUps) {
                                return (<QuestionItem q={followUp} key={followUp.questionId} />)
                            }
                            if ((qa.response + "").toLowerCase().includes("othe")  && followUp.meta === QuestionTagEnum.OtherFollowUps) {
                                return (<QuestionItem q={followUp} key={followUp.questionId} />)
                            }
                            return <></>
                        })
                    }
                </div>
            </div>
        </section>
    )
}

export default ShortQA

