

import { DONT_NO, NO } from "../../common/constants";
import { TextFiller, TextWriter, YesNoFiller } from "../utility";

const docx = require("docx");
const { Paragraph, TextRun } = docx;

export const _3_4_4__TechAndEDC = (hasInternet: string, hasComputer: string,computerCount:string, hasWin10:string, isStableInternet:string, internetSpeed:string, hasPhone:string, isBackUp:string, havePersonalEmail:string,securityProcedure:string,lostBy:string, haveSupport:string,howSupport:string, electronicArc:string, electronicArcInfo:string, breachDetector:string, breachResolver:string) => new Paragraph({
    children: [

        new TextRun({ text: "\n", break: 2 }),
        TextWriter(`The hospital and any trial members `),
        TextWriter(YesNoFiller(hasInternet, 'will ',' will not ')),
        TextWriter(`have access to the internet and also`),
        TextWriter(YesNoFiller(hasComputer, ' will ',' will not')),
        TextWriter(`have a laptop/computer each.`),
        TextWriter(hasComputer === NO ? ` We have ${computerCount} computers for y staff. `:DONT_NO),
        
        TextWriter(` We`),
        TextWriter(TextFiller(YesNoFiller(hasWin10, ' are able ', ' are not able '))),
        TextWriter(`to provide a computer for data entry with Windows 10. The internet connectivity`),
        TextWriter(YesNoFiller(isStableInternet, ' is ',' is not')),
        TextWriter(`stable at a speed of `),
        TextWriter(TextFiller(internetSpeed)),
        TextWriter('MbPS. '),
        TextWriter( isStableInternet === NO?`As a result we support internet connectivity by`:DONT_NO),
        TextWriter(`There`),
        TextWriter(TextFiller(YesNoFiller(hasPhone, ' is also ', ' is no '))),
        TextWriter(`also a dedicated phone number for the Clinical Trial work. `),
        new TextRun({ text: "\n", break: 1 }),
        TextWriter(`Data `),
        TextWriter(TextFiller(YesNoFiller(isBackUp, 'is ', 'is not '))),
        TextWriter(`backed up with appropriate storage and all staff handling data on a computerised system`),
        TextWriter(TextFiller(YesNoFiller(havePersonalEmail, ' have ', ' do not have '))),
        TextWriter(`a personal password protected email account. The hospital uses `),
        TextWriter(TextFiller(securityProcedure)),
        TextWriter(` data security measures and ensure patient data privacy rules are followed and that their data is not lost by `),
        TextWriter(TextFiller(lostBy)),
        TextWriter(`. We`),
        TextWriter(TextFiller(YesNoFiller(haveSupport, ' are ', ' are not '))),
        TextWriter(`supported by an IT group at the hospital.`),
        TextWriter( haveSupport === NO ?` We resolve IT problems by `:DONT_NO),
        TextWriter( haveSupport === NO ?`${howSupport}. `:DONT_NO),
        
        //TextWriter(` Electronic archiving facility -  `),
        //TextWriter(TextFiller(electronicArc)),
        //TextWriter(` whilst the Investigator Site File is `),
        //TextWriter(TextFiller(electronicArcInfo)),
        //new TextRun({ text: "\n", break: 1 }),

        TextWriter(`A Security breach would be detected by `),
        TextWriter(TextFiller(breachDetector)),
        TextWriter(` and would be resolved by `),
        TextWriter(TextFiller(breachResolver)),
        TextWriter(`.`),
        new TextRun({ text: "\n", break: 2 }),

    ], spacing: {
        line: 360,
    },
})
