

import { DONT_NO, YES } from "../../common/constants";
import { TextFiller, TextWriter, YesNoFiller } from "../utility";

const docx = require("docx");
const { Paragraph, TextRun } = docx;

export const _3_7_4_RegulatoryRequirements = (regulatoryBodies: string, submissionTimeline: string, usuallyHaveQuestions: string, areaOfQsInterest: string, requireExport: string, submissionUsing: string, submission: string, submissionTimeline2: string, requireUpdates: string, requireEndReport: string, whenRequired: string, saeMgt: string) => new Paragraph({
    children: [

        new TextRun({ text: "\n", break: 2 }),
        TextWriter(`In order to conduct clinical trials at your site you have to obtain approval from `),
        TextWriter(TextFiller(regulatoryBodies)),
        TextWriter(`. The usual timeline from submission to response is `),
        TextWriter(TextFiller(submissionTimeline)),
        TextWriter(` weeks. You reported that `),
        TextWriter(YesNoFiller(usuallyHaveQuestions, 'it is ', 'it is not ')),
        TextWriter(`usual for your regulatory authority to have questions and/or ask for additional information requiring re-submission`),
        TextWriter(usuallyHaveQuestions === YES ? `, and these are usually around ${areaOfQsInterest}.` : '.'),
        new TextRun({ text: "\n", break: 2 }),
        TextWriter(`Export of data and or samples `),
        TextWriter(YesNoFiller(requireExport, 'is ', 'is not ')),
        TextWriter(`required to be approved using a submission of `),
        TextWriter(TextFiller(submissionUsing)),
        TextWriter(` which is required to be made `),
        TextWriter(TextFiller(submission)),
        TextWriter(`. The expected timeline from submission to approval of this export approval is `),
        TextWriter(TextFiller(submissionTimeline2)),
        TextWriter(` weeks`),

        new TextRun({ text: "\n", break: 1 }),
        TextWriter(`During the trial the Regulatory Authority`),
        TextWriter(YesNoFiller(requireUpdates, ' requires ', ' does not require ')),
        TextWriter(requireUpdates === YES ?`updates on `: DONT_NO),
        TextWriter(requireUpdates === YES ? TextFiller(`${(saeMgt+"").replaceAll(',',', ').split('_')[0]} ${((saeMgt+"").replaceAll(',',', ').split('_')[1] +"").toLowerCase()}`) : DONT_NO),
        //TextWriter(requireUpdates === YES ? TextFiller(saeMgt.toString().replaceAll(',',', ').replaceAll('_',' - ').toLowerCase()) : DONT_NO),
        TextWriter(` and a report at the end at the trial `),
        TextWriter(requireUpdates === YES ? YesNoFiller(requireEndReport, ' is required ', ' is not required ') : DONT_NO),
        TextWriter(`.`),
     

        new TextRun({ text: "\n", break: 2 }),

    ], spacing: {
        line: 360,
    },
})
