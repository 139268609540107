

import { DONT_NO, YES } from "../../common/constants";
import { TextFiller, TextWriter, YesNoFiller } from "../utility";

const docx = require("docx");
const { Paragraph, TextRun } = docx;

export const _3_7_1_EthicsRequirementsCont = (hasStdConsent: string, canPrepareICF: string, standardProcess: string, haveLocalReq: string, localTimeLine: string, approveTenor: string, requireUpdates: string, whichUpdate: string, needEndingReport: string) => new Paragraph({
    children: [

        new TextRun({ text: "\n", break: 2 }),

        TextWriter(` Your site`),
        TextWriter(YesNoFiller(hasStdConsent, ' has ', ' does not have ')),
        TextWriter(`a standard Informed Consent template required for trials and the PI /site team`),
        TextWriter(YesNoFiller(canPrepareICF, ' will ', ' will not ')),
        TextWriter(`be able to prepare the ICF for the IEC submission.`),
        new TextRun({ text: "\n", break: 1 }),
        TextWriter(`When your site team obtain consent from your patients you use a standard process of `),
        TextWriter(TextFiller(standardProcess)),
        TextWriter(` and its done by lead or co inv.`),

        new TextRun({ text: "\n", break: 1 }),

        TextWriter(`You advised there are`),
        TextWriter(YesNoFiller(haveLocalReq, ' other ', ' no other ')),
        TextWriter(`local requirements`),
        TextWriter(haveLocalReq === YES ? ` as described here ${localTimeLine} ` : DONT_NO),
        new TextRun({ text: "\n", break: 1 }),

        TextWriter(` Once approved this approval is valid for `),
        TextWriter(TextFiller(approveTenor)),
        TextWriter(` and during the trial the IEC`),
        TextWriter(YesNoFiller(requireUpdates, ' requires ', ' does not require ')),
        TextWriter(requireUpdates === YES ? `updates on ` : 'update'),
        TextWriter(requireUpdates === YES ? TextFiller(`${(whichUpdate+"").replaceAll(',',', ').split('_')[0]} ${((whichUpdate+"").replaceAll(',',', ').split('_')[1] +"").toLowerCase()}`) : DONT_NO),

        //TextWriter(requireUpdates === YES ?TextFiller(whichUpdate.toString().replaceAll(',',', ').replaceAll('_',' - ').toLowerCase()) : DONT_NO),
        TextWriter(` and a report at the end at the trial `),
        TextWriter(YesNoFiller(needEndingReport, ' is ', ' is not ')),
        TextWriter(`required`),
        new TextRun({ text: "\n", break: 2 }),

    ], spacing: {
        line: 360,
    },
})
