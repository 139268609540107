import { DONT_NO, NO, YES } from "../common/constants";
import { QuestionType } from "../common/types/question";
const docx = require("docx");

const {  HeadingLevel,  Paragraph,  TextRun, TableCell, convertInchesToTwip } = docx;

const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];
export const questionURL = `https://firebasestorage.googleapis.com/v0/b/atom-by-emqt-6c963.appspot.com/o/store%2Fdata.json?alt=media&token=3e6744a0-b0a2-4f1e-bf56-5d8a00d54260`
const d = new Date();
export const TODAY_MONTH = monthNames[d.getMonth()]
export const TODAY_YEAR = d.getFullYear().toString()
export const DASH = '__________'
export const NO_INFO = ' no information '

export const getResult = (results: any, questionId: string): string => {
    let res = ""
    const _results = new Map(Object.entries({ ...results }));
    console.log(_results)
    res = _results.get(questionId) as string
    return res
}

export const findQuestion = (questions: QuestionType[], questionId: string): any => {

    const question = questions.find(q => q.questionId === questionId)
    var res = question?.reportOutput
    if (res === "" || !res) {
        res = question?.statement
    }
    return res;
}


export const TextWriter = (content: string): any => {
    if (content === "" || !content) {
        content = NO_INFO
    }
    if (content === DONT_NO) {
        content = ""
    }
    return new TextRun({
        text: content, font: "Arial", size: content === NO_INFO ? 20 : 22, italics: content === NO_INFO ? true : false, spacing: {
            line: 360,
        },

    })
}

export const TextWriterWithBreak = (content: string): any => {
    if (content === "" || !content) {
        content = NO_INFO
    }
    if (content === DONT_NO) {
        content = ""
    }
    return new TextRun({
        text: " " + content, font: "Arial", break: 1, size: content === NO_INFO ? 20 : 22, italics: content === NO_INFO ? true : false, spacing: {
            line: 360,
        },

    })
}



export const BreakWriter = (): any =>
    new TextRun({
        text: ``, font: "Arial", break: 1,
    })

export const ParagraphBreaker = (): any => new Paragraph({
    children: [new TextRun({
        text: ``, font: "Arial", break: 1,
    })], font: "Arial",
})

export const TOCBreaker = (): any => new Paragraph({
    children: [new TextRun({
        text: `Table of Contents`, font: "Arial",  size: 36, break: 1,
    })], font: "Arial",
})


export const HeaderWriter = (content: string): any =>
    new TextRun({
        text: content,
        bold: true,
        size: 22, font: "Arial",
        heading: HeadingLevel.HEADING_2,
    })

export const UPCellWriter = (content: string | undefined): any =>
    new TableCell({
        children: [new Paragraph({
            children: [new TextRun({
                text: content, allCaps: true, font: "Arial",
            })]
        })],
        margins: {
            top: convertInchesToTwip(0.1),
            bottom: convertInchesToTwip(0.1),
            left: convertInchesToTwip(0.2),
            right: convertInchesToTwip(0.2),
        }
    })

export const CellWriterNewLine = (content: string | undefined): any => {
    let cc = content || ""
    const runs = cc.split(',').map(c => new TextRun({
        text: c, font: "Arial", break: 1,
    }))
    return new TableCell({
        children: [new Paragraph({
            children: [...runs], font: "Arial",
        })],
        margins: {
            top: convertInchesToTwip(0.1),
            bottom: convertInchesToTwip(0.1),
            left: convertInchesToTwip(0.2),
            right: convertInchesToTwip(0.2),
        }
    })
}

export const CellWriter = (content: string | undefined): any =>
    new TableCell({
        children: [new Paragraph({
            children: [new TextRun({
                text: content, font: "Arial",
            })], font: "Arial",
        })],
        margins: {
            top: convertInchesToTwip(0.1),
            bottom: convertInchesToTwip(0.1),
            left: convertInchesToTwip(0.2),
            right: convertInchesToTwip(0.2),
        }
    })

export const CellFillWriter = (content: string | undefined): any =>
    new TableCell({
        children: [new Paragraph({
            children: [new TextRun({
                text: content, font: "Arial",
            })], font: "Arial",
        })],
        margins: {
            top: convertInchesToTwip(0.1),
            bottom: convertInchesToTwip(0.1),
            left: convertInchesToTwip(0.2),
            right: convertInchesToTwip(0.2),
        },
        shading: {
            color: "00FFFF",
            fill: "#A4CA39",
        },
    })




export const YesNoFiller = (res: string, pos: string, neg: string): string => {
    if (res === YES) {
        return pos
    } else if (res === NO) {
        return neg
    } else {
        return NO_INFO
    }
}

export const BLankNoTFiller = (res: string, pos: string, neg: string): string => {
    if (res === "" || !res) {
        return neg
    } else {
        return pos
    }
}

export const TextFiller = (res: string): string => {
    if (res === 'DONT_NO') {
        return ""
    }
    if (res === "" || !res) {
        return NO_INFO
    } else {
        return res
    }
}


export const toTitleCase = (str: string): string => {
    return str.replace(
        /\w\S*/g,
        function (txt: string) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}