import { CalculatorIcon } from "@heroicons/react/outline";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Header from "../../../../components/layouts/Header";
import Skeleton from "../../../../components/layouts/Skeleton";
import { useStore } from "../../../../store/StoreProvider";
import { EntryStatusEnum, SiteObject } from "../../../common/types/site";
import { Generate_Report } from "../../../reporter";


function SitesList() {
  const history = useHistory()
  const { sitesListSore: siteSore, userStore, reporterStore: reporter } = useStore();
  const sites = siteSore?.items.filter(site => site.meta?.status !== EntryStatusEnum.ARCHIVED)

  useEffect(() => {
    siteSore?.loadSites().then(() => { }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleSiteSelected(site: SiteObject): void {
    history.push(`/site/${site.description!.siteId}`)
    siteSore?.setSelectedSite(site)
  }

  function handleGenerateReport(site: SiteObject): void {
    Generate_Report(reporter, site, "")
  }

  return (
    <>
      <Header user={userStore!.currentUser!} />
      <div className="flex items-center  w-full min-h-full justify-center">
        <div className="w-full lg:w-2/3 p-5 flex flex-col lg:flex-row items-start lg:items-center rounded bg-white shadow my-20 mt-36">
          <div className="flex flex-col lg:flex-row w-full items-start lg:items-center">
            <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg  w-full">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-transparent font-bold">
                  <tr>
                    <th scope="col" className="px-3 py-3 text-left text-sm font-bold text-gray-800 uppercase tracking-wider">ID</th>
                    <th scope="col" className="px-6 py-3 text-left text-sm font-bold text-gray-800 uppercase tracking-wider">Site Detail</th>
                    <th scope="col" className="px-3 py-3 text-left text-sm font-bold text-gray-800 uppercase tracking-wider">Lead Investigator</th>
                    <th scope="col" className="relative px-6 py-3">  <span className="sr-only">Edit</span> </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {siteSore?.isLoading &&
                    <tr>
                      <td colSpan={4}>
                        <Skeleton />
                      </td>
                    </tr>
                  }
                  {sites?.map((site) => (
                    <tr key={site.description?.siteId}>
                      <td className="px-3 py-2 whitespace-nowrap">

                        <div className="text-sm text-gray-900">
                          <p className="cursor-pointer hover:text-atomGreen" onClick={() => handleSiteSelected(site)}>
                            {site.description?.siteId}
                          </p>
                        </div>
                      </td>

                      <td className="px-3 py-2 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="ml-4">
                            <div className="text-sm font-medium text-gray-900">{site.description?.department}</div>
                            <div className="text-sm text-gray-500">{site.description?.institutionName}</div>
                          </div>
                        </div>
                      </td>

                      <td className="px-3 py-2 whitespace-nowrap">
                        <div className="text-sm text-gray-500">{site.description?.leadInvestigatorName}</div>
                      </td>

                      <td className="px-3 py-2 whitespace-nowrap">
                        <div className="flex items-center space-x-2 self-center">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 self-center text-gray-400 cursor-pointer hover:text-atomGreen" onClick={() => handleSiteSelected(site)} fill="none" viewBox="0 0 24 24" width={12} height={12} stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                          </svg>
                          <CalculatorIcon className="h-5 w-5 self-center text-gray-400 cursor-pointer hover:text-atomGreen" onClick={() => handleGenerateReport(site)} />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default observer(SitesList)
