

import { TextWriter, YesNoFiller } from "../utility";

const docx = require("docx");
const { Paragraph, TextRun } = docx;

export const _3_3_4_IMPIntro = (experienceDispense:string) => new Paragraph({
    children: [
      
        new TextRun({ text: "\n", break: 2 }),
        TextWriter(`Table 6 below details the experience and facilities at your site for management of the IMP of which you ${YesNoFiller(experienceDispense, 'do', 'do not')} have experience and your dispensing procedures are attached`),
        new TextRun({ text: "\n", break: 2 }),
       
    ], spacing: {
        line: 360,
    },
})
