

import { TextWriter } from "../utility";

const docx = require("docx");
const { Paragraph, TextRun } = docx;

export const _3_6_1_BioIntro = () => new Paragraph({
    children: [
      
        TextWriter("Assessment of your laboratory facilities and experience in collecting, processing, analysing and exporting biological samples provided the information detailed below."),
        new TextRun({ text: "\n", break: 2 }),
       
    ], spacing: {
        line: 360,
    },
})
