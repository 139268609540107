export const data = [

    {
        "shortName": "Team",
        "updatedAt": "Wed, 10 Aug 2022 09:56:11 GMT",
        "updatedBy": "atom_1@emqt.org.uk",
        "sectionId": "form_1",
        "index": 1,
        "isLive": true,
        "title": "SITE TEAM",
        "subs": [
            {
                "id": "form_1_1",
                "isLive": true,
                "title": "Access to Staff",
                "questions": [
                    {
                        "isLive": true,
                        "reportOutput": "",
                        "followUps": [],
                        "statement": "Do you have a dedicated Clinical Trial unit?",
                        "questionId": "50a3",
                        "type": "SINGLE",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "sectionId": "form_1_1",
                        "meta": "MQ",
                        "index": 0
                    },
                    {
                        "statement": "Do you have dedicated Clinical Trial staff?",
                        "index": 1,
                        "isLive": true,
                        "sectionId": "form_1_1",
                        "questionId": "9319",
                        "meta": "MQ",
                        "type": "SINGLE",
                        "options": [
                            "YES",
                            "NO"
                        ]
                    },
                    {
                        "type": "SINGLE",
                        "index": 2,
                        "isLive": true,
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "followUps": [
                            {
                                "type": "SHORT",
                                "isLive": true,
                                "sectionId": "form_1_1",
                                "questionId": "1267",
                                "statement": "How do you ensure enough time is dedicated to any one study? ",
                                "meta": "NF"
                            }
                        ],
                        "meta": "MQ",
                        "questionId": "d537",
                        "statement": "Is there a full-time study co-ordinator allocated to each study?",
                        "sectionId": "form_1_1"
                    }
                ]
            },
            {
                "title": "Site Team",
                "questions": [
                    {
                        "type": "SINGLE",
                        "meta": "MQ",
                        "sectionId": "form_1_2",
                        "statement": "Do you have  back-up personnel to cover holidays, illness, etc?",
                        "index": 0,
                        "questionId": "e276",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "isLive": true
                    },
                    {
                        "isLive": true,
                        "questionId": "dc1e",
                        "statement": "On average, how many studies does each Study Coordinator manage at one time?",
                        "index": 1,
                        "meta": "MQ",
                        "sectionId": "form_1_2",
                        "type": "SHORT"
                    }
                ],
                "id": "form_1_2"
            }
        ]
    },
    {
        "isLive": true,
        "shortName": "Experience",
        "sectionId": "form_2",
        "subs": [
            {
                "title": "General Clinical Study Experience",
                "id": "form_2_1",
                "questions": [
                    {
                        "sectionId": "form_2_0",
                        "questionId": "296f",
                        "isLive": true,
                        "statement": "Has your site undertaken any clinical study in the past 3 years?",
                        "followUps": [
                            {
                                "statement": "When was the most recent clinical study started?",
                                "meta": "YF",
                                "sectionId": "form_2_0",
                                "type": "DATE",
                                "isLive": true,
                                "isAdminOnly": false,
                                "questionId": "68e4"
                            },
                            {
                                "type": "INTEGER",
                                "options": [
                                    "YES",
                                    "NO"
                                ],
                                "sectionId": "form_2_0",
                                "meta": "YF",
                                "statement": "How many of these were pharma sponsored studies?",
                                "isAdminOnly": false,
                                "isLive": true,
                                "questionId": "bc05"
                            },
                            {
                                "statement": "How many were  local, academic and/or other sponsored clinical studies?",
                                "meta": "YF",
                                "type": "INTEGER",
                                "isAdminOnly": false,
                                "sectionId": "form_2_0",
                                "isLive": true,
                                "questionId": "86b5"
                            },
                            {
                                "type": "LONG",
                                "meta": "YF",
                                "statement": "Who supported the study?",
                                "sectionId": "form_2_0",
                                "questionId": "a345",
                                "isAdminOnly": false,
                                "isLive": true
                            },
                            {
                                "isAdminOnly": false,
                                "isLive": true,
                                "sectionId": "form_2_0",
                                "questionId": "ad4c",
                                "statement": "When was the most recent local, academic and/or sponsored clinical study?",
                                "type": "DATE",
                                "meta": "YF"
                            }
                        ],
                        "isAdminOnly": false,
                        "type": "SINGLE",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "meta": "MQ"
                    }
                ]
            },
            {
                "id": "form_2_2",
                "title": "Clinical study experience",
                "questions": [
                    {
                        "isLive": true,
                        "questionId": "a97c",
                        "statement": "Please check if your site is experienced in conducting Phase I studies Clinical Trials?",
                        "type": "SINGLE",
                        "reportOutput": "Phase I studies",
                        "followUps": [
                            {
                                "statement": "How many in last 3 years?",
                                "isLive": true,
                                "meta": "YF",
                                "sectionId": "form_2_1",
                                "type": "SHORT",
                                "questionId": "f476"
                            },
                            {
                                "sectionId": "form_2_1",
                                "questionId": "f28b",
                                "meta": "YF",
                                "isLive": true,
                                "type": "SHORT",
                                "statement": "Patient population studied in last 3 years"
                            }
                        ],
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "sectionId": "form_2_1",
                        "meta": "MQ"
                    },
                    {
                        "type": "SINGLE",
                        "meta": "MQ",
                        "sectionId": "form_2_1",
                        "followUps": [
                            {
                                "type": "SHORT",
                                "meta": "YF",
                                "sectionId": "form_2_1",
                                "statement": "How many in last 3 years?",
                                "isLive": true,
                                "questionId": "7311"
                            },
                            {
                                "questionId": "c020",
                                "meta": "YF",
                                "type": "SHORT",
                                "statement": "Patient population studied in last 3 years",
                                "sectionId": "form_2_1",
                                "isLive": true
                            }
                        ],
                        "reportOutput": "Phase II proof of concept studies",
                        "isLive": true,
                        "questionId": "c3b0",
                        "statement": "Please check if your site is experienced in conducting Phase II proof of concept studies Clinical Trials?",
                        "options": [
                            "YES",
                            "NO"
                        ]
                    },
                    {
                        "type": "SINGLE",
                        "questionId": "9fad",
                        "isLive": true,
                        "statement": "Please check if your site is experienced in conducting Phase III Clinical Trials?",
                        "sectionId": "form_2_1",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "meta": "MQ",
                        "reportOutput": "Phase III studies",
                        "followUps": [
                            {
                                "sectionId": "form_2_1",
                                "type": "SHORT",
                                "meta": "YF",
                                "questionId": "176e",
                                "statement": "How many in last 3 years?",
                                "isLive": true
                            },
                            {
                                "statement": "Patient population studied in last 3 years",
                                "meta": "YF",
                                "sectionId": "form_2_1",
                                "questionId": "8f57",
                                "type": "SHORT",
                                "isLive": true
                            }
                        ]
                    },
                    {
                        "reportOutput": "Post marketing/Phase IV studies",
                        "sectionId": "form_2_1",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "type": "SINGLE",
                        "isLive": true,
                        "meta": "MQ",
                        "statement": "Please check if your site is experienced in conducting Post marketing/Phase IV studies in the last 3 years?",
                        "questionId": "e9e2",
                        "followUps": [
                            {
                                "sectionId": "form_2_1",
                                "questionId": "9e0d",
                                "statement": "How many in last 3 years?",
                                "type": "SHORT",
                                "meta": "YF",
                                "isLive": true
                            },
                            {
                                "type": "SHORT",
                                "meta": "YF",
                                "isLive": true,
                                "questionId": "4e49",
                                "statement": "Patient population studied in last 3 years",
                                "sectionId": "form_2_1"
                            }
                        ]
                    },
                    {
                        "meta": "MQ",
                        "type": "SINGLE",
                        "questionId": "28d0",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "isLive": true,
                        "reportOutput": "Registries/RWE",
                        "sectionId": "form_2_1",
                        "followUps": [
                            {
                                "isLive": true,
                                "sectionId": "form_2_1",
                                "type": "SHORT",
                                "statement": "How many in last 3 years?",
                                "questionId": "a4d5",
                                "meta": "YF"
                            },
                            {
                                "sectionId": "form_2_1",
                                "isLive": true,
                                "meta": "YF",
                                "type": "SHORT",
                                "questionId": "12f2",
                                "statement": "Patient population studied in last 3 years"
                            }
                        ],
                        "statement": "Please check if your site is experienced in conducting Registries/RWE Clinical Trials in the last 3 years?"
                    },
                    {
                        "isLive": true,
                        "statement": "Please check if your site is experienced in conducting Clinical Pharmacology studies Clinical Trials in the last 3 years?",
                        "sectionId": "form_2_1",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "followUps": [
                            {
                                "type": "SHORT",
                                "questionId": "d0be",
                                "isLive": true,
                                "meta": "YF",
                                "statement": "How many in last 3 years?",
                                "sectionId": "form_2_1"
                            },
                            {
                                "questionId": "9cd2",
                                "meta": "YF",
                                "statement": "Patient population studied in last 3 years",
                                "sectionId": "form_2_1",
                                "isLive": true,
                                "type": "SHORT"
                            }
                        ],
                        "reportOutput": "Pharmacology studies",
                        "questionId": "979e",
                        "meta": "MQ",
                        "type": "SINGLE"
                    },
                    {
                        "sectionId": "form_2_0",
                        "statement": "Please check if your site is experienced in conducting Biomarker studies Clinical Trials ",
                        "isAdminOnly": false,
                        "reportOutput": "Biomarker studies",
                        "meta": "MQ",
                        "type": "SINGLE",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "isLive": true,
                        "questionId": "2ca9"
                    },
                    {
                        "reportOutput": "Biomarker studies",
                        "followUps": [
                            {
                                "sectionId": "form_2_1",
                                "questionId": "61ce",
                                "statement": "How many in last 3 years?",
                                "type": "SHORT",
                                "meta": "YF",
                                "isLive": true
                            },
                            {
                                "questionId": "39f0",
                                "isLive": true,
                                "meta": "YF",
                                "type": "SHORT",
                                "sectionId": "form_2_1",
                                "statement": "Patient population studied in last 3 years"
                            }
                        ],
                        "meta": "MQ",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "sectionId": "form_2_1",
                        "type": "SINGLE",
                        "statement": "Have you conducted Clinical Trials in Biomarker studies in the last 3 years?",
                        "isLive": true,
                        "questionId": "9f87"
                    },
                    {
                        "type": "SINGLE",
                        "questionId": "a627",
                        "isAdminOnly": false,
                        "meta": "MQ",
                        "isLive": true,
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "sectionId": "form_2_1",
                        "reportOutput": "Sample collection projects, e.g. blood, saliva, tissue, etc",
                        "statement": "Please check if your site is experienced in conducting Sample collection projects, e.g. blood, saliva, tissue, etc."
                    },
                    {
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "sectionId": "form_2_1",
                        "type": "SINGLE",
                        "questionId": "29b7",
                        "followUps": [
                            {
                                "statement": "How many in last 3 years?",
                                "isLive": true,
                                "meta": "YF",
                                "sectionId": "form_2_1",
                                "questionId": "8712",
                                "type": "SHORT"
                            },
                            {
                                "type": "SHORT",
                                "statement": "Patient population studied in last 3 years",
                                "isLive": true,
                                "questionId": "8f3f",
                                "meta": "YF",
                                "sectionId": "form_2_1"
                            }
                        ],
                        "statement": "Please check if you conducted Clinical Trial in Sample collection projects, e.g. blood, saliva, tissue, etc. in the last 3 years?",
                        "isLive": true,
                        "reportOutput": "Sample collection projects, e.g. blood, saliva, tissue, etc",
                        "meta": "MQ"
                    },
                    {
                        "reportOutput": "Genetic Sampling studies",
                        "isLive": true,
                        "meta": "MQ",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "followUps": [
                            {
                                "statement": "How many in last 3 years?",
                                "sectionId": "form_2_1",
                                "type": "SHORT",
                                "isLive": true,
                                "meta": "YF",
                                "questionId": "5d4a"
                            },
                            {
                                "statement": "Patient population studied in last 3 years",
                                "sectionId": "form_2_1",
                                "type": "SHORT",
                                "questionId": "9294",
                                "meta": "YF",
                                "isLive": true
                            }
                        ],
                        "questionId": "e352",
                        "sectionId": "form_2_1",
                        "type": "SINGLE",
                        "statement": "Please check if your site is experienced in conducting Genetic Sampling studies in the last 3 years?"
                    },
                    {
                        "isLive": true,
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "statement": "Please check if your site is experienced in conducting Device studies",
                        "followUps": [
                            {
                                "sectionId": "form_2_1",
                                "statement": "How many in last 3 years?",
                                "meta": "YF",
                                "type": "SHORT",
                                "isLive": true,
                                "questionId": "af51"
                            },
                            {
                                "statement": "Patient population studied in last 3 years",
                                "isLive": true,
                                "sectionId": "form_2_1",
                                "questionId": "0629",
                                "meta": "YF",
                                "type": "SHORT"
                            }
                        ],
                        "sectionId": "form_2_1",
                        "type": "SINGLE",
                        "questionId": "89ed",
                        "reportOutput": "Device studies",
                        "meta": "MQ"
                    },
                    {
                        "statement": "Please check if your site is experienced in conducting Nutraceutical studies",
                        "followUps": [
                            {
                                "questionId": "a35b",
                                "type": "SHORT",
                                "isLive": true,
                                "statement": "How many in last 3 years?",
                                "sectionId": "form_2_1",
                                "meta": "YF"
                            },
                            {
                                "isLive": true,
                                "questionId": "f6a1",
                                "meta": "YF",
                                "sectionId": "form_2_1",
                                "type": "SHORT",
                                "statement": "Patient population studied in last 3 years"
                            }
                        ],
                        "meta": "MQ",
                        "type": "SINGLE",
                        "isLive": true,
                        "sectionId": "form_2_1",
                        "reportOutput": "Nutraceutical studies",
                        "questionId": "f9f9",
                        "options": [
                            "YES",
                            "NO"
                        ]
                    },
                    {
                        "reportOutput": "Other studies",
                        "type": "SINGLE",
                        "statement": "Please check if your site is experienced in conducting Other studies not listed above",
                        "meta": "MQ",
                        "followUps": [
                            {
                                "statement": "List the type of studies started in last 3 years",
                                "questionId": "a160",
                                "meta": "YF",
                                "isLive": true,
                                "sectionId": "form_2_1",
                                "type": "LONG"
                            },
                            {
                                "type": "SHORT",
                                "sectionId": "form_2_1",
                                "questionId": "9419",
                                "isLive": true,
                                "meta": "YF",
                                "statement": "Patient population studied in last 3 years "
                            }
                        ],
                        "sectionId": "form_2_1",
                        "isLive": true,
                        "questionId": "3cce",
                        "options": [
                            "YES",
                            "NO"
                        ]
                    },
                    {
                        "statement": "Are you able to recruit patients from any external sources?",
                        "meta": "MQ",
                        "followUps": [
                            {
                                "isLive": true,
                                "statement": "From what sources would you be able to recruit patients?",
                                "meta": "YF",
                                "sectionId": "form_2_1",
                                "type": "SHORT",
                                "questionId": "1461"
                            }
                        ],
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "isLive": true,
                        "sectionId": "form_2_1",
                        "questionId": "4aeb",
                        "type": "SINGLE"
                    },
                    {
                        "isLive": true,
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "statement": "Has your site used referrals in the past?",
                        "type": "SINGLE",
                        "followUps": [
                            {
                                "sectionId": "form_2_1",
                                "isLive": true,
                                "meta": "YF",
                                "questionId": "de3c",
                                "type": "SHORT",
                                "statement": "Please provide how many referral sites you have worked with? "
                            }
                        ],
                        "questionId": "8045",
                        "sectionId": "form_2_1",
                        "meta": "MQ"
                    },
                    {
                        "meta": "MQ",
                        "isLive": true,
                        "followUps": [
                            {
                                "type": "LONG",
                                "questionId": "5670",
                                "sectionId": "form_2_1",
                                "isLive": true,
                                "meta": "YF",
                                "statement": "List number of these clinical trials started in last 3 years "
                            },
                            {
                                "questionId": "c4af",
                                "meta": "YF",
                                "statement": "Patient population studied in clinical trials",
                                "sectionId": "form_2_1",
                                "type": "SHORT",
                                "isLive": true
                            }
                        ],
                        "questionId": "2ca2",
                        "statement": "Do you have study staff experienced in the collection and processing of serial blood draws?",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "type": "SINGLE",
                        "sectionId": "form_2_1"
                    },
                    {
                        "reportOutput": "Intensive PK/PD sampling (i.e. taking 15 samples over a 24 Hr period)",
                        "type": "SINGLE",
                        "statement": "Is your site experienced in studies that require intensive PK/PD sampling (i.e. taking 15 samples over a 24 Hr period)?",
                        "followUps": [
                            {
                                "meta": "YF",
                                "type": "SHORT",
                                "sectionId": "form_2_1",
                                "questionId": "1af1",
                                "isLive": true,
                                "statement": "List number of these clinical trials started in last 3 years "
                            },
                            {
                                "type": "SHORT",
                                "meta": "YF",
                                "statement": "Patient population studied in clinical trials",
                                "isLive": true,
                                "questionId": "ffec",
                                "sectionId": "form_2_1"
                            }
                        ],
                        "questionId": "1933",
                        "meta": "MQ",
                        "isLive": true,
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "sectionId": "form_2_1"
                    },
                    {
                        "questionId": "6608",
                        "meta": "MQ",
                        "statement": "Does your site have experience in performing and/or measuring 12 lead ECGs?",
                        "type": "SINGLE",
                        "reportOutput": "Performing and/or measuring 12 lead ECGs",
                        "sectionId": "form_2_1",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "isLive": true
                    },
                    {
                        "type": "SINGLE",
                        "followUps": [
                            {
                                "sectionId": "form_2_1",
                                "questionId": "aa0e",
                                "type": "SHORT",
                                "isLive": true,
                                "meta": "YF",
                                "statement": "What type of assessments?"
                            },
                            {
                                "statement": "How many in last 3 years?",
                                "meta": "YF",
                                "questionId": "8712",
                                "sectionId": "form_2_1",
                                "type": "SHORT",
                                "isLive": true
                            },
                            {
                                "type": "SHORT",
                                "meta": "YF",
                                "questionId": "8f3f",
                                "statement": "Patient population studied in last 3 years",
                                "sectionId": "form_2_1",
                                "isLive": true
                            }
                        ],
                        "questionId": "uu87",
                        "statement": "Please check if you conducted Clinical Trial in performing and/or measuring 12 lead ECGs?. in the last 3 years?",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "reportOutput": "Performing and/or measuring 12 lead ECGs",
                        "isLive": true,
                        "sectionId": "form_2_1",
                        "meta": "MQ"
                    },
                    {
                        "statement": "Does your site have experience in performing radiological assessments?",
                        "sectionId": "form_2_1",
                        "reportOutput": "Radiological assessments",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "isLive": true,
                        "meta": "MQ",
                        "followUps": [
                            {
                                "isLive": true,
                                "statement": "What type of assessments?",
                                "type": "SHORT",
                                "sectionId": "form_2_1",
                                "meta": "YF",
                                "questionId": "u3rp"
                            },
                            {
                                "statement": "List number of these clinical trials started in last 3 years",
                                "meta": "YF",
                                "type": "SHORT",
                                "isLive": true,
                                "questionId": "fded",
                                "sectionId": "form_2_1"
                            },
                            {
                                "type": "SHORT",
                                "isLive": true,
                                "meta": "YF",
                                "sectionId": "form_2_1",
                                "statement": "Patient population studied in clinical trials",
                                "questionId": "18ae"
                            }
                        ],
                        "questionId": "dbf0",
                        "type": "SINGLE"
                    },
                    {
                        "type": "SINGLE",
                        "isLive": true,
                        "meta": "MQ",
                        "reportOutput": "Histopathogical assessments",
                        "statement": "Does your site have experience in performing histopathogical assessments?",
                        "questionId": "5113",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "followUps": [
                            {
                                "type": "SHORT",
                                "isLive": true,
                                "statement": "What type of assessments?",
                                "sectionId": "form_2_1",
                                "questionId": "tt67",
                                "meta": "YF"
                            },
                            {
                                "type": "SHORT",
                                "sectionId": "form_2_1",
                                "statement": "List number of these clinical trials started in last 3 years",
                                "meta": "YF",
                                "isLive": true,
                                "questionId": "52a6"
                            },
                            {
                                "sectionId": "form_2_1",
                                "questionId": "8570",
                                "isLive": true,
                                "meta": "YF",
                                "type": "SHORT",
                                "statement": "Patient population studied in clinical trials"
                            }
                        ],
                        "sectionId": "form_2_1"
                    },
                    {
                        "statement": "Does your site have experience in performing and/or measuring Visual Analogue Scales?",
                        "reportOutput": "Performing and/or measuring Visual Analogue Scales",
                        "isLive": true,
                        "sectionId": "form_2_1",
                        "questionId": "30a4",
                        "type": "SINGLE",
                        "meta": "MQ",
                        "followUps": [
                            {
                                "isLive": true,
                                "type": "SHORT",
                                "meta": "YF",
                                "questionId": "77e6",
                                "sectionId": "form_2",
                                "statement": "What type of assessments?"
                            },
                            {
                                "questionId": "651e",
                                "isLive": true,
                                "sectionId": "form_2",
                                "type": "LONG",
                                "statement": "List number of these clinical trials started in last 3 years",
                                "meta": "YF"
                            },
                            {
                                "statement": "Patient population studied in clinical trials",
                                "meta": "YF",
                                "isLive": true,
                                "questionId": "b33f",
                                "type": "SHORT",
                                "sectionId": "form_2"
                            }
                        ],
                        "options": [
                            "YES",
                            "NO"
                        ]
                    }
                ]
            },
            {
                "id": "form_2_3",
                "title": "Training",
                "questions": [
                    {
                        "followUps": [
                            {
                                "type": "LONG",
                                "questionId": "a66a",
                                "statement": "Please provide details.",
                                "sectionId": "form_2",
                                "isLive": true,
                                "meta": "YF"
                            }
                        ],
                        "sectionId": "form_2_2",
                        "meta": "MQ",
                        "statement": "Is there an ongoing staff clinical research training programme? ",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "type": "SINGLE",
                        "isLive": true,
                        "questionId": "3567"
                    },
                    {
                        "statement": "Are the staff training records maintained and up to date?",
                        "meta": "MQ",
                        "sectionId": "form_2_2",
                        "questionId": "977b",
                        "isLive": true,
                        "followUps": [
                            {
                                "statement": "How are they recorded and maintained (e.g. electronically or on paper)?",
                                "sectionId": "form_2",
                                "type": "SHORT",
                                "questionId": "1a62",
                                "meta": "YF",
                                "isLive": true
                            },
                            {
                                "type": "SHORT",
                                "meta": "NF",
                                "sectionId": "form_2_2",
                                "questionId": "4d3b",
                                "statement": "What percentage of the record are up to date",
                                "isAdminOnly": false,
                                "isLive": true
                            }
                        ],
                        "type": "SINGLE",
                        "options": [
                            "YES",
                            "NO"
                        ]
                    },
                    {
                        "isLive": true,
                        "sectionId": "form_2_2",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "type": "SINGLE",
                        "meta": "MQ",
                        "questionId": "3f05",
                        "statement": "From the general discussion is the investigator aware of his/her ICH GCP responsibilities and compliant with all other applicable regulations?"
                    },
                    {
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "isLive": true,
                        "questionId": "5ecc",
                        "type": "SINGLE",
                        "sectionId": "form_2_2",
                        "meta": "MQ",
                        "statement": "If team members have not had recent GCP training (in the last year) is the site willing to enrol members in the required training course?"
                    },
                    {
                        "questionId": "b247",
                        "meta": "MQ",
                        "isLive": true,
                        "statement": "Are study staff who are required to enter data be able to allocate time (assume at least 4hrs) to complete e-learning training required to learn how to enter data on the allocated data capture system, individually prior to any new study assignment?",
                        "type": "SINGLE",
                        "sectionId": "form_2_2",
                        "options": [
                            "YES",
                            "NO"
                        ]
                    },
                    {
                        "type": "SINGLE",
                        "sectionId": "form_2_2",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "meta": "MQ",
                        "isLive": true,
                        "statement": "Will study staff be able to allocate time (assume at least 8hrs) to complete training required for each study individually prior to any new study assignment?",
                        "questionId": "7919"
                    }
                ]
            },
            {
                "questions": [
                    {
                        "meta": "MQ",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "isLive": true,
                        "questionId": "525d",
                        "followUps": [
                            {
                                "statement": "Which Electronic Data Capture (EDC) systems have been used at the site?",
                                "type": "LONG",
                                "questionId": "6f86",
                                "isLive": true,
                                "sectionId": "form_2",
                                "meta": "YF"
                            }
                        ],
                        "statement": "Has the site had experience with a any Electronic Data Capture (EDC) system?",
                        "sectionId": "form_2_3",
                        "type": "SINGLE"
                    },
                    {
                        "questionId": "1e4a",
                        "isLive": true,
                        "type": "SINGLE",
                        "statement": "Has the site had Experience of working with e-tools?",
                        "meta": "MQ",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "followUps": [
                            {
                                "questionId": "d98a",
                                "statement": "Please provide details  (i.e. electronic scale, ePRO, eICF, eISF, etc.)",
                                "type": "SHORT",
                                "sectionId": "form_2",
                                "meta": "YF",
                                "isLive": true
                            }
                        ],
                        "sectionId": "form_2_3"
                    }
                ],
                "id": "form_2_4",
                "title": "EDC EXPERIENCE"
            },
            {
                "questions": [
                    {
                        "questionId": "ce22",
                        "meta": "MQ",
                        "followUps": [
                            {
                                "questionId": "2449",
                                "sectionId": "form_2",
                                "meta": "YF",
                                "type": "LONG",
                                "statement": "Provide details of this process",
                                "isLive": true
                            },
                            {
                                "isAdminOnly": false,
                                "isLive": true,
                                "type": "LONG",
                                "questionId": "72de",
                                "sectionId": "form_2_4",
                                "statement": "Please provide details of the process",
                                "meta": "NF"
                            }
                        ],
                        "sectionId": "form_2_4",
                        "isLive": true,
                        "statement": "Does the site have an internal QA/QC process for data entry? ",
                        "options": [
                            "YES",
                            "None Formalized "
                        ],
                        "type": "SINGLE"
                    },
                    {
                        "sectionId": "form_2_4",
                        "statement": "Is the site able to transfer the source data into the CRF within 2-5 days? ",
                        "questionId": "578b",
                        "isLive": true,
                        "meta": "MQ",
                        "followUps": [
                            {
                                "sectionId": "form_2",
                                "meta": "NF",
                                "statement": "Provide the reasons.",
                                "questionId": "5cc8",
                                "type": "LONG",
                                "isLive": true
                            }
                        ],
                        "type": "SINGLE",
                        "options": [
                            "YES",
                            "NO"
                        ]
                    },
                    {
                        "questionId": "ff1c",
                        "sectionId": "form_2_4",
                        "type": "SHORT",
                        "statement": "What is the average timeline for addressing data discrepancies/queries?",
                        "meta": "MQ",
                        "isLive": true
                    },
                    {
                        "type": "STAFFS",
                        "sectionId": "form_2_4",
                        "questionId": "65a5",
                        "statement": "Who will be available to provide resolution of scientific and medical queries on an ongoing basis? ",
                        "meta": "MQ",
                        "isLive": true
                    },
                    {
                        "sectionId": "form_2_4",
                        "questionId": "7562",
                        "statement": "Please specify what can be collected electronically e.g. Pharmacodynamic Data, ECG, Cognitive Function Data, etc.",
                        "type": "LONG",
                        "meta": "MQ",
                        "isLive": true
                    }
                ],
                "id": "form_2_5",
                "title": "DATA MANAGEMENT"
            },
            {
                "id": "form_2_6",
                "title": "SOURCE DOCUMENTS",
                "questions": [
                    {
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "type": "SINGLE",
                        "questionId": "638d",
                        "statement": "Is the site experienced in creating/completing source documents for all the data collected for a study? ",
                        "sectionId": "form_2_5",
                        "meta": "MQ",
                        "isLive": true
                    },
                    {
                        "statement": "Please explain whether the source documents (medical records) are in electronic and/or paper format?",
                        "isLive": true,
                        "questionId": "471d",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "meta": "MQ",
                        "sectionId": "form_2_5",
                        "type": "LONG"
                    },
                    {
                        "sectionId": "form_2_5",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "isLive": true,
                        "questionId": "c2da",
                        "meta": "MQ",
                        "statement": "How are medical records maintained and archived?",
                        "type": "LONG"
                    }
                ]
            },
            {
                "questions": [
                    {
                        "sectionId": "form_2_6",
                        "isLive": true,
                        "type": "SHORT",
                        "questionId": "0df5",
                        "statement": "What type of contract is required? (e.g. contract with hospital or subcontract with Investigator)",
                        "reportOutput": "Type of contract required",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "meta": "MQ"
                    },
                    {
                        "statement": "Are you able to use a contract template provided by CRO/Sponsor for this Study?",
                        "questionId": "cd64",
                        "isLive": true,
                        "type": "SINGLE",
                        "followUps": [
                            {
                                "questionId": "aaa9",
                                "sectionId": "form_2_6",
                                "reportOutput": "Supported contract type",
                                "isLive": true,
                                "options": [
                                    "Tri/Bi partite contract template",
                                    "Bilingual contract template"
                                ],
                                "statement": "Choose supported contract type",
                                "isAdminOnly": false,
                                "meta": "YF",
                                "type": "SINGLE"
                            },
                            {
                                "statement": "Tri/Bi partite contract?",
                                "questionId": "9200",
                                "type": "SINGLE",
                                "sectionId": "form_2",
                                "meta": "YF",
                                "options": [
                                    "YES",
                                    "NO"
                                ],
                                "isLive": false
                            },
                            {
                                "type": "SHORT",
                                "isLive": true,
                                "questionId": "f69b",
                                "statement": "What is the typical turnaround time to set up a contract to approval signature with your centre (weeks)?",
                                "meta": "YF",
                                "sectionId": "form_2"
                            }
                        ],
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "meta": "MQ",
                        "sectionId": "form_2_6"
                    },
                    {
                        "statement": "Does your site contract have to be declared to any local authority?",
                        "reportOutput": "Local authority declaration",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "sectionId": "form_2_6",
                        "type": "SINGLE",
                        "isLive": true,
                        "meta": "MQ",
                        "questionId": "24b9"
                    },
                    {
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "isLive": true,
                        "followUps": [
                            {
                                "sectionId": "form_2",
                                "type": "LONG",
                                "meta": "YF",
                                "isLive": true,
                                "questionId": "2491",
                                "statement": "Please elaborate by including percentage and any non-refundable fees:"
                            }
                        ],
                        "statement": "Does your site require an overhead payment? ",
                        "reportOutput": "Overhead payment requirement",
                        "type": "SINGLE",
                        "meta": "MQ",
                        "sectionId": "form_2_6",
                        "questionId": "b7b3"
                    },
                    {
                        "isLive": true,
                        "sectionId": "form_2_6",
                        "statement": "Who is responsible for budget approval?",
                        "type": "SHORT",
                        "questionId": "44c8",
                        "reportOutput": "Budget approval personnel",
                        "meta": "MQ"
                    },
                    {
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "meta": "MQ",
                        "reportOutput": "Invoicing system",
                        "isLive": true,
                        "type": "SINGLE",
                        "questionId": "7eb3",
                        "statement": "Does your site have an invoicing system? ",
                        "sectionId": "form_2_6"
                    },
                    {
                        "statement": "What other aspects related to budget/contracts should be known? ",
                        "isLive": true,
                        "type": "LONG",
                        "sectionId": "form_2_6",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "meta": "MQ",
                        "questionId": "627f"
                    }
                ],
                "title": "BUDGET/ CONTRACTS",
                "id": "form_2_7"
            },
            {
                "id": "form_2_8",
                "title": "AUDITS ",
                "questions": [
                    {
                        "followUps": [
                            {
                                "questionId": "06c8",
                                "isLive": true,
                                "sectionId": "form_2",
                                "meta": "YF",
                                "type": "LONG",
                                "statement": "Provide details and outcome"
                            },
                            {
                                "isLive": true,
                                "statement": "Attach a copy of the audit report if available",
                                "questionId": "a9a1",
                                "type": "FILE",
                                "meta": "YF",
                                "sectionId": "form_2"
                            }
                        ],
                        "isLive": true,
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "statement": "Has the site ever been involved in Inspections/audits including any by a Health Authority?",
                        "sectionId": "form_2_7",
                        "meta": "MQ",
                        "questionId": "1dc9",
                        "type": "SINGLE"
                    }
                ]
            }
        ],
        "title": "Site experience",
        "index": 2,
        "updatedBy": "atom_1@emqt.org.uk",
        "updatedAt": "Wed, 10 Aug 2022 09:56:35 GMT"
    },
    {
        "subs": [
            {
                "title": "Technology",
                "id": "form_3_1",
                "questions": [
                    {
                        "type": "SINGLE",
                        "statement": "Does each member of the team have their own computer/laptop?",
                        "meta": "MQ",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "isLive": true,
                        "sectionId": "form_3_1",
                        "questionId": "b351",
                        "followUps": [
                            {
                                "meta": "NF",
                                "isLive": true,
                                "sectionId": "form_3",
                                "questionId": "24bc",
                                "type": "SHORT",
                                "statement": "How many computers/laptops are available for how many staff?"
                            }
                        ]
                    },
                    {
                        "sectionId": "form_3_1",
                        "statement": "Does the site have a dedicated telephone/mobile for Clinical Trial work?",
                        "isLive": true,
                        "questionId": "4140",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "type": "SINGLE",
                        "meta": "MQ"
                    },
                    {
                        "meta": "MQ",
                        "statement": "Does your site (and team) have access to the internet?",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "type": "SINGLE",
                        "sectionId": "form_3_1",
                        "isLive": true,
                        "questionId": "c136"
                    },
                    {
                        "isLive": true,
                        "meta": "MQ",
                        "type": "SINGLE",
                        "sectionId": "form_3_1",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "questionId": "2ee8",
                        "statement": "Does your site support easy access of the internet for monitors? "
                    },
                    {
                        "type": "SINGLE",
                        "sectionId": "form_3_1",
                        "statement": "Is there a back-up data storage for electronic records at the centre?",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "meta": "MQ",
                        "questionId": "ffa5",
                        "isLive": true
                    },
                    {
                        "sectionId": "form_3_1",
                        "statement": "Does the site have a fast internet connectivity? ",
                        "questionId": "0d50",
                        "isLive": true,
                        "type": "SINGLE",
                        "meta": "MQ",
                        "options": [
                            "YES",
                            "NO"
                        ]
                    },
                    {
                        "sectionId": "form_3_1",
                        "statement": "Does the site have a stable internet connection.",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "meta": "MQ",
                        "isLive": true,
                        "type": "SINGLE",
                        "followUps": [
                            {
                                "sectionId": "form_3",
                                "questionId": "a66f",
                                "isLive": true,
                                "statement": "Please provide any back-up strategies in place at the site to support a continued internet connection?.",
                                "type": "SHORT",
                                "meta": "NF"
                            }
                        ],
                        "questionId": "d343"
                    },
                    {
                        "sectionId": "form_3_1",
                        "meta": "MQ",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "questionId": "c6a3",
                        "statement": "Does the site have the correct site connection speed? \nGo to: http://www.fast.com Please note the result of the test below.",
                        "type": "SINGLE",
                        "isLive": true
                    },
                    {
                        "type": "SHORT",
                        "isLive": true,
                        "sectionId": "form_3_1",
                        "meta": "MQ",
                        "questionId": "ae68",
                        "statement": "Please note speed test results: ( Kbps/Mbps)"
                    },
                    {
                        "type": "SINGLE",
                        "questionId": "bdde",
                        "meta": "MQ",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "sectionId": "form_3_1",
                        "statement": "Does the computer assigned for data entry have Windows 10?",
                        "isLive": true,
                        "followUps": [
                            {
                                "type": "SHORT",
                                "statement": " What version is installed?",
                                "meta": "NF",
                                "sectionId": "form_3",
                                "isLive": true,
                                "questionId": "74f4"
                            }
                        ]
                    },
                    {
                        "isLive": true,
                        "type": "SINGLE",
                        "followUps": [
                            {
                                "type": "LONG",
                                "statement": "How are IT problems resolved?",
                                "questionId": "ae42",
                                "isLive": true,
                                "sectionId": "form_3",
                                "meta": "NF"
                            }
                        ],
                        "questionId": "a5d0",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "sectionId": "form_3_1",
                        "meta": "MQ",
                        "statement": "Does the site have on-site IT support? "
                    }
                ]
            },
            {
                "id": "form_3_2",
                "questions": [
                    {
                        "questionId": "ce0f",
                        "type": "SINGLE",
                        "statement": "Is there at least one dedicated PC and/or laptop suitable for data entry to occur on time? ",
                        "isLive": true,
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "followUps": [
                            {
                                "sectionId": "form_3",
                                "type": "SHORT",
                                "statement": " How will you resolve this?",
                                "questionId": "c5bc",
                                "isLive": true,
                                "meta": "NF"
                            }
                        ],
                        "meta": "MQ",
                        "sectionId": "form_3_2"
                    },
                    {
                        "questionId": "9a4b",
                        "meta": "MQ",
                        "followUps": [
                            {
                                "statement": "Can a company/Institute e-mail account, with the specified requirements, be provided to each study staff member?",
                                "options": [
                                    "YES",
                                    "NO"
                                ],
                                "type": "SINGLE",
                                "sectionId": "form_3",
                                "questionId": "49a2",
                                "meta": "NF",
                                "isLive": true
                            }
                        ],
                        "sectionId": "form_3_2",
                        "isLive": true,
                        "type": "SINGLE",
                        "statement": "Do all the site staff members involved in data handling have a private company/Institute email account (for a named individual, password protected and only accessible by the account owner)? If no, please answer the next question.",
                        "options": [
                            "YES",
                            "NO"
                        ]
                    }
                ],
                "title": "EDC"
            },
            {
                "id": "form_3_3",
                "title": "Data Security",
                "questions": [
                    {
                        "type": "LONG",
                        "statement": "What data security measures are used at your site?",
                        "isLive": true,
                        "questionId": "ac0f",
                        "sectionId": "form_3_3",
                        "meta": "MQ"
                    },
                    {
                        "questionId": "7207",
                        "isLive": true,
                        "meta": "MQ",
                        "type": "SHORT",
                        "sectionId": "form_3_3",
                        "statement": "How do you endure that patient data is not lost and patient privacy is protected (e.g. SOPs)"
                    },
                    {
                        "isLive": true,
                        "type": "SHORT",
                        "questionId": "70e0",
                        "meta": "MQ",
                        "sectionId": "form_3_3",
                        "statement": "What detection measure is in place if there is a security breach?"
                    },
                    {
                        "isLive": true,
                        "type": "LONG",
                        "questionId": "d9d3",
                        "sectionId": "form_3_3",
                        "statement": "What steps are taken to resolve any such event ?",
                        "meta": "MQ"
                    },
                    {
                        "type": "SHORT",
                        "sectionId": "form_3_3",
                        "questionId": "e815",
                        "isLive": true,
                        "statement": "What electronic archiving facilities does the site have and local time retention?",
                        "meta": "MQ"
                    },
                    {
                        "type": "SHORT",
                        "meta": "MQ",
                        "sectionId": "form_3_3",
                        "statement": "How are the Investigator file(s) maintained, location, and access?",
                        "isLive": true,
                        "questionId": "fd17"
                    }
                ]
            }
        ],
        "index": 3,
        "updatedBy": "atom_1@emqt.org.uk",
        "sectionId": "form_3",
        "title": "SITE INFRASTRUCTURE",
        "isLive": true,
        "shortName": "Infra",
        "updatedAt": "Tue, 23 Aug 2022 07:25:04 GMT"
    },
    {
        "isLive": true,
        "subs": [
            {
                "id": "form_4_1",
                "title": "Facilities",
                "questions": [
                    {
                        "isLive": true,
                        "questionId": "3d00",
                        "meta": "MQ",
                        "type": "INTEGER",
                        "sectionId": "form_4_1",
                        "statement": "How many beds are at your site?"
                    },
                    {
                        "sectionId": "form_4_1",
                        "statement": "How many of the beds are allocated for patients under your care in general at any one time?",
                        "questionId": "3c00",
                        "isLive": true,
                        "meta": "MQ",
                        "type": "INTEGER"
                    },
                    {
                        "questionId": "bd5a",
                        "type": "SINGLE",
                        "meta": "MQ",
                        "statement": "Is the accommodation roomy and airy with natural light?",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "isLive": true,
                        "sectionId": "form_4_1"
                    },
                    {
                        "meta": "MQ",
                        "questionId": "7e6e",
                        "sectionId": "form_4_1",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "statement": "Are emergency call procedures available in each room/ward?",
                        "type": "SINGLE",
                        "isLive": true
                    },
                    {
                        "questionId": "4520",
                        "meta": "MQ",
                        "statement": "Are the male and female areas separated on your wards?",
                        "type": "SINGLE",
                        "sectionId": "form_4_1",
                        "isLive": true,
                        "options": [
                            "YES",
                            "NO"
                        ]
                    },
                    {
                        "sectionId": "form_4_1",
                        "type": "SHORT",
                        "questionId": "f5f9",
                        "statement": "How many Clinical trial subjects/patients could you accommodate in your dept at one time?",
                        "meta": "MQ",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "isLive": true
                    },
                    {
                        "isLive": true,
                        "type": "SINGLE",
                        "statement": "Is there an allocated private room/area(s) for consent and screening procedures and for conducting study procedures?",
                        "questionId": "24ff",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "meta": "MQ",
                        "sectionId": "form_4_1"
                    },
                    {
                        "meta": "MQ",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "type": "SINGLE",
                        "sectionId": "form_4_1",
                        "isLive": true,
                        "statement": "Are other suitable rooms available e.g. for blood sampling, ECGs?  ",
                        "questionId": "6cee"
                    },
                    {
                        "questionId": "1350",
                        "sectionId": "form_4_1",
                        "meta": "MQ",
                        "options": [],
                        "type": "SHORT",
                        "isLive": true,
                        "statement": "Typically, what distance do most patients travel to get to your study site?  "
                    },
                    {
                        "options": [],
                        "meta": "MQ",
                        "isLive": true,
                        "sectionId": "form_4_1",
                        "statement": "What is the procedure in the event of a power cut?",
                        "type": "LONG",
                        "questionId": "aea0"
                    },
                    {
                        "statement": "Is there a back-up generator, solar power or other back-up specifically for the site (e.g. Drug, other supplies) in order to maintain temperature controlled conditions?",
                        "type": "SINGLE",
                        "questionId": "1b7c",
                        "isLive": true,
                        "sectionId": "form_4_1",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "meta": "MQ"
                    },
                    {
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "meta": "MQ",
                        "type": "SINGLE",
                        "statement": "Do you have fridge/freezers available on site (-20°C, -70°C or -80°C or Other)",
                        "followUps": [
                            {
                                "isAdminOnly": false,
                                "questionId": "e626",
                                "meta": "YF",
                                "isLive": true,
                                "sectionId": "form_4_1",
                                "type": "SHORT",
                                "statement": "Number of 4°C fridge available."
                            },
                            {
                                "statement": "Number of -20°C freezers available.",
                                "type": "SHORT",
                                "questionId": "69d5",
                                "isLive": true,
                                "meta": "YF",
                                "isAdminOnly": false,
                                "sectionId": "form_4_1"
                            },
                            {
                                "statement": "Number of -70°C freezers available.",
                                "meta": "YF",
                                "isLive": true,
                                "type": "SHORT",
                                "questionId": "129e",
                                "sectionId": "form_4_1",
                                "isAdminOnly": false
                            },
                            {
                                "type": "SHORT",
                                "isAdminOnly": false,
                                "statement": "Number of -80°C freezers available.",
                                "meta": "YF",
                                "sectionId": "form_4_1",
                                "questionId": "cfb7",
                                "isLive": true
                            },
                            {
                                "sectionId": "form_4",
                                "meta": "YF",
                                "type": "LIST",
                                "statement": "Please specify what other freezer are available",
                                "isLive": true,
                                "questionId": "9ccb",
                                "options": [
                                    "-20 degree",
                                    "-70 degree",
                                    "-80 degree"
                                ]
                            },
                            {
                                "sectionId": "form_4",
                                "questionId": "0829",
                                "statement": "Is there an automatic warning system on the fridge(s)/freezer(s) to alert staff of problems?",
                                "meta": "YF",
                                "type": "SINGLE",
                                "options": [
                                    "YES",
                                    "NO"
                                ],
                                "isLive": true
                            },
                            {
                                "questionId": "d3aa",
                                "type": "SHORT",
                                "meta": "YF",
                                "sectionId": "form_4",
                                "isLive": true,
                                "statement": "What is the system of control of these fridge(s)/freezers in case of temperature excursion?"
                            },
                            {
                                "meta": "YF",
                                "questionId": "26d0",
                                "options": [
                                    "YES",
                                    "NO"
                                ],
                                "type": "SINGLE",
                                "sectionId": "form_4",
                                "isLive": true,
                                "statement": "Is a temperature log maintained etc?"
                            },
                            {
                                "questionId": "3682",
                                "type": "SHORT",
                                "statement": "What is the frequency of monitoring the temperature (daily?)",
                                "sectionId": "form_4",
                                "meta": "YF",
                                "isLive": true
                            },
                            {
                                "type": "SHORT",
                                "meta": "YF",
                                "questionId": "8b8f",
                                "isLive": true,
                                "sectionId": "form_4",
                                "statement": "For how long are records kept?"
                            }
                        ],
                        "questionId": "34de",
                        "sectionId": "form_4_1",
                        "isLive": true
                    },
                    {
                        "type": "LONG",
                        "meta": "MQ",
                        "statement": "Describe your process for Service, Calibration, Maintenance and documentation for all equipment e.g. ECG machine, electronic scales, infusion pumps etc at the site?",
                        "questionId": "83de",
                        "sectionId": "form_4_1",
                        "isLive": true
                    },
                    {
                        "isAdminOnly": false,
                        "isLive": true,
                        "meta": "MQ",
                        "type": "FILE",
                        "questionId": "4816",
                        "sectionId": "form_4_0",
                        "statement": "Attach process documentation if available"
                    },
                    {
                        "sectionId": "form_4_1",
                        "isLive": true,
                        "statement": "Is there sufficient space for study supplies, study documents, etc?",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "questionId": "e8fc",
                        "meta": "MQ",
                        "type": "SINGLE",
                        "followUps": [
                            {
                                "statement": "Are they kept in a locked area?",
                                "sectionId": "form_4",
                                "type": "SINGLE",
                                "questionId": "e5b2",
                                "isLive": true,
                                "options": [
                                    "YES",
                                    "NO"
                                ],
                                "meta": "YF"
                            },
                            {
                                "sectionId": "form_4",
                                "type": "SHORT",
                                "questionId": "9121",
                                "meta": "YF",
                                "statement": "Who has access?",
                                "isLive": true
                            }
                        ]
                    }
                ]
            },
            {
                "questions": [
                    {
                        "sectionId": "form_4_2",
                        "isLive": true,
                        "meta": "MQ",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "questionId": "2552",
                        "statement": "Is there a meeting room available for initiation or for larger, private meetings etc.?",
                        "type": "SINGLE"
                    },
                    {
                        "type": "SINGLE",
                        "meta": "MQ",
                        "questionId": "bf04",
                        "statement": "Is there sufficient space to perform monitoring activities (preferably a private room)?",
                        "sectionId": "form_4_2",
                        "isLive": true,
                        "options": [
                            "YES",
                            "NO"
                        ]
                    },
                    {
                        "sectionId": "form_4_2",
                        "statement": "Would you and/or your research staff be able to accommodate regular, frequent visits from the monitor?",
                        "questionId": "b10b",
                        "type": "SINGLE",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "meta": "MQ",
                        "isLive": true
                    },
                    {
                        "questionId": "c371",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "statement": "Are there any restrictions on frequency of monitoring visits? ",
                        "isLive": true,
                        "followUps": [
                            {
                                "isLive": true,
                                "meta": "YF",
                                "type": "LONG",
                                "statement": "Please provide details",
                                "sectionId": "form_4",
                                "questionId": "194a"
                            }
                        ],
                        "type": "SINGLE",
                        "sectionId": "form_4_2",
                        "meta": "MQ"
                    },
                    {
                        "questionId": "1699",
                        "statement": "Can internet access be arranged for the monitor prior to any visits?",
                        "isLive": true,
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "meta": "MQ",
                        "type": "SINGLE",
                        "sectionId": "form_4_2"
                    }
                ],
                "id": "form_4_2",
                "title": "MONITORING FACILITIES"
            },
            {
                "questions": [
                    {
                        "type": "SHORT",
                        "sectionId": "form_4_3",
                        "isLive": true,
                        "statement": "What is the site policy for keeping study documentation/files?",
                        "meta": "MQ",
                        "questionId": "87d0"
                    },
                    {
                        "type": "SINGLE",
                        "statement": "Are there specific archiving facilities? If Yes, please answer the questions  below.",
                        "isLive": true,
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "meta": "MQ",
                        "followUps": [
                            {
                                "options": [
                                    "YES",
                                    "NO"
                                ],
                                "questionId": "e895",
                                "isAdminOnly": false,
                                "sectionId": "form_4_3",
                                "type": "LONG",
                                "statement": "Describe the archiving facilities available at the site",
                                "isLive": true,
                                "meta": "YF"
                            },
                            {
                                "options": [
                                    "YES",
                                    "NO"
                                ],
                                "meta": "YF",
                                "statement": "Is this facility kept locked with restricted access?",
                                "reportOutput": "Trail Documents are secured by restricted access",
                                "questionId": "da4c",
                                "isAdminOnly": false,
                                "type": "SINGLE",
                                "isLive": true,
                                "sectionId": "form_4_3"
                            },
                            {
                                "reportOutput": "Trail Documents are secured by fire protection",
                                "meta": "YF",
                                "options": [
                                    "YES",
                                    "NO"
                                ],
                                "statement": "Is this facility fire protected",
                                "isLive": true,
                                "sectionId": "form_4_3",
                                "type": "SINGLE",
                                "isAdminOnly": false,
                                "questionId": "cd28"
                            },
                            {
                                "isAdminOnly": false,
                                "reportOutput": "Trail Documents are secured by temperature control",
                                "type": "SINGLE",
                                "isLive": true,
                                "questionId": "60f2",
                                "meta": "YF",
                                "statement": "Is this facility temperature controlled?",
                                "options": [
                                    "YES",
                                    "NO"
                                ],
                                "sectionId": "form_4_3"
                            }
                        ],
                        "questionId": "b255",
                        "sectionId": "form_4_3"
                    },
                    {
                        "sectionId": "form_4_3",
                        "questionId": "cd15",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "type": "SINGLE",
                        "meta": "MQ",
                        "statement": "Is this facility kept locked with restricted access?",
                        "isLive": false
                    }
                ],
                "id": "form_4_3",
                "title": "ARCHIVE FACILITIES"
            }
        ],
        "shortName": "Facilities",
        "index": 4,
        "title": "Site Facilities",
        "sectionId": "form_4"
    },
    {
        "index": 5,
        "isLive": true,
        "title": "Sampling Logistics",
        "subs": [
            {
                "title": "BIOSAMPLE LOGISTICS",
                "id": "form_5_1"
            },
            {
                "questions": [
                    {
                        "type": "SINGLE",
                        "isLive": true,
                        "followUps": [
                            {
                                "meta": "YF",
                                "sectionId": "form_5",
                                "statement": "Attach accreditation",
                                "isLive": true,
                                "questionId": "6a76",
                                "type": "FILE"
                            }
                        ],
                        "sectionId": "form_5_2",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "statement": "Is your facility GLP certified? If yes, attach accreditation?",
                        "meta": "MQ",
                        "questionId": "c390"
                    },
                    {
                        "statement": "Will the staff member(s) handling human specimen samples shipment be willing to undertake training?",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "sectionId": "form_5_2",
                        "type": "SINGLE",
                        "isLive": true,
                        "questionId": "994f",
                        "meta": "MQ"
                    },
                    {
                        "statement": "Can the site collect and process PK and PD samples 24/7",
                        "type": "SINGLE",
                        "sectionId": "form_5_2",
                        "isLive": true,
                        "questionId": "5745",
                        "meta": "MQ",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "followUps": [
                            {
                                "statement": "Provide the days of the week that you can collect and process PK and PD samples?",
                                "isAdminOnly": false,
                                "questionId": "b830",
                                "sectionId": "form_5_2",
                                "meta": "NF",
                                "options": [
                                    "Mondays",
                                    "Tuesdays",
                                    "Wednesdays",
                                    "Thursdays",
                                    "Friday",
                                    "Saturday",
                                    "Public Holidays"
                                ],
                                "isLive": true,
                                "type": "MULTIPLE"
                            }
                        ]
                    },
                    {
                        "meta": "MQ",
                        "sectionId": "form_5_2",
                        "statement": "What is your preference - electronic or paper Lab Requisition Form (LRF), please provide option?",
                        "options": [
                            "Paper",
                            "Electronic",
                            "No Preference"
                        ],
                        "isLive": true,
                        "type": "SINGLE",
                        "questionId": "fc9a"
                    },
                    {
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "isLive": true,
                        "followUps": [
                            {
                                "meta": "NF",
                                "type": "SHORT",
                                "isLive": true,
                                "sectionId": "form_5",
                                "statement": "If No, what are the limitations, provide further details",
                                "questionId": "ea24"
                            }
                        ],
                        "type": "SINGLE",
                        "meta": "MQ",
                        "statement": "Do you have the capability to access online portals from central lab vendor (kit provider) e.g. for ordering kits, downloading results, viewing lab manuals and training materials, lab requisition forms, query resolution?",
                        "sectionId": "form_5_2",
                        "questionId": "cbdd"
                    },
                    {
                        "isAdminOnly": false,
                        "isLive": true,
                        "questionId": "765a",
                        "sectionId": "form_5_0",
                        "meta": "MQ",
                        "statement": "Who is responsible for reviewing out of range lab values to decide if clinically relevant?",
                        "type": "SHORT"
                    },
                    {
                        "type": "SINGLE",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "sectionId": "form_5_2",
                        "meta": "MQ",
                        "isLive": true,
                        "statement": "Are there documented, auditable site procedures and processes in place to track samples while they are on site?",
                        "followUps": [
                            {
                                "questionId": "3aae",
                                "isLive": true,
                                "sectionId": "form_5",
                                "statement": "If Yes please provide details",
                                "type": "SHORT",
                                "meta": "YF"
                            }
                        ],
                        "questionId": "1660"
                    },
                    {
                        "isLive": true,
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "meta": "MQ",
                        "sectionId": "form_5_2",
                        "questionId": "a51f",
                        "statement": "Do you routinely maintain a record of all stored samples?",
                        "type": "SINGLE"
                    },
                    {
                        "statement": "Do you have freezers available on site ",
                        "sectionId": "form_5_2",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "followUps": [
                            {
                                "sectionId": "form_5_2",
                                "isAdminOnly": false,
                                "statement": "Number of -20°C freezers available.",
                                "meta": "YF",
                                "type": "INTEGER",
                                "isLive": true,
                                "questionId": "23c1"
                            },
                            {
                                "isAdminOnly": false,
                                "sectionId": "form_5_2",
                                "type": "SHORT",
                                "statement": "Number of -70°C freezers available.",
                                "meta": "YF",
                                "isLive": true,
                                "questionId": "3ae8"
                            },
                            {
                                "type": "SHORT",
                                "isLive": true,
                                "sectionId": "form_5_2",
                                "meta": "YF",
                                "statement": "Number of -80°C freezers available.",
                                "questionId": "d2f5",
                                "isAdminOnly": false
                            },
                            {
                                "questionId": "6769",
                                "isLive": true,
                                "isAdminOnly": false,
                                "sectionId": "form_5_2",
                                "type": "LIST",
                                "statement": "Please specify what other freezer are available",
                                "meta": "YF"
                            }
                        ],
                        "isLive": true,
                        "questionId": "6a2c",
                        "type": "SINGLE",
                        "meta": "MQ"
                    },
                    {
                        "meta": "MQ",
                        "questionId": "cd50",
                        "statement": "Is there an automatic warning system on the freezer to alert staff of problems?",
                        "isLive": true,
                        "sectionId": "form_5_2",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "type": "SINGLE"
                    },
                    {
                        "statement": "What is the system of control of these freezers in case of temperture excursion?",
                        "meta": "MQ",
                        "sectionId": "form_5_2",
                        "type": "SHORT",
                        "isLive": true,
                        "questionId": "e5e7"
                    },
                    {
                        "isLive": true,
                        "questionId": "75e2",
                        "type": "SINGLE",
                        "sectionId": "form_5_2",
                        "statement": " Is a temperature log maintained etc.,?",
                        "meta": "MQ",
                        "options": [
                            "YES",
                            "NO"
                        ]
                    },
                    {
                        "statement": "What is the frequency of monitoring the temperature ",
                        "meta": "MQ",
                        "type": "SINGLE",
                        "sectionId": "form_5_2",
                        "isLive": true,
                        "options": [
                            "Twice Daily",
                            "Daily",
                            "Weekly"
                        ],
                        "questionId": "45b0"
                    },
                    {
                        "isLive": true,
                        "statement": "For how long are records kept?",
                        "type": "SHORT",
                        "sectionId": "form_5_2",
                        "questionId": "9883",
                        "meta": "MQ"
                    },
                    {
                        "sectionId": "form_5_2",
                        "questionId": "bca6",
                        "statement": "Is there a back-up generator,solar power or other back-up specifically for the management of Bio-samples in temperature controlled conditions?",
                        "meta": "MQ",
                        "type": "SINGLE",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "isLive": true
                    },
                    {
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "questionId": "9abf",
                        "meta": "MQ",
                        "statement": "Do you have a centrifuge on site?",
                        "type": "SINGLE",
                        "sectionId": "form_5_2",
                        "isLive": true
                    },
                    {
                        "type": "SINGLE",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "sectionId": "form_5_2",
                        "meta": "MQ",
                        "statement": "Do you have a refrigerated centrifuge on site?",
                        "questionId": "46e8",
                        "isLive": true
                    },
                    {
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "questionId": "a090",
                        "meta": "MQ",
                        "type": "SINGLE",
                        "isLive": true,
                        "statement": "Can it be it adjustable? e.g. for temperature and g -force",
                        "sectionId": "form_5_2"
                    },
                    {
                        "statement": "What size tubes do you have for collection and storage of samples (Blood, Plasma, Serum etc.)?",
                        "sectionId": "form_5_2",
                        "questionId": "639f",
                        "meta": "MQ",
                        "isLive": true,
                        "options": [
                            "10ml",
                            "5ml",
                            "2ml",
                            "1ml",
                            "Other"
                        ],
                        "type": "MULTIPLE"
                    },
                    {
                        "isAdminOnly": false,
                        "type": "LIST",
                        "isLive": true,
                        "meta": "MQ",
                        "sectionId": "form_5_0",
                        "questionId": "b003",
                        "statement": "Other tube sizes available"
                    },
                    {
                        "statement": "What is your capacity for storage of BIOLOGICAL samples (blood/stools/saliva/urine etc.) ",
                        "isLive": true,
                        "questionId": "5575",
                        "sectionId": "form_5_2",
                        "meta": "MQ",
                        "type": "SHORT"
                    },
                    {
                        "meta": "MQ",
                        "questionId": "7d4f",
                        "isLive": true,
                        "type": "LONG",
                        "sectionId": "form_5_2",
                        "statement": "Describe your process for Service, Calibration, Maintenance and documentation for  all LABORATORY equipment, freezers etc., and documentation at the site?"
                    },
                    {
                        "options": [
                            "Monthly",
                            "Quarterly",
                            "As required by each protocol"
                        ],
                        "sectionId": "form_5_2",
                        "type": "MULTIPLE",
                        "meta": "MQ",
                        "questionId": "c586",
                        "isLive": true,
                        "statement": "What is your preference for shipment frequency?   Check all options that apply."
                    },
                    {
                        "meta": "MQ",
                        "sectionId": "form_5_2",
                        "questionId": "54f6",
                        "statement": "What days can you ship samples?",
                        "isLive": true,
                        "type": "MULTIPLE",
                        "options": [
                            "Mondays",
                            "Tuesday",
                            "Wednesday",
                            "Thursday",
                            "Friday",
                            "Saturday",
                            "Sunday",
                            "Public Holidays"
                        ]
                    },
                    {
                        "type": "SINGLE",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "sectionId": "form_5_2",
                        "questionId": "e57d",
                        "meta": "MQ",
                        "statement": "Does your site have experience of shipping samples to another country for analysis?",
                        "isLive": true
                    },
                    {
                        "sectionId": "form_5_2",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "type": "SINGLE",
                        "questionId": "22c8",
                        "statement": "Does your centre have the capability for packing of samples, including access to dry ice?",
                        "meta": "MQ",
                        "isLive": true
                    },
                    {
                        "sectionId": "form_5_2",
                        "statement": "As per your process, is remaining sample destroyed after analyses? ",
                        "isLive": true,
                        "questionId": "cde0",
                        "type": "SINGLE",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "meta": "MQ"
                    },
                    {
                        "meta": "MQ",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "type": "SINGLE",
                        "statement": "Can we store remaining samples until end of study?",
                        "isLive": true,
                        "questionId": "cc53",
                        "sectionId": "form_5_2"
                    },
                    {
                        "sectionId": "form_5_2",
                        "questionId": "eca8",
                        "isLive": true,
                        "statement": "Are you able to provide documented evidence of sample destruction? Provide sample destruction  process document.",
                        "type": "SINGLE",
                        "meta": "MQ",
                        "followUps": [
                            {
                                "sectionId": "form_5",
                                "meta": "YF",
                                "isLive": true,
                                "type": "FILE",
                                "statement": "Attach document",
                                "questionId": "a00b"
                            }
                        ],
                        "options": [
                            "YES",
                            "NO"
                        ]
                    },
                    {
                        "sectionId": "form_5_2",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "isLive": true,
                        "questionId": "09ae",
                        "meta": "MQ",
                        "statement": "Would site like to receive lab flow chart in local language?",
                        "type": "SINGLE"
                    }
                ],
                "title": "BIOSAMPLE MANAGEMENT",
                "id": "form_5_2"
            },
            {
                "questions": [
                    {
                        "followUps": [
                            {
                                "sectionId": "form_5",
                                "statement": "Can the pathology lab receive samples 24/7? ",
                                "isLive": true,
                                "meta": "YF",
                                "options": [
                                    "YES",
                                    "NO"
                                ],
                                "type": "SINGLE",
                                "questionId": "8f22"
                            },
                            {
                                "sectionId": "form_5",
                                "statement": "If not co-located, confirm there is a local pathology service available",
                                "meta": "NF",
                                "options": [
                                    "YES",
                                    "NO"
                                ],
                                "questionId": "d33c",
                                "type": "SINGLE",
                                "isLive": true
                            },
                            {
                                "questionId": "57ab",
                                "isAdminOnly": false,
                                "type": "MULTIPLE",
                                "meta": "YF",
                                "options": [
                                    "Monday",
                                    "Tuesday",
                                    "Wednesday",
                                    "Thursday",
                                    "Friday",
                                    "Saturday",
                                    "Sunday",
                                    "Public Holidays"
                                ],
                                "isLive": true,
                                "sectionId": "form_5_3",
                                "statement": "If not 24/7, provide the days of the week that the lab can receive samples?"
                            }
                        ],
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "questionId": "3a0e",
                        "meta": "MQ",
                        "sectionId": "form_5_3",
                        "type": "SINGLE",
                        "isLive": true,
                        "statement": "Do you have a pathology lab co-located at your site?"
                    },
                    {
                        "statement": "Do you routinely carry out Haematoxylin & Eosin staining?",
                        "meta": "MQ",
                        "sectionId": "form_5_3",
                        "reportOutput": "H & E Staining",
                        "questionId": "9cb5",
                        "isLive": true,
                        "type": "SINGLE",
                        "options": [
                            "YES",
                            "NO"
                        ]
                    },
                    {
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "statement": "Do you have dedicated storage facility for tissues?",
                        "meta": "MQ",
                        "sectionId": "form_5_3",
                        "isLive": true,
                        "type": "SINGLE",
                        "questionId": "809c"
                    },
                    {
                        "questionId": "776b",
                        "statement": "Can you store formalin vials at 4°C?\n",
                        "sectionId": "form_5_3",
                        "isLive": true,
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "type": "SINGLE",
                        "meta": "MQ"
                    },
                    {
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "statement": "Do you have the facilities for tissue embedding?",
                        "type": "SINGLE",
                        "isLive": true,
                        "questionId": "9f07",
                        "sectionId": "form_5_3",
                        "reportOutput": "Tissue embedding",
                        "meta": "MQ"
                    },
                    {
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "questionId": "ce01",
                        "meta": "MQ",
                        "followUps": [
                            {
                                "meta": "YF",
                                "questionId": "8d8c",
                                "statement": "If Yes, specify instrumentation",
                                "reportOutput": "Instrument used for cutting of slides",
                                "isLive": true,
                                "sectionId": "form_5",
                                "options": [
                                    "Sliding - Rotary microtomes",
                                    "Cryostat for cry section",
                                    "RNase free environment",
                                    "Other"
                                ],
                                "type": "SINGLE"
                            },
                            {
                                "sectionId": "form_5",
                                "meta": "YF",
                                "type": "SHORT",
                                "questionId": "44f9",
                                "statement": "If Other instrumentation to cut slides, specify",
                                "isLive": true
                            }
                        ],
                        "reportOutput": "Collection to provision of slides",
                        "isLive": true,
                        "statement": "Do you have the facilities to cut slides in your Lab?  ",
                        "type": "SINGLE",
                        "sectionId": "form_5_3"
                    },
                    {
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "statement": "Can you section tissue to different thicknesses from one block? (i.e. 2.5um, 4um. 5um)",
                        "meta": "MQ",
                        "questionId": "5f71",
                        "isLive": true,
                        "type": "SINGLE",
                        "sectionId": "form_5_3"
                    },
                    {
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "meta": "MQ",
                        "reportOutput": "Fresh Frozen tissue",
                        "isLive": true,
                        "sectionId": "form_5_3",
                        "questionId": "af21",
                        "type": "SINGLE",
                        "statement": "Can your site process biopsies as Fresh Frozen tissue?"
                    },
                    {
                        "isLive": true,
                        "sectionId": "form_5_3",
                        "questionId": "1671",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "statement": "Can your facility collect a biopsy, embed into FFPE and send sections to analysis lab, if IHC testing is required for patient recruitment, within 48 hours?",
                        "followUps": [
                            {
                                "isLive": true,
                                "questionId": "9513",
                                "statement": "If not 48 hours, please provide the shortest time frame you could ship slides from your facility.",
                                "sectionId": "form_5",
                                "meta": "NF",
                                "type": "SHORT"
                            }
                        ],
                        "type": "SINGLE",
                        "meta": "MQ"
                    },
                    {
                        "meta": "MQ",
                        "sectionId": "form_5_3",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "reportOutput": "Storage of archival block at site",
                        "isLive": true,
                        "type": "SINGLE",
                        "questionId": "3968",
                        "followUps": [
                            {
                                "isLive": true,
                                "sectionId": "form_5",
                                "options": [
                                    "YES",
                                    "NO"
                                ],
                                "statement": "If you do not store the block at your lab, does your site have a process for obtaining archival tissue (blocks or slides) from external pathology facilities?",
                                "meta": "NF",
                                "type": "SINGLE",
                                "questionId": "31ad"
                            }
                        ],
                        "statement": "Do you store the archival tissue block for your patients at site?"
                    },
                    {
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "statement": "Would you be able to cut a small number of slides (e.g. 5) and send to one vendor for patient enrolment, and then if the patient is enrolled send the block to another lab ",
                        "isLive": true,
                        "meta": "MQ",
                        "questionId": "ad90",
                        "sectionId": "form_5_3",
                        "type": "SINGLE"
                    },
                    {
                        "reportOutput": "Ability to send slides to one vendor and block to another",
                        "followUps": [
                            {
                                "type": "SINGLE",
                                "options": [
                                    "YES",
                                    "NO"
                                ],
                                "meta": "NF",
                                "questionId": "85b6",
                                "sectionId": "form_5",
                                "statement": "If not, is there a site policy on the  number of slides can you send? ",
                                "isLive": true
                            },
                            {
                                "questionId": "52d3",
                                "isLive": true,
                                "sectionId": "form_5",
                                "statement": "If Yes, what is the maximum?",
                                "reportOutput": "Maximum archival tissue/blocks to be sent from the site to third party",
                                "type": "SHORT",
                                "meta": "YF"
                            }
                        ],
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "isLive": true,
                        "statement": "Does your site policy allow archival tissue and FFPE blocks be sent from your site, If our clinical protocol requires it?",
                        "type": "SINGLE",
                        "sectionId": "form_5_3",
                        "meta": "MQ",
                        "questionId": "3357"
                    },
                    {
                        "statement": "For protocols that mandate tissue collection when safe and accessible, and when archival tissue is not available, how often do your patients agree to a pre-treatment or post-treatment biopsy?",
                        "type": "LONG",
                        "meta": "MQ",
                        "questionId": "7b3a",
                        "isLive": true,
                        "sectionId": "form_5_3"
                    },
                    {
                        "meta": "MQ",
                        "statement": "On average, how long does it take (in days) to schedule and conduct an on-treatment biopsy?",
                        "isLive": true,
                        "questionId": "1e38",
                        "reportOutput": "Time to schedule an on-treatment biopsy",
                        "type": "SHORT",
                        "sectionId": "form_5_3"
                    },
                    {
                        "statement": "On average, how many days does it take to schedule and conduct a pre-treatment biopsy once a patient has consented?",
                        "sectionId": "form_5_3",
                        "isLive": true,
                        "questionId": "3db7",
                        "reportOutput": "Number of days from patient consent to achieving a pre-treatment biopsy ",
                        "type": "SHORT",
                        "meta": "MQ"
                    },
                    {
                        "meta": "MQ",
                        "statement": "For protocols that mandate tissue collection when safe and accessible how often do your patients agree to a biopsy once it has been assessed their cancer has progressed on the current treatment?",
                        "type": "LONG",
                        "questionId": "bb02",
                        "sectionId": "form_5_3",
                        "isLive": true
                    }
                ],
                "id": "form_5_3",
                "title": "TUMOUR TISSUE PROCESSING"
            },
            {
                "title": "LOCAL SITE ASSAY AVAILABILITY",
                "questions": [
                    {
                        "isLive": true,
                        "type": "MULTIPLE",
                        "statement": "What tests does your facility routinely perform?",
                        "sectionId": "form_5_5",
                        "options": [
                            "Haematology",
                            "Biochemistry",
                            "Urinalysis",
                            "Microbiology",
                            "Microscopy analysis",
                            "Biomarkers",
                            "Genetic testing",
                            "Other"
                        ],
                        "meta": "MQ",
                        "questionId": "917e"
                    },
                    {
                        "statement": "Other tests",
                        "questionId": "309b",
                        "meta": "MQ",
                        "isLive": true,
                        "sectionId": "form_5_0",
                        "type": "LIST",
                        "isAdminOnly": false
                    },
                    {
                        "questionId": "7022",
                        "isLive": true,
                        "type": "SINGLE",
                        "meta": "MQ",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "statement": "Does your facility have the capability for sample collection for genetic testing?",
                        "sectionId": "form_5_5"
                    },
                    {
                        "questionId": "f602",
                        "statement": "Does your facility have the capability for sample collection for Other specific testing? ",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "sectionId": "form_5_5",
                        "type": "SINGLE",
                        "isLive": true,
                        "meta": "MQ"
                    },
                    {
                        "isLive": true,
                        "type": "SINGLE",
                        "sectionId": "form_5_5",
                        "meta": "MQ",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "statement": "Does the site's laboratory have the capability to transfer lab data electronically according to client specifications?",
                        "questionId": "deac"
                    },
                    {
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "sectionId": "form_5_5",
                        "type": "SINGLE",
                        "statement": "Have you previously used central labs for specialist testing?",
                        "isLive": true,
                        "meta": "MQ",
                        "questionId": "4f4c"
                    }
                ],
                "id": "form_5_5"
            }
        ],
        "sectionId": "form_5",
        "shortName": "Sampling"
    },
    {
        "sectionId": "form_6",
        "shortName": "P & S",
        "isLive": true,
        "index": 6,
        "subs": [
            {
                "questions": [
                    {
                        "reportOutput": "Experience of Investigational Medicinal Product (IMP) management",
                        "questionId": "a2e5",
                        "type": "SINGLE",
                        "statement": "Does your site have experience of Investigational Medicinal Product (IMP) management?",
                        "meta": "MQ",
                        "isLive": true,
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "sectionId": "form_6_1"
                    },
                    {
                        "followUps": [
                            {
                                "questionId": "8d0e",
                                "statement": "Please provide details",
                                "meta": "OF",
                                "isLive": true,
                                "sectionId": "form_6_1",
                                "type": "SHORT"
                            }
                        ],
                        "reportOutput": "Responsible party for IMP Management",
                        "meta": "MQ",
                        "sectionId": "form_6_1",
                        "questionId": "2125",
                        "statement": "Who will be responsible for IMP Management - hospital pharmacy or other? ",
                        "type": "SINGLE",
                        "options": [
                            "Hospital Pharmacy",
                            "Other"
                        ],
                        "isLive": true
                    },
                    {
                        "questionId": "d31b",
                        "isLive": true,
                        "statement": "Provide the address where IMP should be shipped to.",
                        "sectionId": "form_6_1",
                        "reportOutput": "Shipment address for IMP",
                        "meta": "MQ",
                        "type": "SHORT"
                    },
                    {
                        "isLive": true,
                        "statement": "Who would dispense drug supplies at the site",
                        "questionId": "f8a8",
                        "type": "SINGLE",
                        "meta": "MQ",
                        "options": [
                            "Pharmacist",
                            "Investigator",
                            "Other"
                        ],
                        "followUps": [
                            {
                                "statement": "Please provide details",
                                "type": "SHORT",
                                "meta": "OF",
                                "questionId": "7a81",
                                "isLive": true,
                                "sectionId": "form_6_1"
                            }
                        ],
                        "sectionId": "form_6_1",
                        "reportOutput": "Drug supplies dispensing responsibility "
                    },
                    {
                        "isLive": true,
                        "meta": "MQ",
                        "sectionId": "form_6_1",
                        "type": "SINGLE",
                        "reportOutput": "Experience with electronic Randomization and Trial Supply Management System used for accountability, IMP receipt acknowledgement",
                        "questionId": "d81e",
                        "statement": "Does the site have experience with electronic Randomization and Trial Supply Management System used for accountability, IMP receipt acknowledgement?",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "followUps": [
                            {
                                "isLive": true,
                                "questionId": "8442",
                                "sectionId": "form_6",
                                "type": "LONG",
                                "statement": "Please provide details of Randomisation and Trial Supply Management system used",
                                "meta": "YF"
                            }
                        ]
                    },
                    {
                        "type": "SINGLE",
                        "isLive": true,
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "statement": "Is the pharmacy GMP accredited? ",
                        "sectionId": "form_6_1",
                        "questionId": "bc89",
                        "meta": "MQ",
                        "reportOutput": "Pharmacy GMP accredited"
                    },
                    {
                        "isLive": true,
                        "reportOutput": "Qualified Person on site",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "meta": "MQ",
                        "questionId": "ddb1",
                        "sectionId": "form_6_1",
                        "statement": "Do you have a Qualified Person?",
                        "type": "SINGLE"
                    },
                    {
                        "questionId": "8ffd",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "statement": "Is there a designated clinical trials pharmacist?",
                        "reportOutput": "Designated clinical trials pharmacist",
                        "meta": "MQ",
                        "type": "SINGLE",
                        "isLive": true,
                        "sectionId": "form_6_1"
                    },
                    {
                        "statement": "Is there adequate holiday/sickness cover?",
                        "meta": "MQ",
                        "isLive": true,
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "type": "SINGLE",
                        "questionId": "0f9f",
                        "sectionId": "form_6_1"
                    },
                    {
                        "statement": "List the formulation types you have experience with",
                        "options": [
                            "Tablets",
                            "Injectables",
                            "Infusions",
                            "Inhaled",
                            "Others"
                        ],
                        "isLive": true,
                        "questionId": "be5c",
                        "sectionId": "form_6_1",
                        "meta": "MQ",
                        "type": "MULTIPLE",
                        "followUps": [
                            {
                                "questionId": "469b",
                                "statement": "Please provide details",
                                "isLive": true,
                                "type": "SHORT",
                                "sectionId": "form_6_1",
                                "meta": "OF"
                            }
                        ]
                    },
                    {
                        "isLive": true,
                        "meta": "MQ",
                        "isAdminOnly": false,
                        "statement": "If Others, please provide details",
                        "questionId": "8e93",
                        "sectionId": "form_6_0",
                        "type": "LIST"
                    },
                    {
                        "options": [
                            "Tablets",
                            "Injectables",
                            "Infusions",
                            "Inhaled",
                            "Others"
                        ],
                        "statement": "What types of formulation is the Pharmacy permitted to prepare?",
                        "isLive": true,
                        "questionId": "cc16",
                        "sectionId": "form_6_1",
                        "type": "MULTIPLE",
                        "meta": "MQ"
                    },
                    {
                        "sectionId": "form_6_0",
                        "questionId": "e7fb",
                        "isLive": true,
                        "statement": "If Others, please provide details",
                        "type": "LIST",
                        "isAdminOnly": false,
                        "meta": "MQ"
                    },
                    {
                        "isLive": true,
                        "statement": "Is there flexibility to handle drug requirements, (e.g. Light Sensitive medication, Radiolabelled drugs, Cytotoxic drugs, Controlled substances, Others)",
                        "options": [
                            "Light Sensitive medication",
                            "Radiolabelled drugs",
                            "Cytotoxic drugs",
                            "Controlled Substances",
                            "Others"
                        ],
                        "meta": "MQ",
                        "type": "MULTIPLE",
                        "sectionId": "form_6_1",
                        "questionId": "b894",
                        "followUps": []
                    },
                    {
                        "sectionId": "form_6_0",
                        "questionId": "e940",
                        "isAdminOnly": false,
                        "isLive": true,
                        "meta": "MQ",
                        "statement": "If Others, please provide details",
                        "type": "LIST"
                    },
                    {
                        "meta": "MQ",
                        "isLive": true,
                        "statement": "Are temperature logs monitored and records maintained?",
                        "type": "SINGLE",
                        "sectionId": "form_6_1",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "questionId": "1110",
                        "reportOutput": "Adequate logs and records"
                    },
                    {
                        "sectionId": "form_6_1",
                        "reportOutput": "Temperature out of specified range - action taken",
                        "isLive": true,
                        "type": "SHORT",
                        "statement": "What happens if temperature falls out of a specified range?",
                        "meta": "MQ",
                        "questionId": "7391"
                    },
                    {
                        "questionId": "ced5",
                        "statement": "Who will have access to the drugs?",
                        "isLive": true,
                        "sectionId": "form_6_1",
                        "type": "SHORT",
                        "meta": "MQ",
                        "reportOutput": "Access to the trail drugs"
                    },
                    {
                        "sectionId": "form_6_1",
                        "isLive": true,
                        "reportOutput": "How is access to trail drugs controlled",
                        "statement": "How is the access to your drug storage area controlled?",
                        "questionId": "1429",
                        "type": "SHORT",
                        "meta": "MQ"
                    },
                    {
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "sectionId": "form_6_1",
                        "type": "SINGLE",
                        "isLive": true,
                        "questionId": "8f97",
                        "meta": "MQ",
                        "followUps": [
                            {
                                "type": "FILE",
                                "isAdminOnly": false,
                                "sectionId": "form_6_1",
                                "isLive": true,
                                "questionId": "3066",
                                "statement": "Attach details of drug dispensing procedures",
                                "meta": "YF"
                            }
                        ],
                        "statement": "Does the site have drug dispensing procedures in place? If Yes, attach details of drug dispensing procedures"
                    },
                    {
                        "meta": "MQ",
                        "statement": "Are you able to carry out procedures for unblinding due to SAEs?",
                        "isLive": true,
                        "type": "SINGLE",
                        "sectionId": "form_6_1",
                        "questionId": "f4fe",
                        "options": [
                            "YES",
                            "NO"
                        ]
                    },
                    {
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "sectionId": "form_6_1",
                        "statement": "Does the site agree to observation of drug administration by the monitor?",
                        "isLive": true,
                        "meta": "MQ",
                        "questionId": "f4ab",
                        "type": "SINGLE"
                    },
                    {
                        "type": "SINGLE",
                        "sectionId": "form_6_1",
                        "isLive": true,
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "statement": "Do you have facilities that allow for good segregation of IMPs and separate from other trial medication?",
                        "questionId": "ce02",
                        "meta": "MQ"
                    },
                    {
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "meta": "MQ",
                        "isLive": true,
                        "questionId": "f7e4",
                        "sectionId": "form_6_1",
                        "statement": "Do you have the facilities to ensure that IMPs returned by subjects or have expired are stored separately from unused IMPs?",
                        "type": "SINGLE"
                    },
                    {
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "isLive": true,
                        "type": "SINGLE",
                        "statement": "Do you maintain your own drug accountability records for trial medications?",
                        "questionId": "5630",
                        "meta": "MQ",
                        "sectionId": "form_6_1"
                    },
                    {
                        "statement": "Do you have a dedicated area for freezers/fridges to maintain IMP in accordance with the manufacturers instructions?  ",
                        "isLive": true,
                        "followUps": [
                            {
                                "sectionId": "form_6",
                                "statement": "Where are these stored",
                                "questionId": "1c11",
                                "meta": "NF",
                                "isLive": true,
                                "type": "SHORT"
                            },
                            {
                                "isLive": true,
                                "questionId": "84a4",
                                "sectionId": "form_6",
                                "meta": "YF",
                                "type": "SHORT",
                                "statement": "Provide details of other freezer type"
                            },
                            {
                                "questionId": "fd7a",
                                "type": "SHORT",
                                "statement": "Number of fridges/freezers ",
                                "sectionId": "form_6",
                                "isLive": true,
                                "meta": "YF"
                            }
                        ],
                        "reportOutput": "Dedicated are in freezers/fridges for IMP",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "meta": "MQ",
                        "questionId": "70cb",
                        "type": "SINGLE",
                        "sectionId": "form_6_1"
                    },
                    {
                        "followUps": [
                            {
                                "isLive": true,
                                "sectionId": "form_6",
                                "meta": "NF",
                                "type": "SHORT",
                                "statement": "Where is this performed?",
                                "questionId": "da8c"
                            }
                        ],
                        "isLive": true,
                        "questionId": "9551",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "statement": "Do you have a dedicated area for reconstitution of IMP? ",
                        "meta": "MQ",
                        "type": "SINGLE",
                        "sectionId": "form_6_1"
                    },
                    {
                        "followUps": [
                            {
                                "questionId": "f69e",
                                "sectionId": "form_6",
                                "meta": "YF",
                                "type": "SHORT",
                                "isLive": true,
                                "statement": "Please briefly detail the procedure that you use. "
                            }
                        ],
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "questionId": "e077",
                        "isLive": true,
                        "reportOutput": "Experience with reconstitution of IMP with a diluent to produce a suspension of the required dose (e.g. if provided in another form or as a Bulk Drug Substance (e.g. powder).",
                        "statement": "Do you have experience with reconstitution of IMP with a diluent to produce a suspension of the required dose (e.g. if provided in another form or as a Bulk Drug Substance (e.g. powder).",
                        "sectionId": "form_6_1",
                        "meta": "MQ",
                        "type": "SINGLE"
                    },
                    {
                        "questionId": "ffc7",
                        "statement": "Can IMP destruction be done at site? ",
                        "isLive": true,
                        "meta": "MQ",
                        "reportOutput": "Site facilities are present for IMP destruction",
                        "followUps": [
                            {
                                "statement": "What is the process fo IMP destruction?",
                                "meta": "YF",
                                "sectionId": "form_6",
                                "questionId": "8cf2",
                                "type": "LONG",
                                "isLive": true
                            }
                        ],
                        "type": "SINGLE",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "sectionId": "form_6_1"
                    }
                ],
                "id": "form_6_1",
                "title": "Pharmacy"
            },
            {
                "questions": [
                    {
                        "isLive": true,
                        "sectionId": "form_6_2",
                        "statement": "Is there medical cover for 24 hour contact?",
                        "meta": "MQ",
                        "type": "SINGLE",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "questionId": "663e"
                    },
                    {
                        "statement": "Where would any emergency code breaks information be stored?",
                        "sectionId": "form_6_2",
                        "meta": "MQ",
                        "questionId": "577e",
                        "type": "SHORT",
                        "isLive": true
                    },
                    {
                        "type": "LONG",
                        "sectionId": "form_6_2",
                        "statement": "What is the procedure in the event of a medical emergency? Please attach your written procedure",
                        "isLive": true,
                        "questionId": "9bf9",
                        "meta": "MQ"
                    },
                    {
                        "isLive": true,
                        "questionId": "bd32",
                        "sectionId": "form_6_0",
                        "type": "FILE",
                        "isAdminOnly": false,
                        "statement": "Attach file",
                        "meta": "MQ"
                    },
                    {
                        "statement": "How far away is the nearest emergency treatment facility?  ",
                        "meta": "MQ",
                        "questionId": "12f9",
                        "isLive": true,
                        "sectionId": "form_6_2",
                        "type": "SHORT"
                    },
                    {
                        "isLive": true,
                        "statement": "Does the site have a specific agreement with this emergency treatment facility?  ",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "meta": "MQ",
                        "questionId": "5b3a",
                        "type": "SINGLE",
                        "sectionId": "form_6_2"
                    },
                    {
                        "meta": "MQ",
                        "statement": "What procedures are in place to monitor subject compliance and safety?",
                        "isLive": true,
                        "questionId": "caf4",
                        "type": "SHORT",
                        "sectionId": "form_6_2"
                    },
                    {
                        "questionId": "ec72",
                        "statement": "What information are the subjects given on being discharged from the unit between study visits?",
                        "meta": "MQ",
                        "isLive": true,
                        "type": "SHORT",
                        "sectionId": "form_6_2"
                    },
                    {
                        "type": "SHORT",
                        "questionId": "2ce0",
                        "isLive": true,
                        "statement": "What instructions are subjects given if they want to see the Investigator immediately?",
                        "meta": "MQ",
                        "sectionId": "form_6_2"
                    },
                    {
                        "type": "SINGLE",
                        "sectionId": "form_6_2",
                        "followUps": [
                            {
                                "type": "SINGLE",
                                "options": [
                                    "Daily",
                                    "Weekly",
                                    "Every 2 weeks",
                                    "Monthly",
                                    "Quarterly ",
                                    "Yearly",
                                    "Other"
                                ],
                                "isLive": true,
                                "sectionId": "form_6",
                                "meta": "YF",
                                "questionId": "c876",
                                "statement": "If Yes, how often is emergency resuscitation equipment serviced and checked?"
                            },
                            {
                                "meta": "YF",
                                "type": "SHORT",
                                "questionId": "e03c",
                                "sectionId": "form_6",
                                "isLive": true,
                                "statement": "If Other, provide details"
                            },
                            {
                                "meta": "YF",
                                "questionId": "22f2",
                                "isLive": true,
                                "sectionId": "form_6",
                                "type": "MULTIPLE_STAFFS",
                                "statement": "Which staff are trained to use the emergency resuscitation equipment?"
                            },
                            {
                                "statement": "How many staff are trained to use it? ",
                                "type": "INTEGER",
                                "meta": "YF",
                                "questionId": "b015",
                                "isLive": true,
                                "sectionId": "form_6"
                            },
                            {
                                "type": "SINGLE",
                                "questionId": "d884",
                                "isLive": true,
                                "options": [
                                    "YES",
                                    "NO"
                                ],
                                "statement": "Is the emergency resuscitation equipment stored in an accessible place?",
                                "meta": "YF",
                                "sectionId": "form_6"
                            }
                        ],
                        "isLive": true,
                        "meta": "MQ",
                        "statement": "Does the site have emergency resuscitation equipment on site?",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "questionId": "4664"
                    },
                    {
                        "meta": "MQ",
                        "sectionId": "form_6_2",
                        "type": "SINGLE",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "questionId": "250d",
                        "isLive": true,
                        "statement": "Are subjects informed of evacuation procedures?"
                    },
                    {
                        "statement": " Are all fire doors kept clear?",
                        "meta": "MQ",
                        "sectionId": "form_6_2",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "questionId": "8b5c",
                        "type": "SINGLE",
                        "isLive": true
                    },
                    {
                        "sectionId": "form_6_2",
                        "isLive": true,
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "statement": " Is fire equipment regularly serviced? ",
                        "type": "SINGLE",
                        "meta": "MQ",
                        "questionId": "ac2b"
                    },
                    {
                        "questionId": "0c53",
                        "meta": "YF",
                        "type": "SINGLE",
                        "isAdminOnly": false,
                        "statement": "Are all staff trained to use it?",
                        "isLive": true,
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "sectionId": "form_6_2"
                    }
                ],
                "title": "EMERGENCY PROCEDURES",
                "id": "form_6_2"
            }
        ],
        "title": "Pharmacy and Safety"
    },
    {
        "subs": [
            {
                "id": "form_7_1",
                "questions": [
                    {
                        "questionId": "fc4a",
                        "type": "SINGLE",
                        "meta": "MQ",
                        "reportOutput": "Use of central Institutional Ethic Committees (IECs)",
                        "statement": "Do you use  central Institutional Ethic Committees (IECs)?",
                        "isLive": true,
                        "sectionId": "form_7_1",
                        "followUps": [
                            {
                                "type": "SHORT",
                                "questionId": "c2be",
                                "meta": "YF",
                                "statement": "Provide the name of the central IEC you use?",
                                "sectionId": "form_7",
                                "isLive": true
                            },
                            {
                                "options": [
                                    "YES",
                                    "NO"
                                ],
                                "questionId": "f42a",
                                "statement": "Does your hospital have an established IEC it routinely uses?",
                                "isLive": true,
                                "meta": "NF",
                                "type": "SINGLE"
                            },
                            {
                                "questionId": "t6ui",
                                "statement": "Which IEC would you propose to use and why?",
                                "meta": "NF",
                                "type": "SHORT",
                                "isLive": true
                            }
                        ],
                        "options": [
                            "YES",
                            "NO"
                        ]
                    },
                    {
                        "reportOutput": "Composition of the IEC committee in plan to use",
                        "questionId": "8966",
                        "statement": "What is the composition of the IEC committee that you would plan to use?",
                        "isLive": true,
                        "sectionId": "form_7_1",
                        "type": "SHORT",
                        "meta": "MQ"
                    },
                    {
                        "reportOutput": "Written procedures in place",
                        "statement": "Do they have written procedures in place? (check compliance if required)",
                        "meta": "MQ",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "sectionId": "form_7_1",
                        "type": "SINGLE",
                        "isLive": true,
                        "questionId": "549d"
                    },
                    {
                        "isLive": true,
                        "sectionId": "form_7_1",
                        "statement": "Based on your experience in submitting clinical study protocols to your IEC in the past, do you usually get questions, or have to re-submit? ",
                        "reportOutput": "Follow up questions or re-submission",
                        "questionId": "b206",
                        "meta": "MQ",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "followUps": [
                            {
                                "questionId": "2b2a",
                                "type": "LONG",
                                "statement": "Please provide more information on your experience with your IEC and the types of questions, re-submission information",
                                "meta": "YF",
                                "sectionId": "form_7",
                                "isLive": true
                            }
                        ],
                        "type": "SINGLE"
                    },
                    {
                        "questionId": "c089",
                        "meta": "MQ",
                        "statement": "On average, what is the timeline for the submission of documents for the IEC meeting?",
                        "sectionId": "form_7_1",
                        "type": "SHORT",
                        "isLive": true,
                        "reportOutput": "Submission of documents prior to the IEC meeting"
                    },
                    {
                        "type": "SHORT",
                        "statement": "What is the average time to IEC approval (from the initial submission date)?",
                        "reportOutput": "IEC approval (from the initial submission date)",
                        "sectionId": "form_7_1",
                        "isLive": true,
                        "questionId": "cd3a",
                        "meta": "MQ"
                    },
                    {
                        "options": [
                            "Weekly",
                            "Every Two Weeks",
                            "Every Month",
                            "Every 6 Weeks",
                            "Every 2 Month",
                            "Quarterly",
                            "Other"
                        ],
                        "statement": "What is the frequency of the IEC meetings (e.g. weekly, monthly, etc.)?",
                        "sectionId": "form_7_1",
                        "isLive": true,
                        "reportOutput": "Frequency of the IEC meetings ",
                        "type": "SINGLE",
                        "questionId": "add5",
                        "followUps": [
                            {
                                "type": "SHORT",
                                "sectionId": "form_7_1",
                                "isLive": true,
                                "meta": "OF",
                                "isAdminOnly": false,
                                "statement": "Other frequency",
                                "questionId": "da78"
                            }
                        ],
                        "meta": "MQ"
                    },
                    {
                        "isLive": true,
                        "reportOutput": "Preparation time at site for submission of Study documents ",
                        "statement": "What is the length of time that the study documents are required at your site from the sponsor prior to IEC submission to allow for submission preparation, translations, etc.?",
                        "sectionId": "form_7_1",
                        "questionId": "7d7c",
                        "type": "SHORT",
                        "meta": "MQ"
                    },
                    {
                        "reportOutput": "IEC communicates its decision Verbally (weeks)",
                        "meta": "MQ",
                        "options": [
                            "Verbally",
                            "In Writing after the meeting"
                        ],
                        "statement": "How soon will the IEC communicate its decision Verbally (weeks)",
                        "questionId": "770e",
                        "type": "SHORT",
                        "sectionId": "form_7_1",
                        "isLive": true
                    },
                    {
                        "sectionId": "form_7_0",
                        "meta": "MQ",
                        "isLive": true,
                        "questionId": "20dd",
                        "isAdminOnly": false,
                        "statement": "How soon will the IEC communicate its decision in writing after the meeting (weeks)",
                        "type": "INTEGER",
                        "reportOutput": "IEC communicates its decision in writing after the meeting (weeks)"
                    },
                    {
                        "questionId": "41cd",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "followUps": [
                            {
                                "type": "SHORT",
                                "meta": "YF",
                                "isLive": true,
                                "questionId": "f065",
                                "statement": "Please specify the process",
                                "sectionId": "form_7"
                            }
                        ],
                        "statement": "Is there the possibility to gain expedited approval for minor changes? ",
                        "isLive": true,
                        "sectionId": "form_7_1",
                        "type": "SINGLE",
                        "reportOutput": "Expedited approval for minor changes/administrative protocol updates ",
                        "meta": "MQ"
                    },
                    {
                        "sectionId": "form_7_1",
                        "meta": "MQ",
                        "statement": "Are there any aspects of study design that may hinder enrolment and/or a favourable opinion from your ethics committee? For example, adaptive design of  cohorts, placebo controlled studies, sample and/or data export",
                        "isLive": true,
                        "type": "SINGLE",
                        "followUps": [
                            {
                                "sectionId": "form_7",
                                "isLive": true,
                                "statement": "Please provide more information ",
                                "questionId": "9048",
                                "meta": "YF",
                                "type": "SHORT"
                            }
                        ],
                        "reportOutput": "Aspects of study design that may hinder enrolment and/or a favorable opinion from your ethics committee",
                        "questionId": "0d1e",
                        "options": [
                            "YES",
                            "NO"
                        ]
                    },
                    {
                        "meta": "MQ",
                        "sectionId": "form_7_1",
                        "reportOutput": "Turnaround time for approval for amendments/revised consent forms from submission (weeks)",
                        "isLive": true,
                        "type": "INTEGER",
                        "questionId": "3af2",
                        "statement": "What is the turnaround time to approval for amendments and revised consents from submission (weeks)?"
                    },
                    {
                        "type": "VFILE",
                        "sectionId": "form_7_1",
                        "questionId": "a253",
                        "statement": "What documents are required by the IEC for submission? Please list in full and check the box for documents requiring specific signatures or approval to get IEC/IRB approval.",
                        "meta": "MQ",
                        "reportOutput": "Documents required by the IEC for submission",
                        "isLive": true
                    },
                    {
                        "questionId": "fb68",
                        "isLive": true,
                        "meta": "MQ",
                        "sectionId": "form_7_1",
                        "statement": "How many copies of each document are required for submission? ",
                        "type": "INTEGER",
                        "reportOutput": "Number of copies of each document required for submission"
                    },
                    {
                        "reportOutput": "Special formatting requirements",
                        "questionId": "609c",
                        "statement": "Are there any special formatting requirements?",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "isLive": true,
                        "meta": "MQ",
                        "sectionId": "form_7_1",
                        "type": "SINGLE"
                    },
                    {
                        "type": "SINGLE",
                        "followUps": [
                            {
                                "isLive": true,
                                "sectionId": "form_7_1",
                                "meta": "YF",
                                "type": "SHORT",
                                "isAdminOnly": false,
                                "statement": "If Yes, does it need to be draft or final?",
                                "questionId": "300e"
                            }
                        ],
                        "statement": "Do you require the contract for the EC submission?",
                        "reportOutput": "Contract requirement for IEC submission",
                        "isLive": true,
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "questionId": "4f62",
                        "meta": "MQ",
                        "sectionId": "form_7_1"
                    },
                    {
                        "meta": "MQ",
                        "sectionId": "form_7_0",
                        "isLive": true,
                        "reportOutput": "Investigator presence at the IEC meeting to explain the study",
                        "statement": "Will the Investigator be required to be present at the IEC meeting to explain the study?",
                        "type": "SINGLE",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "questionId": "0dd6",
                        "isAdminOnly": false
                    },
                    {
                        "followUps": [
                            {
                                "isAdminOnly": false,
                                "meta": "YF",
                                "statement": "If a copy of the blank CRF is required, is it a condition to approve the study? If yes, please answer the next question.",
                                "questionId": "32db",
                                "isLive": true,
                                "sectionId": "form_7_1",
                                "type": "SHORT"
                            },
                            {
                                "questionId": "c500",
                                "statement": "When does the blank CRF need to be submitted to the IEC (i.e. with the primary submission, or other time, please specify)?",
                                "isAdminOnly": false,
                                "sectionId": "form_7_1",
                                "isLive": true,
                                "meta": "YF",
                                "type": "SHORT"
                            }
                        ],
                        "type": "SINGLE",
                        "questionId": "0625",
                        "statement": "Is a paper copy of the blank CRF required by the IEC? If yes, please answer the next question.",
                        "reportOutput": "Paper copy of the blank CRF required by the IEC",
                        "sectionId": "form_7_1",
                        "isLive": true,
                        "meta": "MQ",
                        "options": [
                            "YES",
                            "NO"
                        ]
                    },
                    {
                        "sectionId": "form_7_1",
                        "type": "SINGLE",
                        "reportOutput": "Local requirement after IEC approval",
                        "followUps": [
                            {
                                "type": "SHORT",
                                "sectionId": "form_7",
                                "isLive": true,
                                "questionId": "fada",
                                "meta": "YF",
                                "statement": "What are the other local requirements before the site can be initiated; e.g. R&D approval, contract signatures? "
                            },
                            {
                                "isLive": true,
                                "statement": "How long does this usually take to get the approval?",
                                "questionId": "e5b3",
                                "type": "INTEGER",
                                "sectionId": "form_7",
                                "meta": "YF"
                            }
                        ],
                        "meta": "MQ",
                        "isLive": true,
                        "statement": "Are there any other local requirements after IEC approval before the site can be initiated? ",
                        "questionId": "5f74",
                        "options": [
                            "YES",
                            "NO"
                        ]
                    },
                    {
                        "isAdminOnly": false,
                        "meta": "MQ",
                        "reportOutput": "Validity of IEC approval until end of the study",
                        "questionId": "ec72",
                        "isLive": true,
                        "followUps": [
                            {
                                "meta": "OF",
                                "type": "INTEGER",
                                "isLive": true,
                                "isAdminOnly": false,
                                "questionId": "hi08",
                                "sectionId": "form_7",
                                "statement": "Specify time in weeeks"
                            }
                        ],
                        "options": [
                            "End of study",
                            "Set Time"
                        ],
                        "sectionId": "form_7_0",
                        "statement": "Is approval from your IEC valid until the end of the study or for a set time?",
                        "type": "SINGLE"
                    },
                    {
                        "sectionId": "form_7_1",
                        "questionId": "f7f0",
                        "isLive": true,
                        "followUps": [
                            {
                                "isLive": true,
                                "type": "TEXTFREQUENCY",
                                "statement": "What are the specific reporting requirements to your local IEC - SAEs management, Protocol Deviations etc?",
                                "questionId": "00b6",
                                "meta": "YF",
                                "sectionId": "form_7"
                            },
                            {
                                "meta": "NF",
                                "statement": "What are the timelines for reporting at the end of the study?",
                                "isLive": true,
                                "questionId": "a4c8",
                                "sectionId": "form_7",
                                "type": "SHORT"
                            }
                        ],
                        "reportOutput": "IEC requirements on written updates on study progress",
                        "type": "SINGLE",
                        "statement": "Does your IEC require written updates on study progress?",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "meta": "MQ"
                    },
                    {
                        "sectionId": "form_7_0",
                        "isLive": true,
                        "meta": "MQ",
                        "statement": "Does your IEC require a report at the end of the study?",
                        "followUps": [
                            {
                                "isAdminOnly": false,
                                "isLive": true,
                                "meta": "YF",
                                "questionId": "b798",
                                "sectionId": "form_7_0",
                                "statement": "What are the timelines for reporting at the end of the study",
                                "type": "INTEGER"
                            }
                        ],
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "questionId": "2892",
                        "reportOutput": "Report requirement at the end of study",
                        "type": "SINGLE",
                        "isAdminOnly": false
                    }
                ],
                "title": "ETHICS"
            },
            {
                "title": "INFORMED CONSENT ",
                "questions": [
                    {
                        "meta": "MQ",
                        "statement": "Would you be able to prepare the ICF for submission to the IEC?",
                        "reportOutput": "Ability to prepare the ICF for submission to the IEC",
                        "questionId": "560e",
                        "isLive": true,
                        "sectionId": "form_7_2",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "type": "SINGLE"
                    },
                    {
                        "isLive": true,
                        "reportOutput": "Standard ICF template that is required to be used and adapted for the study",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "statement": "Does your site have a standard ICF template that is required to be used and adapted for the study?",
                        "questionId": "a9ea",
                        "meta": "MQ",
                        "sectionId": "form_7_2",
                        "type": "SINGLE"
                    },
                    {
                        "reportOutput": "Detail your informed consent process and who will obtain the ICF from the subject",
                        "statement": "Please detail your informed consent process and who will obtain the ICF from the subject?",
                        "meta": "MQ",
                        "type": "LONG",
                        "isLive": true,
                        "questionId": "53fa",
                        "sectionId": "form_7_2"
                    },
                    {
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "type": "SINGLE",
                        "isLive": true,
                        "sectionId": "form_7_2",
                        "meta": "MQ",
                        "reportOutput": "Any specific Ethics Committee requirements",
                        "questionId": "cc10",
                        "followUps": [
                            {
                                "statement": "Please specify",
                                "questionId": "5d10",
                                "isLive": true,
                                "meta": "YF",
                                "sectionId": "form_7",
                                "type": "SHORT"
                            }
                        ],
                        "statement": "Are there any specific Ethics Committee requirements?"
                    }
                ],
                "id": "form_7_2"
            },
            {
                "questions": [
                    {
                        "statement": "Which Regulatory body/Health Authority (HA) do you require approval from to conduct clinical trials at your site?",
                        "type": "SHORT",
                        "questionId": "77ab",
                        "meta": "MQ",
                        "isLive": true,
                        "sectionId": "form_7_3"
                    },
                    {
                        "questionId": "29f7",
                        "options": [],
                        "meta": "MQ",
                        "type": "INTEGER",
                        "sectionId": "form_7_3",
                        "statement": "What is the expected timeline from submission to response?",
                        "isLive": true,
                        "reportOutput": "Expected timeline from submission to response"
                    },
                    {
                        "isLive": true,
                        "meta": "MQ",
                        "type": "SINGLE",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "questionId": "bbd6",
                        "followUps": [
                            {
                                "statement": "Please provide details of type of submission",
                                "isLive": true,
                                "sectionId": "form_7",
                                "meta": "YF",
                                "questionId": "a2d7",
                                "type": "LONG"
                            },
                            {
                                "isLive": true,
                                "questionId": "e289",
                                "type": "SHORT",
                                "meta": "YF",
                                "statement": "Provided details of when submission is required?",
                                "sectionId": "form_7"
                            }
                        ],
                        "statement": "Does your Regulatory body/Health Authority have a specific process for export of data and/or samples e.g Material Transfer Agreement (MTA)? ",
                        "reportOutput": "Regulatory body/Health Authority specific process for export of data and/or samples",
                        "sectionId": "form_7_3"
                    },
                    {
                        "reportOutput": "Timeline from submission to approval",
                        "statement": "What is the expected timeline from submission to approval?",
                        "type": "INTEGER",
                        "sectionId": "form_7_3",
                        "isLive": true,
                        "questionId": "ed99",
                        "meta": "MQ"
                    },
                    {
                        "sectionId": "form_7_3",
                        "options": [
                            "YES",
                            "NO"
                        ],
                        "type": "SINGLE",
                        "followUps": [
                            {
                                "statement": "Please provide more information on your experience with your HA and the types of questions, re-submission information",
                                "isLive": true,
                                "type": "SHORT",
                                "sectionId": "form_7",
                                "meta": "YF",
                                "questionId": "2db2"
                            }
                        ],
                        "statement": "Based on your experience in submitting clinical study protocols to your HA, do you usually get questions, or have to re-submit? ",
                        "isLive": true,
                        "meta": "MQ",
                        "questionId": "15f9"
                    },
                    {
                        "followUps": [
                            {
                                "isLive": true,
                                "statement": "What are the specific reporting requirements to your Regulatory body/Health Authority - SAEs management, Protocol Deviations etc?",
                                "questionId": "f0aa",
                                "isAdminOnly": false,
                                "meta": "YF",
                                "type": "TEXTFREQUENCY",
                                "sectionId": "form_7_3"
                            },
                            {
                                "isLive": true,
                                "options": [
                                    "YES",
                                    "NO"
                                ],
                                "meta": "YF",
                                "statement": "Does your Regulatory body/Health Authority require a report at the end of the study?",
                                "sectionId": "form_7_3",
                                "isAdminOnly": false,
                                "type": "SINGLE",
                                "questionId": "c98c"
                            }
                        ],
                        "type": "SINGLE",
                        "meta": "MQ",
                        "questionId": "80d5",
                        "sectionId": "form_7_3",
                        "isLive": true,
                        "statement": "Does your Regulatory body/Health Authority require written updates on study progress?",
                        "reportOutput": "Written updates on study progress to regulatory body/health authority",
                        "options": [
                            "YES",
                            "NO"
                        ]
                    },
                    {
                        "questionId": "2da3",
                        "statement": "What are the timelines for reporting at the end of the study?",
                        "type": "INTEGER",
                        "meta": "MQ",
                        "isLive": true,
                        "sectionId": "form_7_3",
                        "reportOutput": "Timelines for reporting at the end of the study"
                    }
                ],
                "title": "REGULATORY",
                "id": "form_7_3"
            }
        ],
        "sectionId": "form_7",
        "title": "ETHICS AND REGULATORY",
        "index": 7,
        "shortName": "E & R",
        "isLive": true
    }

]