
import { TableCell, Paragraph, convertInchesToTwip } from "docx";
import { TeamMemberType } from "../../common/types/site";
import { CellFillWriter } from "../utility";


const docx = require("docx");
const { Table, TableRow, } = docx;
const { TODAY_YEAR } = require("../utility");


type staffTrainingSummary = {
    description: string
    count: number
    valid: number
    expired: number
}

export const SummarizeTraining = (counts: TeamMemberType[]): staffTrainingSummary[] => {
    var result: staffTrainingSummary[] = [{
        count: 0,
        description: "GCP Training",
        valid: 0,
        expired: 0,
    }, {
        count: 0,
        description: "EDC Training",
        valid: 0,
        expired: 0
    }
    ]
    counts.forEach(element => {
        if (element.gcpTrained) {
            result[0].count += 1
        }
        if (element.edcTrained) {
            result[1].count += 1
        }
        if (element.gcpTrained && Number(TODAY_YEAR) - parseInt(element.gcpDate || "0") <= 3) {
            result[0].valid += 1
        }
        if (element.edcTrained && Number(TODAY_YEAR) - parseInt(element.edcDate || "0") <= 3) {
            result[1].valid += 1
        }
        if (element.gcpTrained && Number(TODAY_YEAR) - parseInt(element.gcpDate || "0") > 3) {
            console.log(Number(TODAY_YEAR))
            console.log(parseInt(element.gcpDate || "0"))
            result[0].expired += 1
        }
        if (element.edcTrained && Number(TODAY_YEAR) - parseInt(element.edcDate || "0") > 3) {
            result[1].expired += 1
        }
    });
    return result
}

export const _3_2_StaffTrainingTable = (counts: staffTrainingSummary[]) => new Table({
    rows: [
        new TableRow({
            children: [           
                CellFillWriter(`Training`),
                CellFillWriter(`Trained`),
                CellFillWriter(`Training Lapsed`),
                CellFillWriter(`Staff with up-to-date Training Records`)
            ]
        })].concat(

            counts.map(count => new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                text: count.description,

                            }),
                        ],
                        margins: {
                            top: convertInchesToTwip(0.1),
                            bottom: convertInchesToTwip(0.1),
                            left: convertInchesToTwip(0.2),
                            right: convertInchesToTwip(0.2),
                        }
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                text: count.count.toString(),

                            }),
                        ],
                        margins: {
                            top: convertInchesToTwip(0.1),
                            bottom: convertInchesToTwip(0.1),
                            left: convertInchesToTwip(0.2),
                            right: convertInchesToTwip(0.2),
                        }
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                text: count.expired.toString(),

                            }),

                        ],
                        margins: {
                            top: convertInchesToTwip(0.1),
                            bottom: convertInchesToTwip(0.1),
                            left: convertInchesToTwip(0.2),
                            right: convertInchesToTwip(0.2),
                        }
                    }), new TableCell({
                        children: [
                            new Paragraph({
                                text: count.valid.toString(),

                            }),

                        ],
                        margins: {
                            top: convertInchesToTwip(0.1),
                            bottom: convertInchesToTwip(0.1),
                            left: convertInchesToTwip(0.2),
                            right: convertInchesToTwip(0.2),
                        }
                    })
                ],
            }))
        )

})
