import React from 'react'
import { useStore } from '../../store/StoreProvider';
import { QuestionnaireType } from '../common/types/question'

type QAChildProps = {
    qa: QuestionnaireType,
    setQA: React.Dispatch<React.SetStateAction<QuestionnaireType>>
}

function LongQA({ qa, setQA }: QAChildProps) {
    const { question } = qa

    const { sitesListSore: siteStore } = useStore();
    let staff = siteStore?.selectedSite?.accessToStaff?.tms?.map((tm) => tm.name)
    staff = staff ? staff : []

    return (
        <div className="flex w-full my-2 py-2" key={question.sectionId+ question.questionId}>
        <p className="font-bold self-center w-6/12"> {question.statement}</p>
        <div className="w-1/12"></div>
        <section className={"flex-1 self-center"}>

            <select title='-' name={question.questionId} onChange={(e) => setQA({...qa, response:e.target.value})} className="border border-gray-100 w-full px-1 py-2 bg-gray-50">
                <option value="">Choose Member</option>
                {staff?.map((option, i) => <option selected={option === qa.response}>  {option}  </option>)}
            </select>
        </section>
    </div>
    )
}

export default LongQA




