import React, { useEffect, useState } from 'react'
import { QResponseTypeEnum, QuestionTagEnum, QuestionType } from '../../../common/types/question'
import { qInit } from '../../services/utility'
import NewDialog from './dialog'

type addMainQuestionProps = {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    section: string
    save: any
}

function AddMainQuestion({ isOpen, setIsOpen, section, save }: addMainQuestionProps) {

    const [q, setQ] = useState<QuestionType>(qInit(QuestionTagEnum.MainQuestion, section))

    useEffect(() => {
        return () => { setQ(qInit(QuestionTagEnum.MainQuestion, section)) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen])

    return (
        <NewDialog title="Add New Question" isOpen={isOpen} setIsOpen={setIsOpen} >
            <div className="space-y-2">
                <input type="text" value={q.statement} onChange={(e) => setQ({ ...q, meta: QuestionTagEnum.MainQuestion, "statement": e.target.value, isLive: false, isAdminOnly: false })} name="statement" className="mt-1 block w-full text-xs border border-gray-200 focus:outline-none rounded-md p-2" />
                <select onChange={(e) => setQ({ ...q, "type": e.target.value as QResponseTypeEnum })} className="p-2 border w-full rounded border-gray-200 text-xs">
                    <option defaultValue="">Choose response type</option>
                    <option selected={q.type === QResponseTypeEnum.SHORT} value={QResponseTypeEnum.SHORT}>Short Text</option>
                    <option selected={q.type === QResponseTypeEnum.INTEGER} value={QResponseTypeEnum.INTEGER}>Number</option>
                    <option selected={q.type === QResponseTypeEnum.LONG} value={QResponseTypeEnum.LONG}>Long Text</option>
                    <option selected={q.type === QResponseTypeEnum.DATE} value={QResponseTypeEnum.DATE}>DATE</option>
                    <option selected={q.type === QResponseTypeEnum.SINGLE} value={QResponseTypeEnum.SINGLE}>Single Choice</option>
                    <option selected={q.type === QResponseTypeEnum.MULTIPLE} value={QResponseTypeEnum.MULTIPLE}>Multiple Answers</option>
                    <option selected={q.type === QResponseTypeEnum.LIST} value={QResponseTypeEnum.LIST}>List</option>
                    <option selected={q.type === QResponseTypeEnum.FILE} value={QResponseTypeEnum.FILE}>File Upload</option>
                </select>
                <div className="flex justify-center">
                    <button className="p-2 border-0 bg-atomGreen mt-2 text-xs text-white px-5" onClick={() => { save(q); setIsOpen(false) }}>Save & Continue</button>
                </div>
            </div>
        </NewDialog>
    )
}

export default AddMainQuestion



