export const OTHERS = "OTHERS"
export const Others = "Other"
export const SetTime = "time"

export const YES = "YES"
export const NO = "NO"
export const DONT_NO = "DONT_NO"
export const PASSWORD = "PASSWORD"
export const PSDs = ["Hospital Outpatient Clinic", "Hospital Inpatients", "Primary Care Clinic", "Doctors Surgery", "Laboratory Clinic", OTHERS]
export const MemberResponsibilities = ["Administer Consent", "Screen Subjects", "Obtain Medical History", "Determine Eligibility", "Assess Adverse Events", "Dosing of patients", "Management of project specific treatments eg dispensing", "Complete Source Documents", "Perform protocol specific medical assessments", "Carry out protocol specific tests/procedures", "Management of bio-samples appropriately including collection, labelling, storage,shipment and/or disposal", "Complete Project Data Forms", "Data tool access (where required)", "Data entry (eCRF or paper)", "Query Management (via access to entered data or on paper)"]



export const SpecialtyArea: string[] = ["CHD", "Heart Failure", "Stroke", "Acute Coronary Syndrome", "Hyperlipideamia", "Atherosclerosis", "Pulmonary Hypertension", "Alzheimers", "Dementia", "Parkinsons", "Multiple Sclerosis", "Motor Neurone Disease (ALS)",   "Acne", "Alopecia", "Atopic dermatitis", "Cellulitis", "Contact dermatitis", "Exema", "Herpes", "Lupus", "Scleroderma",  "Diabetes", "Growth Hormone deficiency", "Thyroid disease", "Calcium and Bone disorders", "Gastritis", "Oesophagitis", "Peptic ulcers", "Crohns disease", "Ulcerative Colitis", "Baretts Oesophagus",  "Sickle Cell disease", "Anemia", "Hemophilia",  "Chikungunya", "Dengue fever", "Ebola", "Lassa fever", "Malaria",   "Depression", "Major Depressive Disorder", "Schizophrenia", "Restless Leg Syndrome", "Fibromyalgia", "Migraine",  "Rheumatoid Arthritis", "Osteology", "Osteoporosis",   "CKD", "Benigh Prostatic Hyperplasia",  "Maternity Care", "Ovarian Cysts", "Endometriosis", "Uterine Fibroids",  "Breast cancer", "Lung cancer", "Colon cancer", "Prostate cancer", "Thyroid cancer", "Liver cancer", "Kidney cancer", "Melanoma", "Lymphoma", "Gastric cancer", "Leukemias", "AMD", "Diabetic Macular Degeneration", "Chronic Idiopathic pain", "Post-surgical pain", "Neuropathic pain",  "General Paediatrics", "Neonatology", "Pediatric surgery",  "Muscular Dystrophy", "Huntingtons disease",  "Rhinitis", "Asthma", "COPD", "Ankylosing Spondilitis", "Psoriasis", "Transplantaion",  "HIV/AIDS", "Flu", "SARS", "Zika virus", "COVID"]

export const FIREBASE_USER_NOT_FOUND = "auth/user-not-found"


export const AccessToStaffTabID = "form_1"
export const SiteExperienceTabID = "form_2"
export const SiteFacilityTabID = "form_6"
export const SiteEthicsTabID = "form_7"
export const SitePharmacyTabID = "form_4"
export const SiteInfraTabID = "form_3"
export const SiteBioSampleTabID = "form_5"

export const HomeTabID = "form_home"
export const MediaTabID = "form_media"

export const MONTHS = ["JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE"]
export const YEAR = ["2000", "2001", "2002", "2003", "2004", "2005"]



export const SITE_CREATED = "SITE CREATED"
export const NEW_TM = "NEW TEAM MEMBER"
export const NEW_STAFF_COUNT = "NEW STAFF COUNT"