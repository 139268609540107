import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { useStore } from '../../../../../store/StoreProvider'
import { StaffCountTagEnum } from '../../../../common/types/site'
import Questionnaire from '../../../../Questionnaire'
import AddStaffCount from './AddStaffCount'
import AddTeamMember from './AddTeamMember'
import StaffCounts from './StaffCounts'
import TeamMembersList from './TeamMembersList'

function Index() {
    const { siteQuestioningStore: questionStore } = useStore();

    const [open, setOpen] = useState(false)

    return (
        <div className="sm:px-20 sm:py-10 px-10 py-5">
            <h6 className="text-atomGreen text-3xl py-2 border-b-4 border-atomGreen">Team Members</h6>

            <TeamMembersList />
            <div className="p-5 justify-center flex">
                <button onClick={() => setOpen(!open)} className="border-2 border-atomGreen px-5 py-3 rounded-lg bg-green-50">Add New Member</button>
            </div>
            <AddTeamMember open={open} setOpen={setOpen} />
            <h6 className="text-atomGreen text-2xl py-2 border-b-4 border-atomGreen ">Clinical Research Staff</h6>

            <Questionnaire subId={questionStore!.selectedSection!.sectionId + "_1"} key={questionStore!.selectedSection!.sectionId + "_1"} />

            <h6 className="p-1 px-3 -mx-2 text-lg bg-gray-50 text-atomGreen">Current Research Staff</h6>
            <h6 className=" py-3 text-lg text-gray-700">What type of staff do you have to assist in research? Complete for each role.</h6>
            <StaffCounts tag={StaffCountTagEnum.HIRED} />
            <AddStaffCount tag={StaffCountTagEnum.HIRED} />
            
            <Questionnaire subId={questionStore!.selectedSection!.sectionId + "_2"}  key={questionStore!.selectedSection!.sectionId + "_2"} />

            <h6 className="p-1 px-3 -mx-2 text-lg bg-gray-50 text-atomGreen">Staff to hire</h6>

            <h6 className=" py-3 text-lg text-gray-700">List the site staff that will need to be hired</h6>

            <StaffCounts tag={StaffCountTagEnum.TO_HIRE} />
            <AddStaffCount tag={StaffCountTagEnum.TO_HIRE} />

        </div>
    )
}


export default observer(Index)

