import React from 'react'
import { useStore } from '../../store/StoreProvider';
import { QuestionnaireType } from '../common/types/question'

type QAChildProps = {
    qa: QuestionnaireType,
    setQA: React.Dispatch<React.SetStateAction<QuestionnaireType>>
}

function LongQA({ qa, setQA }: QAChildProps) {
    const { question } = qa

    const {  sitesListSore: siteStore } = useStore();
    let staff = siteStore?.selectedSite?.accessToStaff?.tms?.map((tm) => tm.name)
    staff = staff ? staff : []

    return (
        <div className="flex w-full my-2 py-2" key={question.sectionId + question.questionId}>
            <p className="font-bold self-center w-6/12"> {question.statement}</p>
            <div className="w-1/12"></div>
            {staff!.length > 3 ?
                (<div className="w-4/12 px-1">
                    {staff?.map((option, i) => {
                        let response = qa.response as string[];
                        let selected = response.includes(option)
                        return (<div className="flex" key={question.questionId + i}>
                            <input title='-' className="w-3 mr-2 self-center" type="checkbox" name={question.questionId} onChange={() => setQA({ ...qa, response: [...qa.response as string[], option] })} checked={selected} />
                            <label className="p-2 flex-1 w-full self-center"> {option} </label>
                        </div>)
                    })}
                </div>) : (<div className="w-4/12 px-1 flex">
                    {staff?.map((option, i) => {
                        let response = qa.response as string[];
                        let selected = response.includes(option)
                        return (<div className="flex" key={question.questionId + i}>
                            <input title='-' className="w-3 mr-2 self-center" type="checkbox" name={question.questionId} onChange={() => setQA({ ...qa, response: [...qa.response as string[], option] })} checked={selected} />
                            <label className="p-2 flex-1 w-full self-center"> {option} </label>
                        </div>)
                    })}
                </div>)
            }
        </div>
    )
}

export default LongQA




