import { observer } from 'mobx-react'
import React from 'react'
import { useStore } from '../../../../../store/StoreProvider';
import Questionnaire from '../../../../Questionnaire'

function Index() {
    const { siteQuestioningStore: questionStore } = useStore();

    return (
        <div className="sm:px-20 sm:py-10 px-10 py-5">
            <Questionnaire subId={questionStore!.selectedSection!.sectionId + "_1"} showTitle={true} />
            <Questionnaire subId={questionStore!.selectedSection!.sectionId + "_2"} showTitle={true} />
            <Questionnaire subId={questionStore!.selectedSection!.sectionId + "_3"} showTitle={true} />
            <Questionnaire subId={questionStore!.selectedSection!.sectionId + "_4"} showTitle={true} />
            <Questionnaire subId={questionStore!.selectedSection!.sectionId + "_5"} showTitle={true} />
            <Questionnaire subId={questionStore!.selectedSection!.sectionId + "_6"} showTitle={true} />

        </div>
    )
}
export default observer(Index)