
import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {CheckIcon } from '@heroicons/react/outline'

type NotifierProps = {
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export default function Notifier({ open, setOpen }: NotifierProps) {

    const cancelButtonRef = useRef(null)

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={() => {
                setOpen(false);
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
            }}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 space-y-5">
                                <div className="flex  flex-col justify-center w-full px-4 py-3">

                                    <div className="mt-3 text-center w-full">
                                        <Dialog.Title as="h3" className="text-gray-900 text-center w-full  font-extrabold text-2xl">
                                            Records Updated!
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">

                                            </p>
                                        </div>
                                    </div>
                                    <div className="mx-auto flex-shrink-0 flex items-center justify-center  h-24 w-24 rounded-full bg-green-100 px-4 my-3">
                                        <CheckIcon className="h-12 w-12 text-green-600 text-center font-black text-3xl " aria-hidden="true" />
                                    </div>
                                </div>
                                <div className=" px-4  sm:px-6 sm:flex justify-center">
                                    <button
                                        type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-8 py-2 bg-greed-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm bg-atomGreen"
                                        onClick={() => {
                                            setOpen(false);
                                            window.scrollTo({
                                                top: 0,
                                                behavior: "smooth"
                                            });
                                        }}  >
                                        Okay
                                    </button>
                                </div>
                            </div>

                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
