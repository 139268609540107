

const docx = require("docx");
const { Paragraph, TextRun } = docx;

export const _3_3_SiteProcessSummary = () => new Paragraph({
    children: [
      
        new TextRun({ text: "\n", break: 2 }),

        new TextRun({
            text: new TextRun(`The expertise you have at your site in assessing patients health using specific equipment and evaluation procedures is a key necessity for running clinical trials. Table 4 below provides this experience both in routine use at your site but also use within clinical trials.`), font: "Arial", size: 22,
        }),
        new TextRun({ text: "\n", break: 2 }),
       
    ], spacing: {
        line: 360,
    },
})
