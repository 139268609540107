

import { YES } from "../../common/constants";
import { LogisticsPartner } from "../../common/types/site";
import { BLankNoTFiller, TextFiller, TextWriter, YesNoFiller } from "../utility";

const docx = require("docx");
const { Paragraph, TextRun } = docx;

export const _3_6_1_BioSampleIntro = (labs:LogisticsPartner[], labAddress: string, trailContact: string, trailBackUp: string, willToTrain: string, needLabFlow: string,  rangeReviewer: string, isGLpCertified: string, docAttached: string, isAuditable: string, keepFullRecord: string, maintainedUsing: string, staffHaveAccess: string, limitation: string, paperOrNot: string, freezerAreMonitored: string, monitoredBy: string, recordTenor: string, storageCapacity: string, hasCentrifuge:string, hasCentrifugeRefrig:string, gForce:string) =>{ 
    
    const labsList = labs.map(lab => TextWriter(TextFiller(`${lab.name}, address: ${lab.address} and the contact for clinical trials is ${lab.primaryContact} `)),)
    
    
    return new Paragraph({
    children: [

   

        TextWriter(`Your lab partners is/are `),
        ...labsList,
        TextWriter(`. The lab staff`),
        TextWriter(YesNoFiller(willToTrain, ' are ',' are not ')),
        TextWriter(`willing to undertake specific training for trials as may be required by the protocol and`),
        TextWriter(YesNoFiller(needLabFlow, ' do ',' do not ')),
        TextWriter(`need to receive the lab flow chart for a protocol in local language. `),
        TextWriter(TextFiller(rangeReviewer)),
        TextWriter(` is responsible for reviewing out of range values for clinical relevance.`),
        new TextRun({ text: "\n", break: 2 }),

        TextWriter(`This lab`),
        TextWriter(YesNoFiller(isGLpCertified, ' is ', ' is not ')),
        TextWriter(`GLP certified and the accreditation documentation`),
        TextWriter(BLankNoTFiller(docAttached,' is attached ',' is not attached')),
        TextWriter(`. There`),
        TextWriter(YesNoFiller(isAuditable,  ' are ',' are not ')),
        TextWriter(`auditable, documented procedures in place to track samples and a full record`),
        TextWriter(YesNoFiller(keepFullRecord, ' is ',' is not ')),
        TextWriter(`maintained. Service, calibration and maintenance is managed using `),
        TextWriter(TextFiller(maintainedUsing)),
        TextWriter(`. The lab staff`),
        TextWriter(YesNoFiller(staffHaveAccess, ' are ',' are not ')),
        TextWriter(`able to access on line portals for the requirements of a trial,`),
        TextWriter(staffHaveAccess === YES? " without limitation ": `with ${TextFiller(limitation)} limitations`),
        TextWriter(`and prefer `),
        TextWriter(TextFiller(paperOrNot)),
        TextWriter(` forms.`),
        new TextRun({ text: "\n", break: 2 }),

        TextWriter(`For sample storage you have the following fridge/freezers available which`),
        TextWriter(YesNoFiller(freezerAreMonitored, ' are ',' are not ')),
        TextWriter(`monitored by a `),
        TextWriter(TextFiller(monitoredBy)),
        TextWriter(` check with records maintained for `),
        TextWriter(TextFiller(recordTenor)),
        TextWriter(` Total capacity for sample storage is `),
        TextWriter(TextFiller(storageCapacity)),
        TextWriter(`.`),

        new TextRun({ text: "\n", break: 2 }),
        TextWriter(`Table 8`),
        new TextRun({ text: "\n", break: 1 }),

    ], spacing: {
        line: 360,
    },
})
}