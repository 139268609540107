

import { TextWriter, YesNoFiller } from "../utility";

const docx = require("docx");
const { Paragraph, TextRun } = docx;

export const _3_6_1_Centrifuge = (hasCentrifuge:string, hasCentrifugeRefrig:string, gForce:string) => new Paragraph({
    children: [
        new TextRun({ text: "\n", break: 2 }),

        TextWriter(`The lab`),
        TextWriter(YesNoFiller(hasCentrifuge, ' has ',' does not have ')),
        TextWriter(`centrifuges`),
        TextWriter(YesNoFiller(hasCentrifugeRefrig, ' including ',' not including ')),
        TextWriter(`refrigerated ones `),
        TextWriter(`and at least one is adjustable for temp and g-force.`),

        new TextRun({ text: "\n", break: 2 }),

    ], spacing: {
        line: 360,
    },
})
