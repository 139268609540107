import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { useStore } from "../store/StoreProvider";
import SiteDashboardHome from "../modules/sites/screens/SiteDetail/SiteHome";
import Logs from "../modules/dashboard/screens/logs";
import SitesList from "../modules/sites/screens/SitesList/SitesList";
import Dashboard from "../modules/dashboard/screens/index";
import Finder from "../modules/search/screens/find"
import Admin from "../components/layouts/admin";
import LandingHome from "../components/pages/AuthenticationPage";

const ApplicationRoutes = () => {
  const { sitesListSore: siteSore } = useStore();
  return (
    <Router>
          <Switch>
            <Route path="/dashboard" render={() => <Dashboard />} />
            <Route path="/logs" render={() => <Logs />} />
            <Route path="/sites" render={() => <SitesList />} />
            <Route path="/search" render={() => <Finder />} />
            <Route path="/admin" render={() => <Admin />} />
            <Route path="/site/:id" render={() => <SiteDashboardHome selected={siteSore!.selectedSite} />} />
            <Route path="/auth" component={LandingHome} />
            <Redirect to="/auth" from="/" />
          </Switch>
    </Router>
  );
};

export default ApplicationRoutes;
