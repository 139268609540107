import { observer } from 'mobx-react';
import React, { useState } from 'react'
import { useStore } from '../../../../store/StoreProvider';
import { Administrator } from '../../../common/types/admin';
import AddNewAdmin from '../../components/dialog/add_admin';

function AdminAdministrators() {

    const [newAdmin, setNewAdmin] = useState<Administrator>({})
    const [seeAddAdmin, setSeeAddAdmin] = useState(false)
    const { administratorStore: adminStore } = useStore();
    const administrators = adminStore?.admins || []

    function handleSaveNewAdmin(): any {
        adminStore?.addAdmin(newAdmin).then(() =>
            setSeeAddAdmin(false)
        )
    }

    function handleRemoveAdmin(admin: Administrator): any {
        adminStore?.removeAdmin(admin)
    }

    return (
        <div className=" w-full">
            <div className="bg-gray-800 h-64  top-0 w-full z-0 justify-between">
            </div>
            <div className="w-full overflow-x-scroll xl:overflow-x-hidden p-5 z-20 -mt-60">
                <div className="bg-white p-5 flex justify-between">
                    <h5 className="font-extrabold text-lg self-center">Administrators: {administrators.length}</h5>
                    <button className="transition duration-150 ease-in-out hover:bg-green-600 focus:outline-none border bg-atomGreen text-white px-8 py-2 text-sm self-center rounded-lg" onClick={() => setSeeAddAdmin(!seeAddAdmin)}>Add New</button>
                </div>
                <div className="bg-white mt-1">
                    {administrators.map((admin, index) => <div key={index} className="p-3 border-b-2 border-gray-100 px-5 flex justify-between">
                        <div className="flex space-x-5 self-center w-full">
                            <h6 className='font-bold'>{index + 1}</h6>
                            <h5 className="self-center w-1/3"> {admin.name}</h5>
                            <h5 className="self-center"> {admin.email}</h5>
                        </div>
                        <button className="focus:outline-none border  px-8 py-1 text-xs self-center font-bold" onClick={() => handleRemoveAdmin(admin)}>remove</button>
                    </div>)}
                </div>
            </div>
            <AddNewAdmin isOpen={seeAddAdmin} setIsOpen={setSeeAddAdmin} save={handleSaveNewAdmin} newAdmin={newAdmin} setNewAdmin={setNewAdmin} />
        </div>
    )
}

export default observer(AdminAdministrators)