
import { TableCell, Paragraph, VerticalAlign, convertInchesToTwip } from "docx";
import { QuestionType } from "../../common/types/question";
import { CellWriter, findQuestion, NO_INFO } from "../utility";

const docx = require("docx");
const { Table, TableRow, } = docx;


type ttSummary = {
    description?: string
    response?: string
}

type ttQuestionMAp = {
    questionIds: string[]
}

export const SummarizeTt = (results: any, questions: QuestionType[]): ttSummary[] => {
    var result: ttSummary[] = []

    trialQuestionIdMaps.map(trail => (
        result.push({
            description: findQuestion(questions, trail.questionIds[0]) as string,
            response: results[trail.questionIds[0]] ? results[trail.questionIds[0]] : NO_INFO

        })
    ))
    return result
}

const trialQuestionIdMaps: ttQuestionMAp[] = [
    {
        questionIds: ["9cb5", "", ""]
    }, {
        questionIds: ["9f07", "", ""]
    }, {
        questionIds: ["af21", "", ""]
    }, {
        questionIds: ["8d8c", "", ""]
    }

]


export const _3_6_3_TumourTissueTable = (counts: ttSummary[]) => new Table({
    rows: [
        new TableRow({
            children: [
                new TableCell({
                    children: [new Paragraph({ text: "Tumour Tissue Processing" })],
                    verticalAlign: VerticalAlign.CENTER,
                    margins: {
                        top: convertInchesToTwip(0.1),
                        bottom: convertInchesToTwip(0.1),
                        left: convertInchesToTwip(0.2),
                        right: convertInchesToTwip(0.2),
                    },
                    shading: {
                        color: "00FFFF",
                        fill: "#A4CA39",
                    },
                }),
                new TableCell({
                    children: [new Paragraph({ text: "" })],
                    verticalAlign: VerticalAlign.CENTER,
                    margins: {
                        top: convertInchesToTwip(0.1),
                        bottom: convertInchesToTwip(0.1),
                        left: convertInchesToTwip(0.2),
                        right: convertInchesToTwip(0.2),
                    },
                    shading: {
                        color: "00FFFF",
                        fill: "#A4CA39",
                    },
                }),

            ],
        })].concat(

            counts.map(count => new TableRow({
                children: [
                    CellWriter(count.description),
                    CellWriter(count.response?.toString()),
                ]
            }))
        )

})
