
import { CellFillWriter, CellWriter, CellWriterNewLine, NO_INFO } from "../utility";

const docx = require("docx");
const { Table, TableRow, } = docx;

type shipmentSummary = {
    days?: string
    freq?: string
    dryIce?: string
    export?: string
}

type shipmentQuestionMAp = {
    questionIds: string[]
}

export const SummarizeShipment = (results: any): shipmentSummary[] => {
    var result: shipmentSummary[] = []

    trialQuestionIdMaps.map(trail => (
        result.push({
            days: results[trail.questionIds[1]] ? results[trail.questionIds[1]] : NO_INFO,
            freq: results[trail.questionIds[2]] ? results[trail.questionIds[2]] : NO_INFO,
            dryIce: results[trail.questionIds[3]] ? results[trail.questionIds[3]] : NO_INFO,
            export: results[trail.questionIds[4]] ? results[trail.questionIds[4]] : NO_INFO
        })
    ))
    return result
}

const trialQuestionIdMaps: shipmentQuestionMAp[] = [
    {
        questionIds: ["", "54f6", "c586", "22c8", "e57d"]
    },

]


export const _3_6_2_ShipmentTable = (counts: shipmentSummary[]) => new Table({
    rows: [
        new TableRow({
            children: [

                CellFillWriter("Days"),
                CellFillWriter("Shipment frequency "),
                CellFillWriter("Dry ice availability"),
                CellFillWriter("Export experience"),

            ],
        })].concat(

            counts.map(count => new TableRow({
                children: [
                    CellWriterNewLine(count.days?.toString()),
                    CellWriter(count.freq?.toString()),
                    CellWriter(count.dryIce?.toString()),
                    CellWriter(count.export?.toString()),
                ]
            }))
        )

})
