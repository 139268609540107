import { ref, uploadBytes } from 'firebase/storage'
import React from 'react'
import { setupFireStore } from '../../firebase/firebase.config'
import { useStore } from '../../store/StoreProvider'
import { QuestionnaireType } from '../common/types/question'
import { FILE_DATE } from '../common/types/utility'

type QAChildProps = {
    qa: QuestionnaireType,
    setQA: React.Dispatch<React.SetStateAction<QuestionnaireType>>
}

function LongQA({ qa, setQA }: QAChildProps) {
    const { question } = qa
    const { FirebaseStorage: storage } = setupFireStore()
    const { sitesListSore: siteStore } = useStore();

    function handleRespondingToFile(files: FileList | null): void {
        if (!files || files!.length < 1) {
            return
        }

        let file = files![0]
        const filename = `${siteStore?.selectedSite?.description?.siteId}/q/${question.questionId}-${FILE_DATE}-${file.name.replace(" ", "_")}`
        const qResRef = ref(storage, filename.toLocaleLowerCase());
        uploadBytes(qResRef, file).then((snapshot) => {
            console.log(snapshot.ref.fullPath)
        }).then(() => {
            setQA({ ...qa, response: filename })
        });

    }
    return (
        <div className="flex w-full my-2 py-2" key={question.sectionId + question.questionId}>
            <p className="font-bold self-center w-6/12"> {question.statement}</p>
            <div className="w-1/12"></div>
            <section className={"flex-1 self-center"}>
                <input title='-' type="file" onChange={(e) => handleRespondingToFile(e.target.files)} className="border border-gray-100 w-full px-1 py-2 bg-gray-50" />
                {<p className="text-xs"> {qa.response ? "File Uploaded" : ""}</p>}
            </section>
        </div>
    )
}

export default LongQA






