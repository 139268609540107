


import { doc, setDoc, collection, getDocs, getDoc,  query, QueryConstraint } from "firebase/firestore";
import { setupFireStore } from "../../../firebase/firebase.config";
import { FormType } from "../../common/types/question";
import {  SiteObject } from "../../common/types/site";
import { SavedSearchQuery } from "../types";

const { Firestore: db } = setupFireStore()

const sitesRef = collection(db, "sites");

export class SearchService {

    async runQuery(queries: QueryConstraint[]): Promise<SiteObject[]> {
        let results: SiteObject[] = []
        const q = query(sitesRef, ...queries);
        const querySnapshot = await getDocs(q);
        querySnapshot.docs.forEach((item) => {
            results = [...results, item.data()];
        });
        return results
    };

    async getSite(Id: string): Promise<SiteObject> {
        const response = doc(db, "sites", Id);
        const data = await getDoc(response);
        return data.data() as SiteObject;
    };

    async getSites(): Promise<SiteObject[]> {
        let results: any = [];
        const response = collection(db, "sites");
        const data = await getDocs(response);
        data.docs.forEach((item) => {
            results = [...results, item.data()];
        });

        return results;
    };

    async saveQuery(query: SavedSearchQuery) {
        let site = doc(db, "search", query.id);
       await setDoc(site, {
            ...query
        });
    };

    async getQueryHistory(): Promise<SavedSearchQuery[]> {
        let results: any = [];
        const response = collection(db, "search");
        const data = await getDocs(response);
        data.docs.forEach((item) => {
            results = [...results, item.data()];
        });

        return results;
    };

    async getAllForms(): Promise<FormType[]> {

        let response: FormType[] = [];
        const result = collection(db, "sections");
        const data = await getDocs(result);
        data.docs.forEach((item) => {
            response = [...response, item.data() as FormType];
        });

        return response || [];
    }
}