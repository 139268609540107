import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react'
import { FormType, QuestionType, QuestionTagEnum } from '../../../common/types/question';
import { useHistory, useLocation } from 'react-router';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import { useStore } from '../../../../store/StoreProvider';
import EditQuestion from './EditQuestion';
import AddMainQuestion from '../../components/dialog/add_question';

function FormQuestions() {
    const { adminViewStore: adminView, adminQuestionSectionsStore: questionStore } = useStore();

    const location = useLocation();
    const history = useHistory();

    const state = location.state
    const _form = JSON.parse(state as string) as FormType;

    const [form, setForm] = useState<FormType>(_form)
    const [section, setSection] = useState(0)
    const [questions, setQuestions] = useState<QuestionType[]>(form.subs![0].questions || [])
    const [question, setQuestion] = useState<QuestionType | undefined>(undefined)
    const [index, setIndex] = useState<number | undefined>(undefined)
    const [parent, setParent] = useState<QuestionType | undefined>(undefined)
    const [seeAddQuestion, setSeeAddQuestion] = useState(false)

    function handleSubSwitch(index: number): void {
        setQuestion(undefined)
        setSection(index)
        setQuestions(form.subs![index].questions || [])
    }

    function handleSelectQuestion(question: QuestionType, index: number): void {
        setIndex(index)
        setQuestion(question)
    }
    function refreshSelectedQuestion() {
        setIndex(undefined)
        setQuestion(undefined)
        setQuestion(question)
        setIndex(index)
    }


    function toggleVisibility(question: QuestionType, index: number): void {
        let copyForm = { ...form }
        let currentSub = copyForm.subs![section]
        let questions = currentSub.questions || []

        if (question.meta !== QuestionTagEnum.MainQuestion) {
            let _question = findFollowUp(questions, index)
            _question!.isLive = !_question!.isLive
            setForm(copyForm)
            return
        }

        questions![index].isLive = !questions![index].isLive
        setForm(copyForm)
    }

    function handleQuestionUpdate(q: QuestionType, e: React.ChangeEvent<HTMLTextAreaElement> & React.ChangeEvent<HTMLSelectElement>, name: keyof QuestionType, index: number): void {
        let copyForm = { ...form }
        let questions = copyForm.subs![section].questions || []
        let obj: any = {}
        obj[name] = e.target.value

        if (q.meta !== QuestionTagEnum.MainQuestion) {
            let followUps = findFollowUps(questions, index)
            followUps[index] = { ...q, ...obj }
            setForm(copyForm)
            return
        }
        questions[index] = { ...q, ...obj }
        setForm(copyForm)
    }

    function findFollowUps(questions: QuestionType[], index: number): QuestionType[] {
        let question = questions.find(({ questionId }) => questionId === parent?.questionId);
        return question?.followUps || []
    }

    function findFollowUp(questions: QuestionType[], index: number): QuestionType {
        let question = questions.find(({ questionId }) => questionId === parent?.questionId);
        let followUps = question?.followUps || []
        return followUps[index]
    }

    function handleMoveQuestion(question: QuestionType, direction: string, mq: QuestionType): void {
        let copyForm = { ...form }
        let questions = copyForm.subs![section].questions || []
        let index = questions.findIndex(({ questionId }) => questionId === question.questionId);

        if (question.meta === QuestionTagEnum.MainQuestion) {
            if (direction === "UP") {
                if (index! > 0) {
                    const copyAbove = questions[index! - 1]
                    questions[index - 1] = question
                    questions[index] = copyAbove
                }
            }
            if (direction === "DN") {
                if (index < questions.length - 1) {
                    const copyBellow = questions[index! + 1]
                    questions[index + 1] = question
                    questions[index] = copyBellow
                }
            }
        } else {
            const followUps = mq.followUps || []
            const i = followUps.findIndex(({ questionId }) => questionId === question.questionId);
            if (direction === "UP") {
                if (i! > 0) {
                    const copyAbove = followUps[i! - 1]
                    followUps[i - 1] = question
                    followUps[i] = copyAbove
                }
            }
            if (direction === "DN") {
                if (i < followUps.length - 1) {
                    const copyBellow = followUps[i! + 1]
                    followUps[i + 1] = question
                    followUps[i] = copyBellow
                }
            }
        }
        setForm(copyForm)
    }

    function handleDeleteOption(q: QuestionType, option: string, index: number): void {
        let copyForm = { ...form }
        let questions = copyForm.subs![section].questions || []

        if (q.meta !== QuestionTagEnum.MainQuestion) {
            let followUps = findFollowUps(questions, index)
            let question = followUps[index]
            question!.options = [...question?.options || []].filter(x => x !== option)
            setForm(copyForm)
            return
        }
        let question = questions.find(({ questionId }) => questionId === q.questionId);
        question!.options = [...question?.options || []].filter(x => x !== option)
        setForm(copyForm)
    }

    function handleAddNewOption(q: QuestionType, option: string, index: number): void {
        let copyForm = { ...form }
        let questions = copyForm.subs![section].questions || []

        if (q.meta !== QuestionTagEnum.MainQuestion) {
            let followUps = findFollowUps(questions, index)
            let question = followUps[index]
            question!.options = [...q?.options || [], option]
            setForm(copyForm)
            return
        }

        let question = questions.find(({ questionId }) => questionId === q.questionId);
        question!.options = [...q?.options || [], option]
        setForm(copyForm)
    }

    function handleAddQuestionToList(q: QuestionType): void {
        let copyForm = { ...form }
        let questions = copyForm.subs![section].questions || []
        questions.push(q)
        setForm(copyForm)
    }

    function handleAddFollowUp(q: QuestionType, qid: string): void {
        let copyForm = { ...form }
        let questions = copyForm.subs![section].questions || []
        let question = questions.find(({ questionId }) => questionId === qid);
        refreshSelectedQuestion()
        let _followups = question?.followUps || []
        question!.followUps = [..._followups!, q]
        setForm(copyForm)
    }

    function handleSaveToDb(): void {
        questionStore?.setIsLoading(true)
        questionStore?.updateForm(form).then(() => questionStore.setIsLoading(false)).then(() => history.goBack())
    }

    useEffect(() => {
        adminView?.setSideBar(false)
        return () => {
            adminView?.setSideBar(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <div className="flex w-full">
            <div className="flex-1">
                <div className="bg-gray-800 h-64  top-0 w-full z-0 justify-between">
                </div>
                <div className="w-full overflow-x-scroll xl:overflow-x-hidden p-5 z-20 -mt-60">
                    <div className="flex justify-between">
                        <div className="flex self-center">
                            <svg xmlns="http://www.w3.org/2000/svg" onClick={() => history.goBack()} className="h-6 w-6 text-white  self-center mr-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 17l-5-5m0 0l5-5m-5 5h12" />
                            </svg>
                            <h5 className="font-extrabold text-lg self-center text-white">{form.title}</h5>

                        </div>
                        <div className="flex self-center space-x-5">
                            <button className="transition duration-150 ease-in-out hover:bg-atomGreen focus:outline-none border text-white px-5 py-2 text-xs self-center" onClick={() => setSeeAddQuestion(!seeAddQuestion)}>Add New Question</button>
                            {questionStore?.isLoading && <i className="fa fa-spinner fa-spin text-gray-50 self-center"></i>}
                            <button className="transition duration-150 ease-in-out hover:bg-green-600 focus:outline-none border bg-atomGreen text-white px-5 py-2 text-xs self-center " onClick={(e) => handleSaveToDb()}>Save Changes</button>
                        </div>
                    </div>

                    <div className="py-3 flex-1  flex  space-x-2">
                        { form.subs?.map((sub, index) => <p className={"text-white hover:text-gray-200 shadow-sm cursor-pointer  " + (index === section && " border-b-2 mx-2 px-2 shadow border-atomGreen")} onClick={() => handleSubSwitch(index)}>{sub.title}</p>)   }
                    </div>

                    <div className="bg-white w-full min-h-screen">
                        {
                            questions!.map((question, index) => <div className="" onClick={() => { }}>
                                <div className="p-3 border-t-2  border-gray-100 hover:bg-green-100 flex">
                                    <div className="relative inline-block w-10 mr-2 align-middle self-center select-none transition duration-200 ease-in">
                                        <input title="toogle" type="checkbox" name="toggle" id="toggle" className="toggle-checkbox absolute block w-4 h-4  rounded-full bg-white border-4 appearance-none cursor-pointer" checked={question.isLive} onClick={() => toggleVisibility(question, index)} />
                                        <label className="toggle-label block overflow-hidden h-4  rounded-full bg-gray-300 cursor-pointer"></label>
                                    </div>

                                    <h4 className="mx-2 flex-1 text-xs self-center cursor-pointer" onClick={() => handleSelectQuestion(question, index)}>{question.statement}</h4>
                                    <div className=" flex flex-col">
                                        <ChevronUpIcon className="h-3 w-3 text-gray-500 cursor-pointer" onClick={() => handleMoveQuestion(question, "UP", question)} />
                                        <ChevronDownIcon className="h-3 w-3 text-gray-500 cursor-pointer" onClick={() => handleMoveQuestion(question, "DN", question)} />
                                    </div>
                                </div>

                                {question.followUps?.map((_question, index) => <div className="p-3 flex bg-gray-200 hover:bg-green-200">
                                    <div className="relative inline-block w-10 mr-2 align-middle self-center select-none transition duration-200 ease-in">
                                        <input title="toogle" type="checkbox" name="toggle" id="toggle" className="toggle-checkbox absolute block w-4 h-4  rounded-full bg-white border-4 appearance-none cursor-pointer" checked={_question.isLive} onClick={() => toggleVisibility(question.followUps![index], index)} />
                                        <label className="toggle-label block overflow-hidden h-4  rounded-full bg-gray-300 cursor-pointer"></label>
                                    </div>
                                    <h4 className="mx-2 flex-1 text-xs self-center cursor-pointer" onClick={() => { handleSelectQuestion(question.followUps![index], index); setParent(question) }}>{_question.statement}</h4>
                                    <div className=" flex flex-col">
                                        <ChevronUpIcon className="h-3 w-3 text-gray-500 cursor-pointer" onClick={() => handleMoveQuestion(_question, "UP", question)} />
                                        <ChevronDownIcon className="h-3 w-3 text-gray-500 cursor-pointer" onClick={() => handleMoveQuestion(_question, "DN", question)} />
                                    </div>
                                </div>)}

                            </div>
                            )
                        }
                    </div>
                </div>
            </div>

            <div className="min-h-full w-64 bg-white ">
                <div className="fixed w-64">
                    <div className="px-3">
                        <h4 className="my-5 font-bold">Question Details</h4>
                    </div>

                    <hr />

                    <EditQuestion
                        q={question}
                        index={index}
                        key={question?.questionId}
                        handleQuestionUpdate={handleQuestionUpdate}
                        handleAddNewOption={handleAddNewOption}
                        handleDeleteOption={handleDeleteOption}
                        handleAddFollowUp={handleAddFollowUp}/>
                </div>
            </div>
            <AddMainQuestion
                isOpen={seeAddQuestion}
                setIsOpen={setSeeAddQuestion}
                section={_form.sectionId + "_" + section}
                save={handleAddQuestionToList} />
        </div>
    )
}

export default observer(FormQuestions)
