import React from 'react'
import { QuestionnaireType } from '../common/types/question'

type QAChildProps = {
    qa: QuestionnaireType,
    setQA: React.Dispatch<React.SetStateAction<QuestionnaireType>>
}

function NumbQA({ qa, setQA }: QAChildProps) {
    const { question } = qa

    function handleNumberInput(e: React.ChangeEvent<HTMLInputElement>): void {
        if (isNaN(Number(e.target.value))) {
            return
        }
        setQA({ ...qa, response: e.target.value })
    }
    return (
        <div className="flex w-full my-2 py-2" key={question.questionId + question.sectionId}>
            <p className="font-bold self-center w-6/12"> {question.statement}</p>
            <div className="w-1/12"></div>
            <section className={"flex-1 self-center"}>
                <input className="rounded w-full p-2 border" type='number' placeholder="1000" onChange={(e) => handleNumberInput(e)} value={qa.response} />
            </section>
        </div>
    )
}


export default NumbQA


