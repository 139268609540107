import { action, makeObservable, observable } from "mobx";
import { LogService } from "../services/LogService";
import { SiteActivityType } from "../types/site";

export class ActivityLogStore {
    items: SiteActivityType[] = [];

    constructor(private logService: LogService) {
        makeObservable(this, {
            items: observable,
            setLogs:action
        });

        this.initialize();
        this.loadAllLogs()
    }

    setLogs(items: SiteActivityType[]) {
        this.items = items;
    }

    async initialize() { }

    async loadAllLogs() {
        const logs = await this.logService.fetchAllLogs();
        this.setLogs(logs)
    }
}
