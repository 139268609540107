
import { StaffCountTagEnum, StaffMemberType } from "../../common/types/site";
import { CellFillWriter, CellWriter, toTitleCase } from "../utility";

const docx = require("docx");
const { Table, TableRow, } = docx;


type staffCountSummary = {
    description?: string
    count?: string
    vacancy?: string
}

export const SummarizeCount = (counts: StaffMemberType[]): staffCountSummary[] => {
    var result: staffCountSummary[] = []
    counts = counts ||[]
    counts.forEach(element => {
        let hired = 0
        let vacancy = 0
        if (element.tag ===StaffCountTagEnum.HIRED) {
            hired += element.count || 0
        }
        if (element.tag ===StaffCountTagEnum.TO_HIRE) {
            vacancy += element.count || 0
        }
        let targetIndex = result.findIndex(e => e.description === element.jobDescription)
        if (targetIndex !==-1) {
            result[targetIndex].count = (Number(result[targetIndex].count) + hired).toString()
            result[targetIndex].vacancy = (Number(result[targetIndex].vacancy) + vacancy).toString()
        } else {
            result.push(
                {
                    count: hired.toString(),
                    description: element.jobDescription,
                    vacancy: vacancy.toString()
                }
            )
        }
    });
    return result
}

export const _3_1_StaffCountTable = (counts: staffCountSummary[]) => new Table({
    rows: [
        new TableRow({
            children: [
                CellFillWriter("Roles"),
                CellFillWriter("Current"),
                CellFillWriter("Number of Vacancies"),

            ],
        })].concat(
            counts.map(count => new TableRow({
                children: [
                    CellWriter(toTitleCase(count.description!.replace('_', ' '))),
                    CellWriter(count.count),
                    CellWriter(count.vacancy)
                ]
            }))
        )
})
