import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { useStore } from '../../../../../store/StoreProvider';
import Questionnaire from '../../../../Questionnaire'
import AddNewLogistics from './AddLogistics';
import LogisticsPartnerList from './LogisticsPartnerList';

function Index() {

    const [open, setOpen] = useState(false)
    const { siteQuestioningStore: questionStore } = useStore();

    return (
        <div className="sm:px-20 sm:py-10 px-10 py-5">
            <h6 className="p-1 px-3 -mx-2 bg-gray-100 uppercase text-atomGreen">Biosample Logistics</h6>
            <LogisticsPartnerList />
            <div className="p-5 justify-center flex">
                <button onClick={() => setOpen(!open)} className="border-2 border-atomGreen px-5 py-3 rounded-lg bg-green-50">Add Logistics Partner</button>
            </div>
            <Questionnaire subId={questionStore!.selectedSection!.sectionId + "_2"} showTitle={true} />
            <Questionnaire subId={questionStore!.selectedSection!.sectionId + "_3"} showTitle={true} />
            <Questionnaire subId={questionStore!.selectedSection!.sectionId + "_4"} showTitle={true} />
            <Questionnaire subId={questionStore!.selectedSection!.sectionId + "_5"} showTitle={true} />
            <Questionnaire subId={questionStore!.selectedSection!.sectionId + "_6"} showTitle={true} />
            <AddNewLogistics open={open} setOpen={setOpen} />
        </div>
    )
}
export default observer(Index)