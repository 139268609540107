
import { TODAY_MONTH, TODAY_YEAR } from "../utility";

const docx = require("docx");
const { Paragraph, TextRun } = docx;

export const CoverPage = (description: any) => new Paragraph({
    children: [
        new TextRun({ text: "\n", break: 2 }),

        new TextRun({
            text: "eMQT Investigator Site Assessment Report", font: "Arial", size: 70,

        }),
        new TextRun({ text: "\n", break: 2 }),
        new TextRun({
            text: `${description.leadInvestigatorName}, ${description.department}, ${description.institutionName}, ${description.location}, ${description.country}`, font: "Arial", size: 45,
        }),
        new TextRun({ text: "\n", break: 2 }),

        new TextRun({
            text: `Dated: `, font: "Arial", size: 42,
        }),
        new TextRun({
            text: `${TODAY_MONTH}, ${TODAY_YEAR}`, font: "Arial", size: 43,
        }),
        new TextRun({ text: "\n", break: 2 }),
        new TextRun({ text: "\n", break: 2 }),
        new TextRun({ text: "\n", break: 2 }),

        new TextRun({
            text: `based on information received as of the date given:`, font: "Arial", size: 22,
        }),
    ],
})
