import { RootStore } from "../../../store/types/rootstore";
import { LogService } from "../services/LogService";
import { ActivityLogStore } from "./ActivityLogStore";


export type ActivityStoreTypes = {
  logsListSore?: ActivityLogStore;
};

export function injectLogsIntoRoot(root: RootStore) {
  const service = new LogService();
  root.logsListSore = new ActivityLogStore(service);
}
