import { QuestionResponseType } from "./question";

export type SiteObject = {
  description?: SiteGeneralDescription;
  accessToStaff?: AtS;
  responses?: QuestionResponseType[];
  results?: {};
  lps?: LogisticsPartner[]
  logs?: SiteActivityType[]
  meta?:SiteMetaProps;
};

export type SiteMetaProps = {
  dateCreated?: string
  dateApproved?: string
  dateEntry?: string
  dateReview?: string
  lastUpdated?: string
  createdBy?: string
  approvedBy?: string
  lastReviewBy?: string
  status?: EntryStatusEnum;
  rating?: EntryRatingEnum;
}

export type LogisticsPartner = {
  regId?: string;
  name?: string;
  country?: string;
  location?: string;
  primaryContact?: string;
  cvLink?: string;
  role?: string;
  address?: string;
  generalNumber?: string;
  otherNumber?: string;
  emailAddress?: string;
  acceptAll?: boolean;
  acceptDetails?: string;
}


export type SiteActivityType = {
  id: string;
  title?: string;
  user?: string;
  detail?: string;
  timeline?: string;
}

export enum EntryRatingEnum {
  RED = "RED",
  AMBER = "AMBER",
  GREEN = "GREEN",
}

export enum EntryStatusEnum {
  ENTRY = "DATA ENTRY",
  REVIEW = "IN REVIEW",
  VALID = "VALIDATED",
  ARCHIVED = "ARCHIVED"
}

export type SiteGeneralDescription = {
  institutionName?: string;
  siteId: string;
  location?: string;
  department?: string;
  address?: string;
  leadInvestigatorName: string;
  phoneNumber?: string;
  emailAddress?: string;
  otherPhones?: string;
  leadInvestigatorCvLink?: string;
  country?: string
  ccode?: string
};

type AtS = {
  tms?: TeamMemberType[];
  access?: Array<QuestionResponseType>;
  staffs?: StaffMemberType[]
};

export type TeamMemberType = {
  regId: string;
  name: string;
  mLineCode?: string;
  mobileNumber?: string;
  emailAddress?: string;
  faxNumber?: string;
  directLine?: string;
  otherRr?: string;
  psd?: string[];
  gcpTrained?: boolean;
  gcpDate?: string;
  gcpCompany?: string;
  edcTrained?: boolean;
  edcDate?: string;
  edcCertLink?: string;
  gcpCertLink?: string;
  hasNetwork?: boolean;
  otherPSDs?: TeamMemberSpecialtyEnum[] | string[];
  dLineCode?: string;
  cvLink?: string;
  roles?: TeamMemberRoleEnum[];
  specialties?: TeamMemberSpecialtyEnum[] | string[];
  specialtyNetwork?: string;
  edcTrainingReceived?: string[];
  edcSystemExperience?: string[];
  responsibilities?: string[]
};

export type StaffMemberType = {
  jobDescription?: string | StaffJdEnum;
  count?: number;
  tag: StaffCountTagEnum
}

export enum TeamMemberRoleEnum {
  LEAD_INVESTIGATOR = "LEAD_INVESTIGATOR",
  CO_INVESTIGATOR = "CO_INVESTIGATOR",
  STUDY_NURSE = "STUDY_NURSE",
  DATA_MANAGER = "DATA_MANAGER",
  STUDY_CO_ORDINATOR = "STUDY_CO_ORDINATOR",
  CLINICAL_TRIAL_PHARMACIST = "CLINICAL_TRIAL_PHARMACIST",
  LABORATORY_SCIENTIST = "LABORATORY_SCIENTIST",
  OTHERS = "OTHERS"
}
export enum TeamMemberSpecialtyEnum {
  CHD = "CHD",
  HEARTH_FAILURE = "HEARTH_FAILURE",
  STROKE = "STROKE",
  ACUTE_CORONARY_SYNDROME = "ACUTE_CORONARY_SYNDROME",
  HYPERLIPIDEAMIA = "HYPERLIPIDEAMIA",
  ATHEROSCLEROSIS = "ATHEROSCLEROSIS",
  PULMONARY_HYPERTENSION = "PULMONARY_HYPERTENSION"
}

export enum StaffJdEnum {
  Co_Investigators = "Co_Investigators",
  Study_Nurses = "Study_Nurses",
  Study_Coordinator = "Study_Co-ordinator",
  Clinical_Trial_Pharmacist = "Clinical_Trial_Pharmacist",
  STUDY_NURSE = "STUDY_NURSE",
  DATA_MANAGER = "DATA_MANAGER",
  LABORATORY_SCIENTIST = "LABORATORY_SCIENTIST",
}

export enum StaffCountTagEnum {
  TO_HIRE = "TO_HIRE",
  HIRED = "HIRED",
}



export const MemberRoles: TeamMemberRoleEnum[] = [TeamMemberRoleEnum.LEAD_INVESTIGATOR, TeamMemberRoleEnum.CO_INVESTIGATOR, TeamMemberRoleEnum.STUDY_NURSE, TeamMemberRoleEnum.DATA_MANAGER, TeamMemberRoleEnum.LABORATORY_SCIENTIST, TeamMemberRoleEnum.STUDY_CO_ORDINATOR, TeamMemberRoleEnum.CLINICAL_TRIAL_PHARMACIST, TeamMemberRoleEnum.OTHERS]
export const MemberSpecialties: TeamMemberSpecialtyEnum[] = [TeamMemberSpecialtyEnum.CHD, TeamMemberSpecialtyEnum.HEARTH_FAILURE, TeamMemberSpecialtyEnum.STROKE, TeamMemberSpecialtyEnum.ACUTE_CORONARY_SYNDROME, TeamMemberSpecialtyEnum.HYPERLIPIDEAMIA, TeamMemberSpecialtyEnum.ATHEROSCLEROSIS, TeamMemberSpecialtyEnum.PULMONARY_HYPERTENSION]
export const StaffJds: StaffJdEnum[] = [StaffJdEnum.Co_Investigators, StaffJdEnum.Study_Coordinator, StaffJdEnum.Clinical_Trial_Pharmacist, StaffJdEnum.STUDY_NURSE, StaffJdEnum.DATA_MANAGER, StaffJdEnum.LABORATORY_SCIENTIST]