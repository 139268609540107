import { getDownloadURL, listAll, ref, StorageReference, uploadBytesResumable } from 'firebase/storage'
import React, { useEffect, useState } from 'react'
import { setupFireStore } from '../../../../firebase/firebase.config'
import { FileData } from '../../../common/types/file'

function Index() {
    const { FirebaseStorage: storage } = setupFireStore()
    const [wikis, setWikis] = useState<FileData[]>([])
    const [progress, setProgress] = useState(0)

    useEffect(() => {
        const wikiRef = ref(storage, `wiki`);
        getWikiFiles(wikiRef)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function getWikiFiles(ref: StorageReference) {
        let result: FileData[] = []
        await listAll(ref)
            .then((res) => {
                res.items.forEach((itemRef) => {
                    result.push({ name: itemRef.name, path: itemRef.fullPath })
                });
                setWikis(result)
            }).catch((error) => {
                console.log(error)

            });
        setWikis(result)
    }

    function download(path?: string) {
        if (!path)
            return
        getDownloadURL(ref(storage, path))
            .then((url) => {
                // This can be downloaded directly:
                const xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.onload = (event) => {
                    //  const blob = xhr.response;
                };
                xhr.open('GET', url);
                xhr.send();
                window.open(url, '_blank');

            })
            .catch((error) => {
                console.log(error)
                // handle any errors
            });
    }

    function handleUpload(e: React.ChangeEvent<HTMLInputElement>): void {
        const files = e.target.files
        if (!files)
            return;
        const storageRef = ref(storage, `wiki/${files[0]!.name}`);
        const uploadTask = uploadBytesResumable(storageRef, files[0]!);

        uploadTask.on("state_changed",
            (snapshot) => {
                const p = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setProgress(p);
            },
            (error) => {
                alert(error);
            },
            () => {
                const wikiRef = ref(storage, `wiki`);
                getWikiFiles(wikiRef).then(() => {
                    window.location.reload();
                })
            }
        );
    }

    return (
        <div className="relative overflow-x-auto sm:rounded-lg p-10 w-full">
            <table className="w-full text-sm text-left text-gray-500 ">
                <thead className="text-black uppercase bg-gray-50 font-bold">
                    <tr>
                        <th scope="col" className="px-6 py-3">File Name</th>
                        <th scope="col" className="px-2 py-3 text-right"><input type='file' onChange={(e) => handleUpload(e)} title={"file"} /></th>
                    </tr>
                    {(progress !== 0 && progress !== 100) && <tr>  <div className='innerbar px-6 self-center' style={{ width: `${progress}%` }}>{progress}%</div> </tr>}
                </thead>
                <tbody>
                    {wikis.map(wiki => <tr className="bg-white border-b hover:bg-gray-50">
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">{wiki.name}</th>
                        <td className="px-6 py-4 text-right">
                            <p onClick={() => { download(wiki.path) }} className="font-medium text-atomGreen cursor-pointer">Download</p>
                        </td>
                    </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}

export default Index