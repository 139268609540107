import React from 'react'
import { SiteObject } from '../../../common/types/site'

type listProps = {
    sites: SiteObject[]
}

function SiteTimeLapsList({ sites }: listProps) {
    return (

        <div className="w-full overflow-x-scroll xl:overflow-x-hidden p-5 z-20 space-y-1">
            <div className="flex flex-col">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="overflow-x-auto">
                            <table className="min-w-full">
                                <thead className="border-b">
                                    <tr>

                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            Site
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            Moved To Entry
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            Moved to review
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            Date approved
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sites?.map(site => {
                                        return (
                                            <SiteItem site={site} />
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SiteTimeLapsList

type siteItemProp = {
    site: SiteObject
}
const SiteItem = ({ site }: siteItemProp) => {
    return (
        <tr className='border-b'>
            <td className="text-xs text-gray-900 font-light px-6 py-2 whitespace-nowrap">
                <p> {site.description?.institutionName}</p>

            </td>
            <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">
                {site.meta?.dateEntry}
            </td>
            <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">
                {site.meta?.dateReview}
            </td>
            <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">
                {site.meta?.dateApproved}
            </td>
        </tr>
    )
}
