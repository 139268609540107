import { action, makeObservable, observable } from "mobx";
import { QuestionType, QuestionTagEnum } from "../../common/types/question";
import { AdminService } from "../services/AdminService";

export class NewQuestionStore {

  newQuestion: QuestionType = this.init(QuestionTagEnum.MainQuestion, "")
  isLoading: boolean = false
  constructor(private adminServices: AdminService) {
    makeObservable(this, {
      newQuestion: observable,
      isLoading: observable,
      setQuestion: action.bound,
      updateFollowUps: action.bound,
      setIsLoading: action.bound
    });

  //  this.initialize();
  }

  async initialize() { }

  setQuestion(item: QuestionType) {
    console.log(item);
    this.newQuestion = item;
  }

  updateFollowUps(meta: string, index: number, q: QuestionType) {
    this.newQuestion.followUps![index] = q;
  }

  setIsLoading(flag: boolean) {
    this.isLoading = flag
  }

  private isValidQuestion(q: QuestionType): boolean {
    let result = false;
    return result;
  }

  init(meta: QuestionTagEnum, section: string): QuestionType {
    let q: QuestionType = { questionId: genId(), meta: meta, sectionId: section };
    return q;
  }

  async saveQuestion(question: QuestionType) {
    const res = await this.adminServices.addNewQuestion(question)
    return res;
  }

  async saveQuestionToSub(formId: string, question: QuestionType) {
    const section = await this.adminServices.getSection(formId)
    const subs = section.subs
    let sub = subs!.find(({ id }) => id === question.sectionId);
    const questions = sub?.questions || []
    const updateQuestion = [...questions, question]
    sub!.questions = updateQuestion
    const res = await this.adminServices.addNewQuestionToSub(formId, subs!)
    return res;
  }



}

export function genId(): string {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
}
